import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import WnaFileInfo from "@domain/contracts/types/WnaFileInfo";
import WnaChapter from "@domain/entities/WnaChapter";
import WnaChapterDao from "@infrastructure/dao/WnaChapterDao";
import { i18nKeysCommon, i18nKeysDialog } from "@infrastructure/i18n/i18nKeys";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import { minifyImageAsync } from "@infrastructure/services/images/WnaImageService";
import WnaButtonIconText from "@ui/components/buttons/WnaButtonIconText";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaCardActivityIndicator from "@ui/components/cards/WnaCardActivityIndicator";
import WnaImage from "@ui/components/images/WnaImage";
import WnaImageBackground from "@ui/components/images/WnaImageBackground";
import WnaRefreshNotifier from "@ui/events/refresh/WnaRefreshNotifier";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import * as DocumentPicker from "expo-document-picker";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, View } from "react-native";
import { useModal } from "react-native-modalfy";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

export type WnaChapterEditViewProps = {
    currentChapter: WnaChapter;
    canDelete: boolean;
    isVisible: boolean;
    onUpdate: (updatedChapter: WnaChapter) => void;
    parentProps: WnaStackScreenProps;
    closeSideBar: () => void;
};

export default function WnaChapterEditView(props: WnaChapterEditViewProps) {
    const {
        currentUser,
        currentAdminUser,
        appSettings,
        currentAppTheme,
        currentAppStyle,
        isInternetReachable,
    } = useContext(WnaAppContext);

    const { openModal } = useModal();

    const [isBusy, setIsBusy] = useState(false);
    const isBusyRef = useRef(isBusy);

    const [isBusyText, setIsBusyText] = useState("");

    const [currentDesignator, setCurrentDesignator] = useState<string>(
        props.currentChapter.designator
    );
    const currentDesignatorRef = useRef(currentDesignator);
    const designatorInputRef = useRef<TextInput>(null);

    const [currentFileInfo, setCurrentFileInfo] = useState<WnaFileInfo>({
        url: props.currentChapter.imageUrl,
    } as WnaFileInfo);
    const currentFileInfoRef = useRef(currentFileInfo);

    const [isDirty, setIsDirty] = useState(false);
    const isDirtyRef = useRef(false);
    const dirtyItemRef = useRef<WnaChapter>(
        new WnaChapter(props.currentChapter)
    );

    const { t } = useTranslation(["common", "dialog"]);

    if (currentUser == null || currentUser.id == "") return null;

    const onUpdate = () => {
        const updatedItem = new WnaChapter(dirtyItemRef.current);
        updatedItem.designator = currentDesignatorRef.current;
        updatedItem.imageUrl = currentFileInfoRef.current.url;
        dirtyItemRef.current = updatedItem;
        setIsDirty(true);
        isDirtyRef.current = true;
    };

    const onSaveAsync = async () => {
        if (isBusyRef.current) return;

        try {
            WnaLogger.start(WnaChapterEditView.name, onSaveAsync.name);
            if (isInternetReachable == false) {
                WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
                return;
            }

            setIsBusy(true);
            isBusyRef.current = true;

            const dirtyChapter = new WnaChapter(dirtyItemRef.current);
            if (
                currentFileInfoRef.current.url !=
                    props.currentChapter.imageUrl &&
                appSettings != null
            ) {
                const miniBlob = await minifyImageAsync(
                    currentFileInfoRef.current.url,
                    appSettings.imageMaxWidth,
                    appSettings.imageMaxHeight,
                    appSettings.imageQuality
                );

                const resultChapter = await WnaChapterDao.uploadImageAsync(
                    currentUser,
                    props.currentChapter,
                    currentFileInfoRef.current.fileName,
                    miniBlob
                );

                if (resultChapter == null) {
                    setIsBusy(false);
                    isBusyRef.current = false;
                    return;
                }

                const resultFi = {
                    url: resultChapter?.imageUrl ?? "",
                    width: appSettings.imageMaxWidth,
                    height: appSettings.imageMaxHeight,
                } as WnaFileInfo;

                currentFileInfoRef.current = resultFi;
                dirtyChapter.imageUrl = resultFi.url;
            }

            const resultChapter = await WnaChapterDao.updateAsync(
                currentUser,
                dirtyChapter
            );
            if (resultChapter == null) {
                setIsBusy(false);
                isBusyRef.current = false;
                return;
            }

            props.onUpdate(resultChapter);

            setIsDirty(false);
            isDirtyRef.current = false;
            setIsBusy(false);
            isBusyRef.current = false;
            dirtyItemRef.current = dirtyChapter;
            const refreshParameter = new WnaRefreshParam(
                WnaApplicationConstants.ViewNameChapterEdit,
                ""
            );
            refreshParameter.action = WnaRefreshAction.update;
            refreshParameter.context = resultChapter;
            WnaRefreshNotifier.notify({ refreshParameter: refreshParameter });
        } catch (error) {
            WnaLogger.error(WnaChapterEditView.name, onSaveAsync.name, error);
        } finally {
            WnaLogger.end(WnaChapterEditView.name, onSaveAsync.name);
        }
    };

    const onUpdateImageAsync = async () => {
        if (isBusyRef.current) return;

        try {
            WnaLogger.start(WnaChapterEditView.name, onUpdateImageAsync.name);

            if (isInternetReachable == false) {
                WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
                return;
            }

            if (appSettings == null) return;

            const result = await DocumentPicker.getDocumentAsync({
                copyToCacheDirectory: true,
                type: "image/*",
                multiple: false,
            });
            if (result.assets == null || result.assets.length < 1) return;

            for (let i = 0; i < result.assets.length; i++) {
                const fileItem = result.assets[i];
                if (fileItem == null) continue;

                const fileInfo = {
                    url: fileItem.uri,
                    urlTh256: fileItem.uri,
                    urlTh512: fileItem.uri,
                    fileName: fileItem.name,
                } as WnaFileInfo;

                setCurrentFileInfo(fileInfo);
                currentFileInfoRef.current = fileInfo;

                onUpdate();
            }
        } catch (error) {
            WnaLogger.error(
                WnaChapterEditView.name,
                onUpdateImageAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaChapterEditView.name, onUpdateImageAsync.name);
        }
    };

    const onDeleteAsync = async () => {
        if (isBusyRef.current) return;

        try {
            WnaLogger.start(WnaChapterEditView.name, onDeleteAsync.name);

            setIsBusy(true);
            isBusyRef.current = true;

            const goAhead = await WnaDialogProvider.showYesNoAsync(
                t(i18nKeysDialog.questionDelete)
            );
            if (!goAhead) {
                setIsBusy(false);
                isBusyRef.current = false;
                setIsBusyText("");
                return;
            }

            const deleteResult = await WnaChapterDao.deleteAsync(
                currentUser,
                props.currentChapter
            );

            setIsBusy(false);
            isBusyRef.current = false;
            setIsBusyText("");
            if (deleteResult == null) throw t(i18nKeysCommon.errorDelete);

            const refreshParameter = new WnaRefreshParam(
                WnaApplicationConstants.ViewNameChapterEdit,
                ""
            );
            refreshParameter.action = WnaRefreshAction.delete;
            refreshParameter.context = deleteResult;
            WnaRefreshNotifier.notify({ refreshParameter: refreshParameter });

            props.onUpdate(deleteResult);

            WnaToastProvider.showSuccess(t(i18nKeysCommon.successDelete));
        } catch (error) {
            WnaLogger.error(WnaChapterEditView.name, onDeleteAsync.name, error);
            WnaToastProvider.showError(t(i18nKeysCommon.errorDelete));
        } finally {
            WnaLogger.end(WnaChapterEditView.name, onDeleteAsync.name);
        }
    };

    const printToPdfAsync = async () => {
        if (appSettings == null) return;

        props.closeSideBar();
        props.parentProps.navigation.push(
            WnaApplicationConstants.ScreenNameDiaryPreview,
            {
                currentChapter: props.currentChapter,
            }
        );
    };

    return props.isVisible ? (
        <WnaImageBackground image={currentFileInfo.url} blurRadius={16}>
            <View
                style={{
                    backgroundColor: convertHexToRgba(
                        currentAppTheme.colors.coolgray2,
                        0.8
                    ),
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                {isBusy ? (
                    <View style={currentAppStyle.containerCenterCenter}>
                        <WnaCardActivityIndicator
                            isBusyText={isBusyText}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                        />
                    </View>
                ) : (
                    <View
                        style={{
                            flex: 1,
                            alignItems: "flex-start",
                        }}>
                        {/* designator */}
                        <View style={{ height: 64, width: 256 }}>
                            <TextInput
                                ref={designatorInputRef}
                                style={[
                                    currentAppStyle.textInput,
                                    {
                                        position: "absolute",
                                        width: "auto",
                                        top: 8,
                                        left: 8,
                                        right: 8,
                                        backgroundColor:
                                            currentAppTheme.colors.white,
                                        color: currentAppTheme.colors.black,
                                    },
                                ]}
                                selectionColor={currentAppTheme.colors.accent1}
                                value={currentDesignator}
                                onChangeText={(text) => {
                                    setCurrentDesignator(text);
                                    currentDesignatorRef.current = text;
                                    onUpdate();
                                }}
                                placeholder={t(
                                    i18nKeysCommon.placeholderChapter
                                )}
                                maxLength={1024}
                                editable={true}
                                placeholderTextColor={
                                    currentAppTheme.colors.coolgray3
                                }
                            />
                        </View>
                        {/* image */}
                        <View style={{ height: 144, width: 256 }}>
                            <WnaPressable
                                style={{
                                    borderRadius: 8,
                                    position: "absolute",
                                    left: 8,
                                    right: 8,
                                    backgroundColor:
                                        currentAppTheme.colors.coolgray2,
                                }}
                                onPress={() => {
                                    onUpdateImageAsync();
                                }}
                                t={t}
                                checkInternetConnection={true}>
                                <WnaImage
                                    currentAppTheme={currentAppTheme}
                                    imageUrl={currentFileInfo.url}
                                    style={{
                                        width: "100%",
                                        height: 144,
                                        backgroundColor:
                                            currentAppTheme.colors.coolgray2,
                                    }}
                                />
                            </WnaPressable>
                        </View>

                        {/* print - only for admin visible */}
                        {currentAdminUser?.identifier ? (
                            <View
                                style={{
                                    height: 64,
                                    width: 256,
                                    marginVertical: 16,
                                }}>
                                <WnaButtonIconText
                                    iconName={"file-pdf"}
                                    style={{
                                        position: "absolute",
                                        left: 8,
                                        right: 8,
                                    }}
                                    text={t(i18nKeysCommon.actionPrint)}
                                    currentAppTheme={currentAppTheme}
                                    onPress={() => {
                                        printToPdfAsync();
                                    }}
                                    t={t}
                                    checkInternetConnection={true}
                                />
                            </View>
                        ) : null}

                        {/* delete */}
                        {props.canDelete ? (
                            <View
                                style={{
                                    height: 64,
                                    width: 256,
                                    marginVertical: 16,
                                }}>
                                <WnaButtonIconText
                                    iconName={"trash"}
                                    style={{
                                        position: "absolute",
                                        left: 8,
                                        right: 8,
                                    }}
                                    text={t(i18nKeysCommon.actionDelete)}
                                    currentAppTheme={currentAppTheme}
                                    onPress={onDeleteAsync}
                                    t={t}
                                    checkInternetConnection={true}
                                />
                            </View>
                        ) : null}
                        {isDirty ? (
                            <View
                                style={{
                                    height: 64,
                                    width: 256,
                                    marginVertical: 16,
                                    position: "absolute",
                                    bottom: 0,
                                }}>
                                <WnaButtonIconText
                                    iconName={"check"}
                                    style={{
                                        position: "absolute",
                                        left: 8,
                                        right: 8,
                                    }}
                                    text={t(i18nKeysCommon.actionSave)}
                                    currentAppTheme={currentAppTheme}
                                    onPress={onSaveAsync}
                                    t={t}
                                    checkInternetConnection={true}
                                />
                            </View>
                        ) : null}
                    </View>
                )}
            </View>
        </WnaImageBackground>
    ) : null;
}
