import WnaChapter from "@domain/entities/WnaChapter";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaUser from "@domain/entities/WnaUser";
import { initFormRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const uploadDiaryEntryImageAsync = async (
    wnaUser: WnaUser,
    chapter: WnaChapter,
    diaryEntryIdentifier: string,
    fileName: string,
    isFavorite: boolean,
    file: Blob
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/${chapter.identifier}/diaryEntries/${diaryEntryIdentifier}/uploadImage/${fileName}/${isFavorite ?? false}`;
    const request = initFormRequest();
    request.body = new FormData();
    request.body.append("file", file);
    const response = await handleResponseAsync(await fetch(url, request));
    return new WnaDiaryEntry(await response.json());
};

export default uploadDiaryEntryImageAsync;
