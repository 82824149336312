import WnaNavigationHeaderOptions from "@app/WnaNavigationHeaderOptions";
import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";
import WnaMenuScreen from "@app/screens/menu/WnaMenuScreen";
import WnaAdminScreen from "@app/screens/menu/admin/AdminScreen";
import WnaDisclaimerScreen from "@app/screens/menu/disclaimer/WnaDisclaimerScreen";
import WnaErrorLogScreen from "@app/screens/menu/errorlog/ErrorLogScreen";
import WnaPrivacyScreen from "@app/screens/menu/privacy/WnaPrivacyScreen";
import WnaProfileScreen from "@app/screens/menu/profile/WnaProfileScreen";
import WnaSettingsAdvancedScreen from "@app/screens/menu/settings/WnaSettingsAdvancedScreen";
import WnaSettingsDiaryScreen from "@app/screens/menu/settings/WnaSettingsDiaryScreen";
import WnaSettingsMapScreen from "@app/screens/menu/settings/WnaSettingsMapScreen";
import WnaSettingsScreen from "@app/screens/menu/settings/WnaSettingsScreen";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { WnaNavigationStackProps } from "@navigation/WnaNavigationStackProps";
import WnaNavigatorScreenOptions from "@navigation/WnaNavigatorScreenoptions";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";

const Stack = createStackNavigator<WnaNavigationStackPropsMap>();

const WnaMenuStack: React.FC<WnaNavigationStackProps> = (props) => {
    const { t } = useTranslation(["common"]);
    return (
        <Stack.Navigator
            initialRouteName={WnaApplicationConstants.ScreenNameMenu}
            screenOptions={WnaNavigatorScreenOptions}>
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameMenu}
                component={WnaMenuScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleMenu)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameProfile}
                component={WnaProfileScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleProfile)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNamePrivacy}
                component={WnaPrivacyScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitlePrivacy)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameAdmin}
                component={WnaAdminScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleAdministration)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameDisclaimer}
                component={WnaDisclaimerScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleDisclaimer)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameSettings}
                component={WnaSettingsScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleSettings)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameSettingsDiary}
                component={WnaSettingsDiaryScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleSettingsDiary)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameSettingsMap}
                component={WnaSettingsMapScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleSettingsMap)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameSettingsAdvanced}
                component={WnaSettingsAdvancedScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleSettingsAdvanced)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameErrorLog}
                component={WnaErrorLogScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenErrorLog)
                )}
            />
        </Stack.Navigator>
    );
};
export default WnaMenuStack;
