import WnaAppointment from "@domain/entities/WnaAppointment";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const deleteAppointmentAsync = async (appointment: WnaAppointment) => {
    const url = `${WnaApiSettings.apiBaseUrl}/admin/appointments/delete`;
    const request = initJsonRequest("DELETE");
    request.body = JSON.stringify(appointment);
    const response = await handleResponseAsync(await fetch(url, request));
    return new WnaAppointment(await response.json());
};

export default deleteAppointmentAsync;
