import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

let _jwt = "";
let _userId = "";
let _idToken: string = "";

const setJwt = (userId: string, jwt: string) => {
    _jwt = jwt;
    _userId = userId;
};

const initJsonRequest = (
    method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH"
) => {
    return {
        headers: {
            ApiKey: WnaApiSettings.apiKey,
            Authorization: `Bearer ${_jwt}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        method: method,
        mode: "cors",
    } as RequestInit;
};

const initPdfRequest = () => {
    return {
        headers: {
            ApiKey: WnaApiSettings.apiKey,
            Authorization: `Bearer ${_jwt}`,
            "Content-Type": "application/pdf",
            Accept: "application/pdf",
        },
        method: "GET",
        mode: "cors",
    } as RequestInit;
};

const initFormRequest = () => {
    return {
        headers: {
            ApiKey: WnaApiSettings.apiKey,
            Authorization: `Bearer ${_jwt}`,
            // "Content-Type": "multipart/form-data",
            // "Content-Type": "undefined",
            Accept: "application/json",
        },
        method: "POST",
        mode: "cors",
    } as RequestInit;
};
export { initFormRequest, initJsonRequest, initPdfRequest, setJwt };
