import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaNavigationItem from "@ui/components/navigation/WnaNavigationItem";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaSettingsScreen: React.FC<WnaStackScreenProps> = (props) => {
    const { currentAppTheme, currentAppStyle } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [
        {
            screen: WnaApplicationConstants.ScreenNameSettingsDiary,
            text: t(i18nKeysCommon.wordDiary),
            icon: "book-open",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameSettingsMap,
            text: t(i18nKeysCommon.wordMap),
            icon: "map",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameSettingsAdvanced,
            text: t(i18nKeysCommon.wordAdvanced),
            icon: "cogs",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
    ];

    const handleClick = function (text: string) {
        try {
            WnaLogger.start(WnaSettingsScreen.name, handleClick.name, text);
            const currentItem = items.find((x) => x.text === text)!;
            if (currentItem.screen == "") return;

            // navigate
            props.navigation.push(currentItem.screen, {
                currentItem,
            });
        } catch (error) {
            WnaLogger.error(WnaSettingsScreen.name, handleClick.name, error);
        } finally {
            WnaLogger.end(WnaSettingsScreen.name, handleClick.name, text);
        }
    };

    const renderItem = (currentItem: any) => {
        return (
            <WnaNavigationItem
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                text={currentItem.text}
                iconName={currentItem.icon}
                onPress={handleClick}
                t={t}
            />
        );
    };

    return (
        <WnaBaseScreenWithInfo>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaSettingsScreen;
