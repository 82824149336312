import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import { WnaNotificationDetailsScreenNavigationProps } from "@app/screens/notification/details/WnaNotificationDetailsScreenNavigationProps";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import WnaNotification from "@domain/entities/WnaNotification";
import WnaNotificationDao from "@infrastructure/dao/WnaNotificationDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import { i18nKeysCommon, i18nKeysDialog } from "@infrastructure/i18n/i18nKeys";
import { dateToStringDateTimeWeekday } from "@infrastructure/services/WnaDateTimeService";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaMultilineHeader from "@ui/components/tabbar/WnaMultilineHeader";
import WnaRefreshNotifier from "@ui/events/refresh/WnaRefreshNotifier";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TextInput, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import WnaLogger from "wna-logger";

const WnaNotificationDetailsScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        isInternetReachable,
        currentUser,
        appSettings,
        currentAppTheme,
        currentAppStyle,
        unreadNotificationCount,
        setUnreadNotificationCount,
    } = useContext(WnaAppContext);
    if (currentUser == null || appSettings == null) return null;

    const { t } = useTranslation(["common", "dialog"]);
    const navigationProps = props.route
        .params as WnaNotificationDetailsScreenNavigationProps;
    const currentItem = navigationProps.currentItem ?? new WnaNotification();
    const canEdit =
        new Date(currentItem.dateCreate).toISOString() ==
            getUnixMinDate().toISOString() &&
        WnaUserDao.isAdmin(currentUser, appSettings) &&
        isInternetReachable === true;
    const [currentDesignator, setCurrentDesignator] = useState(
        currentItem.designator
    );
    const [currentComment, setCurrentComment] = useState(currentItem.comment);
    const [isBusy, setIsBusy] = useState(false);
    const refreshParameter = new WnaRefreshParam(
        props.route.name,
        props.route.path
    );
    const designatorInputRef = useRef<TextInput>(null);
    const commentInputRef = useRef<TextInput>(null);

    const markAsReadAsync = async () => {
        if (
            currentItem.identifier.length > 0 &&
            new Date(currentItem.dateRead).toISOString() <=
                getUnixMinDate().toISOString()
        ) {
            WnaLogger.info(
                WnaNotificationDetailsScreen.name,
                markAsReadAsync.name
            );
            const resultItem =
                await WnaNotificationDao.markNotificationAsReadAsync(
                    currentUser,
                    currentItem,
                    true
                );
            refreshParameter.action = WnaRefreshAction.update;
            refreshParameter.context = resultItem;
            WnaRefreshNotifier.notify({ refreshParameter });
            if (unreadNotificationCount != null && unreadNotificationCount > 0)
                setUnreadNotificationCount(unreadNotificationCount - 1);
            else setUnreadNotificationCount(null);
        }
    };

    const onSaveAsync = async () => {
        currentItem.designator = currentDesignator;
        currentItem.comment = currentComment;

        if (currentDesignator.length < 1 || currentComment.length < 1) {
            WnaToastProvider.showError(t(i18nKeysCommon.errorInputInvalid));
            return;
        }

        const onDialogResult = async () => {
            setIsBusy(true);
            let notification = new WnaNotification();
            notification.designator = currentDesignator;
            notification.comment = currentComment;
            notification =
                await WnaNotificationDao.sendNotificationToAllUsersAsync(
                    notification
                );
            setCurrentDesignator("");
            setCurrentComment("");
            refreshParameter.action = WnaRefreshAction.create;
            refreshParameter.context = notification;
            WnaRefreshNotifier.notify({ refreshParameter: refreshParameter });
            setIsBusy(false);

            if (unreadNotificationCount != null && unreadNotificationCount > 0)
                setUnreadNotificationCount(unreadNotificationCount + 1);
            else setUnreadNotificationCount(1);

            props.navigation.goBack();
        };

        WnaDialogProvider.showYesNo(
            t(i18nKeysDialog.questionSend),
            onDialogResult
        );
    };

    useEffect(() => {
        if (
            navigationProps.currentItem != null &&
            navigationProps.currentItem.identifier != ""
        ) {
            const title = dateToStringDateTimeWeekday(
                navigationProps.currentItem.dateCreate
            );
            props.navigation.setOptions({
                headerTitle: () => {
                    return WnaMultilineHeader(
                        currentAppStyle,
                        currentAppTheme,
                        title
                    );
                },
            });
            markAsReadAsync();
        } else {
            setTimeout(() => designatorInputRef.current?.focus(), 100);
        }
    }, [props]);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            {canEdit ? (
                <View
                    style={[
                        currentAppStyle.containerFillPage,
                        { gap: 16, padding: 16 },
                    ]}>
                    <View style={{ alignSelf: "stretch" }}>
                        <TextInput
                            ref={designatorInputRef}
                            style={[
                                currentAppStyle.textInput,
                                {
                                    width: "100%",
                                    backgroundColor:
                                        currentAppTheme.colors.white,
                                    color: currentAppTheme.colors.black,
                                },
                            ]}
                            selectionColor={currentAppTheme.colors.accent4}
                            value={currentDesignator}
                            onChangeText={setCurrentDesignator}
                            onSubmitEditing={() => {
                                commentInputRef.current?.focus();
                            }}
                            placeholder="Titel"
                            placeholderTextColor={
                                currentAppTheme.colors.coolgray3
                            }
                        />
                    </View>
                    <View style={{ flex: 1, alignSelf: "stretch" }}>
                        <TextInput
                            ref={commentInputRef}
                            style={[
                                currentAppStyle.textInput,
                                {
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 64,
                                    width: "auto",
                                    verticalAlign: "top",
                                    color: currentAppTheme.colors.black,
                                },
                            ]}
                            selectionColor={currentAppTheme.colors.accent1}
                            value={currentComment}
                            onChangeText={(text) => {
                                setCurrentComment(text);
                            }}
                            placeholder="Text"
                            multiline
                            placeholderTextColor={
                                currentAppTheme.colors.coolgray3
                            }
                            maxLength={1024}
                        />
                    </View>
                </View>
            ) : (
                <ScrollView
                    style={[
                        currentAppStyle.containerCenterMaxWidth,
                        { padding: 16 },
                    ]}>
                    <View
                        style={{
                            backgroundColor: currentAppTheme.colors.white,
                            padding: 16,
                        }}>
                        <View
                            style={{ alignSelf: "stretch", paddingBottom: 0 }}>
                            <Text
                                style={[
                                    currentAppStyle.textTitleLarge,
                                    {
                                        width: "100%",
                                        color: currentAppTheme.colors.black,
                                    },
                                ]}>
                                {currentDesignator}
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "stretch",
                            }}>
                            <Text
                                style={[
                                    currentAppStyle.textSmall,
                                    {
                                        height: "100%",
                                        width: "100%",
                                        color: currentAppTheme.colors.black,
                                    },
                                ]}>
                                {currentComment}
                            </Text>
                        </View>
                    </View>
                </ScrollView>
            )}

            {canEdit ? (
                <WnaButtonIcon
                    toolTip={t(i18nKeysCommon.actionSend)}
                    toolTipPosition="left"
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    iconName="bullhorn"
                    style={{ position: "absolute", bottom: 16, right: 16 }}
                    onPress={async () => {
                        await onSaveAsync();
                    }}
                    t={t}
                    checkInternetConnection={true}
                />
            ) : null}
        </WnaBaseScreenWithInfo>
    );
};

export default WnaNotificationDetailsScreen;
