import { WnaAppContext } from "@app/WnaAppContext";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaButtonIconDecent from "@ui/components/buttons/WnaButtonIconDecent";
import WnaButtonTextDecent from "@ui/components/buttons/WnaButtonTextDecent";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, StyleSheet, Text, View } from "react-native";
import WnaDialogProvider from "./WnaDialogProvider";

export default function WnaDialog() {
    const { currentAppTheme, currentAppStyle } =
        React.useContext(WnaAppContext);
    const { t } = useTranslation(["dialog"]);
    const [isVisible, setIsVisible] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    useEffect(() => {
        WnaDialogProvider.init(setIsVisible, setShowCancel);
    }, []);
    return (
        <Modal
            transparent
            statusBarTranslucent
            visible={isVisible}
            onRequestClose={() => WnaDialogProvider.onCancel}
            animationType="fade">
            <View
                style={[
                    currentAppStyle.containerCenterCenter,
                    { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                ]}>
                <View
                    style={[
                        componentStyle(currentAppTheme).dialogIcon,
                        { backgroundColor: currentAppTheme.colors.accent4 },
                    ]}>
                    {showCancel ? (
                        <FontAwesome5
                            name="question"
                            size={32}
                            color={currentAppTheme.colors.staticWhite}
                        />
                    ) : (
                        <FontAwesome5
                            name="info"
                            size={32}
                            color={currentAppTheme.colors.staticWhite}
                        />
                    )}
                </View>
                <View
                    style={[
                        WnaShadowStyle(),
                        currentAppStyle.containerCenter,
                        componentStyle(currentAppTheme).mainWrapper,
                    ]}>
                    <View
                        style={
                            componentStyle(currentAppTheme).dialogTextContainer
                        }>
                        <Text
                            style={[
                                currentAppStyle.textSmall,
                                {
                                    fontSize: 20,
                                    color: currentAppTheme.colors.black,
                                },
                            ]}>
                            {WnaDialogProvider.text}
                        </Text>
                    </View>
                    <View
                        style={componentStyle(currentAppTheme).buttonContainer}>
                        {showCancel ? (
                            <>
                                <WnaButtonTextDecent
                                    text={t("answerNo")}
                                    onPress={WnaDialogProvider.onCancel}
                                    t={t}
                                    checkInternetConnection={false}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                    style={[
                                        componentStyle(currentAppTheme).button,
                                        {
                                            borderRightColor:
                                                currentAppTheme.colors
                                                    .coolgray2,
                                            borderRightWidth: 1,
                                        },
                                    ]}
                                />
                                <WnaButtonTextDecent
                                    text={t("answerYes")}
                                    onPress={WnaDialogProvider.onOk}
                                    t={t}
                                    checkInternetConnection={false}
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                    style={[
                                        componentStyle(currentAppTheme).button,
                                    ]}
                                />
                            </>
                        ) : (
                            <WnaButtonIconDecent
                                iconName="check"
                                onPress={WnaDialogProvider.onOk}
                                t={t}
                                checkInternetConnection={false}
                                style={[componentStyle(currentAppTheme).button]}
                            />
                        )}
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const componentStyle = (theme: WnaTheme) =>
    StyleSheet.create({
        mainWrapper: {
            backgroundColor: theme.colors.white,
            borderRadius: 16,
            maxHeight: 256,
            overflow: "hidden",
            margin: 32,
        },
        dialogIcon: {
            width: 64,
            height: 64,
            borderRadius: 32,
            borderWidth: 0,
            marginBottom: -64,
            zIndex: 3, // works on ios
            elevation: 3, // works on android
            alignItems: "center",
            padding: 16,
        },
        dialogTextContainer: {
            alignItems: "center",
            gap: 16,
            marginTop: 56,
            marginBottom: 32,
            marginHorizontal: 24,
        },
        buttonContainer: {
            flex: 1,
            flexDirection: "row",
            alignContent: "center",
            height: 44,
            maxHeight: 44,
            width: "100%",
            borderTopColor: theme.colors.coolgray2,
            borderTopWidth: 1,
        },
        button: {
            width: "50%", // is 50% of container width
            margin: 0,
            height: 44,
        },
    });
