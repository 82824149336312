import WnaNotification from "@domain/entities/WnaNotification";
import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns count of unread notifications
 * @param wnaUser
 * @param identifier
 * @param markAsRead
 */
const markNotificationAsReadAsync = async (
    wnaUser: WnaUser,
    identifier: string,
    markAsRead: boolean
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/notifications/${identifier}/markAsRead/${markAsRead}`;
    const request = initJsonRequest("PATCH");
    const response = await handleResponseAsync(await fetch(url, request));
    return (await response.json()) as WnaNotification;
};

export default markNotificationAsReadAsync;
