import { WnaAppContext } from "@app/WnaAppContext";
import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaMapsInfos from "@ui/components/maps/WnaMapsInfos";
import { WnaDataSourceItem } from "@ui/components/misc/WnaPicker";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

export type WnaMapInfoModalProps = {
    modal: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closeModal;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getParam;
    };
};

const WnaMapInfoModal: React.FC<WnaMapInfoModalProps> = (props) => {
    const {
        currentWindowWidth,
        currentWindowHeight,
        currentAppStyle,
        currentAppTheme,
        setIsStatusBarVisible,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const graphData = props.modal.getParam("graphData", new WnaMapsGraphData());
    const isEditMode = props.modal.getParam("isEditMode", false) as boolean;
    const routes = props.modal.getParam(
        "routes",
        new Array<WnaDataSourceItem>()
    );
    const onSelectGeoDataFileAsync = props.modal.getParam(
        "onSelectGeoDataFileAsync",
        async () => {}
    );
    const onSelectRouteFromDropDownAsync = props.modal.getParam(
        "onSelectRouteFromDropDownAsync",
        async (selectedValue: any) => {}
    );
    const onCancel = props.modal.closeModal as () => void;
    const onClose = () => {
        setIsStatusBarVisible(true);
        onCancel();
    };
    const internalOnSelectGeoDataFileAsync = async () => {
        await onSelectGeoDataFileAsync();
        onClose();
    };
    const internalOnSelectRouteFromDropDownAsync = async (
        selectedValue: any
    ) => {
        await onSelectRouteFromDropDownAsync(selectedValue);
        onClose();
    };

    useEffect(() => {
        setIsStatusBarVisible(false);
    }, []);

    return (
        <View
            style={{
                backgroundColor: currentAppTheme.colors.coolgray2,
                width: currentWindowWidth,
                height: currentWindowHeight,
            }}>
            <ScrollView style={{ paddingTop: 64, paddingBottom: 16 }}>
                <WnaMapsInfos
                    graphData={graphData}
                    routes={routes}
                    paneWidth={currentWindowWidth}
                    onSelectGeoDataFileAsync={internalOnSelectGeoDataFileAsync}
                    onSelectRouteFromDropDownAsync={
                        internalOnSelectRouteFromDropDownAsync
                    }
                    isEditMode={isEditMode}
                />
            </ScrollView>
            <WnaButtonIcon
                toolTip=""
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                iconName="times"
                onPress={onClose}
                style={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                }}
                t={t}
                checkInternetConnection={false}
            />
        </View>
    );
};
export default WnaMapInfoModal;
