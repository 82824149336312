import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { getLangCode } from "@infrastructure/i18n/i18n";
import WnaWebView from "@ui/components/misc/WnaWebView";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import React, { useContext, useEffect, useState } from "react";

const WnaAccommodationScreen: React.FC<WnaStackScreenProps> = () => {
    const { currentAppTheme } = useContext(WnaAppContext);
    const [, setIsBusy] = useState(true);
    const [html, setHtml] = useState("");

    const style = `
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Courgette"
    />
    <link rel="stylesheet" 
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/solid.min.css" 
        integrity="sha512-Hp+WwK4QdKZk9/W0ViDvLunYjFrGJmNDt6sCflZNkjgvNq9mY+0tMbd6tWMiAlcf1OQyqL4gn2rYp7UsfssZPA==" 
        crossorigin="anonymous" 
        referrerpolicy="no-referrer" /> 
    <style>
      h1{
        font-family: Courgette, sans-serif;
        font-size: x-large;    
        text-align: center;  
      }
      h2 {
        text-align: center;
        font-size: large;    
        text-transform: uppercase;
      }
      a {
        color: ${currentAppTheme.colors.black};
      }
      .wrapper{
        margin: 0.5em;
      }
      .block {
        font-family: "Roboto", "Arial", sans-serif;
        font-size: 10pt;
        line-height: 16pt;
        color: ${currentAppTheme.colors.coolgray6};
        max-width: 960px;
        background-color: ${currentAppTheme.colors.white};
        padding: 1em;
        margin: auto;
        margin-top: 1em !important;
        margin-bottom: 1em !important;
      }
    </style>`;

    useEffect(() => {
        const document =
            getLangCode() === "en"
                ? "day-schedule-en.html"
                : "day-schedule-de.html";
        WnaAppSettingsDao.readResourceAsync(document).then((html) => {
            const utf8decoded = require("utf8").decode(html) as string;
            const styledUtf8decoded = utf8decoded.replace("$STYLE", style);
            setHtml(styledUtf8decoded);
            setIsBusy(false);
        });
    }, []);
    return (
        <WnaBaseScreenWithInfo isBusy={false}>
            <WnaWebView urlOrHtml={html} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaAccommodationScreen;
