import WnaNavigationHeaderOptions from "@app/WnaNavigationHeaderOptions";
import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";
import WnaNotificationDetailsScreen from "@app/screens/notification/details/WnaNotificationDetailsScreen";
import WnaNotificationListScreen from "@app/screens/notification/list/WnaNotificationListScreen";
import WnaStartPageScreen from "@app/screens/startPage/WnaStartPageScreen";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { WnaNavigationStackProps } from "@navigation/WnaNavigationStackProps";
import WnaNavigatorScreenOptions from "@navigation/WnaNavigatorScreenoptions";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";

const Stack = createStackNavigator<WnaNavigationStackPropsMap>();
const WnaStartPageStack: React.FC<WnaNavigationStackProps> = (props) => {
    const { t } = useTranslation(["common"]);

    return (
        <Stack.Navigator screenOptions={WnaNavigatorScreenOptions}>
            <Stack.Screen
                name={
                    WnaApplicationConstants.ScreenNameStartPage as keyof WnaNavigationStackPropsMap
                }
                component={WnaStartPageScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleStartPage)
                )}
            />
            <Stack.Screen
                name={
                    WnaApplicationConstants.ScreenNameNotificationList as keyof WnaNavigationStackPropsMap
                }
                component={WnaNotificationListScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleNotificationList)
                )}
            />
            <Stack.Screen
                name={
                    WnaApplicationConstants.ScreenNameNotificationDetails as keyof WnaNavigationStackPropsMap
                }
                component={WnaNotificationDetailsScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleNotificationDetails)
                )}
            />
        </Stack.Navigator>
    );
};
export default WnaStartPageStack;
