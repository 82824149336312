import WnaChapter from "@domain/entities/WnaChapter";
import WnaUser from "@domain/entities/WnaUser";
import { initFormRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const uploadChapterImageAsync = async (
    wnaUser: WnaUser,
    identifier: string,
    fileName: string,
    file: Blob
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/uploadImage?identifier=${identifier}&fileName=${fileName}`;
    const request = initFormRequest();
    request.body = new FormData();
    request.body.append("file", file);
    const response = await handleResponseAsync(await fetch(url, request));
    return new WnaChapter(await response.json());
};

export default uploadChapterImageAsync;
