import WnaUser from "@domain/entities/WnaUser";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns list of users
 */
const getAllUsersAsync = async () => {
    const url = `${WnaApiSettings.apiBaseUrl}/admin/users`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 10000,
            abortHandler: (err) => {
                return new Response(JSON.stringify([] as Array<WnaUser>));
            },
        })
    );
    return (await response.json()) as Array<WnaUser>;
};

export default getAllUsersAsync;
