import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaImage from "@ui/components/images/WnaImage";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import { Dimensions, Text, View } from "react-native";

export type WnaListCardWhiteImageProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    children?: React.ReactNode;
    color?: string | null;
    headerTitle?: string | null;
    imageUrl?: string | null;
    tinyImageUrl?: string | null;
    hasRoute?: boolean | null;
    hasImages?: boolean | null;
};

const _borderRadius = 8;

class WnaListCardWhiteImage extends Component<WnaListCardWhiteImageProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaListCardWhiteImageProps>
    ): boolean {
        return (
            nextProps.children != this.props.children ||
            nextProps.color != this.props.color ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.hasImages != this.props.hasImages ||
            nextProps.hasRoute != this.props.hasRoute ||
            nextProps.headerTitle != this.props.headerTitle ||
            nextProps.imageUrl != this.props.imageUrl ||
            nextProps.tinyImageUrl != this.props.tinyImageUrl
        );
    }

    render() {
        // WnaLogger.info(WnaListCardWhiteImage.name, this.render.name, this.props.imageUrl);
        // let color = this.props.color ?? "transparent";
        // if (color != "transparent" && this.props.currentAppTheme.dark)
        //     color = WnaColorHelper.convertHexToRgba(color, 0.7);
        const windowWidth = Dimensions.get("screen").width;
        const windowHeight = Dimensions.get("screen").height;
        const isLandscape = windowWidth > windowHeight;
        const imageHeight = isLandscape ? windowWidth / 4 : windowHeight / 4;
        const imageMaxSize = 512;
        const tinyImageSize = isLandscape ? windowWidth / 8 : windowHeight / 8;
        const tinyImageMaxSize = 256;
        const tinyImageMaxWidth = 256;
        return (
            <LinearGradient
                colors={[
                    this.props.color != null &&
                    this.props.color != "" &&
                    this.props.color != "transparent"
                        ? this.props.color
                        : this.props.currentAppTheme.colors.coolgray2,
                    this.props.currentAppTheme.colors.coolgray2,
                    this.props.currentAppTheme.colors.coolgray2,
                    this.props.color != null &&
                    this.props.color != "" &&
                    this.props.color != "transparent"
                        ? this.props.color
                        : this.props.currentAppTheme.colors.coolgray2,
                ]}
                start={[0, 0]}
                end={[1, 1]}
                locations={[0, 0.3, 0.7, 1]}
                pointerEvents="none"
                style={[{ padding: 1, borderRadius: _borderRadius }]}>
                <View
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor:
                            this.props.currentAppTheme.colors.white,
                        borderRadius: _borderRadius,
                        overflow: "hidden",
                    }}>
                    <View
                        style={{
                            width: "100%",
                            backgroundColor:
                                this.props.currentAppTheme.colors.white,
                        }}>
                        {(this.props.headerTitle != null &&
                            this.props.headerTitle != "") ||
                        this.props.hasImages === true ||
                        this.props.hasRoute === true ? (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}>
                                {this.props.hasImages === true ||
                                this.props.hasRoute === true ? (
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            alignSelf: "flex-start",
                                            alignItems: "center",
                                            alignContent: "center",
                                            gap: 12,
                                            padding: 8,
                                        }}>
                                        {this.props.hasImages === true ? (
                                            <FontAwesome5
                                                size={16}
                                                name={"images"}
                                                color={
                                                    this.props.currentAppTheme
                                                        .colors.coolgray6
                                                }
                                            />
                                        ) : null}
                                        {this.props.hasRoute === true ? (
                                            <FontAwesome5
                                                size={16}
                                                name={"map"}
                                                color={
                                                    this.props.currentAppTheme
                                                        .colors.coolgray6
                                                }
                                            />
                                        ) : null}
                                    </View>
                                ) : null}
                                {this.props.headerTitle == null ||
                                this.props.headerTitle == "" ? null : (
                                    <Text
                                        style={[
                                            this.props.currentAppStyle
                                                .textSmall,
                                            {
                                                alignSelf: "flex-end",
                                                color: this.props
                                                    .currentAppTheme.colors
                                                    .accent4,
                                                textAlign: "right",
                                                padding: 8,
                                            },
                                        ]}>
                                        {this.props.headerTitle}
                                    </Text>
                                )}
                            </View>
                        ) : null}
                        <View>
                            <WnaImage
                                currentAppTheme={this.props.currentAppTheme}
                                style={{
                                    height: imageHeight,
                                    maxHeight: imageMaxSize,
                                    backgroundColor:
                                        this.props.currentAppTheme.colors
                                            .coolgray2,
                                }}
                                imageUrl={this.props.imageUrl ?? ""}
                            />
                            {(this.props.tinyImageUrl ?? "") != "" ? (
                                <View
                                    style={[
                                        WnaShadowStyle(),
                                        {
                                            height: tinyImageSize,
                                            width: tinyImageSize,
                                            maxHeight: tinyImageMaxSize,
                                            maxWidth: tinyImageMaxWidth,
                                            position: "absolute",
                                            bottom: 8,
                                            right: 8,
                                            borderWidth: 1,
                                            borderColor:
                                                this.props.currentAppTheme
                                                    .colors.staticCoolgray6,
                                            borderRadius: 16,
                                            overflow: "hidden",
                                        },
                                    ]}>
                                    <WnaImage
                                        currentAppTheme={
                                            this.props.currentAppTheme
                                        }
                                        style={{
                                            height: tinyImageSize,
                                            width: tinyImageSize,
                                            maxHeight: tinyImageMaxSize,
                                            maxWidth: tinyImageMaxSize,
                                            backgroundColor:
                                                this.props.currentAppTheme
                                                    .colors.coolgray3,
                                        }}
                                        imageUrl={this.props.tinyImageUrl ?? ""}
                                    />
                                </View>
                            ) : null}
                        </View>
                        {this.props.children == null ? null : (
                            <View
                                style={{
                                    padding: 24,
                                    backgroundColor:
                                        this.props.currentAppTheme.colors.white,
                                }}>
                                {this.props.children}
                            </View>
                        )}
                    </View>
                </View>
            </LinearGradient>
        );
    }
}

export default WnaListCardWhiteImage;
