import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { WnaStaticColors } from "@infrastructure/services/WnaThemeService";

export const WnaCatalogGraphTolerance: Array<WnaCatalogItem> = [
    {
        id: 0,
        text: "0 m",
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 1,
        text: "2 m",
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 2,
        text: "5 m",
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
    {
        id: 3,
        text: "10 m",
        displayColor: "transparent",
        selectionColor: WnaStaticColors.staticCoolgray6,
    },
];
