import WnaNotification from "@domain/entities/WnaNotification";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns notification
 * @param notification
 */
const sendNotificationToAllUsersAsync = async (
    notification: WnaNotification
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/admin/notifications/sendToAllUsers`;
    const request = initJsonRequest("POST");
    request.body = JSON.stringify(notification);
    const response = await fetch(url, request);
    return await response.json();
};

export default sendNotificationToAllUsersAsync;
