import {
    Messaging,
    getToken,
    isSupported,
    onMessage,
} from "firebase/messaging";
import { Platform } from "react-native";

import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";
import WnaLogger from "wna-logger";
import WnaNotificationDao from "../dao/WnaNotificationDao";
import WnaFirebase from "../firebase/WnaFirebase";

const storageVarName = "@currentFcmToken";

const registerForPushNotificationsAsync = async () => {
    WnaLogger.start(
        WnaFirebaseFcm.name,
        registerForPushNotificationsAsync.name
    );
    let token = "";

    WnaLogger.end(WnaFirebaseFcm.name, registerForPushNotificationsAsync.name);
    return token;
};

export default class WnaFirebaseFcm {
    private static messagingSw: Messaging;

    public static async getCurrentFcmTokenAsync() {
        return (
            (await WnaAsyncStorageProvider.getItemAsync(
                storageVarName,
                true,
                false
            )) ?? ""
        );
    }

    public static async setCurrentFcmTokenAsync(fcmToken: string) {
        return await WnaAsyncStorageProvider.setItemAsync(
            storageVarName,
            fcmToken,
            true,
            false
        );
    }

    public static init(
        notificationListener: any,
        notificationResponseListener: any,
        onNotificationReceived: (title: string, body: string) => void
    ) {
        if (Platform.OS !== "web") return;

        WnaLogger.start(WnaFirebaseFcm.name, WnaFirebaseFcm.init.name);

        Notification.requestPermission().then(async (permission) => {
            if (permission === "granted") {
                this.messagingSw = getMessaging(WnaFirebase.FirebaseApp);

                const isFcmSupported = await isSupported();
                console.warn("isFcmSupported: " + isFcmSupported);

                getToken(this.messagingSw, {
                    vapidKey:
                        "BNJ7y-J3jQuuGnMrjKx82bLSy-UW4d-8XgjAWBbQDT0qn9UclB6f4yGLTDKZq_7v870wQ__C075mxZ7FA_Ll5rg",
                })
                    .then(async (currentToken) => {
                        if (currentToken) {
                            WnaLogger.warn("fcmToken: " + currentToken);
                            await WnaFirebaseFcm.setCurrentFcmTokenAsync(
                                currentToken
                            );
                        } else {
                            // Show permission request UI
                            console.log(
                                "No registration token available. Request permission to generate one."
                            );
                            // ...
                        }
                    })
                    .catch((err) => {
                        console.log(
                            "An error occurred while retrieving token. ",
                            err
                        );
                        // ...
                    });

                onMessage(this.messagingSw, (remoteMessage) => {
                    debugger;
                    WnaLogger.warn(
                        "A new FCM message arrived!",
                        JSON.stringify(remoteMessage)
                    );
                    WnaLogger.start(WnaFirebaseFcm.name, "onMessage");
                    if (remoteMessage.notification) {
                        WnaNotificationDao.resetIsSynced();
                        onNotificationReceived(
                            remoteMessage.notification.title ?? "",
                            remoteMessage.notification.body ?? ""
                        );
                    } else {
                        WnaLogger.error(
                            WnaFirebaseFcm.name,
                            "onMessage",
                            "remoteMessage.notification was undefined"
                        );
                    }
                });
            }
        });

        WnaLogger.end(WnaFirebaseFcm.name, WnaFirebaseFcm.init.name);
    }
}
