import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaStartPageScreenNavigationProps } from "@app/screens/startPage/WnaStartPageScreenNavigationProps";
import { useIsFocused } from "@react-navigation/native";
import WnaWebView from "@ui/components/misc/WnaWebView";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaNotificationHeaderRight from "@ui/components/tabbar/WnaNotificationHeaderRight";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaStartPageScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        appSettings,
        currentAppTheme,
        currentAppStyle,
        currentUser,
        unreadNotificationCount,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const isFocused = useIsFocused();
    const [isBusy, setIsBusy] = useState(true);
    const currentUrlRef = useRef<string>("");
    const navigateToNotificationListRef = useRef<string | null>(null);

    useEffect(() => {
        WnaLogger.start(WnaStartPageScreen.name, useEffect.name);
        props.navigation.setOptions({
            headerRight: () => (
                <WnaNotificationHeaderRight
                    unreadNotificationCount={unreadNotificationCount}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    currentUser={currentUser}
                    t={t}
                />
            ),
        });

        try {
            if (props.route.params != undefined) {
                const navParam = props.route
                    .params as WnaStartPageScreenNavigationProps;
                const pNavigateToNotificationList =
                    navParam.navigateToNotifications ?? null;
                if (
                    pNavigateToNotificationList != null &&
                    pNavigateToNotificationList !=
                        navigateToNotificationListRef.current
                ) {
                    navigateToNotificationListRef.current =
                        pNavigateToNotificationList;
                    setTimeout(() => {
                        props.navigation.push(
                            WnaApplicationConstants.ScreenNameNotificationList,
                            {}
                        );
                    }, 300);
                }
            }
        } catch (error) {
            // ignore
        }

        if (
            appSettings != null &&
            appSettings.startPageDarkUrl != null &&
            appSettings.startPageDarkUrl != "" &&
            appSettings.startPageLightUrl != null &&
            appSettings.startPageLightUrl != ""
        ) {
            const url = currentAppTheme.dark
                ? appSettings!.startPageDarkUrl
                : appSettings!.startPageLightUrl;
            if (currentUrlRef.current != url) currentUrlRef.current = url;
        }

        WnaLogger.end(WnaStartPageScreen.name, useEffect.name);
    }, [
        props,
        isFocused,
        currentAppTheme,
        currentUser,
        unreadNotificationCount,
    ]);
    return (
        <WnaBaseScreenWithInfo isBusy={false}>
            <>
                <WnaWebView
                    urlOrHtml={currentUrlRef.current}
                    onLoaded={() => {
                        setIsBusy(false);
                    }}
                />
                {isBusy ? (
                    <View
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            flex: 1,
                            justifyContent: "center",
                        }}>
                        <Image
                            style={{
                                width: 256,
                                height: 256,
                                alignSelf: "center",
                            }}
                            source={require("../../../../assets/adaptive-icon-2.png")}
                        />
                    </View>
                ) : null}
            </>
        </WnaBaseScreenWithInfo>
    );
};
export default WnaStartPageScreen;
