import WnaLogger from "wna-logger";

const handleResponseAsync = async (response: Response) => {
    switch (response.status) {
        case 200:
            return response;
        case 401:
            // todo reauthenticate
            WnaLogger.error(`${response.status} - ${response.url}`);
            return response;
        default:
            WnaLogger.error(`${response.status} - ${response.url}`);
            return response;
    }
};

export default handleResponseAsync;
