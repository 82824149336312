import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { Text, View } from "react-native";

export default function WnaMultilineHeader(
    currentAppStyle: WnaAppStyle,
    currentAppTheme: WnaTheme,
    fullTitle: string,
    title?: string | null,
    subtitle?: string | null
) {
    if (title != null && title != "" && subtitle != null && subtitle != "")
        return (
            <View
                style={
                    {
                        // backgroundColor: "green"
                    }
                }>
                <Text
                    style={[
                        currentAppStyle.textSmall,
                        {
                            color: currentAppTheme.colors.staticWhite,
                        },
                    ]}>
                    {title.trim()}
                </Text>

                <Text
                    style={[
                        currentAppStyle.textSmall,
                        {
                            color: currentAppTheme.colors.staticWhite,
                        },
                    ]}>
                    {subtitle.trim()}
                </Text>
            </View>
        );
    else
        return (
            <Text
                style={[
                    currentAppStyle.textTitleLarge,
                    {
                        color: currentAppTheme.colors.staticWhite,
                        fontSize: 20,
                    },
                ]}>
                {fullTitle}
            </Text>
        );
}
