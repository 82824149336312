export enum WnaRefreshAction {
    none,
    create,
    read,
    update,
    delete,
}

export default class WnaRefreshParam {
    public action: WnaRefreshAction = WnaRefreshAction.none;
    public context: any;
    public sender: string;
    public target: any;

    constructor(sender: string, target: any) {
        this.action = WnaRefreshAction.none;
        this.context = null;
        this.sender = sender;
        this.target = target;
    }
}
