import WnaDataViewFastingEdit from "@app/screens/diary/diaryEntry/detail/dataView/WnaDataViewFastingEdit";
import WnaDataViewReadOnly from "@app/screens/diary/diaryEntry/detail/dataView/WnaDataViewReadOnly";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaDiaryEntryPlaceholders } from "@domain/contracts/types/WnaDiaryEntryPlaceholder";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import React, { Component } from "react";

export type WnaDataViewProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    currentItem: WnaDiaryEntry;
    onUpdate: (item: WnaDiaryEntry, isDirty: boolean) => void;
    onCommentTyping: (isTyping: boolean) => void;
    isEditMode: boolean;
    placeholders: WnaDiaryEntryPlaceholders;
};

class WnaDataView extends Component<WnaDataViewProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaDataViewProps>): boolean {
        return (
            nextProps.currentItem.designator !=
                this.props.currentItem.designator ||
            nextProps.currentItem.identifier !=
                this.props.currentItem.identifier ||
            nextProps.currentItem.comment != this.props.currentItem.comment ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.isEditMode != this.props.isEditMode
        );
    }

    render() {
        return this.props.isEditMode ? (
            <WnaDataViewFastingEdit
                currentItem={this.props.currentItem}
                onUpdate={this.props.onUpdate}
                onCommentTyping={this.props.onCommentTyping}
                currentAppStyle={this.props.currentAppStyle}
                currentAppTheme={this.props.currentAppTheme}
                placeholders={this.props.placeholders}
            />
        ) : (
            <WnaDataViewReadOnly
                currentItem={this.props.currentItem}
                currentAppStyle={this.props.currentAppStyle}
                currentAppTheme={this.props.currentAppTheme}
            />
        );
    }
}

export default WnaDataView;
