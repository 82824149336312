import WnaUser from "@domain/entities/WnaUser";
import { User as FirebaseUser } from "@firebase/auth";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import * as firebaseAuthReact from "firebase/auth";
import { AccessToken, LoginManager } from "react-native-fbsdk-next";
import WnaLogger from "wna-logger";
import WnaUserDao from "../../dao/WnaUserDao";
import WnaFirebase from "../../firebase/WnaFirebase";

const firebaseUserToWnaUserAsync = async (user: FirebaseUser) => {
    const wnaUser = new WnaUser();
    if (user == null) return wnaUser;

    const providerData = user.providerData[0];
    wnaUser.id = providerData.uid;
    wnaUser.identifier = providerData.uid;
    wnaUser.email = user.email ?? "";
    wnaUser.designator = user.displayName ?? "";
    wnaUser.picture = user.photoURL ?? "";
    wnaUser.pictureBase64 =
        await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
            user.photoURL ?? "",
            "png"
        );
    wnaUser.given_name = user.displayName ?? "";
    wnaUser.name = user.displayName ?? "";
    wnaUser.verified_email = user.emailVerified ?? false;
    wnaUser.refreshToken = user.refreshToken;
    wnaUser.idToken = await user.getIdToken();
    wnaUser.wnaApiJwt = await WnaUserDao.verifyIdTokenAsync(
        wnaUser.id,
        wnaUser.idToken
    );
    return await WnaUserDao.createOrUpdateAsync(wnaUser);
};

export default class WnaFacebookSignIn {
    public static async signInWithFacebookAsync() {
        try {
            WnaLogger.start(
                WnaFacebookSignIn.name,
                WnaFacebookSignIn.signInWithFacebookAsync.name
            );

            // Attempt login with permissions
            const result = await LoginManager.logInWithPermissions([
                "public_profile",
                "email",
            ]);
            if (result.isCancelled) {
                throw "User cancelled the login process";
            }

            // Once signed in, get the users AccessToken
            const data = await AccessToken.getCurrentAccessToken();
            if (!data) {
                throw "Something went wrong obtaining access token";
            }

            // Create a Firebase credential with the AccessToken
            const facebookAuthProvider =
                firebaseAuthReact.FacebookAuthProvider.credential(
                    data.accessToken
                );

            // Sign-in with credential from the Facebook user.
            const resp = await firebaseAuthReact.signInWithCredential(
                WnaFirebase.FirebaseAuth,
                facebookAuthProvider
            );
            const firebaseResponse = resp as firebaseAuthReact.UserCredential;
            return await firebaseUserToWnaUserAsync(firebaseResponse.user);
        } catch (error) {
            WnaLogger.error(
                WnaFacebookSignIn.name,
                WnaFacebookSignIn.signInWithFacebookAsync.name,
                JSON.stringify(error)
            );
            return new WnaUser();
        } finally {
            WnaLogger.end(
                WnaFacebookSignIn.name,
                WnaFacebookSignIn.signInWithFacebookAsync.name
            );
        }
    }
}
