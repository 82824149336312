import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import {
    initJsonRequest,
    setJwt,
} from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import WnaLogger from "wna-logger";

/**
 * returns jwt
 * @param userId
 * @param jwt
 */
const verifyJwtAsync = async (userId: string, jwt: string) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${userId}/verifyjwt?userId=${userId}&jwt=${jwt}`;
    WnaLogger.start(verifyJwtAsync.name, url);
    const request = initJsonRequest("POST");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 5000,
            abortHandler: (err) => {
                return new Response("");
            },
        })
    );

    let newJwt = "";
    if (response.ok) newJwt = await response.text();

    setJwt(userId, newJwt);
    WnaLogger.warn(`JWT: ${newJwt}`);
    WnaLogger.end(verifyJwtAsync.name, url);
    return newJwt;
};

export default verifyJwtAsync;
