import { Platform } from "react-native";

export function WnaShadowStyle() {
    const opacity = 0.3;
    const radius = 4.65;
    const elevation = 6;
    const offsetWidth = 0;
    const offsetHeight = 3;

    return Platform.select({
        ios: {
            shadowColor: "black",
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight,
            },
            elevation: elevation,
        },
        android: {
            shadowColor: "black",
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight,
            },
            elevation: elevation,
        },
        web: {
            shadowColor: "black",
            shadowOpacity: opacity,
            shadowRadius: radius,
            shadowOffset: {
                width: offsetWidth,
                height: offsetHeight,
            },
            elevation: elevation,
        },
    });
}
