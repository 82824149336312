import WnaToastProvider from "@app/toast/WnaToastProvider";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import IsAlive from "@infrastructure/wnaApi/user/queries/IsAliveAsync";
import { TFunction } from "i18next";

const execWhenOnlineAsync = async (
    checkInternetConnection: boolean,
    t: TFunction<string[], undefined>,
    exec: () => void
) => {
    if (checkInternetConnection)
        if (await IsAlive()) exec();
        else WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
    else exec();
};

export { execWhenOnlineAsync };
