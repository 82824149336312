import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { WnaStaticColors } from "./WnaStaticColors";

export const WnaDarkThemeColors = {
    dark: true,
    id: 2,
    colors: {
        primary: "transparent",
        accent: "transparent",
        background: "transparent",
        notification: "transparent",
        card: "transparent",
        text: "transparent",
        border: "transparent",

        primaryContainer: "transparent",
        secondary: "transparent",
        secondaryContainer: "transparent",
        tertiary: "transparent",
        tertiaryContainer: "transparent",
        surface: "transparent",
        surfaceVariant: "transparent",
        surfaceDisabled: "transparent",
        error: "transparent",
        errorContainer: "transparent",
        onPrimary: "transparent",
        onPrimaryContainer: "transparent",
        onSecondary: "transparent",
        onSecondaryContainer: "transparent",
        onTertiary: "transparent",
        onTertiaryContainer: "transparent",
        onSurface: "transparent",
        onSurfaceVariant: "transparent",
        onSurfaceDisabled: "transparent",
        onError: "transparent",
        onErrorContainer: "transparent",
        onBackground: "transparent",
        outline: "transparent",
        outlineVariant: "transparent",
        inverseSurface: "transparent",
        inverseOnSurface: "transparent",
        inversePrimary: "transparent",
        shadow: "transparent",
        scrim: "transparent",
        backdrop: "transparent",

        white: WnaStaticColors.staticCoolgray8,
        black: WnaStaticColors.staticWhite,

        coolgray1: WnaStaticColors.staticCoolgray8,
        coolgray2: WnaStaticColors.staticCoolgray7,
        coolgray3: WnaStaticColors.staticCoolgray6,
        coolgray4: WnaStaticColors.staticCoolgray5,
        coolgray5: WnaStaticColors.staticCoolgray4,
        coolgray6: WnaStaticColors.staticCoolgray3,
        coolgray7: WnaStaticColors.staticCoolgray2,
        coolgray8: WnaStaticColors.staticCoolgray1,

        warmgray1: WnaStaticColors.staticWarmgray8,
        warmgray2: WnaStaticColors.staticWarmgray7,
        warmgray3: WnaStaticColors.staticWarmgray6,
        warmgray4: WnaStaticColors.staticWarmgray5,
        warmgray5: WnaStaticColors.staticWarmgray4,
        warmgray6: WnaStaticColors.staticWarmgray3,
        warmgray7: WnaStaticColors.staticWarmgray2,
        warmgray8: WnaStaticColors.staticWarmgray1,

        accent1: WnaStaticColors.staticAccent1,
        accent2: WnaStaticColors.staticAccent2,
        accent3: WnaStaticColors.staticAccent3,
        accent4: WnaStaticColors.staticAccent4,
        accent5: WnaStaticColors.staticAccent5,
        accent6: WnaStaticColors.staticAccent6,
        accent7: WnaStaticColors.staticAccent7,
        accent8: WnaStaticColors.staticAccent8,

        ...WnaStaticColors,
    },
} as WnaTheme;
