import WnaDiaryEntryListItem from "@app/screens/diary/diaryEntry/list/WnaDiaryEntryListItem";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { addDays } from "@infrastructure/services/WnaDateTimeService";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import WnaButtonIconText from "@ui/components/buttons/WnaButtonIconText";
import WnaSeparatorHorizontal from "@ui/components/misc/WnaSeparatorHorizontal";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

export type WnaDiaryEntryListDummyProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    onLogIn: () => void;
    t: TFunction<string[], undefined>;
};

const _today = new Date();
const _yesterday = addDays(_today, -1);

const _dummy0 = {
    imageUrl: WnaAppSettingsDao.getImageResourceUrl("ph_003.jpg"),
    dateStart: _today,
    dateCreate: _today,
    dateUpdate: _today,
    designator: "",
    comment: "",
    images: [{}, {}],
    routeIdentifier: "dummy",
} as WnaDiaryEntry;

const _dummy1 = {
    imageUrl: WnaAppSettingsDao.getImageResourceUrl("ph_006.jpg"),
    dateStart: _yesterday,
    dateCreate: _yesterday,
    dateUpdate: _yesterday,
    designator: "",
    comment: "",
    images: [{}, {}],
    routeIdentifier: "Dummy",
} as WnaDiaryEntry;

class WnaDiaryEntryListDummy extends Component<WnaDiaryEntryListDummyProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDiaryEntryListDummyProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        _dummy0.designator = this.props.t(
            i18nKeysCommon.dummyDiaryEntry1Designator
        );
        _dummy0.comment = this.props.t(i18nKeysCommon.dummyDiaryEntry1Comment);
        _dummy1.designator = this.props.t(
            i18nKeysCommon.dummyDiaryEntry2Designator
        );
        _dummy1.comment = this.props.t(i18nKeysCommon.dummyDiaryEntry2Comment);
        return (
            <>
                <ScrollView
                    style={[
                        this.props.currentAppStyle.containerFillPage,
                        {
                            padding: 16,
                        },
                    ]}>
                    <WnaDiaryEntryListItem
                        currentAppStyle={this.props.currentAppStyle}
                        currentAppTheme={this.props.currentAppTheme}
                        key={"dummy0"}
                        currentItem={_dummy0}
                        onPress={() => {}}
                        t={this.props.t}
                    />

                    <View style={{ height: 8 }} />

                    <WnaDiaryEntryListItem
                        currentAppStyle={this.props.currentAppStyle}
                        currentAppTheme={this.props.currentAppTheme}
                        key={"dummy1"}
                        currentItem={_dummy1}
                        onPress={() => {}}
                        t={this.props.t}
                    />
                </ScrollView>
                <View
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: convertHexToRgba("#000000", 0.7),
                    }}>
                    <View
                        style={[
                            this.props.currentAppStyle.containerCenterCenter,
                            { marginHorizontal: 24 },
                        ]}>
                        <View
                            style={[
                                WnaShadowStyle(),
                                this.props.currentAppStyle.containerCenter,
                                {
                                    backgroundColor:
                                        this.props.currentAppTheme.colors.white,
                                    borderRadius: 16,
                                    paddingVertical: 32,
                                    paddingHorizontal: 24,
                                    maxWidth: 386,
                                },
                            ]}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    gap: 32,
                                    alignItems: "center",
                                }}>
                                <FontAwesome5
                                    name="google"
                                    size={24}
                                    color={
                                        this.props.currentAppTheme.colors
                                            .coolgray6
                                    }
                                />

                                <FontAwesome5
                                    name="facebook"
                                    size={30}
                                    color={"#3A4F83"}
                                />
                            </View>
                            <WnaSeparatorHorizontal />
                            <Text
                                style={[
                                    this.props.currentAppStyle.textTitleLarge,
                                    { textAlign: "center", marginVertical: 16 },
                                ]}>
                                {this.props.t(i18nKeysCommon.infoNeedToLogIn)}
                            </Text>
                            <WnaSeparatorHorizontal />
                            <WnaButtonIconText
                                iconName={"sign-in-alt"}
                                text={this.props.t(
                                    i18nKeysCommon.actionLogInForFree
                                )}
                                currentAppTheme={this.props.currentAppTheme}
                                onPress={() => {
                                    this.props.onLogIn();
                                }}
                                t={this.props.t}
                                checkInternetConnection={true}
                            />
                        </View>
                    </View>
                </View>
            </>
        );
    }
}

export default WnaDiaryEntryListDummy;
