import { WnaAppContext } from "@app/WnaAppContext";
import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaLogInProvider from "@app/services/WnaLogInPorvider";
import { WnaCatalogYesNo } from "@domain/constants/catalogs/WnaCatalogYesNo";
import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaListCardWhiteDecent from "@ui/components/cards/WnaListCardWhiteDecent";
import WnaQrCode from "@ui/components/misc/WnaQrCode";
import WnaNavigationItem from "@ui/components/navigation/WnaNavigationItem";
import WnaNavigationItemProfile from "@ui/components/navigation/WnaNavigationItemProfile";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaSettingsRadioButton from "@ui/components/settings/WnaSettingsRadioButton";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform, View } from "react-native";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaMenuScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        currentUser,
        currentAdminUser,
        appSettings,
        currentAppTheme,
        currentAppStyle,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [];

    // web fullscreen
    if (Platform.OS === "web") {
        // web
        items.push({
            screen: "",
            text: t(i18nKeysCommon.actionFullscreen),
            icon: "",
            rightIcon: "",
            type: "webToggleFullscreen",
            data: undefined,
        });
    }

    // user profile
    items.push({
        screen: "",
        text: "",
        icon: "",
        rightIcon: "",
        type: "userEdit",
        data: undefined,
    });

    // paypal
    if ((appSettings?.paypalUrl ?? "") != "")
        items.push({
            screen: "",
            text: t(i18nKeysCommon.actionDonate),
            icon: "paypal",
            rightIcon: "",
            type: "paypal",
            data: undefined,
        });

    // admin screen
    if (currentAdminUser != null && currentAdminUser.id != "") {
        items.push({
            screen: WnaApplicationConstants.ScreenNameAdmin,
            text: t(i18nKeysCommon.screenTitleAdministration),
            icon: "user-ninja",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        });
    }

    // settings
    items.push({
        screen: WnaApplicationConstants.ScreenNameSettings,
        text: t(i18nKeysCommon.screenTitleSettings),
        icon: "cog",
        rightIcon: "angle-right",
        type: "screen",
        data: undefined,
    });

    // // documents
    // if (appSettings?.documents != null) {
    //     appSettings.documents.forEach((doc) => {
    //         if (doc.isVisible)
    //             items.push({
    //                 screen: "DocumentScreen",
    //                 text: doc.designator,
    //                 icon: "file-pdf",
    //                 rightIcon:
    //                     Platform.OS !== "web" ? "download" : "angle-right",
    //                 type: "file",
    //                 data: doc
    //             });
    //     });
    // }

    // disclaimer and privacy
    items.push(
        {
            screen: WnaApplicationConstants.ScreenNamePrivacy,
            text: t("common:screenTitlePrivacy"),
            icon: "balance-scale",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: WnaApplicationConstants.ScreenNameDisclaimer,
            text: t("common:screenTitleDisclaimer"),
            icon: "info-circle",
            rightIcon: "angle-right",
            type: "screen",
            data: undefined,
        },
        {
            screen: "",
            text: "",
            icon: "",
            rightIcon: "",
            type: "qr",
            data: undefined,
        }
    );

    const onSelectWebFullScreen = async (currentItem: WnaCatalogItem) => {
        if (currentItem.id == 1)
            await document.documentElement.requestFullscreen();
        else await document.exitFullscreen();
    };

    const openPayPalAsync = async () => {
        const url = appSettings?.paypalUrl ?? "";
        if (url === "") return;

        await Linking.openURL(url);
    };

    const handleClick = function (text: string) {
        try {
            WnaLogger.start(WnaMenuScreen.name, handleClick.name, text);
            const currentItem = items.find((x) => x.text === text)!;
            if (currentItem.screen == "") {
                return;
                // } else if (
                //     Platform.OS !== "web" &&
                //     currentItem.screen == "DocumentScreen"
                // ) {
                //     // download
                //     const doc = currentItem.data as WnaDocument;
                //     WnaLogger.info(
                //         MenuScreen.name,
                //         handleClick.name,
                //         "download: " + doc.fileName
                //     );
                //     fetch(
                //         WnaAppSettingsDao.getFileProviderRequest(
                //             doc.fileName,
                //             "base64"
                //         )
                //     ).then((response) => {
                //         response.text().then(async (b64) => {
                //             const filePath = await WnaRepository.writeTextFileAsync(
                //                 doc.fileName,
                //                 b64,
                //                 "base64"
                //             );
                //             if (filePath !== "")
                //                 await WnaRepository.ShareFileAsync(filePath);
                //         });
                //     });
            } else {
                // navigate
                WnaLogger.info(
                    WnaMenuScreen.name,
                    handleClick.name,
                    "navigate"
                );
                props.navigation.push(
                    currentItem.screen as keyof WnaNavigationStackPropsMap,
                    { currentItem }
                );
            }
        } catch (error) {
            WnaLogger.error(WnaMenuScreen.name, handleClick.name, error);
        } finally {
            WnaLogger.end(WnaMenuScreen.name, handleClick.name, text);
        }
    };

    const renderItem = (currentItem: any) => {
        switch (currentItem.type) {
            case "webToggleFullscreen":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon="expand"
                        data={WnaCatalogYesNo}
                        selectedId={document.fullscreenElement ? 1 : 0}
                        isEditMode
                        onSelect={onSelectWebFullScreen}
                    />
                );
            case "paypal":
                return (
                    <WnaNavigationItem
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        iconName={currentItem.icon}
                        onPress={openPayPalAsync}
                        t={t}
                    />
                );
            case "userEdit":
                return (
                    <WnaNavigationItemProfile
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        currentUser={currentUser}
                        logInTitle={t("common:actionLogIn")}
                        onPress={() => {
                            if (currentUser != null && currentUser.id != "")
                                props.navigation.push(
                                    WnaApplicationConstants.ScreenNameProfile,
                                    {}
                                );
                            else WnaLogInProvider.show();
                        }}
                        t={t}
                    />
                );
            case "qr":
                return (
                    <WnaListCardWhiteDecent
                        style={[
                            currentAppStyle.containerCenterMaxWidth,
                            {
                                backgroundColor: currentAppTheme.dark
                                    ? currentAppTheme.colors.staticCoolgray8
                                    : currentAppTheme.colors.staticCoolgray6,
                            },
                        ]}
                        currentAppTheme={currentAppTheme}>
                        <View
                            style={[
                                {
                                    gap: 24,
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                },
                            ]}>
                            {/*TODO - OPTIONS!!!*/}
                            <WnaQrCode
                                url="https://app.fasten-mit-volker.de"
                                imageUrl={WnaAppSettingsDao.getImageResourceUrl(
                                    "logo_pwa_96.png"
                                )}
                                size={256}
                                currentAppTheme={currentAppTheme}
                                t={t}
                            />

                            {/*TODO - OPTIONS!!!*/}
                            <WnaQrCode
                                url="https://play.google.com/store/apps/details?id=com.wna.fastentagebuch&pli=1"
                                imageUrl={WnaAppSettingsDao.getImageResourceUrl(
                                    "logo_gp_96.png"
                                )}
                                size={256}
                                currentAppTheme={currentAppTheme}
                                t={t}
                            />
                        </View>
                    </WnaListCardWhiteDecent>
                );
            default:
                return (
                    <WnaNavigationItem
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        iconName={currentItem.icon}
                        onPress={handleClick}
                        t={t}
                    />
                );
        }
    };

    return (
        <WnaBaseScreenWithInfo>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaMenuScreen;
