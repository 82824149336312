import WnaChapter from "@domain/entities/WnaChapter";
import WnaUser from "@domain/entities/WnaUser";
import WnaDiaryEntryDao from "@infrastructure/dao/WnaDiaryEntryDao";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import createChapterAsync from "@infrastructure/wnaApi/chapter/commands/CreateChapter";
import deleteChapterAsync from "@infrastructure/wnaApi/chapter/commands/DeleteChapter";
import updateChapterAsync from "@infrastructure/wnaApi/chapter/commands/UpdateChapter";
import uploadChapterImageAsync from "@infrastructure/wnaApi/chapter/commands/UploadChapterImage";
import getAllChaptersAsync from "@infrastructure/wnaApi/chapter/queries/GetAllChapters";
import IsAliveAsync from "@infrastructure/wnaApi/user/queries/IsAliveAsync";
import WnaLogger from "wna-logger";

const _col = "chapters";
const _useDb = true;
const _encrypted = true;

/**
 * Dao for Diary entries
 */
export default class WnaChapterDao {
    public static Col = _col;
    private static _isSynced: boolean = false;

    public static resetIsSynced() {
        WnaChapterDao._isSynced = false;
    }

    public static getPath(wnaUser: WnaUser, itemIdentifier: string) {
        if (itemIdentifier == "") return "";

        return wnaUser.id + "/" + _col + "/" + itemIdentifier;
    }

    public static async createAsync(user: WnaUser, item: WnaChapter) {
        WnaLogger.start(WnaChapterDao.name, WnaChapterDao.createAsync.name);
        try {
            WnaChapterDao._isSynced = false;
            item = await createChapterAsync(user, item);
        } catch (error) {
            WnaLogger.error(
                WnaChapterDao.name,
                WnaChapterDao.createAsync.name,
                error
            );
        }
        WnaLogger.end(WnaChapterDao.name, WnaChapterDao.createAsync.name);
        return item;
    }

    public static async readAllAsync(
        user: WnaUser,
        sortBy: 0 | 1,
        forceRefresh: boolean
    ) {
        WnaLogger.start(
            WnaChapterDao.name,
            WnaChapterDao.readAllAsync.name,
            user.id + " | forceRefresh: " + forceRefresh
        );
        let ret = new Array<WnaChapter>();
        try {
            if (await IsAliveAsync()) {
                if (forceRefresh) WnaChapterDao.resetIsSynced();

                if (!forceRefresh && WnaChapterDao._isSynced) {
                    WnaLogger.info(
                        WnaChapterDao.name,
                        WnaChapterDao.readAllAsync.name,
                        "is synced - read from async storage"
                    );
                    const json =
                        (await WnaAsyncStorageProvider.getItemAsync(
                            _col + user.id,
                            _encrypted,
                            _useDb
                        )) ?? "";
                    ret = JSON.parse(json);
                } else {
                    WnaLogger.info(
                        WnaChapterDao.name,
                        WnaChapterDao.readAllAsync.name,
                        "is not synced - write to async storage"
                    );
                    ret = await getAllChaptersAsync(user);

                    // store to async storage
                    const json = JSON.stringify(ret);
                    await WnaAsyncStorageProvider.setItemAsync(
                        _col + user.id,
                        json,
                        _encrypted,
                        _useDb
                    );

                    // pre load diary entries
                    for (const chapter of ret)
                        await WnaDiaryEntryDao.readAllByChapterAsync(
                            user,
                            chapter,
                            sortBy,
                            forceRefresh
                        );

                    WnaChapterDao._isSynced = true;
                }
            } else {
                // offline
                const json =
                    (await WnaAsyncStorageProvider.getItemAsync(
                        _col + user.id,
                        _encrypted,
                        _useDb
                    )) ?? "";
                ret = JSON.parse(json);
            }
        } catch (error) {
            WnaLogger.error(
                WnaChapterDao.name,
                WnaChapterDao.readAllAsync.name,
                error
            );
            WnaChapterDao._isSynced = false;
        }
        WnaLogger.end(
            WnaChapterDao.name,
            WnaChapterDao.readAllAsync.name,
            user.id + " | forceRefresh: " + forceRefresh
        );
        return ret;
    }

    /**
     * readAllOfflineAsync
     * @param user
     * @returns
     */
    public static async readAllOfflineAsync(user: WnaUser) {
        WnaLogger.start(
            WnaChapterDao.name,
            WnaChapterDao.readAllOfflineAsync.name,
            user.id
        );
        WnaChapterDao._isSynced = false;
        let ret = new Array<WnaChapter>();
        try {
            const json =
                (await WnaAsyncStorageProvider.getItemAsync(
                    _col + user.id,
                    _encrypted,
                    _useDb
                )) ?? "";
            ret = JSON.parse(json);
        } catch (error) {
            WnaLogger.error(
                WnaChapterDao.name,
                WnaChapterDao.readAllOfflineAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaChapterDao.name,
            WnaChapterDao.readAllOfflineAsync.name,
            user.id
        );
        return ret;
    }

    /**
     * updateAsync
     * @param user
     * @param item
     * @returns
     */
    public static async updateAsync(user: WnaUser, item: WnaChapter) {
        WnaLogger.start(WnaChapterDao.name, WnaChapterDao.updateAsync.name);
        try {
            if (item.identifier === "") return null;

            item = await updateChapterAsync(user, item);
        } catch (error) {
            WnaLogger.error(
                WnaChapterDao.name,
                WnaChapterDao.updateAsync.name,
                error
            );
        }
        WnaLogger.end(WnaChapterDao.name, WnaChapterDao.updateAsync.name);
        return item;
    }

    /**
     * deleteAsync
     * @param user
     * @param item
     * @returns
     */
    public static async deleteAsync(user: WnaUser, item: WnaChapter) {
        WnaLogger.start(WnaChapterDao.name, WnaChapterDao.deleteAsync.name);
        try {
            if (item.identifier === "") return null;

            WnaChapterDao._isSynced = false;
            item = await deleteChapterAsync(user, item);
        } catch (error) {
            WnaLogger.error(
                WnaChapterDao.name,
                WnaChapterDao.deleteAsync.name,
                error
            );
        }
        WnaLogger.end(WnaChapterDao.name, WnaChapterDao.deleteAsync.name);
        return item;
    }

    public static async uploadImageAsync(
        user: WnaUser,
        item: WnaChapter,
        fileName: string,
        file: Blob
    ) {
        WnaLogger.start(
            WnaChapterDao.name,
            WnaChapterDao.uploadImageAsync.name
        );
        try {
            if (item.identifier === "" || fileName === "") return null;

            WnaChapterDao._isSynced = false;
            item = await uploadChapterImageAsync(
                user,
                item.identifier,
                fileName,
                file
            );
        } catch (error) {
            WnaLogger.error(
                WnaChapterDao.name,
                WnaChapterDao.uploadImageAsync.name,
                error
            );
        }
        WnaLogger.end(WnaChapterDao.name, WnaChapterDao.uploadImageAsync.name);
        return item;
    }
}
