import getNewIdentifier from "@domain/contracts/WnaIdentifier";
import React, { Component } from "react";
import { Linking, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import WebView from "react-native-webview";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

export type WnaWebViewProps = {
    urlOrHtml: string;
    jsCode?: string;
    onLoaded?: () => void;
    marginTop?: Int32;
    marginBottom?: Int32;
};

class WnaWebView extends Component<WnaWebViewProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaWebViewProps>): boolean {
        return nextProps.urlOrHtml !== this.props.urlOrHtml;
    }

    render() {
        const currentSource = this.props.urlOrHtml.startsWith("http")
            ? { uri: this.props.urlOrHtml }
            : { html: this.props.urlOrHtml };

        if (Platform.OS === "web" && this.props.urlOrHtml.startsWith("http")) {
            // url in iframe
            return (
                <iframe
                    id="ifr"
                    key={getNewIdentifier()}
                    style={{
                        borderWidth: 0,
                    }}
                    onLoad={() => {
                        if (this.props.onLoaded) this.props.onLoaded();
                    }}
                    src={this.props.urlOrHtml}
                    height={"100%"}
                    width={"100%"}
                />
            );
        } else if (
            Platform.OS === "web" &&
            !this.props.urlOrHtml.startsWith("http")
        ) {
            // plain html in div
            return (
                <ScrollView>
                    <div
                        key={getNewIdentifier()}
                        dangerouslySetInnerHTML={{
                            __html: this.props.urlOrHtml,
                        }}
                    />
                    <img
                        key={"img" + getNewIdentifier()}
                        width={1}
                        height={1}
                        alt=""
                        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        onLoad={() => {
                            if (this.props.onLoaded) this.props.onLoaded();
                        }}
                    />
                </ScrollView>
            );
        } else {
            // native webview
            return (
                <WebView
                    containerStyle={{ backgroundColor: "transparent" }}
                    source={currentSource}
                    pullToRefreshEnabled={true}
                    sharedCookiesEnabled
                    thirdPartyCookiesEnabled
                    domStorageEnabled
                    scalesPageToFit={true}
                    style={{
                        marginTop: this.props.marginTop ?? 0,
                        marginBottom: this.props.marginBottom ?? 0,
                        flex: 1,
                        backgroundColor: "transparent",
                    }}
                    setSupportMultipleWindows={false}
                    originWhitelist={["*"]}
                    injectedJavaScript={this.props.jsCode ?? ""}
                    onShouldStartLoadWithRequest={(request) => {
                        try {
                            WnaLogger.start(
                                WnaWebView.name,
                                "onShouldStartLoadWithRequest",
                                request.url
                            );
                            // short circuit these - open external links in system browser
                            if (
                                !request.url ||
                                request.url.startsWith("http") ||
                                request.url.startsWith("https") ||
                                request.url.startsWith("/") ||
                                request.url.startsWith("#") ||
                                request.url.startsWith("javascript") ||
                                request.url.startsWith("about:blank")
                            ) {
                                WnaLogger.info(
                                    WnaWebView.name,
                                    "onShouldStartLoadWithRequest",
                                    "open external links in system browser: " +
                                        request.url
                                );
                                Linking.openURL(request.url);
                                return false;
                            }

                            // blocked blobs
                            if (request.url.startsWith("blob")) {
                                WnaLogger.info(
                                    WnaWebView.name,
                                    "onShouldStartLoadWithRequest",
                                    "blocked blobs: " + request.url
                                );
                                // WnaToastProvider.showError(
                                //     "Link cannot be opened."
                                // );
                                return false;
                            }

                            // list of schemas we will allow the webview
                            // to open natively
                            if (
                                request.url.startsWith("tel:") ||
                                request.url.startsWith("mailto:") ||
                                request.url.startsWith("maps:") ||
                                request.url.startsWith("geo:") ||
                                request.url.startsWith("sms:")
                            ) {
                                Linking.openURL(request.url).catch((error) => {
                                    WnaLogger.error(
                                        WnaWebView.name,
                                        "onShouldStartLoadWithRequest",
                                        "Failed to open Link: " + error.message
                                    );
                                    // WnaToastProvider.showError(
                                    //     "Failed to open Link: " + error.message
                                    // );
                                });
                                return false;
                            }

                            // let everything else to the webview
                            WnaLogger.warn(
                                WnaWebView.name,
                                "onShouldStartLoadWithRequest",
                                "request: " + request.url
                            );
                            return true;
                        } catch (error) {
                            WnaLogger.error(
                                WnaWebView.name,
                                "onShouldStartLoadWithRequest",
                                error
                            );
                            return false;
                        } finally {
                            WnaLogger.end(
                                WnaWebView.name,
                                "onShouldStartLoadWithRequest",
                                request.url
                            );
                        }
                    }}
                    onLoadEnd={() => {
                        if (this.props.onLoaded) this.props.onLoaded();
                    }}
                />
            );
        }
    }
}

export default WnaWebView;
