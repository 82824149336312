import { WnaFontFamilies } from "@domain/contracts/theme/WnaFontFamilies";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import {
    getColorByWeekday,
    toStringDateWeekday,
} from "@infrastructure/services/WnaDateTimeService";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaListCardWhiteImage from "@ui/components/cards/WnaListCardWhiteImage";
import WnaText from "@ui/components/text/WnaText";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { Text, View } from "react-native";

export type WnaDiaryEntryListItemProps = {
    currentItem: WnaDiaryEntry;
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    onPress: (currentItem: WnaDiaryEntry) => void;
    t: TFunction<string[], undefined>;
};

class WnaDiaryEntryListItem extends Component<WnaDiaryEntryListItemProps> {
    shouldComponentUpdate(nextProps: WnaDiaryEntryListItemProps) {
        return (
            nextProps.currentItem.dateCreate !=
                this.props.currentItem.dateCreate ||
            nextProps.currentItem.dateUpdate !=
                this.props.currentItem.dateUpdate ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.currentItem.imageUrl != this.props.currentItem.imageUrl ||
            nextProps.currentItem.routeStatic256Url !=
                this.props.currentItem.routeStatic256Url ||
            nextProps.currentItem.routeStatic512Url !=
                this.props.currentItem.routeStatic512Url
        );
    }

    render() {
        // WnaLogger.info(DayListEntry.name, this.render.name, this.props.currentItem.designator);
        return (
            <WnaPressable
                disableHover
                style={[
                    this.props.currentAppStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                        backgroundColor:
                            this.props.currentAppTheme.colors.white,
                    },
                ]}
                onPress={() => this.props.onPress(this.props.currentItem)}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteImage
                    currentAppStyle={this.props.currentAppStyle}
                    currentAppTheme={this.props.currentAppTheme}
                    headerTitle={toStringDateWeekday(
                        this.props.currentItem.dateStart
                    )}
                    color={getColorByWeekday(this.props.currentItem.dateStart)}
                    hasRoute={
                        (this.props.currentItem.routeIdentifier ?? "") != ""
                    }
                    hasImages={this.props.currentItem.images.length > 0}
                    imageUrl={this.props.currentItem.imageUrl}
                    tinyImageUrl={this.props.currentItem.routeStatic256Url}>
                    {this.props.currentItem.designator != "" &&
                    this.props.currentItem.designator.trim() != "" ? (
                        <Text
                            style={[
                                this.props.currentAppStyle.textTitleLarge,
                                { marginBottom: 8 },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {this.props.currentItem.designator}
                        </Text>
                    ) : null}
                    {this.props.currentItem.comment != "" &&
                    this.props.currentItem.comment.trim() != "" ? (
                        <View style={{ maxHeight: 256, overflow: "hidden" }}>
                            <WnaText
                                currentAppTheme={this.props.currentAppTheme}
                                currentAppStyle={this.props.currentAppStyle}
                                style={[this.props.currentAppStyle.textSmall]}
                                fontFamily={WnaFontFamilies.Courgette}
                                textBreakStrategy={"highQuality"}
                                numberOfLines={8}
                                ellipseMode={"tail"}
                                maxHeight={256}
                                text={this.props.currentItem.comment}
                            />
                        </View>
                    ) : null}
                </WnaListCardWhiteImage>
            </WnaPressable>
        );
    }
}

export default WnaDiaryEntryListItem;
