import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import {
    initJsonRequest,
    setJwt,
} from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import WnaLogger from "wna-logger";

/**
 * returns jwt
 * @param userId
 * @param idToken
 */
const verifyIdTokenAsync = async (userId: string, idToken: string) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${userId}/verifyIdToken?userId=${userId}&idToken=${idToken}`;
    WnaLogger.start(verifyIdTokenAsync.name, url);
    const request = initJsonRequest("POST");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 5000,
            abortHandler: (err) => {
                return new Response("");
            },
        })
    );

    let jwt = "";
    if (response.ok) jwt = await response.text();

    setJwt(userId, jwt);
    WnaLogger.warn(`JWT: ${jwt}`);
    WnaLogger.end(verifyIdTokenAsync.name, url);
    return jwt;
};

export default verifyIdTokenAsync;
