import React, { useContext, useEffect, useState } from "react";
// @ts-ignore
import { Editor } from "react-draft-wysiwyg";
// @ts-ignore
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";

import { WnaAppContext } from "@app/WnaAppContext";
import { WnaFontFamilies } from "@domain/contracts/theme/WnaFontFamilies";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { View } from "react-native";

// https://jpuri.github.io/react-draft-wysiwyg/#/demo
// https://github.com/jpuri/react-draft-wysiwyg
// https://jpuri.github.io/react-draft-wysiwyg/#/docs

export type WnaRichTextEditorProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    text: string;
    placeHolderText: string;
    style: any;
    onUpdate: (text: string) => void;
    onFocus: () => void;
    onBlur: () => void;
};

const WnaRichTextEditor: React.FC<WnaRichTextEditorProps> = (props) => {
    const { currentAppTheme } = useContext(WnaAppContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const blocksFromHTML = htmlToDraft(props.text); //convertFromHTML(props.text);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        const newEditorState = EditorState.createWithContent(state);
        setEditorState(newEditorState);
    }, [props]);
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
        props.onUpdate(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    };

    return (
        <View style={props.style}>
            <Editor
                editorState={editorState}
                editorStyle={{
                    backgroundColor: currentAppTheme.colors.staticWhite,
                    color: currentAppTheme.colors.staticBlack,

                    position: "absolute",
                    top: 40,
                    bottom: 0,
                    left: 0,
                    right: 0,

                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: currentAppTheme.colors.coolgray2,
                    borderRadius: 8,
                    fontFamily: WnaFontFamilies.Roboto,
                    padding: 16,
                    paddingTop: 0,
                    fontSize: 18,
                }}
                toolbarStyle={{
                    backgroundColor: "transparent",
                    border: 0,
                    height: 40,
                    padding: 0,
                    margin: -3,
                }}
                toolbar={{
                    options: [
                        "blockType",
                        "inline",
                        "colorPicker",
                        "list",
                        "textAlign",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                    ],
                    blockType: {
                        inDropdown: true,
                        options: ["Normal", "H1", "H2", "Blockquote"],
                    },
                    inline: {
                        inDropdown: false,
                        options: ["underline", "strikethrough"],
                    },
                    history: { inDropdown: false },
                    colorPicker: {
                        colors: [
                            props.currentAppTheme.colors.staticBlack,
                            props.currentAppTheme.colors.staticCoolgray8,
                            props.currentAppTheme.colors.staticCoolgray6,
                            props.currentAppTheme.colors.staticCoolgray4,
                            props.currentAppTheme.colors.staticWhite,

                            props.currentAppTheme.colors.staticAccent8,
                            props.currentAppTheme.colors.staticAccent6,
                            props.currentAppTheme.colors.staticAccent4,
                            props.currentAppTheme.colors.staticAccent3,
                            props.currentAppTheme.colors.staticAccent2,

                            props.currentAppTheme.colors.yellow8,
                            props.currentAppTheme.colors.yellow6,
                            props.currentAppTheme.colors.yellow4,
                            props.currentAppTheme.colors.yellow3,
                            props.currentAppTheme.colors.yellow2,

                            props.currentAppTheme.colors.orange8,
                            props.currentAppTheme.colors.orange6,
                            props.currentAppTheme.colors.orange4,
                            props.currentAppTheme.colors.orange3,
                            props.currentAppTheme.colors.orange2,

                            props.currentAppTheme.colors.red8,
                            props.currentAppTheme.colors.red6,
                            props.currentAppTheme.colors.red4,
                            props.currentAppTheme.colors.red3,
                            props.currentAppTheme.colors.red2,

                            props.currentAppTheme.colors.burgundy8,
                            props.currentAppTheme.colors.burgundy6,
                            props.currentAppTheme.colors.burgundy4,
                            props.currentAppTheme.colors.burgundy3,
                            props.currentAppTheme.colors.burgundy2,

                            props.currentAppTheme.colors.pink8,
                            props.currentAppTheme.colors.pink6,
                            props.currentAppTheme.colors.pink4,
                            props.currentAppTheme.colors.pink3,
                            props.currentAppTheme.colors.pink2,

                            props.currentAppTheme.colors.violet8,
                            props.currentAppTheme.colors.violet6,
                            props.currentAppTheme.colors.violet4,
                            props.currentAppTheme.colors.violet3,
                            props.currentAppTheme.colors.violet2,

                            props.currentAppTheme.colors.blue8,
                            props.currentAppTheme.colors.blue6,
                            props.currentAppTheme.colors.blue4,
                            props.currentAppTheme.colors.blue3,
                            props.currentAppTheme.colors.blue2,

                            props.currentAppTheme.colors.cyan8,
                            props.currentAppTheme.colors.cyan6,
                            props.currentAppTheme.colors.cyan4,
                            props.currentAppTheme.colors.cyan3,
                            props.currentAppTheme.colors.cyan2,

                            props.currentAppTheme.colors.turquoise8,
                            props.currentAppTheme.colors.turquoise6,
                            props.currentAppTheme.colors.turquoise4,
                            props.currentAppTheme.colors.turquoise3,
                            props.currentAppTheme.colors.turquoise2,

                            props.currentAppTheme.colors.lightgreen8,
                            props.currentAppTheme.colors.lightgreen6,
                            props.currentAppTheme.colors.lightgreen4,
                            props.currentAppTheme.colors.lightgreen3,
                            props.currentAppTheme.colors.lightgreen2,

                            props.currentAppTheme.colors.green8,
                            props.currentAppTheme.colors.green6,
                            props.currentAppTheme.colors.green4,
                            props.currentAppTheme.colors.green3,
                            props.currentAppTheme.colors.green2,
                        ],
                    },
                    list: {
                        inDropdown: true,
                    },
                    textAlign: {
                        inDropdown: true,
                        options: ["left", "center", "right"],
                    },
                }}
                onEditorStateChange={onEditorStateChange}
                placeholder={props.placeHolderText}
            />
        </View>
    );
};
export default WnaRichTextEditor;
