import CryptoES from "crypto-es";
import WnaLogger from "wna-logger";

const _tsFileName = "WnaCryptoProvider.ts";

const decrypt = (data: string, passphrase: string) => {
    // https://stackoverflow.com/questions/74951647/malformed-utf-8-data-error-malformed-utf-8-data-on-decrypting-data-in-cryptojs
    const json = JSON.parse(data);
    const cipherParams = {
        ciphertext: CryptoES.enc.Base64.parse(json.ct),
        salt: CryptoES.enc.Hex.parse(json.s),
    }; // apply a CipherParams object
    const decryptedData = CryptoES.AES.decrypt(
        cipherParams,
        passphrase
    ).toString(CryptoES.enc.Utf8); // decrypt and UTF-8 decode
    // convert to JavaScript object
    return JSON.parse(decryptedData);
};

const encryptLocal = (value: string, passphrase: string) => {
    try {
        const encrypted = CryptoES.AES.encrypt(
            JSON.stringify(value),
            passphrase
        );
        return JSON.stringify(encrypted);
    } catch (error) {
        WnaLogger.error(_tsFileName, encryptLocal.name, error);
        return "";
    }
};

const decryptLocal = (value: string, passphrase: string) => {
    try {
        const json = JSON.parse(value);
        const decryptedData = CryptoES.AES.decrypt(json, passphrase).toString(
            CryptoES.enc.Utf8
        ); // decrypt and UTF-8 decode
        // convert to JavaScript object
        return JSON.parse(decryptedData);
    } catch (error) {
        WnaLogger.error(_tsFileName, decryptLocal.name, error);
        return "";
    }
};

export { decrypt, decryptLocal, encryptLocal };
