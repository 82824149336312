import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import { WnaAppointmentDetailScreenNavigationProps } from "@app/screens/appointment/detail/WnaAppointmentDetailScreenNavigationProps";
import WnaAppointmentEdit from "@app/screens/appointment/detail/WnaAppointmentEdit";
import WnaAppointmentReadOnly from "@app/screens/appointment/detail/WnaAppointmentReadOnly";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import getNewIdentifier from "@domain/contracts/WnaIdentifier";
import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import WnaAppointment from "@domain/entities/WnaAppointment";
import WnaAppointmentDao from "@infrastructure/dao/WnaAppointmentDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import { i18nKeysCommon, i18nKeysDialog } from "@infrastructure/i18n/i18nKeys";
import {
    addDays,
    dateToStringDateWeekdayShort,
} from "@infrastructure/services/WnaDateTimeService";
import WnaButtonHeader from "@ui/components/buttons/WnaButtonHeader";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaMultilineHeader from "@ui/components/tabbar/WnaMultilineHeader";
import WnaRefreshNotifier from "@ui/events/refresh/WnaRefreshNotifier";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaAppointmentDetailScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        appSettings,
        currentAppTheme,
        currentAppStyle,
        currentUser,
        isLandscape,
        isInternetReachable,
    } = useContext(WnaAppContext);

    const [currentItem, setCurrentItem] = useState<WnaAppointment>(
        new WnaAppointment({
            identifier: getNewIdentifier(),
            dateStart: undefined,
            dateEnd: undefined,
        })
    );
    const currentItemRef = useRef(currentItem);

    const isDirtyRef = useRef(false);
    const saveChangesRef = useRef(false);

    const refreshParameter = new WnaRefreshParam(
        WnaApplicationConstants.ScreenNameAppointmentDetails,
        props.route.path
    );
    refreshParameter.action = WnaRefreshAction.create;

    const [isEditMode, setIsEditMode] = useState(false);
    const isEditModeRef = useRef(false);

    const isAddNewModeRef = useRef(false);

    const { t } = useTranslation(["common", "dialog"]);
    if (currentUser == null || appSettings == null) return null;

    const [isBusy, setIsBusy] = useState(false);

    const onDeleteAsync = async () => {
        try {
            WnaLogger.start(
                WnaAppointmentDetailScreen.name,
                onDeleteAsync.name
            );

            // dlg onDeleteAsync
            const onDialogResult = async () => {
                await WnaAppointmentDao.deleteAsync(currentItemRef.current);
                refreshParameter.action = WnaRefreshAction.delete;
                refreshParameter.context = currentItemRef.current;
                WnaRefreshNotifier.notify({ refreshParameter });
                let newItem = new WnaAppointment(currentItemRef.current);
                newItem.dateDelete = new Date();
                setCurrentItem(newItem);
                currentItemRef.current = newItem;
                props.navigation.goBack();
                WnaToastProvider.showSuccess(t(i18nKeysCommon.successDelete));
            };
            WnaDialogProvider.showYesNo(
                t(i18nKeysDialog.questionDelete),
                onDialogResult
            );
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDetailScreen.name,
                onDeleteAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaAppointmentDetailScreen.name, onDeleteAsync.name);
        }
    };

    const headerRight = (pIsEditMode: boolean, pIsAddNewMode: boolean) => {
        if (
            currentUser == null ||
            currentUser.id == "" ||
            !WnaUserDao.isAdmin(currentUser, appSettings)
        )
            return;

        return (
            <View style={{ flexDirection: "row" }}>
                {!pIsEditMode ? (
                    <View style={{ alignItems: "center" }}>
                        <WnaButtonHeader
                            text={t(i18nKeysCommon.actionEdit)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="pen"
                            onPress={() => {
                                if (isInternetReachable === true) {
                                    initCurrentItem(
                                        currentItemRef.current,
                                        true
                                    );
                                } else {
                                    WnaToastProvider.showError(
                                        t(i18nKeysCommon.errorNoInternet)
                                    );
                                }
                            }}
                            t={t}
                            checkInternetConnection={false}
                        />
                    </View>
                ) : !pIsAddNewMode ? (
                    <View style={{ alignItems: "center" }}>
                        <WnaButtonHeader
                            text={t(i18nKeysCommon.actionDelete)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="trash-alt"
                            onPress={onDeleteAsync}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>
                ) : null}
            </View>
        );
    };

    const initCurrentItem = (item: WnaAppointment, pIsEditMode: boolean) => {
        try {
            WnaLogger.start(
                WnaAppointmentDetailScreen.name,
                initCurrentItem.name
            );

            setIsEditMode(pIsEditMode);
            isEditModeRef.current = pIsEditMode;

            initHeader(item, pIsEditMode, isAddNewModeRef.current);
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDetailScreen.name,
                initCurrentItem.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaAppointmentDetailScreen.name,
                initCurrentItem.name
            );
        }
    };

    const onUpdate = (item: WnaAppointment) => {
        const updatedItem = new WnaAppointment(currentItemRef.current);
        updatedItem.designator = item.designator;
        updatedItem.dateStart = item.dateStart;
        updatedItem.dateEnd = item.dateEnd;
        updatedItem.comment = item.comment;
        updatedItem.dateUpdate = new Date();
        currentItemRef.current = updatedItem;
        isDirtyRef.current = true;
    };

    const onGoBackAsync = async () => {
        if (currentItemRef.current.dateDelete > getUnixMinDate()) return;

        refreshParameter.sender =
            WnaApplicationConstants.ScreenNameAppointmentDetails;
        refreshParameter.action = WnaRefreshAction.none;
        refreshParameter.context = currentItemRef.current;

        if (!saveChangesRef.current) {
            // save changes was not set by user - check isDirty
            if (isDirtyRef.current) {
                const goAhead = await WnaDialogProvider.showYesNoAsync(
                    t(i18nKeysDialog.questionSaveChanges)
                );
                //WnaLogger.info(CalendarEditScreen.name, onGoBackAsync.name, "goAhead: " + goAhead);
                if (!goAhead) {
                    WnaRefreshNotifier.notify({
                        refreshParameter: refreshParameter,
                    });
                    return; // do not save
                }
            } else {
                WnaRefreshNotifier.notify({
                    refreshParameter: refreshParameter,
                });
                return; // item is not dirty - just trigger refresh
            }
        }

        setIsBusy(true);

        if (currentItemRef.current.dateCreate > getUnixMinDate()) {
            // update
            refreshParameter.action = WnaRefreshAction.update;
            refreshParameter.context = await WnaAppointmentDao.updateAsync(
                currentItemRef.current
            );
        } else {
            // add new
            refreshParameter.action = WnaRefreshAction.create;
            refreshParameter.context = await WnaAppointmentDao.createAsync(
                currentItemRef.current
            );
        }
        WnaRefreshNotifier.notify({ refreshParameter: refreshParameter });
    };

    const initHeader = (
        currentItem: WnaAppointment,
        pIsEditMode: boolean,
        pIsAddNewMode: boolean
    ) => {
        const dtStartString = dateToStringDateWeekdayShort(
            currentItem.dateStart
        );
        const dtEndString = dateToStringDateWeekdayShort(currentItem.dateEnd);
        let title = dtStartString;
        if (dtEndString != dtStartString) title += " - " + dtEndString;

        const titleSegments = title.split(" - ");
        props.navigation.setOptions({
            title: title,
            headerTitle: () => {
                return isLandscape
                    ? WnaMultilineHeader(
                          currentAppStyle,
                          currentAppTheme,
                          title
                      )
                    : WnaMultilineHeader(
                          currentAppStyle,
                          currentAppTheme,
                          title,
                          titleSegments[0],
                          titleSegments[1]
                      );
            },
            headerRight: () => headerRight(pIsEditMode, pIsAddNewMode),
        });
    };

    React.useEffect(() => {
        const p = props.route
            .params as WnaAppointmentDetailScreenNavigationProps;
        let currentItem = p.currentItem;
        if (currentItem == null) {
            // add new
            isAddNewModeRef.current = true;
            setIsEditMode(true);
            isEditModeRef.current = true;
            currentItem = new WnaAppointment();
            currentItem.dateStart = new Date();
            currentItem.dateEnd = new Date();
            currentItem.identifier = getNewIdentifier();

            if (p.previousItem !== undefined) {
                currentItem.dateStart = new Date(
                    p.previousItem?.dateEnd ?? new Date()
                );
                currentItem.dateEnd = addDays(
                    new Date(p.previousItem?.dateEnd ?? new Date()),
                    appSettings.appointmentRangeInDays
                );
            }
        }

        WnaLogger.info(DataView.name, React.useEffect.name);
        if (currentItem.dateCreate > getUnixMinDate()) {
            // update mode
            WnaLogger.info(
                DataView.name,
                React.useEffect.name,
                "currentItem.identifier: " + currentItem.identifier
            );
        }

        setCurrentItem(currentItem);
        currentItemRef.current = currentItem;
        initHeader(currentItem, isEditModeRef.current, isAddNewModeRef.current);

        // back navigation
        props.navigation.addListener("beforeRemove", (e) => {
            e.preventDefault();
            onGoBackAsync().then(() =>
                props.navigation.dispatch(e.data.action)
            );
        });
    }, [props]);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            {isEditMode ? (
                <>
                    <WnaAppointmentEdit
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        appSettings={appSettings}
                        currentItem={currentItem}
                        onUpdate={onUpdate}
                        t={t}
                    />
                    <WnaButtonIcon
                        toolTip={t(i18nKeysCommon.actionSave)}
                        toolTipPosition="left"
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        iconName="check"
                        style={{ position: "absolute", bottom: 16, right: 16 }}
                        onPress={() => {
                            saveChangesRef.current = true;
                            props.navigation.goBack();
                        }}
                        t={t}
                        checkInternetConnection={true}
                    />
                </>
            ) : (
                <WnaAppointmentReadOnly
                    appSettings={appSettings}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    currentItem={currentItem}
                    t={t}
                />
            )}
        </WnaBaseScreenWithInfo>
    );
};
export default WnaAppointmentDetailScreen;
