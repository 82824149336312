import WnaUser from "@domain/entities/WnaUser";
import {
    User as FirebaseUser,
    GoogleAuthProvider,
    signInWithPopup,
} from "@firebase/auth";
import WnaLogger from "wna-logger";
import WnaUserDao from "../../dao/WnaUserDao";
import WnaFirebase from "../../firebase/WnaFirebase";

// https://firebase.google.com/docs/auth/web/facebook-login
const provider = new GoogleAuthProvider();
provider.addScope("profile");
provider.addScope("email");

const firebaseUserToWnaUserAsync = async (user: FirebaseUser) => {
    const wnaUser = new WnaUser();
    if (user == null) return wnaUser;

    const providerData = user.providerData[0];
    wnaUser.id = providerData.uid;
    wnaUser.identifier = providerData.uid;
    wnaUser.email = user.email ?? "";
    wnaUser.designator = user.displayName ?? "";
    wnaUser.picture = user.photoURL ?? "";
    wnaUser.pictureBase64 = "";
    wnaUser.given_name = user.displayName ?? "";
    wnaUser.name = user.displayName ?? "";
    wnaUser.verified_email = user.emailVerified ?? true;
    wnaUser.refreshToken = user.refreshToken;
    wnaUser.idToken = await user.getIdToken();
    wnaUser.wnaApiJwt = await WnaUserDao.verifyIdTokenAsync(
        wnaUser.id,
        wnaUser.idToken
    );
    return await WnaUserDao.createOrUpdateAsync(wnaUser);
};

export default class WnaGoogleSignIn {
    public static async signInWithGoogleAsync() {
        try {
            WnaLogger.start(
                WnaGoogleSignIn.name,
                WnaGoogleSignIn.signInWithGoogleAsync.name
            );
            const result = await signInWithPopup(
                WnaFirebase.FirebaseAuth,
                provider
            );
            return await firebaseUserToWnaUserAsync(result.user);
        } catch (error) {
            WnaLogger.error(
                WnaGoogleSignIn.name,
                WnaGoogleSignIn.signInWithGoogleAsync.name,
                error
            );
            return new WnaUser();
        } finally {
            WnaLogger.end(
                WnaGoogleSignIn.name,
                WnaGoogleSignIn.signInWithGoogleAsync.name
            );
        }
    }
}
