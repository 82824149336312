import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import WnaUser from "@domain/entities/WnaUser";
import WnaChapterDao from "@infrastructure/dao/WnaChapterDao";
import WnaDiaryEntryDao from "@infrastructure/dao/WnaDiaryEntryDao";
import WnaRouteDao from "@infrastructure/dao/WnaRouteDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import WnaFirebase from "@infrastructure/firebase/WnaFirebase";
import { i18nKeysCommon, i18nKeysDialog } from "@infrastructure/i18n/i18nKeys";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaNavigationProvider from "@navigation/WnaNavigationProvider";
import WnaButtonIconText from "@ui/components/buttons/WnaButtonIconText";
import WnaButtonText from "@ui/components/buttons/WnaButtonText";
import WnaImage from "@ui/components/images/WnaImage";
import WnaSeparatorHorizontal from "@ui/components/misc/WnaSeparatorHorizontal";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, View } from "react-native";
import WnaLogger from "wna-logger";

const WnaProfileScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        currentAppTheme,
        currentAppStyle,
        currentUser,
        currentUserSettings,
        setCurrentUser,
        setCurrentAdminUser,
        isInternetReachable,
    } = useContext(WnaAppContext);
    const [isBusy, setIsBusy] = useState(false);
    const [isBusyText, setIsBusyText] = useState("");
    const { t } = useTranslation(["common", "dialog"]);

    const questionLogOut = () => {
        if (isInternetReachable == false) {
            WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
            return;
        }

        WnaDialogProvider.showYesNo(
            t(i18nKeysDialog.questionLogOut),
            async () => {
                await WnaFirebase.signOutAsync();
                await WnaAsyncStorageProvider.clearAsync();
                WnaDiaryEntryDao.resetIsSynced();
                setCurrentUser(new WnaUser());
                setCurrentAdminUser(new WnaUser());
                props.navigation.popToTop();
                WnaNavigationProvider.navigateToStartPageScreen();
                setCurrentUser(new WnaUser());
            }
        );
    };

    const questionLogOutAndDeleteAll = () => {
        if (isInternetReachable == false) {
            WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
            return;
        }

        WnaDialogProvider.showYesNo(
            t(t(i18nKeysDialog.questionLogOutAndDeleteData)),
            async () => {
                await WnaFirebase.signOutAndDeleteDataAsync();
                setCurrentUser(new WnaUser());
                setCurrentAdminUser(new WnaUser());
                props.navigation.popToTop();
                WnaNavigationProvider.navigateToStartPageScreen();
            }
        );
    };

    const runDownloadAsync = async () => {
        try {
            WnaLogger.start(WnaProfileScreen.name, runDownloadAsync.name);

            if (currentUser == null) return;

            WnaUserDao.disableCreateOrUpdate();
            setIsBusy(true);
            setIsBusyText("Loading Data ...");

            // chapters
            setIsBusyText("Downloading chapters ...");
            const chapters = await WnaChapterDao.readAllAsync(
                currentUser,
                currentUserSettings?.diaryOrderBy ?? 1,
                true
            );
            for (
                let chapterIndex = 0;
                chapterIndex < chapters.length;
                chapterIndex++
            ) {
                const chapter = chapters[chapterIndex];

                const pIsBusyItemText =
                    "Downloading " +
                    (chapterIndex + 1) +
                    " / " +
                    chapters.length;

                setIsBusyText(pIsBusyItemText);

                // chapter image
                await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                    chapter.imageUrl ?? "",
                    "png"
                );

                // diary entries
                const diaryEntries =
                    await WnaDiaryEntryDao.readAllByChapterAsync(
                        currentUser!,
                        chapter,
                        1,
                        true
                    );

                for (
                    let diaryEntryIndex = 0;
                    diaryEntryIndex < diaryEntries.length;
                    diaryEntryIndex++
                ) {
                    const diaryEntry = diaryEntries[diaryEntryIndex];

                    const pIsBusyItemText =
                        "Downloading " +
                        (diaryEntryIndex + 1) +
                        " / " +
                        diaryEntries.length;

                    setIsBusyText(pIsBusyItemText);
                    await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                        diaryEntry.imageUrl ?? "",
                        "png"
                    );

                    // diary entry images
                    if (
                        diaryEntry.images == null ||
                        diaryEntry.images.length < 1
                    ) {
                        for (
                            let imageIndex = 0;
                            imageIndex < diaryEntry.images.length;
                            imageIndex++
                        ) {
                            const image = diaryEntry.images[imageIndex];
                            const isBusyImageText =
                                pIsBusyItemText +
                                " ( " +
                                (imageIndex + 1) +
                                " / " +
                                diaryEntry.images.length +
                                " )";
                            setIsBusyText(isBusyImageText);

                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                image.urlTh256 ?? "",
                                "png"
                            );
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                image.urlTh512 ?? "",
                                "png"
                            );
                            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                                image.url ?? "",
                                "png"
                            );
                        }
                    }

                    // route
                    if (diaryEntry.routeIdentifier != "")
                        await WnaRouteDao.readByIdentifierAsync(
                            currentUser,
                            diaryEntry.routeIdentifier
                        );

                    const cacheKey =
                        "cinfo_" +
                        WnaDiaryEntryDao.getPath(
                            currentUser,
                            chapter,
                            diaryEntry.identifier
                        );
                    await WnaAsyncStorageProvider.setItemAsync(
                        cacheKey,
                        diaryEntry.images.length.toString(),
                        false,
                        false
                    );
                }
            }

            WnaToastProvider.showSuccess(t(i18nKeysCommon.successDownload));
        } catch (error) {
            WnaLogger.error(
                WnaProfileScreen.name,
                runDownloadAsync.name,
                error
            );
            WnaToastProvider.showError(t(i18nKeysCommon.errorUnknown));
        } finally {
            WnaUserDao.enableCreateOrUpdate();
            setIsBusy(false);
            setIsBusyText("");
            WnaLogger.end(WnaProfileScreen.name, runDownloadAsync.name);
        }
    };

    const clearCacheAsync = async () => {
        try {
            WnaLogger.start(WnaProfileScreen.name, clearCacheAsync.name);

            await WnaAsyncFileCacheProvider.clearCacheAsync();
            await WnaAsyncStorageProvider.clearAsync("diaryentries");
            await WnaAsyncStorageProvider.clearAsync("chapters");
            await WnaAsyncStorageProvider.clearAsync("routes");

            WnaToastProvider.showSuccess(t(i18nKeysCommon.success));
        } catch (error) {
            WnaLogger.error(WnaProfileScreen.name, clearCacheAsync.name, error);
            WnaToastProvider.showError(t(i18nKeysCommon.errorUnknown));
        } finally {
            WnaLogger.end(WnaProfileScreen.name, clearCacheAsync.name);
        }
    };

    if (currentUser === null) return null;

    useEffect(() => {}, []);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy} isBusyText={isBusyText}>
            <ScrollView style={[{ padding: 16 }]}>
                <View
                    style={[
                        currentAppStyle.containerCenterMaxWidth,
                        {
                            padding: 24,
                            backgroundColor: currentAppTheme.colors.white,
                        },
                    ]}>
                    <View
                        style={[currentAppStyle.containerCenter, { gap: 16 }]}>
                        <WnaImage
                            imageUrl={currentUser?.pictureBase64}
                            currentAppTheme={currentAppTheme}
                            style={{
                                width: 96,
                                height: 96,
                                borderRadius: 48,
                                borderColor: currentAppTheme.colors.white,
                                borderWidth: 2,
                            }}
                        />
                        <Text
                            style={[
                                currentAppStyle.textTitleLarge,
                                {
                                    marginTop: 16,
                                    color: currentAppTheme.colors.coolgray6,
                                    maxWidth: 256,
                                },
                            ]}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {currentUser?.name}
                        </Text>
                    </View>
                    <View
                        style={[currentAppStyle.containerCenter, { gap: 16 }]}>
                        <WnaSeparatorHorizontal />
                        <WnaButtonText
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            style={{ width: 256 }}
                            text={t("common:actionLogOut")}
                            onPress={questionLogOut}
                            checkInternetConnection={false}
                            t={t}
                        />
                        <WnaButtonText
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            style={{ width: 256 }}
                            text={t("common:actionLogOutAndDeleteData")}
                            onPress={questionLogOutAndDeleteAll}
                            t={t}
                            checkInternetConnection={true}
                        />
                        <WnaSeparatorHorizontal />

                        <WnaButtonIconText
                            currentAppTheme={currentAppTheme}
                            text="Download"
                            iconName={"download"}
                            style={{ width: 256 }}
                            onPress={runDownloadAsync}
                            t={t}
                            checkInternetConnection={true}
                        />

                        <WnaButtonIconText
                            text="Cache leeren"
                            currentAppTheme={currentAppTheme}
                            style={{ width: 256 }}
                            iconName={"trash-alt"}
                            onPress={clearCacheAsync}
                            checkInternetConnection={false}
                            t={t}
                        />
                    </View>
                </View>
            </ScrollView>
        </WnaBaseScreenWithInfo>
    );
};
export default WnaProfileScreen;
