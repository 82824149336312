import WnaAppointmentConfig from "@domain/contracts/types/appSettings/WnaAppointmentConfig";
import WnaFirebaseConfig from "@domain/contracts/types/appSettings/WnaFirebaseConfig";
import WnaGoogleAuthConfig from "@domain/contracts/types/appSettings/WnaGoogleAuthConfig";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaAppSettings extends WnaEntityBase {
    public googleApiKey: string = "";
    public firebaseAppConfig: WnaFirebaseConfig = {
        apiKey: "",
        authDomain: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: "",
    };
    public googleAuthConfig: WnaGoogleAuthConfig = {
        androidClientId: "",
        iosClientId: "",
        webClientId: "",
        scopes: [],
        offlineAccess: true,
    };
    public appointmentConfig: WnaAppointmentConfig = {
        tel: "",
        email: "",
        url: "",
        address: "",
        imageUrl: "",
        infoUrl: "",
    };
    public startPageLightUrl: string = "";
    public startPageDarkUrl: string = "";
    public startTitle: string = "Willkommen";
    public isCalendarVisible: boolean = false;
    public maxUploadFiles: Int32 = 0;
    public backgroundImageUrl: string = "";
    public backgroundImageDarkUrl: string = "";
    public colorAccent: string = "";
    public logMailTo: string = "";
    public admins: Array<string> = [];
    public userLogs: Array<string> = [];
    public imageMaxWidth: Int32 = 1920;
    public imageMaxHeight: Int32 = 1080;
    public imageQuality: Int32 = 80;
    public paypalUrl: string = "";
    public appointmentRangeInDays: number = 7;

    constructor(init?: Partial<WnaAppSettings | null>) {
        super(init);
        Object.assign(this, init);
    }
}
