import { WnaStaticColors } from "@domain/contracts/theme/WnaStaticColors";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import { execWhenOnlineAsync } from "@infrastructure/services/WnaExecWhenOnline";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import { WnaPressableState } from "@ui/types/WnaPressableState";
import { TFunction } from "i18next";
import { Component } from "react";
import { Pressable, StyleSheet, Text, View, ViewStyle } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

export type WnaButtonIconTextProps = {
    currentAppTheme: WnaTheme;
    text: string;
    iconName: keyof typeof FontAwesome5.glyphMap;
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
    backgroundColor?: string;
    textColor?: string;
    borderColor?: string;
    borderWidth?: Int32;
    style?: ViewStyle;
};

class WnaButtonIconText extends Component<WnaButtonIconTextProps> {
    render() {
        const textColor =
            this.props.textColor != null
                ? this.props.textColor
                : this.props.currentAppTheme.colors.staticWhite;

        const backgroundColor =
            this.props.backgroundColor ??
            this.props.currentAppTheme.colors.staticCoolgray6;
        const borderColor = this.props.borderColor ?? backgroundColor;
        const borderWidth = this.props.borderWidth ?? 0;
        return (
            <View
                style={[
                    {
                        height: 56,
                        borderRadius: 28,
                        overflow: "hidden",
                    },
                ]}>
                <Pressable
                    style={({
                        pressed,
                        hovered,
                        focused,
                    }: WnaPressableState) => [
                        WnaShadowStyle(),
                        {
                            opacity: pressed
                                ? 0.7
                                : hovered || focused
                                  ? 0.9
                                  : 1,
                        },
                        componentStyle.pressableContainer,
                        this.props.style,
                        {
                            padding: 0,
                            backgroundColor: backgroundColor,
                            borderBlockColor: borderColor,
                            borderWidth: borderWidth,
                        },
                    ]}
                    android_ripple={{
                        color: this.props.currentAppTheme.colors.white,
                    }}
                    onPress={() =>
                        execWhenOnlineAsync(
                            this.props.checkInternetConnection,
                            this.props.t,
                            this.props.onPress
                        )
                    }>
                    <View style={componentStyle.container}>
                        <FontAwesome5
                            name={this.props.iconName}
                            size={18}
                            color={textColor}
                        />
                        <Text
                            style={[
                                componentStyle.text,
                                {
                                    color: textColor,
                                },
                            ]}>
                            {this.props.text}
                        </Text>
                    </View>
                </Pressable>
            </View>
        );
    }
}

const componentStyle = StyleSheet.create({
    // Buttons
    pressableContainer: {
        backgroundColor: WnaStaticColors.staticCoolgray6,
        height: 56,
        borderRadius: 28,
        overflow: "hidden",
    },
    container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        marginHorizontal: 22,
    },
    text: {
        textTransform: "uppercase",
        fontSize: 16,
        marginHorizontal: 8,
        alignSelf: "center",
    },
});
export default WnaButtonIconText;
