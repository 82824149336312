import WnaChapter from "@domain/entities/WnaChapter";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaUser from "@domain/entities/WnaUser";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns list of diaryEntries by the given chapter
 * @param wnaUser
 * @param chapter
 */
const getAllDiaryEntriesByChapterAsync = async (
    wnaUser: WnaUser,
    chapter: WnaChapter
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/${chapter.identifier}/diaryEntries`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 90000,
            abortHandler: (err) => {
                return new Response(JSON.stringify([] as Array<WnaDiaryEntry>));
            },
        })
    );
    return (await response.json()) as Array<WnaDiaryEntry>;
};

export default getAllDiaryEntriesByChapterAsync;
