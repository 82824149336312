import WnaNavigationHeaderOptions from "@app/WnaNavigationHeaderOptions";
import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";
import WnaChapterListScreen from "@app/screens/diary/chapter/list/WnaChapterListScreen";
import WnaDiaryEntryDetailScreen from "@app/screens/diary/diaryEntry/detail/WnaDiaryEntryDetailScreen";
import WnaDiaryEntryListScreen from "@app/screens/diary/diaryEntry/list/WnaDiaryEntryListScreen";
import WnaDiaryPreviewScreen from "@app/screens/diary/diaryEntry/preview/WnaDiaryPreviewScreen";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { WnaNavigationStackProps } from "@navigation/WnaNavigationStackProps";
import WnaNavigatorScreenOptions from "@navigation/WnaNavigatorScreenoptions";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";

const Stack = createStackNavigator<WnaNavigationStackPropsMap>();
const WnaDiaryStack: React.FC<WnaNavigationStackProps> = (props) => {
    const { t } = useTranslation(["common"]);
    return (
        <Stack.Navigator screenOptions={WnaNavigatorScreenOptions}>
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameChapterList}
                component={WnaChapterListScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleDayList)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameDiaryEntryList}
                component={WnaDiaryEntryListScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleDayList)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameDiaryPreview}
                component={WnaDiaryPreviewScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.actionPrint)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameDiaryEntryDetail}
                component={WnaDiaryEntryDetailScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleDayDetails)
                )}
            />
        </Stack.Navigator>
    );
};
export default WnaDiaryStack;
