import WnaNotification from "@domain/entities/WnaNotification";
import WnaUser from "@domain/entities/WnaUser";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import markNotificationAsReadAsync from "@infrastructure/wnaApi/notification/commands/MarkNotificationAsRead";
import sendNotificationToAllUsersAsync from "@infrastructure/wnaApi/notification/commands/SendNotificationForAllUsers";
import countUnreadNotificationsAsync from "@infrastructure/wnaApi/notification/queries/CountUnreadNotifications";
import getAllNotificationsAsync from "@infrastructure/wnaApi/notification/queries/GetAllNotifications";
import isAliveAsync from "@infrastructure/wnaApi/user/queries/IsAliveAsync";
import { Mutex } from "async-mutex";
import WnaLogger from "wna-logger";

const mutex = new Mutex();
const _col = "notifications";
const _useDb = true;
const _encrypted = false;

const readAllFromStorageAsync = async (user: WnaUser) => {
    try {
        WnaLogger.start(WnaNotificationDao.name, readAllFromStorageAsync.name);
        const json =
            (await WnaAsyncStorageProvider.getItemAsync(
                _col + user.id,
                _encrypted,
                _useDb
            )) ?? "";
        return JSON.parse(json);
    } catch (error) {
        WnaLogger.error(
            WnaNotificationDao.name,
            readAllFromStorageAsync.name,
            error
        );
        return null;
    } finally {
        WnaLogger.end(WnaNotificationDao.name, readAllFromStorageAsync.name);
    }
};

export default class WnaNotificationDao {
    private static _isSynced: boolean = false;

    public static resetIsSynced() {
        WnaNotificationDao._isSynced = false;
    }

    /**
     * readAllAsync
     * @param user
     * @param forceRefresh
     * @returns
     */
    public static async readAllAsync(user: WnaUser, forceRefresh: boolean) {
        try {
            WnaLogger.start(
                WnaNotificationDao.name,
                WnaNotificationDao.readAllAsync.name
            );
            let ret = new Array<WnaNotification>();

            if (await isAliveAsync()) {
                if (forceRefresh) WnaNotificationDao.resetIsSynced();

                if (WnaNotificationDao._isSynced) {
                    ret = await readAllFromStorageAsync(user);
                    if (ret != null) return ret;
                }

                WnaLogger.info(
                    WnaNotificationDao.name,
                    WnaNotificationDao.readAllAsync.name,
                    "is not synced - write to async storage"
                );
                ret = await getAllNotificationsAsync(user);

                // store to async storage
                const json = JSON.stringify(ret);
                await WnaAsyncStorageProvider.setItemAsync(
                    _col + user.id,
                    json,
                    _encrypted,
                    _useDb
                );
                WnaNotificationDao._isSynced = true;
            } else {
                // offline
                ret = await readAllFromStorageAsync(user);
                if (ret != null) return ret;
            }
            return ret;
        } catch (error) {
            WnaLogger.error(
                WnaNotificationDao.name,
                WnaNotificationDao.readAllAsync.name,
                error
            );
            return [];
        } finally {
            WnaLogger.end(
                WnaNotificationDao.name,
                WnaNotificationDao.readAllAsync.name
            );
        }
    }

    /**
     * readAllOfflineAsync
     * @param wnaUser
     * @param forceRefresh
     * @returns
     */
    public static async readAllOfflineAsync(
        wnaUser: WnaUser,
        forceRefresh: boolean
    ) {
        WnaLogger.start(
            WnaNotificationDao.name,
            WnaNotificationDao.readAllOfflineAsync.name,
            "forceRefresh: " + forceRefresh
        );
        WnaNotificationDao._isSynced = false;
        let ret = new Array<WnaNotification>();
        try {
            const json =
                (await WnaAsyncStorageProvider.getItemAsync(
                    _col + wnaUser.id,
                    _encrypted,
                    _useDb
                )) ?? "";
            ret = JSON.parse(json);
        } catch (error) {
            WnaLogger.error(
                WnaNotificationDao.name,
                WnaNotificationDao.readAllOfflineAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaNotificationDao.name,
            WnaNotificationDao.readAllOfflineAsync.name,
            "forceRefresh: " + forceRefresh
        );
        return ret;
    }

    /**
     * readCountUnreadAsync
     * @param user
     * @returns
     */
    public static async readCountUnreadAsync(user: WnaUser | null) {
        return await mutex.runExclusive(async () => {
            try {
                WnaLogger.start(
                    WnaNotificationDao.name,
                    WnaNotificationDao.readCountUnreadAsync.name
                );
                if (user == null) {
                    WnaLogger.info(
                        WnaNotificationDao.name,
                        WnaNotificationDao.readCountUnreadAsync.name,
                        "wnaUser is null"
                    );
                    return 0;
                }
                return await countUnreadNotificationsAsync(user);
            } catch (error) {
                WnaLogger.error(
                    WnaNotificationDao.name,
                    WnaNotificationDao.readCountUnreadAsync.name,
                    error
                );
                return 0;
            } finally {
                WnaLogger.end(
                    WnaNotificationDao.name,
                    WnaNotificationDao.readCountUnreadAsync.name
                );
            }
        });
    }

    public static async sendNotificationToAllUsersAsync(
        notification: WnaNotification
    ) {
        let ret = new WnaNotification();
        WnaLogger.start(
            WnaNotificationDao.name,
            WnaNotificationDao.sendNotificationToAllUsersAsync.name
        );

        try {
            ret = await sendNotificationToAllUsersAsync(notification);
        } catch (error) {
            WnaLogger.error(
                WnaNotificationDao.name,
                WnaNotificationDao.sendNotificationToAllUsersAsync.name,
                error
            );
        }

        WnaLogger.end(
            WnaNotificationDao.name,
            WnaNotificationDao.sendNotificationToAllUsersAsync.name
        );
        return ret;
    }

    public static async markNotificationAsReadAsync(
        user: WnaUser,
        notification: WnaNotification,
        markAsRead: boolean
    ) {
        let ret = new WnaNotification();
        WnaLogger.start(
            WnaNotificationDao.name,
            WnaNotificationDao.markNotificationAsReadAsync.name
        );

        try {
            ret = await markNotificationAsReadAsync(
                user,
                notification.identifier,
                markAsRead
            );
        } catch (error) {
            WnaLogger.error(
                WnaNotificationDao.name,
                WnaNotificationDao.markNotificationAsReadAsync.name,
                error
            );
        }

        WnaLogger.end(
            WnaNotificationDao.name,
            WnaNotificationDao.markNotificationAsReadAsync.name
        );
        return ret;
    }
}
