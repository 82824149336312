/** https://gist.github.com/tranquan/7c219eda2a24165e21000cdbe840a9ad */
import React from "react";
import WnaEventEmitter from "./WnaEventEmitter";

function useEventListener<T extends (...params: any) => void>(
    event: string,
    listener: T,
    deps: ReadonlyArray<any>
) {
    React.useEffect(() => {
        WnaEventEmitter.addListener(event, listener);
        return () => {
            WnaEventEmitter.removeListener(event, listener);
        };
    }, deps);
}

export function makeEventNotifier<P>(name: string) {
    return {
        name: name,
        notify: (param: P) => {
            WnaEventEmitter.notify(name, param);
        },
        useEventListener: (
            listener: (param: P) => void,
            deps: ReadonlyArray<any>
        ) => useEventListener(name, listener, deps),
    };
}
