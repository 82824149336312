import { WnaAppContext } from "@app/WnaAppContext";
import WnaUser from "@domain/entities/WnaUser";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaDiaryPrintProvider from "@infrastructure/services/WnaDiaryPrintProvider";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaWebView from "@ui/components/misc/WnaWebView";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

export type WnaDiaryViewModalProps = {
    modal: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closeModal;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getParam;
    };
};

const WnaDiaryViewModal: React.FC<WnaDiaryViewModalProps> = (props) => {
    const {
        currentWindowWidth,
        currentWindowHeight,
        currentAppStyle,
        currentAppTheme,
        setIsStatusBarVisible,
    } = useContext(WnaAppContext);

    const onCancel = props.modal.closeModal as () => void;
    const html = props.modal.getParam("html", "");
    const currentUser = props.modal.getParam("currentUser", new WnaUser());
    const currentChapterIdentifier = props.modal.getParam(
        "currentChapterIdentifier",
        ""
    );
    const [isBusy, setIsBusy] = useState(true);
    const { t } = useTranslation(["common", "dialog"]);

    const onClose = () => {
        setIsStatusBarVisible(true);
        onCancel();
    };

    const printToPdfAsync = async () => {
        await WnaDiaryPrintProvider.printToPdfAsync(
            currentUser,
            currentChapterIdentifier,
            t
        );
        onClose();
    };

    useEffect(() => {
        setIsStatusBarVisible(false);
    }, []);

    return (
        <View
            style={{
                backgroundColor: currentAppTheme.colors.coolgray2,
                width: currentWindowWidth,
                height: currentWindowHeight,
            }}>
            <WnaWebView
                urlOrHtml={html}
                onLoaded={() => {
                    setIsBusy(false);
                }}
            />
            <WnaButtonIcon
                toolTip={t(i18nKeysCommon.actionGoBack)}
                toolTipPosition={"bottom"}
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                iconName="times"
                onPress={onClose}
                t={t}
                checkInternetConnection={false}
                style={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                }}
            />

            {isBusy ? null : (
                <WnaButtonIcon
                    toolTip={t(i18nKeysCommon.actionPrint)}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    iconName="print"
                    onPress={() => printToPdfAsync()}
                    t={t}
                    checkInternetConnection={true}
                    style={{
                        position: "absolute",
                        bottom: 16,
                        right: 16,
                    }}
                />
            )}
        </View>
    );
};
export default WnaDiaryViewModal;
