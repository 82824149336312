import WnaLogger from "wna-logger";
// eslint-disable-next-line
const jsonMinify = require("jsonminify");

const _tsFileName = "WnaJsonMinify.ts";

const minifyJson = (json: string) => {
    const ret = json;
    try {
        WnaLogger.start(_tsFileName, minifyJson.name);
        if (json == "") return ret;

        const miniJson = jsonMinify(json) as string;
        if (miniJson == "") {
            WnaLogger.error(
                _tsFileName,
                minifyJson.name,
                "failed to minify JSON"
            );
            return ret;
        } else {
            WnaLogger.info(
                _tsFileName,
                minifyJson.name,
                "successfully minified " +
                    json.length +
                    " to " +
                    miniJson.length
            );
            return miniJson;
        }
    } catch (error) {
        WnaLogger.error(_tsFileName, minifyJson.name, error);
    } finally {
        WnaLogger.end(_tsFileName, minifyJson.name);
    }
    return ret;
};

export { minifyJson };
