import { WnaAppContext } from "@app/WnaAppContext";
import WnaMultilineHeader from "@ui/components/tabbar/WnaMultilineHeader";
import { useContext } from "react";

export default function WnaNavigationHeaderOptions(
    title: string,
    subtitle?: string | null
) {
    const { currentAppTheme, currentAppStyle } = useContext(WnaAppContext);
    const headerBgColor = currentAppTheme.dark
        ? currentAppTheme.colors.accent3
        : currentAppTheme.colors.accent5;
    const headerStyle = {
        backgroundColor: headerBgColor,
        borderTopWidth: 0,
        borderBottomWidth: 0,
    };
    return {
        title: title,
        headerTintColor: currentAppTheme.colors.staticWhite,
        headerTitleStyle: currentAppStyle.navigationHeaderTitleStyle,
        headerStyle: headerStyle,
        headerShadowVisible: true,
        headerTitle: () =>
            WnaMultilineHeader(
                currentAppStyle,
                currentAppTheme,
                title,
                subtitle
            ),
    };
}
