import WnaRoute from "@domain/entities/WnaRoute";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns route
 * @param route
 */
const createOrUpdateRouteAsync = async (route: WnaRoute) => {
    debugger;
    const url = `${WnaApiSettings.apiBaseUrl}/routes/createOrUpdate`;
    const request = initJsonRequest("POST");
    request.body = JSON.stringify(route);
    const response = await fetch(url, request);
    return new WnaRoute(await response.json());
};

export default createOrUpdateRouteAsync;
