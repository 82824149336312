import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import { Component } from "react";
import { View, ViewStyle } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

export type WnaButtonIconInnerIconProps = {
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
    iconName: keyof typeof FontAwesome5.glyphMap;
    color?: string;
    style?: ViewStyle;
    size?: Int32;
};

const _size = 52;
const _borderRadius = _size / 2;
const _iconSize = _size * 0.35;

class WnaButtonIconInnerIcon extends Component<WnaButtonIconInnerIconProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaButtonIconInnerIconProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.color != this.props.color ||
            nextProps.iconName != this.props.iconName
        );
    }

    render() {
        const defaultClr = convertHexToRgba(
            this.props.currentAppTheme.colors.staticBlack,
            0.6
        );
        const size = this.props.size ?? _size;
        return (
            <View
                style={[
                    this.props.currentAppStyle.containerCenterCenter,
                    {
                        width: size,
                        height: size,
                        maxWidth: size,
                        maxHeight: size,
                        backgroundColor: defaultClr,
                        borderRadius: _borderRadius,
                    },
                ]}>
                <FontAwesome5
                    name={this.props.iconName}
                    size={_iconSize}
                    color={
                        this.props.color ??
                        this.props.currentAppTheme.colors.staticWhite
                    }
                />
            </View>
        );
    }
}

export default WnaButtonIconInnerIcon;
