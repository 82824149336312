import WnaAppointment from "@domain/entities/WnaAppointment";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import createAppointmentAsync from "@infrastructure/wnaApi/appointment/commands/CreateAppointment";
import deleteAppointmentAsync from "@infrastructure/wnaApi/appointment/commands/DeleteAppointment";
import updateAppointmentAsync from "@infrastructure/wnaApi/appointment/commands/UpdateAppointment";
import getAllAppointmentsAsync from "@infrastructure/wnaApi/appointment/queries/GetAllAppointments";
import isAliveAsync from "@infrastructure/wnaApi/user/queries/IsAliveAsync";
import WnaLogger from "wna-logger";

const _col = "Appointments";
const _useDb = true;
const _encrypted = true;
export default class WnaAppointmentDao {
    private static _isSynced: boolean = false;

    public static resetIsSynced() {
        WnaAppointmentDao._isSynced = false;
    }

    public static getPath(itemIdentifier: string) {
        if (itemIdentifier == "") return "";

        return _col + "/" + itemIdentifier;
    }

    public static async createAsync(item: WnaAppointment) {
        WnaLogger.start(
            WnaAppointmentDao.name,
            WnaAppointmentDao.createAsync.name
        );
        try {
            WnaAppointmentDao._isSynced = false;
            item = await createAppointmentAsync(item);
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDao.name,
                WnaAppointmentDao.createAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaAppointmentDao.name,
            WnaAppointmentDao.createAsync.name
        );
        return item;
    }

    /**
     * readAllAsync
     * @param forceRefresh
     * @returns
     */
    public static async readAllAsync(forceRefresh: boolean) {
        WnaLogger.start(
            WnaAppointmentDao.name,
            WnaAppointmentDao.readAllAsync.name
        );
        let ret = new Array<WnaAppointment>();
        try {
            if (await isAliveAsync()) {
                if (forceRefresh) WnaAppointmentDao.resetIsSynced();

                if (WnaAppointmentDao._isSynced) {
                    WnaLogger.info(
                        WnaAppointmentDao.name,
                        WnaAppointmentDao.readAllAsync.name,
                        "is synced - read from async storage"
                    );
                    const json =
                        (await WnaAsyncStorageProvider.getItemAsync(
                            _col,
                            _encrypted,
                            _useDb
                        )) ?? "";
                    ret = JSON.parse(json);
                    return ret;
                }

                WnaLogger.info(
                    WnaAppointmentDao.name,
                    WnaAppointmentDao.readAllAsync.name,
                    "is not synced - write to async storage"
                );
                ret = await getAllAppointmentsAsync();
                // store to async storage
                const json = JSON.stringify(ret);
                // WnaLogger.info(json);
                await WnaAsyncStorageProvider.setItemAsync(
                    _col,
                    json,
                    _encrypted,
                    _useDb
                );
                WnaAppointmentDao._isSynced = true;
            } else {
                // offline
                const json =
                    (await WnaAsyncStorageProvider.getItemAsync(
                        _col,
                        _encrypted,
                        _useDb
                    )) ?? "";
                ret = JSON.parse(json);
                return ret;
            }
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDao.name,
                WnaAppointmentDao.readAllAsync.name,
                error
            );
            WnaAppointmentDao._isSynced = false;
        }
        WnaLogger.end(
            WnaAppointmentDao.name,
            WnaAppointmentDao.readAllAsync.name
        );
        return ret;
    }

    /**
     * readAllOfflineAsync
     * @param forceRefresh
     * @returns
     */
    public static async readAllOfflineAsync(forceRefresh: boolean) {
        WnaLogger.start(
            WnaAppointmentDao.name,
            WnaAppointmentDao.readAllOfflineAsync.name,
            "forceRefresh: " + forceRefresh
        );
        WnaAppointmentDao._isSynced = false;
        let ret = new Array<WnaAppointment>();
        try {
            const json =
                (await WnaAsyncStorageProvider.getItemAsync(
                    _col,
                    _encrypted,
                    _useDb
                )) ?? "";
            if (json == "") ret = await WnaAppointmentDao.readAllAsync(true);
            else ret = JSON.parse(json);
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDao.name,
                WnaAppointmentDao.readAllOfflineAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaAppointmentDao.name,
            WnaAppointmentDao.readAllOfflineAsync.name,
            "forceRefresh: " + forceRefresh
        );
        return ret;
    }

    // updateAsync
    public static async updateAsync(item: WnaAppointment) {
        WnaLogger.start(
            WnaAppointmentDao.name,
            WnaAppointmentDao.updateAsync.name
        );
        try {
            if (item.identifier === "") return null;
            WnaAppointmentDao._isSynced = false;
            item = await updateAppointmentAsync(item);
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDao.name,
                WnaAppointmentDao.updateAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaAppointmentDao.name,
            WnaAppointmentDao.updateAsync.name
        );
        return item;
    }

    // deleteAsync
    public static async deleteAsync(item: WnaAppointment) {
        WnaLogger.start(
            WnaAppointmentDao.name,
            WnaAppointmentDao.deleteAsync.name
        );
        try {
            if (item.identifier === "") return;

            WnaAppointmentDao._isSynced = false;
            item = await deleteAppointmentAsync(item);
        } catch (error) {
            WnaLogger.error(
                WnaAppointmentDao.name,
                WnaAppointmentDao.deleteAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaAppointmentDao.name,
            WnaAppointmentDao.deleteAsync.name
        );
        return item;
    }
}
