import Resizer from "react-image-file-resizer";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

const _tsFileName = "WnaImageResizer.ts";

/**
 * ONLY WEB
 * @param pathOrFileBlob needs to be BLOB in WEB and FILEPATH in NATIVE
 * @param maxWidth
 * @param maxHeight
 * @param quality
 * @returns
 */
const resizeImageInternalAsync = (
    pathOrFileBlob: string | Blob,
    maxWidth: Int32,
    maxHeight: Int32,
    quality: Int32
) =>
    new Promise<Blob | undefined>((resolve) => {
        if (typeof pathOrFileBlob === "string") {
            resolve(undefined);
            return;
        }

        WnaLogger.start(
            _tsFileName,
            resizeImageAsync.name,
            "maxWidth: " +
                maxWidth +
                " | maxHeight: " +
                maxHeight +
                " | quality: " +
                quality
        );
        // https://javascript.plainenglish.io/compression-images-before-upload-in-a-react-app-with-react-image-file-resizer-c1870c9bcf47
        // https://github.com/onurzorluer/react-image-file-resizer
        Resizer.imageFileResizer(
            pathOrFileBlob,
            maxWidth,
            maxHeight,
            "JPEG",
            quality,
            0,
            (result) => {
                WnaLogger.end(
                    _tsFileName,
                    resizeImageAsync.name,
                    "maxWidth: " +
                        maxWidth +
                        " | maxHeight: " +
                        maxHeight +
                        " | quality: " +
                        quality
                );
                resolve(result as Blob);
            },
            "blob",
            256,
            256
        );
    });

const resizeImageAsync = async (
    pathOrFileBlob: string | Blob,
    maxWidth: Int32,
    maxHeight: Int32,
    quality: Int32
): Promise<Blob | undefined> => {
    return await resizeImageInternalAsync(
        pathOrFileBlob,
        maxWidth,
        maxHeight,
        quality
    );
};

export { resizeImageAsync };
