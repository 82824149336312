import WnaLogInProvider from "@app/services/WnaLogInPorvider";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaUser from "@domain/entities/WnaUser";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaNavigationProvider from "@navigation/WnaNavigationProvider";
import WnaButtonHeader from "@ui/components/buttons/WnaButtonHeader";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaProfileIcon from "@ui/components/misc/WnaProfileIcon";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { View } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

export type WnaNotificationHeaderRightProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    currentUser: WnaUser | null;
    unreadNotificationCount: Int32 | null;
    t: TFunction<string[], undefined>;
};

export default class WnaNotificationHeaderRight extends Component<WnaNotificationHeaderRightProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaNotificationHeaderRightProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            (nextProps.currentUser?.identifier ?? "") !=
                (this.props.currentUser?.identifier ?? "") ||
            nextProps.unreadNotificationCount !=
                this.props.unreadNotificationCount
        );
    }

    render() {
        return this.props.currentUser == null ||
            this.props.currentUser.id == "" ? (
            <View
                style={[
                    this.props.currentAppStyle.containerCenter,
                    {
                        marginRight: 16,
                        marginTop: 4,
                        width: 32,
                        height: 32,
                        borderRadius: 16,
                        borderColor:
                            this.props.currentAppTheme.colors.staticWhite,
                        borderWidth: 2,
                        overflow: "hidden",
                    },
                ]}>
                <WnaPressable
                    onPress={() => {
                        WnaLogInProvider.show();
                    }}
                    t={this.props.t}
                    checkInternetConnection={false}>
                    <WnaProfileIcon
                        currentAppTheme={this.props.currentAppTheme}
                    />
                </WnaPressable>
            </View>
        ) : (
            <View style={{ alignItems: "center" }}>
                <WnaButtonHeader
                    currentAppStyle={this.props.currentAppStyle}
                    currentAppTheme={this.props.currentAppTheme}
                    text={this.props.t(
                        i18nKeysCommon.screenTitleNotificationList
                    )}
                    toolTipPosition="bottom"
                    iconName="bell"
                    badgeVisible={
                        this.props.unreadNotificationCount != null &&
                        this.props.unreadNotificationCount > 0
                    }
                    onPress={() =>
                        WnaNavigationProvider.navigateToNotificationListScreen()
                    }
                    t={this.props.t}
                    checkInternetConnection={false}
                />
            </View>
        );
    }
}
