import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaCatalogNotificationState } from "@domain/constants/catalogs/WnaCatalogNotificationState";
import WnaNotification from "@domain/entities/WnaNotification";
import WnaNotificationDao from "@infrastructure/dao/WnaNotificationDao";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { dateToStringDateTimeWeekday } from "@infrastructure/services/WnaDateTimeService";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaListCardWhite from "@ui/components/cards/WnaListCardWhite";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import { useOnRefreshListener } from "@ui/events/refresh/WnaUseOnRefreshListener";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RefreshControl, Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaNotificationListScreen: React.FC<WnaStackScreenProps> = (props) => {
    const {
        isInternetReachable,
        currentUser,
        appSettings,
        currentAppTheme,
        currentAppStyle,
    } = useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);
    const [isBusy, setIsBusy] = useState(true);
    const [items, setItems] = useState(Array<WnaNotification>);
    const itemsRef = useRef(items);
    const [extraData, setExtraData] = useState(new Date());

    const loadItemsFromDbAsync = async (forceReload: boolean) => {
        let newItems = new Array<WnaNotification>();
        try {
            if (currentUser == null) {
                WnaLogger.warn("currentUser is null");
                return newItems;
            }

            let needReload = false;
            if (itemsRef.current.length < 1) needReload = true;

            if (forceReload) needReload = true;

            if (needReload) {
                setIsBusy(true);
                if (isInternetReachable === true) {
                    WnaLogger.info(
                        "internetIsReachable --> will load from FireStore"
                    );
                    newItems = await WnaNotificationDao.readAllAsync(
                        currentUser,
                        forceReload
                    );
                } else {
                    WnaLogger.info(
                        "internetIsReachable --> will load from Cache"
                    );
                    newItems = await WnaNotificationDao.readAllOfflineAsync(
                        currentUser,
                        forceReload
                    );
                }
                setItems(newItems);
                itemsRef.current = newItems;
                setIsBusy(false);
            }
        } catch (error) {
            WnaLogger.error(
                WnaNotificationListScreen.name,
                loadItemsFromDbAsync.name,
                error
            );
        }

        setIsBusy(false);
        WnaLogger.end(
            WnaNotificationListScreen.name,
            loadItemsFromDbAsync.name
        );
    };

    const onRefreshAsync = async (p: WnaRefreshParam) => {
        if (p.sender != WnaApplicationConstants.ScreenNameNotificationDetails)
            return;

        WnaLogger.start(WnaNotificationListScreen.name, onRefreshAsync.name);
        switch (p.action) {
            case WnaRefreshAction.create:
                await loadItemsFromDbAsync(true);
                break;
            case WnaRefreshAction.update:
                const indexOfItem = itemsRef.current.findIndex(
                    (x) => x.identifier == p.context.identifier
                );
                itemsRef.current[indexOfItem] = p.context;
                setExtraData(new Date());
                break;
            case WnaRefreshAction.delete:
                await loadItemsFromDbAsync(true);
                break;
        }

        WnaLogger.end(WnaNotificationListScreen.name, onRefreshAsync.name);
        return p;
    };

    useEffect(() => {
        loadItemsFromDbAsync(true);
    }, []);

    useOnRefreshListener(async (p) => {
        await onRefreshAsync(p.refreshParameter);
    }, []);

    const renderItem = (currentItem: WnaNotification) => {
        return (
            <WnaPressable
                toolTip=""
                style={[
                    currentAppStyle.containerCenterMaxWidth,
                    { borderRadius: 8, padding: 0 },
                ]}
                onPress={() => {
                    props.navigation.push(
                        WnaApplicationConstants.ScreenNameNotificationDetails,
                        {
                            currentItem,
                        }
                    );
                }}
                t={t}
                checkInternetConnection={false}>
                <WnaListCardWhite
                    currentAppTheme={currentAppTheme}
                    color={
                        WnaCatalogNotificationState[currentItem.state]
                            .displayColor
                    }>
                    <Text
                        style={[
                            currentAppStyle.textSmall,
                            {
                                color: currentAppTheme.colors.accent4,
                                textAlign: "right",
                                marginBottom: 8,
                                marginTop: -16,
                                marginRight: -8,
                            },
                        ]}>
                        {dateToStringDateTimeWeekday(currentItem.dateCreate)}
                    </Text>
                    <Text
                        style={[
                            currentAppStyle.textTitleLarge,
                            { color: currentAppTheme.colors.black },
                        ]}
                        textBreakStrategy={"highQuality"}
                        numberOfLines={1}
                        ellipsizeMode={"tail"}>
                        {currentItem.designator}
                    </Text>
                    <Text
                        style={[
                            currentAppStyle.textSmall,
                            { color: currentAppTheme.colors.black },
                        ]}
                        textBreakStrategy={"highQuality"}
                        numberOfLines={3}
                        ellipsizeMode={"tail"}>
                        {currentItem.comment}
                    </Text>
                </WnaListCardWhite>
            </WnaPressable>
        );
    };

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            <FlatList
                contentContainerStyle={{
                    paddingBottom: 80,
                    paddingTop: 16,
                    paddingHorizontal: 16,
                }}
                ItemSeparatorComponent={() => (
                    <View style={{ height: 8 }}></View>
                )}
                data={items}
                extraData={extraData}
                keyExtractor={(item) => item.identifier}
                refreshControl={
                    <RefreshControl
                        refreshing={isBusy}
                        onRefresh={() => loadItemsFromDbAsync(true)}
                    />
                }
                renderItem={({ item: currentItem }) => renderItem(currentItem)}
            />

            {/* Add Button */}
            {isInternetReachable === true &&
            currentUser != null &&
            WnaUserDao.isAdmin(currentUser, appSettings) ? (
                <WnaButtonIcon
                    toolTip={t(i18nKeysCommon.actionCreateNotification)}
                    toolTipPosition="left"
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    iconName="bullhorn"
                    style={{ position: "absolute", bottom: 16, right: 16 }}
                    onPress={() => {
                        props.navigation.push(
                            WnaApplicationConstants.ScreenNameNotificationDetails,
                            {
                                currentItem: null,
                            }
                        );
                    }}
                    checkInternetConnection={true}
                    t={t}
                />
            ) : null}
        </WnaBaseScreenWithInfo>
    );
};

export default WnaNotificationListScreen;
