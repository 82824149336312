import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaDiaryPreviewProps } from "@app/screens/diary/diaryEntry/preview/WnaDiaryPreviewScreenProps";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaDiaryPrintProvider from "@infrastructure/services/WnaDiaryPrintProvider";
import getHtmlAsync from "@infrastructure/wnaApi/diaryGenerator/queries/GetHtml";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaWebView from "@ui/components/misc/WnaWebView";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaMultilineHeader from "@ui/components/tabbar/WnaMultilineHeader";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import WnaLogger from "wna-logger";

const WnaDiaryPreviewScreen: FC<WnaStackScreenProps> = (props) => {
    const { currentUser, appSettings, currentAppTheme, currentAppStyle } =
        useContext(WnaAppContext);

    const pProps = props.route.params as WnaDiaryPreviewProps;
    if (currentUser == null || appSettings == null) return null;

    const currentPageIndexRef = useRef(0);
    const currentPagesLength = useRef(0);
    const [isBusy, setIsBusy] = useState(true);
    const { t } = useTranslation(["common", "dialog"]);
    const [html, setHtml] = useState("");
    const pagesToLoadLength = 1;
    const loadDataAsync = async (currentPageIndex: number) => {
        setIsBusy(true);
        try {
            const resp = await getHtmlAsync(
                currentUser,
                pProps.currentChapter?.identifier!,
                currentPageIndex,
                pagesToLoadLength
            );
            currentPageIndexRef.current = resp.startIndex;
            currentPagesLength.current = resp.length;
            setHtml(resp.html);
        } catch (e) {
            WnaLogger.error(WnaDiaryPreviewScreen.name, loadDataAsync.name, e);
        }
        setIsBusy(false);
    };

    const printToPdfAsync = async () => {
        await WnaDiaryPrintProvider.printToPdfAsync(
            currentUser,
            pProps.currentChapter?.identifier!,
            t
        );
        props.navigation.goBack();
    };

    const navigateToNextPage = async () => {
        await loadDataAsync(currentPageIndexRef.current + pagesToLoadLength);
    };

    const navigateToPrevPage = async () => {
        if (currentPageIndexRef.current < 1) return;

        await loadDataAsync(currentPageIndexRef.current - pagesToLoadLength);
    };

    useEffect(() => {
        props.navigation.setOptions({
            title: pProps.currentChapter?.designator!,
            headerTitle: () =>
                WnaMultilineHeader(
                    currentAppStyle,
                    currentAppTheme,
                    "",
                    pProps.currentChapter?.designator!,
                    t(i18nKeysCommon.screenTitleDiaryPreview)
                ),
        });
        if (html === "") loadDataAsync(currentPageIndexRef.current);
    }, [html]);

    return (
        <WnaBaseScreenWithInfo
            isBusy={isBusy}
            isBusyText={t(i18nKeysCommon.infoPreviewInProgress)}>
            <>
                <View
                    style={{
                        flex: 1,
                        padding: 16,
                    }}>
                    <WnaWebView
                        urlOrHtml={html}
                        onLoaded={() => {
                            setIsBusy(false);
                        }}
                    />
                </View>
                {isBusy ? null : (
                    <>
                        {/*prev*/}
                        <WnaButtonIcon
                            toolTip={t(i18nKeysCommon.actionPreviousEntry)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="arrow-left"
                            onPress={() => navigateToPrevPage()}
                            t={t}
                            checkInternetConnection={true}
                            style={{
                                position: "absolute",
                                bottom: 16,
                                left: 16,
                            }}
                        />

                        {/*next*/}
                        <WnaButtonIcon
                            toolTip={t(i18nKeysCommon.actionNextEntry)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="arrow-right"
                            onPress={() => navigateToNextPage()}
                            t={t}
                            checkInternetConnection={true}
                            style={{
                                position: "absolute",
                                bottom: 16,
                                right: 16,
                            }}
                        />

                        {/*print*/}
                        <WnaButtonIcon
                            toolTip={t(i18nKeysCommon.actionPrint)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="print"
                            onPress={() => printToPdfAsync()}
                            t={t}
                            checkInternetConnection={true}
                            style={{
                                position: "absolute",
                                bottom: 128,
                                right: 16,
                            }}
                        />
                    </>
                )}
            </>
        </WnaBaseScreenWithInfo>
    );
};

export default WnaDiaryPreviewScreen;
