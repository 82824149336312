import WnaUser from "@domain/entities/WnaUser";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import WnaLogger from "wna-logger";

/**
 * returns user
 * @param user
 */
const createOrUpdateUserAsync = async (user: WnaUser) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/createOrUpdate`;
    WnaLogger.start(createOrUpdateUserAsync.name, url);
    const request = initJsonRequest("POST");

    if (user.id === "") user.identifier = user.id;

    request.body = JSON.stringify(user);
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 5000,
            abortHandler: (err) => {
                return new Response("");
            },
        })
    );
    WnaLogger.end(createOrUpdateUserAsync.name, url);
    return new WnaUser(await response.json());
};

export default createOrUpdateUserAsync;
