import { getPathFromState } from "@react-navigation/native";
import * as Linking from "expo-linking";

// https://www.thisdot.co/blog/setting-up-react-navigation-in-expo-web-a-practical-guide/
const WnaLinking = {
    prefixes: [Linking.createURL("/")], // this is the prefix for our app. Could be anything eg https://myapp.com
    config: {
        screens: {
            start: {
                path: "",
                screens: {
                    WnaStartPageScreen: "",
                    // NotificationListScreen: "notifications",
                    // NotificationDetailScreen: "notification"
                    // NoMatch: 'web'
                },
            },
            appointments: {
                path: "kurse",
                screens: {
                    WnaAppointmentListScreen: "",
                    // WnaAppointmentDetailScreen: "appointment"
                    WnaAccommodationScreen: "hotel-haus-kranich",
                },
            },
            diary: {
                path: "tagebuch",
                screens: {
                    WnaChapterListScreen: "",
                    // WnaDiaryEntryListScreen: {
                    //     path: "tagebuch/:identifier",
                    //     parse: {
                    //         identifier: (currentChapter: WnaChapter) =>
                    //             `${currentChapter.identifier}`,
                    //     },
                    // },
                    // DayListScreen: "entries",
                    // DayDetailsTabScreen: "entry",
                },
                // NoMatch: 'diary',
            },
            location: {
                path: "position",
                screens: {
                    WnaLocationScreen: "",
                },
            },
            menu: {
                path: "menu",
                screens: {
                    WnaMenuScreen: "",
                    WnaProfileScreen: "profil",
                    WnaSettingsScreen: "einstellungen",
                    WnaSettingsAdvancedScreen: "einstellungen/erweitert",
                    WnaSettingsMapScreen: "einstellungen/karte",
                    WnaSettingsDiaryScreen: "einstellungen/tagebuch",
                    WnaAdminScreen: "admin",
                    WnaDisclaimerScreen: "haftungsauschluss",
                    WnaPrivacyScreen: "datenschutz",
                    // DocumentScreen: "doc",
                    // ErrorLogScreen: "log",
                },
            },
            // NoMatch: '*',
            // NotFound: '*'
        },
    },
    getPathFromState(state: any, config: any) {
        // https://stackoverflow.com/questions/64570083/hide-route-params-from-the-url-with-react-navigation
        let path = getPathFromState(state, config);
        const index = path.indexOf("?");
        if (index >= 0) path = path.substring(0, index);

        return path;
    },
};

export default WnaLinking;
