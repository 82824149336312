import { WnaStaticColors } from "@domain/contracts/theme/WnaStaticColors";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { execWhenOnlineAsync } from "@infrastructure/services/WnaExecWhenOnline";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import { WnaPressableState } from "@ui/types/WnaPressableState";
import { TFunction } from "i18next";
import { Component } from "react";
import { Pressable, Text, ViewStyle } from "react-native";

export type WnaButtonTextProps = {
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
    text: string;
    textColor?: string;
    backgroundColor?: string;
    style?: ViewStyle | ViewStyle[];
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
};

// Use the full props within the actual component
class WnaButtonText extends Component<WnaButtonTextProps> {
    render() {
        const textColor =
            this.props.textColor ??
            this.props.currentAppTheme.colors.staticWhite;
        const backgroundColor =
            this.props.backgroundColor ??
            this.props.currentAppTheme.colors.staticAccent6;
        return (
            <Pressable
                style={({ pressed, hovered, focused }: WnaPressableState) => [
                    WnaShadowStyle(),
                    {
                        opacity: pressed ? 0.7 : hovered || focused ? 0.9 : 1,
                    },
                    this.props.currentAppStyle.buttonWithText,
                    this.props.style,
                    {
                        backgroundColor: backgroundColor,
                    },
                ]}
                android_ripple={{ color: WnaStaticColors.staticWhite }}
                onPress={() =>
                    execWhenOnlineAsync(
                        this.props.checkInternetConnection,
                        this.props.t,
                        this.props.onPress
                    )
                }>
                <Text
                    style={{
                        color: textColor,
                        verticalAlign: "middle",
                        textAlign: "center",
                        textTransform: "uppercase",
                    }}>
                    {this.props.text}
                </Text>
            </Pressable>
        );
    }
}

export default WnaButtonText;
