import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import { WnaCatalogAppTheme } from "@domain/constants/catalogs/WnaCatalogAppTheme";
import { WnaCatalogYesNo } from "@domain/constants/catalogs/WnaCatalogYesNo";
import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import WnaUserSettings from "@domain/entities/WnaUserSettings";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import WnaUserSettingsDao from "@infrastructure/dao/WnaUserSettingsDao";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { getThemedColors } from "@infrastructure/services/WnaThemeService";
import WnaNavigationItem from "@ui/components/navigation/WnaNavigationItem";
import WnaNavigationList from "@ui/components/navigation/WnaNavigationList";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaSettingsRadioButton from "@ui/components/settings/WnaSettingsRadioButton";
import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Platform, useColorScheme } from "react-native";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaSettingsAdvancedScreen: React.FC<WnaStackScreenProps> = (props) => {
    const currentSystemColorScheme = useColorScheme();
    const {
        appSettings,
        currentAppTheme,
        setCurrentAppTheme,
        currentAppStyle,
        currentUserSettings,
        setCurrentUserSettings,
    } = useContext(WnaAppContext);
    const currentUserSettingsRef = useRef(currentUserSettings);
    const { t } = useTranslation(["common"]);
    const items: WnaMenuItem[] = [
        {
            screen: "",
            text: t(i18nKeysCommon.settingsTheme),
            icon: "cube",
            rightIcon: "",
            type: "theme",
            data: undefined,
        },
        {
            screen: "",
            text: t(i18nKeysCommon.settingsAdvancedLogging),
            icon: "bug",
            rightIcon: "",
            type: "appIsLoggingEnabled",
            data: undefined,
        },
    ];

    // error protocoll
    if (Platform.OS !== "web") {
        items.push(
            {
                screen: "",
                text: t(i18nKeysCommon.actionDeleteLog),
                icon: "bug",
                rightIcon: "trash",
                type: "clearLog",
                data: undefined,
            },
            {
                screen: WnaApplicationConstants.ScreenNameErrorLog,
                text: t("common:screenErrorLog"),
                icon: "file-code",
                rightIcon: "angle-right",
                type: "screen",
                data: undefined,
            }
        );
    }

    const onSelectCurrentAppThemeAsync = async (
        currentItem: WnaCatalogItem
    ) => {
        WnaLogger.start(
            WnaSettingsAdvancedScreen.name,
            onSelectCurrentAppThemeAsync.name
        );
        let newCurrentAppTheme = getThemedColors(
            appSettings,
            currentItem.id,
            currentSystemColorScheme == "dark"
        );
        WnaLogger.info(
            WnaSettingsAdvancedScreen.name,
            onSelectCurrentAppThemeAsync.name,
            "newCurrentAppTheme: " + newCurrentAppTheme.id
        );
        await WnaAppSettingsDao.setCurrentAppThemeIdAsync(currentItem.id);
        setCurrentAppTheme(newCurrentAppTheme);
        WnaLogger.end(
            WnaSettingsAdvancedScreen.name,
            onSelectCurrentAppThemeAsync.name
        );
    };

    const onSelectAppIsLoggingEnabled = async (currentItem: WnaCatalogItem) => {
        WnaLogger.start(
            WnaSettingsAdvancedScreen.name,
            onSelectAppIsLoggingEnabled.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.appIsLoggingEnabled = currentItem.id === 1;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        WnaLogger.end(
            WnaSettingsAdvancedScreen.name,
            onSelectAppIsLoggingEnabled.name
        );
    };

    const clearLogAsync = async () => {
        await WnaLogger.deleteLogFileAsync();
        WnaToastProvider.showSuccess(t(i18nKeysCommon.success));
    };

    const onSelectAppIsOfflineCacheEnabled = async (
        currentItem: WnaCatalogItem
    ) => {
        WnaLogger.start(
            WnaSettingsAdvancedScreen.name,
            onSelectAppIsOfflineCacheEnabled.name
        );
        const newUserSettings = new WnaUserSettings(
            currentUserSettingsRef.current ?? new WnaUserSettings()
        );
        newUserSettings.appIsOfflineCacheEnabled = currentItem.id === 1;
        await WnaUserSettingsDao.createOrUpdateAsync(newUserSettings);
        setCurrentUserSettings(newUserSettings);
        currentUserSettingsRef.current = newUserSettings;
        WnaLogger.end(
            WnaSettingsAdvancedScreen.name,
            onSelectAppIsOfflineCacheEnabled.name
        );
    };

    const handleClickAsync = async (text: string) => {
        try {
            const currentItem = items.find((x) => x.text === text)!;

            if (currentItem.type === "clearLog") {
                await clearLogAsync();
                return;
            }

            // navigate
            if (currentItem.screen == "") return;
            props.navigation.push(currentItem.screen, {
                currentItem,
            });
        } catch (error) {
            WnaLogger.error(
                WnaSettingsAdvancedScreen.name,
                handleClickAsync.name,
                error
            );
        }
    };

    const renderItem = (currentItem: any) => {
        switch (currentItem.type) {
            case "theme":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogAppTheme}
                        selectedId={currentAppTheme.id}
                        isEditMode
                        onSelect={onSelectCurrentAppThemeAsync}
                    />
                );
            case "appIsLoggingEnabled":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettings?.appIsLoggingEnabled == true
                                ? 1
                                : 0
                        }
                        isEditMode
                        onSelect={onSelectAppIsLoggingEnabled}
                    />
                );
            case "appIsOfflineCacheEnabled":
                return (
                    <WnaSettingsRadioButton
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        icon={currentItem.icon}
                        data={WnaCatalogYesNo}
                        selectedId={
                            currentUserSettings?.appIsOfflineCacheEnabled ==
                            true
                                ? 1
                                : 0
                        }
                        isEditMode
                        onSelect={onSelectAppIsOfflineCacheEnabled}
                    />
                );
            default:
                return (
                    <WnaNavigationItem
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        text={currentItem.text}
                        iconName={currentItem.icon}
                        iconRightName={currentItem.rightIcon}
                        onPress={handleClickAsync}
                        t={t}
                    />
                );
        }
    };

    return (
        <WnaBaseScreenWithInfo>
            <WnaNavigationList items={items} renderItem={renderItem} />
        </WnaBaseScreenWithInfo>
    );
};

export default WnaSettingsAdvancedScreen;
