import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { Component } from "react";
import { ActivityIndicator } from "react-native";

export type WnaActivityIndicatorProps = { currentAppTheme: WnaTheme };

class WnaActivityIndicator extends Component<WnaActivityIndicatorProps> {
    render() {
        return (
            <ActivityIndicator
                size={48}
                color={this.props.currentAppTheme.colors.accent4}
            />
        );
    }
}

export default WnaActivityIndicator;
