import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import { execWhenOnlineAsync } from "@infrastructure/services/WnaExecWhenOnline";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import { WnaPressableState } from "@ui/types/WnaPressableState";
import { TFunction } from "i18next";
import { Component } from "react";
import { Platform, Pressable } from "react-native";
import { Popable } from "react-native-popable";
import WnaButtonIconInnerIcon from "./WnaButtonIconInnerIcon";

const _shadowStyle = WnaShadowStyle();
const _defaultToolTipPos = "top";

export type WnaButtonIconProps = {
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
    iconName: keyof typeof FontAwesome5.glyphMap;
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
    toolTip?: string;
    color?: string;
    style?: object;
    toolTipPosition?: "top" | "right" | "bottom" | "left" | undefined;
};

const _size = 52;
const _borderRadius = _size / 2;

class WnaButtonIcon extends Component<WnaButtonIconProps> {
    state = {
        isToolTipVisible: false,
    };

    render() {
        const toolTipPos = this.props.toolTipPosition ?? _defaultToolTipPos;
        const defaultClr = convertHexToRgba(
            this.props.currentAppTheme.colors.staticBlack,
            0.6
        );
        const shadowStyle = Platform.OS == "web" ? _shadowStyle : null;
        const toolTip = this.props.toolTip ?? "";
        return (
            <Pressable
                style={({ pressed, hovered, focused }: WnaPressableState) => [
                    Platform.OS == "web" ? { cursor: "pointer" } : null,
                    shadowStyle,
                    {
                        opacity: pressed ? 0.7 : hovered || focused ? 0.9 : 1,
                        backgroundColor: defaultClr,
                    },
                    {
                        borderRadius: _borderRadius,
                        width: _size,
                        height: _size,
                        overflow: Platform.OS == "web" ? "visible" : "hidden",
                    },
                    this.props.style,
                ]}
                onHoverIn={() => {
                    if (toolTip != "" && Platform.OS == "web")
                        this.setState({ isToolTipVisible: true });
                }}
                onHoverOut={() => {
                    this.setState({ isToolTipVisible: false });
                }}
                android_ripple={{
                    color: this.props.currentAppTheme.colors.white,
                    borderless: true,
                }}
                onPress={() => {
                    this.setState({ isToolTipVisible: false });
                    execWhenOnlineAsync(
                        this.props.checkInternetConnection,
                        this.props.t,
                        this.props.onPress
                    );
                }}>
                <>
                    <WnaButtonIconInnerIcon
                        currentAppStyle={this.props.currentAppStyle}
                        currentAppTheme={this.props.currentAppTheme}
                        color={this.props.color}
                        iconName={this.props.iconName}
                    />
                    <Popable
                        content={toolTip}
                        position={
                            this.props.toolTipPosition ?? _defaultToolTipPos
                        }
                        numberOfLines={2}
                        style={{
                            bottom:
                                toolTipPos == "right" || toolTipPos == "left"
                                    ? 0
                                    : _size,
                        }}
                        visible={toolTip != "" && this.state.isToolTipVisible}>
                        {null}
                    </Popable>
                </>
            </Pressable>
        );
    }
}

export default WnaButtonIcon;
