import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaChapterListEntry from "@app/screens/diary/chapter/list/WnaChapterListEntry";
import WnaDiaryEntryListDummy from "@app/screens/diary/diaryEntry/listDummy/WnaDiaryEntryListDummy";
import WnaLogInProvider from "@app/services/WnaLogInPorvider";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import getNewIdentifier from "@domain/contracts/WnaIdentifier";
import WnaChapter from "@domain/entities/WnaChapter";
import WnaChapterDao from "@infrastructure/dao/WnaChapterDao";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { isGreaterThan } from "@infrastructure/services/WnaDateTimeService";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import isAliveAsync from "@infrastructure/wnaApi/user/queries/IsAliveAsync";
import { useIsFocused } from "@react-navigation/native";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaNotificationHeaderRight from "@ui/components/tabbar/WnaNotificationHeaderRight";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import { useOnRefreshListener } from "@ui/events/refresh/WnaUseOnRefreshListener";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, RefreshControl, View } from "react-native";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaChapterListScreen: React.FC<WnaStackScreenProps> = (props) => {
    const isFocused = useIsFocused();
    const {
        currentUser,
        currentAppTheme,
        currentAppStyle,
        currentUserSettings,
        isInternetReachable,
        unreadNotificationCount,
    } = useContext(WnaAppContext);

    const currentUserIdentifierRef = useRef<string>("");

    const [isBusy, setIsBusy] = useState(
        currentUser != null && currentUser.id != ""
    );
    const [items, setItems] = useState(Array<WnaChapter>);
    const [extraData, setExtraData] = useState(new Date());
    const { t } = useTranslation(["common", "dialog"]);

    const itemsRef = useRef(items);
    const currentIndexRef = useRef(0);
    const flRef = useRef<FlatList<WnaChapter>>(null);

    const onRefreshAsync = async (p: WnaRefreshParam) => {
        if (p.sender !== WnaApplicationConstants.ViewNameChapterEdit) return;

        try {
            WnaLogger.start(WnaChapterListScreen.name, onRefreshAsync.name);
            let newItems = itemsRef.current ?? new Array<WnaChapter>();
            let scrollToIndex = itemsRef.current.findIndex(
                (x) => x.identifier === p.context.identifier
            );
            switch (p.action) {
                case WnaRefreshAction.create:
                    WnaLogger.info("create");
                    await loadItemsFromDbAsync(true);
                    scrollToIndex = newItems.findIndex(
                        (x) => x.identifier === p.context.identifier
                    );
                    break;
                case WnaRefreshAction.update:
                    WnaLogger.info("update");
                    const index = itemsRef.current.findIndex(
                        (x) => x.identifier == p.context.identifier
                    );
                    itemsRef.current[index] = p.context;
                    newItems = itemsRef.current;
                    setExtraData(new Date());
                    break;
                case WnaRefreshAction.delete:
                    WnaLogger.info("delete");
                    newItems = [
                        ...newItems.filter(
                            (x) => x.identifier != p.context.identifier
                        ),
                    ];
                    setItems(newItems);
                    itemsRef.current = newItems;
                    if (scrollToIndex < 1 && newItems.length > 0) {
                        scrollToIndex = 0;
                    } else if (
                        scrollToIndex > 0 &&
                        newItems.length > 0 &&
                        scrollToIndex < newItems.length
                    ) {
                        scrollToIndex -= 1;
                        // keep index
                    } else {
                        scrollToIndex = 0;
                    }
                    setExtraData(new Date());
                    break;
                case WnaRefreshAction.none:
                    WnaLogger.info("none");
                    break;
                default:
                    WnaLogger.info("default");
                    break;
            }

            const animated =
                Math.abs(currentIndexRef.current - scrollToIndex) < 4;
            WnaLogger.info(
                "scroll to index: " + scrollToIndex + " | animated: " + animated
            );

            if (
                currentIndexRef.current === scrollToIndex ||
                scrollToIndex < 0 ||
                scrollToIndex >= newItems.length
            )
                return;

            setTimeout(() => {
                WnaLogger.info("scrolling to index now: " + scrollToIndex);
                flRef.current?.scrollToIndex({
                    animated: animated,
                    index: scrollToIndex,
                    viewPosition: 0.5,
                });
            }, 300);
        } catch (error) {
            WnaLogger.error(
                WnaChapterListScreen.name,
                onRefreshAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaChapterListScreen.name, onRefreshAsync.name);
        }

        return p;
    };

    const addChapterAsync = async () => {
        try {
            if (currentUser == null) return;

            if (isInternetReachable == false) {
                WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
                return;
            }

            if (
                !(await WnaDialogProvider.showYesNoAsync(
                    "Wirklich neues Kapitel anlegen?"
                ))
            )
                return;

            WnaLogger.start(WnaChapterListScreen.name, addChapterAsync.name);
            const newChapter = new WnaChapter({
                designator: "Kapitel " + (items.length + 1).toString(),
            });
            const resultChapter = await WnaChapterDao.createAsync(
                currentUser,
                newChapter
            );
            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                resultChapter.imageUrl,
                "png"
            );
            const chapters = [...items];
            chapters.push(resultChapter);
            chapters.sort((a, b) => isGreaterThan(b.dateCreate, a.dateCreate));
            setItems(chapters);
            itemsRef.current = chapters;
            props.navigation.push(
                WnaApplicationConstants.ScreenNameDiaryEntryList,
                {
                    currentChapter: resultChapter,
                    goToAddNew: true,
                }
            );
        } catch (error) {
            WnaLogger.error(
                WnaChapterListScreen.name,
                addChapterAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaChapterListScreen.name, addChapterAsync.name);
        }
    };

    const loadItemsFromDbAsync = async (forceReload: boolean) => {
        let newItems = new Array<WnaChapter>();
        try {
            if (currentUser == null) return;

            WnaLogger.start(
                WnaChapterListScreen.name,
                loadItemsFromDbAsync.name
            );
            currentIndexRef.current = 0;
            currentUserIdentifierRef.current = currentUser.id;

            if (isInternetReachable === true && (await isAliveAsync())) {
                WnaLogger.info(
                    "internetIsReachable --> will load from FireStore"
                );
                newItems = await WnaChapterDao.readAllAsync(
                    currentUser,
                    currentUserSettings?.diaryOrderBy ?? 1,
                    forceReload
                );
            } else {
                WnaLogger.info("internetIsReachable --> will load from Cache");
                newItems = await WnaChapterDao.readAllOfflineAsync(currentUser);
            }
            if (newItems.length < 1) {
                // there is no chapter --> create initial chapter
                if (isInternetReachable === true && (await isAliveAsync())) {
                    const initialChapter = new WnaChapter({
                        identifier: getNewIdentifier(),
                        designator: "Kapitel 1",
                    });
                    const resultChapter = await WnaChapterDao.createAsync(
                        currentUser,
                        initialChapter
                    );
                    newItems.push(resultChapter);
                } else {
                    WnaToastProvider.showError(
                        t(i18nKeysCommon.errorNoInternet)
                    );
                }
            }

            setItems(newItems);
            itemsRef.current = newItems;
        } catch (error) {
            WnaLogger.error(
                WnaChapterListScreen.name,
                loadItemsFromDbAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaChapterListScreen.name, loadItemsFromDbAsync.name);
            setIsBusy(false);
        }
        return newItems;
    };

    const renderItem = (currentItem: WnaChapter) => {
        return (
            <WnaChapterListEntry
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                currentItem={currentItem}
                onPress={() => {
                    props.navigation.push(
                        WnaApplicationConstants.ScreenNameDiaryEntryList,
                        {
                            currentChapter: currentItem,
                        }
                    );
                }}
                t={t}
            />
        );
    };

    useEffect(() => {
        WnaLogger.start(WnaChapterListScreen.name, useEffect.name);
        props.navigation.setOptions({
            headerRight: () => (
                <WnaNotificationHeaderRight
                    unreadNotificationCount={unreadNotificationCount}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    currentUser={currentUser}
                    t={t}
                />
            ),
        });

        if (isFocused && currentUser != null && currentUser.id != "") {
            if (currentUser?.identifier != currentUserIdentifierRef.current) {
                loadItemsFromDbAsync(true);
            }
        }

        WnaLogger.end(WnaChapterListScreen.name, useEffect.name);
    }, [isFocused, currentUserSettings, currentUser, unreadNotificationCount]);

    useOnRefreshListener(async (p) => {
        await onRefreshAsync(p.refreshParameter);
    }, []);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            {currentUser == null || currentUser.id == "" ? (
                <WnaDiaryEntryListDummy
                    onLogIn={() => {
                        WnaLogInProvider.show();
                    }}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    t={t}
                />
            ) : (
                <>
                    <FlatList
                        ref={flRef}
                        keyExtractor={(item) => item.identifier}
                        ItemSeparatorComponent={() => (
                            <View style={{ height: 8 }} />
                        )}
                        data={items}
                        contentContainerStyle={{
                            paddingBottom: 80,
                            paddingTop: 16,
                            paddingHorizontal: 16,
                        }}
                        initialNumToRender={3}
                        maxToRenderPerBatch={3}
                        extraData={extraData}
                        refreshControl={
                            <RefreshControl
                                refreshing={isBusy}
                                onRefresh={() => loadItemsFromDbAsync(true)}
                            />
                        }
                        renderItem={({ item: currentItem }) =>
                            renderItem(currentItem)
                        }
                    />
                    {/* Add Button */}
                    {isInternetReachable === true ? (
                        <WnaButtonIcon
                            toolTip={t(i18nKeysCommon.actionCreateChapter)}
                            toolTipPosition="left"
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="book-medical"
                            style={{
                                position: "absolute",
                                bottom: 16,
                                right: 16,
                            }}
                            onPress={addChapterAsync}
                            t={t}
                            checkInternetConnection={true}
                        />
                    ) : null}
                </>
            )}
        </WnaBaseScreenWithInfo>
    );
};

export default WnaChapterListScreen;
