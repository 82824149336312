import * as Crypto from "expo-crypto";
import WnaLogger from "wna-logger";

const _tsFileName = "WnaHashService.ts";

const getSha256HashAsync = async (text: string) => {
    let hashHex = "";
    try {
        // WnaLogger.start(WnaCryptoProvider.name, WnaCryptoProvider.getSha256HashAsync.name);
        hashHex = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA256,
            text
        );
        // WnaLogger.info("hashHex: " + hashHex);
    } catch (error) {
        WnaLogger.end(_tsFileName, getSha256HashAsync.name, error);
    } finally {
        // WnaLogger.end(WnaCryptoProvider.name, WnaCryptoProvider.getSha256HashAsync.name);
    }
    return hashHex;
};

export { getSha256HashAsync };
