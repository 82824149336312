import { Buffer } from "buffer";
import WnaLogger from "wna-logger";

const getMimeTypeFromBase64 = (b64: string) => {
    return b64.split(";base64,")[0].replace("data:", "");
};

const stripMimeTypeFromBase64 = (b64: string) => {
    return b64.split(";base64,")[1];
};

// const getBlobFromBase64 = (b64: string) => {
//     const mimeType = getMimeTypeFromBase64(b64);
//     const plainB64 = stripMimeTypeFromBase64(b64);
//
//     WnaLogger.info(__filename, getBlobFromBase64.name, "mimeType: " + mimeType);
//     WnaLogger.info(
//         __filename,
//         getBlobFromBase64.name,
//         "plainB64.length: " + plainB64.length
//     );
//
//     const buffer = Buffer.from(plainB64, "base64");
//     return new Blob([buffer], { type: mimeType + ";charset=utf-8" });
// };

const blobToBase64Async = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = () => reject(fileReader.error);
        fileReader.onloadend = () => {
            const dataUrl = fileReader.result as string;
            // remove "data:mime/type;base64," prefix from data url
            const base64 = dataUrl.substring(dataUrl.indexOf(",") + 1);
            resolve(base64);
        };
        fileReader.readAsDataURL(blob);
    });
};

const blobToDataUriAsync = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = () => reject(fileReader.error);
        fileReader.onloadend = () => {
            const dataUrl = fileReader.result as string;
            resolve(dataUrl);
        };
        fileReader.readAsDataURL(blob);
    });
};

export {
    blobToBase64Async,
    blobToDataUriAsync,
    // getBlobFromBase64,
    getMimeTypeFromBase64,
    stripMimeTypeFromBase64,
};
