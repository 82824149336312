import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaChapter from "@domain/entities/WnaChapter";
import { getColorByWeekday } from "@infrastructure/services/WnaDateTimeService";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaListCardWhiteImage from "@ui/components/cards/WnaListCardWhiteImage";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { Text } from "react-native";

export type WnaChapterListEntryProps = {
    currentItem: WnaChapter;
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    onPress: (currentItem: WnaChapter) => void;
    t: TFunction<string[], undefined>;
};

class WnaChapterListEntry extends Component<WnaChapterListEntryProps> {
    shouldComponentUpdate(nextProps: WnaChapterListEntryProps) {
        const nextDateCreate = new Date(nextProps.currentItem.dateCreate);
        const nextDateUpdate = new Date(nextProps.currentItem.dateUpdate);
        const currentDateCreate = new Date(this.props.currentItem.dateCreate);
        const currentDateUpdate = new Date(this.props.currentItem.dateUpdate);
        return (
            nextDateCreate.toISOString() != currentDateCreate.toISOString() ||
            nextDateUpdate.toISOString() != currentDateUpdate.toISOString() ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        // WnaLogger.info(ChapterListEntry.name, this.render.name, this.props.currentItem.designator);
        return (
            <WnaPressable
                disableHover
                toolTip=""
                style={[
                    this.props.currentAppStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                    },
                ]}
                onPress={() => this.props.onPress(this.props.currentItem)}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteImage
                    currentAppStyle={this.props.currentAppStyle}
                    currentAppTheme={this.props.currentAppTheme}
                    headerTitle={""}
                    color={getColorByWeekday(this.props.currentItem.dateCreate)}
                    hasRoute={false}
                    hasImages={false}
                    imageUrl={this.props.currentItem.imageUrl}>
                    {this.props.currentItem.designator != "" &&
                    this.props.currentItem.designator.trim() != "" ? (
                        <Text
                            style={[
                                this.props.currentAppStyle.textTitleLarge,
                                { marginBottom: 8 },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {this.props.currentItem.designator}
                        </Text>
                    ) : null}
                </WnaListCardWhiteImage>
            </WnaPressable>
        );
    }
}

export default WnaChapterListEntry;
