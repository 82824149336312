import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

/**
 * returns count of unread notifications
 * @param wnaUser
 */
const countUnreadNotificationsAsync = async (wnaUser: WnaUser) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/notifications/countUnread`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(await fetch(url, request));
    return (await response.json()) as Int32;
};

export default countUnreadNotificationsAsync;
