import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import { Component } from "react";
import { Text } from "react-native";
import WnaListCardWhiteDecent from "./WnaListCardWhiteDecent";

export type WnaCardActivityIndicatorProps = {
    isBusyText: string;
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
};

class WnaCardActivityIndicator extends Component<WnaCardActivityIndicatorProps> {
    render() {
        return (
            <WnaListCardWhiteDecent
                currentAppTheme={this.props.currentAppTheme}>
                <WnaActivityIndicator
                    currentAppTheme={this.props.currentAppTheme}
                />
                {this.props.isBusyText != "" ? (
                    <Text
                        style={[
                            this.props.currentAppStyle.textTitleLarge,
                            {
                                width: 128,
                                marginTop: 24,
                                textAlign: "center",
                            },
                        ]}>
                        {this.props.isBusyText}
                    </Text>
                ) : null}
            </WnaListCardWhiteDecent>
        );
    }
}

export default WnaCardActivityIndicator;
