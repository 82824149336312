import { ImageStyle } from "expo-image";
import React, { Component } from "react";
import { ImageBackground } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

export type WnaImageBackgroundProps = {
    image: string;
    style?: ImageStyle;
    children: React.ReactNode;
    blurRadius?: Int32;
};

const defaultStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
} as ImageStyle;

class WnaImageBackground extends Component<WnaImageBackgroundProps> {
    // shouldComponentUpdate(nextProps: Readonly<WnaImageBackgroundProps>): boolean {
    //     return nextProps.image != this.props.image
    //     || nextProps.children !=  this.props.children ;
    // }
    render() {
        if (this.props.image == "") {
            WnaLogger.warn(
                WnaImageBackground.name,
                this.render.name,
                "imageUri is empty"
            );
            return <>{this.props.children}</>;
        } else {
            return (
                <ImageBackground
                    source={{ uri: this.props.image, cache: "force-cache" }}
                    resizeMode="cover"
                    blurRadius={
                        this.props.blurRadius != null
                            ? this.props.blurRadius
                            : undefined
                    }
                    style={this.props.style ?? defaultStyle}>
                    {this.props.children}
                </ImageBackground>
            );
        }
    }
}
export default WnaImageBackground;
