import WnaDiaryEntryListItem from "@app/screens/diary/diaryEntry/list/WnaDiaryEntryListItem";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import WnaFileInfo from "@domain/contracts/types/WnaFileInfo";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { TFunction } from "i18next";
import { Component } from "react";
import { ScrollView } from "react-native-gesture-handler";

export type WnaDiaryEntryListEmptyProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    onPress: () => void;
    t: TFunction<string[], undefined>;
};

const _today = new Date();

const _dummy0 = {
    imageUrl: WnaAppSettingsDao.getImageResourceUrl("ph_003.jpg"),
    dateStart: _today,
    dateCreate: _today,
    dateUpdate: _today,
    designator: "Erstelle einen neuen Eintrag",
    comment: "",
    images: new Array<WnaFileInfo>(),
    routeIdentifier: "",
} as WnaDiaryEntry;

class WnaDiaryEntryListEmpty extends Component<WnaDiaryEntryListEmptyProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDiaryEntryListEmptyProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        return (
            <ScrollView
                style={[
                    this.props.currentAppStyle.containerFillPage,
                    {
                        padding: 16,
                    },
                ]}>
                <WnaDiaryEntryListItem
                    currentAppStyle={this.props.currentAppStyle}
                    currentAppTheme={this.props.currentAppTheme}
                    key={"dummy0"}
                    currentItem={_dummy0}
                    onPress={this.props.onPress}
                    t={this.props.t}
                />
            </ScrollView>
        );
    }
}

export default WnaDiaryEntryListEmpty;
