import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import { WnaMapPoint } from "@domain/contracts/types/WnaMapPoint";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

export class WnaMapsGraphData {
    public geoJson: string = "";
    public routeName: string = "";
    public routeHash: string = "";
    public xAxisData: Array<string> = [];
    public yAxisData: Array<Int32> = [];
    public yAxisMax: Int32 = 3000;
    public yAxisMin: Int32 = 0;
    public routePoints: Array<WnaMapPoint> = [];
    public featurePoints: Array<WnaMapPoint> = [];
    public distanceScale: number = 0;
    public timeStampStart: Date = getUnixMinDate();
    public timeStampEnd: Date = getUnixMinDate();
    public distanceUnitText: string = "";
    public prosaText: string = "";

    public googleMapsStaticUrl: string = "";

    public infoDistance: Int32 = 0;
    public infoDistanceText: string = "";
    public infoDistanceDurationInMinutes: number = 0;

    public infoAltMin: Int32 = 0;
    public infoAltMinText: string = "";

    public infoAltMax: Int32 = 0;
    public infoAltMaxText: string = "";

    public infoAltDiff: Int32 = 0;
    public infoAltDiffText: string = "";

    public infoAltUp: Int32 = 0;
    public infoAltUpText: string = "";
    public infoAltUpDistance: Int32 = 0;
    public infoAltUpDistanceText: string = "";
    public infoAltUpDurationInMinutes: number = 0;

    public infoAltDown: Int32 = 0;
    public infoAltDownText: string = "";
    public infoAltDownDistance: Int32 = 0;
    public infoAltDownDistanceText: string = "";
    public infoAltDownDurationInMinutes: number = 0;

    public infoAltEqual: Int32 = 0;
    public infoAltEqualText: string = "";
    public infoAltEqualDistance: Int32 = 0;
    public infoAltEqualDistanceText: string = "";
    public infoAltEqualDurationInMinutes: number = 0;

    public infoTotalDurationInMinutes: number = 0;
    public infoTotalDurationText: string = "";

    constructor(init?: Partial<WnaMapsGraphData | null>) {
        Object.assign(this, init);
    }
}
