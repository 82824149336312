// import WnaGeoHelper, {
//     WnaMapsGraphData
// } from "../../infrastructure/WnaGeoHelper";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaRoute extends WnaEntityBase {
    public imageUrl: string = "";
    public geoJson: string = "";
    public geoJsonUrl: string = "";

    public staticSatelliteUrl: string = "";
    public staticSatellite512Url: string = "";
    public staticSatellite256Url: string = "";

    public staticHybridUrl: string = "";
    public staticHybrid512Url: string = "";
    public staticHybrid256Url: string = "";

    public staticRoadMapUrl: string = "";
    public staticRoadMap512Url: string = "";
    public staticRoadMap256Url: string = "";

    public staticTerrainUrl: string = "";
    public staticTerrain512Url: string = "";
    public staticTerrain256Url: string = "";

    public geoJsonHash: string = "";
    public distance: number = 0;
    constructor(init?: Partial<WnaRoute>) {
        super(init);
        Object.assign(this, init);
    }

    // public static async getGraphDataAsync(geoJson: string, quality: Int32) {
    //     let ret = new WnaMapsGraphData();
    //     try {
    //         WnaLogger.start(WnaRoute.name, this.getGraphDataAsync.name);
    //         ret = await WnaGeoHelper.getGraphDataByGeoJsonAsync(
    //             geoJson,
    //             quality
    //         );
    //     } catch (error) {
    //         WnaLogger.error(WnaRoute.name, this.getGraphDataAsync.name, error);
    //     } finally {
    //         WnaLogger.end(WnaRoute.name, this.getGraphDataAsync.name);
    //     }
    //     return ret;
    // }
}
