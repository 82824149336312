import { WnaAppContext } from "@app/WnaAppContext";
import { FontAwesome5 } from "@expo/vector-icons";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaButtonIconText from "@ui/components/buttons/WnaButtonIconText";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import WnaSeparatorHorizontal from "@ui/components/misc/WnaSeparatorHorizontal";
import { WnaShadowStyle } from "@ui/components/misc/WnaShadowStyle";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

export type WnaLoginModalProps = {
    modal: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closeModal;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getParam;
    };
};

const WnaLoginModal: React.FC<WnaLoginModalProps> = (props) => {
    const { currentAppTheme, currentAppStyle } =
        React.useContext(WnaAppContext);
    const pkg = require("wna-app-json");
    const { t } = useTranslation(["common"]);
    const [isBusy, setIsBusy] = useState(props.modal.getParam("isBusy", false));
    const onCancel = props.modal.closeModal as () => void;
    const onLogInWithGoogleParam = props.modal.getParam(
        "onLogInWithGoogle",
        async () => {}
    ) as () => {};
    const onLogInWithFacebookParam = props.modal.getParam(
        "onLogInWithFacebook",
        async () => {}
    ) as () => {};

    const onLogInWithGoogleAsync = async () => {
        onLogInWithGoogleParam();
        setIsBusy(false);
        setTimeout(() => {
            onCancel();
        }, 3000);
    };

    const onLogInWithFacebookAsync = async () => {
        onLogInWithFacebookParam();
        setIsBusy(false);
        setTimeout(() => {
            onCancel();
        }, 3000);
    };

    return (
        <>
            <View style={currentAppStyle.containerCenterCenter}>
                <View
                    style={[
                        WnaShadowStyle(),
                        currentAppStyle.containerCenter,
                        {
                            backgroundColor: currentAppTheme.colors.white,
                            borderRadius: 16,
                            paddingVertical: 44,
                            paddingHorizontal: 24,
                            maxWidth: 386,
                        },
                    ]}>
                    <View
                        style={[
                            currentAppStyle.containerCenterCenter,
                            {
                                width: 96,
                                height: 96,
                                maxHeight: 96,
                                backgroundColor:
                                    currentAppTheme.colors.staticAccent4,
                                borderRadius: 48,
                                overflow: "hidden",
                                padding: 22,
                            },
                        ]}>
                        <FontAwesome5
                            name={"user"}
                            size={48}
                            color={currentAppTheme.colors.staticWhite}
                        />
                    </View>
                    <Text
                        style={[
                            currentAppStyle.textTitleLarge,
                            {
                                marginTop: 16,
                                color: currentAppTheme.colors.coolgray6,
                            },
                        ]}>
                        {t(i18nKeysCommon.actionLogIn)}
                    </Text>
                    <WnaSeparatorHorizontal />
                    <View style={{ gap: 16, minWidth: 256 }}>
                        {isBusy ? (
                            <WnaActivityIndicator
                                currentAppTheme={currentAppTheme}
                            />
                        ) : (
                            <>
                                <WnaButtonIconText
                                    currentAppTheme={currentAppTheme}
                                    iconName="google"
                                    backgroundColor="#ffffff"
                                    textColor={
                                        currentAppTheme.colors.staticCoolgray6
                                    }
                                    borderColor={
                                        currentAppTheme.colors.staticCoolgray6
                                    }
                                    borderWidth={1}
                                    text={t(
                                        i18nKeysCommon.actionLogInWithGoogle
                                    )}
                                    onPress={() => {
                                        setIsBusy(true);
                                        onLogInWithGoogleAsync();
                                        setTimeout(
                                            () => setIsBusy(false),
                                            10000
                                        );
                                    }}
                                    t={t}
                                    checkInternetConnection={true}
                                />
                                <WnaButtonIconText
                                    currentAppTheme={currentAppTheme}
                                    iconName="facebook"
                                    backgroundColor="#3A4F83"
                                    text={t(
                                        i18nKeysCommon.actionLogInWithFacebook
                                    )}
                                    onPress={() => {
                                        setIsBusy(true);
                                        onLogInWithFacebookAsync();
                                        setTimeout(
                                            () => setIsBusy(false),
                                            10000
                                        );
                                    }}
                                    t={t}
                                    checkInternetConnection={true}
                                />
                            </>
                        )}
                    </View>
                    <WnaSeparatorHorizontal />
                    <View style={{ gap: 16, minWidth: 256 }}>
                        <WnaButtonIconText
                            currentAppTheme={currentAppTheme}
                            iconName="arrow-left"
                            text={t(i18nKeysCommon.actionGoBack)}
                            onPress={onCancel}
                            t={t}
                            checkInternetConnection={false}
                        />
                    </View>
                    <View
                        pointerEvents="none"
                        style={[
                            currentAppStyle.containerCenter,
                            {
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                padding: 8,
                            },
                        ]}>
                        <Text
                            style={[
                                currentAppStyle.textNeutralSmall,
                                { color: currentAppTheme.colors.black },
                            ]}>
                            {pkg.expo.version}
                        </Text>
                    </View>
                </View>
            </View>
        </>
    );
};

export default WnaLoginModal;
