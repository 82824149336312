import { WnaFontFamilies } from "@domain/contracts/theme/WnaFontFamilies";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { StyleSheet } from "react-native";

const setAppStyle = (wnaTheme: WnaTheme) => {
    const style = StyleSheet.create({
        maxWidth: {
            maxWidth: 960,
            width: "100%",
        },
        containerCenterMaxWidth: {
            maxWidth: 960,
            width: "100%",
            alignSelf: "center",
            padding: 16,
        },

        // VIEW CONTAINER
        containerCenter: {
            alignItems: "center",
        },

        containerCenterCenter: {
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
        },

        containerFillPage: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },

        containerFillPageMaxWidth: {
            maxWidth: 960,
            width: "100%",
            // alignSelf: "center", // 24-02-24
            alignSelf: "stretch",
            padding: 16,
            position: "absolute",
            top: 0,
            bottom: 0,
        },

        containerForInputTopCenter: {
            alignItems: "center",
            justifyContent: "flex-start",
            flex: 1,
            flexDirection: "column",
            gap: 16,
            width: "100%",
        },

        containerForInputCenterCenter: {
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
            gap: 16,
            width: "100%",
        },

        // NAVIGATION

        navigationHeaderTitleStyle: {
            fontFamily: WnaFontFamilies.Courgette,
            width: "100%",
            borderTopWidth: 0,
            borderBottomWidth: 0,
        },

        // tabbar
        tabBarStyle: {
            backgroundColor: wnaTheme.colors.white,
            borderTopWidth: 0,
            borderBottomWidth: 0,
        },

        tabBarBadgeStyle: {
            backgroundColor: wnaTheme.colors.red3,
            color: wnaTheme.colors.staticWhite,
        },

        // Buttons
        buttonWithText: {
            padding: 16,
            borderRadius: 8,
            minWidth: 128,
            overflow: "hidden",
        },
        separator: {
            marginVertical: 30,
            height: 1,
            width: "80%",
        },

        // TEXT

        textTitleLarge: {
            fontSize: 18,
            fontFamily: WnaFontFamilies.Courgette,
            color: wnaTheme.colors.black,
        },

        textSmall: {
            fontSize: 16,
            fontFamily: WnaFontFamilies.Courgette,
            color: wnaTheme.colors.black,
        },

        textNeutralSmall: {
            fontSize: 12,
            fontFamily: WnaFontFamilies.Roboto,
            color: wnaTheme.colors.black,
        },

        textNeutralMedium: {
            fontSize: 14,
            fontFamily: WnaFontFamilies.Roboto,
            color: wnaTheme.colors.black,
        },

        textInput: {
            borderWidth: 1,
            borderRadius: 8,
            width: "80%",
            padding: 8,
            fontSize: 18,
            lineHeight: 24,
            borderColor: wnaTheme.colors.coolgray2,
            backgroundColor: wnaTheme.colors.white,
            color: wnaTheme.colors.black,
            verticalAlign: "middle",
            fontFamily: WnaFontFamilies.Roboto,
        },

        // SEPARATOR
        separatorHorizontal: {
            minHeight: 1,
            margin: 16,
            minWidth: 128,
            width: "90%",
        },
    });
    return style as WnaAppStyle;
};

export { setAppStyle };
