import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import { minifyJson } from "@infrastructure/services/WnaJsonMinify";
import {
    convertGpxToGeoJson,
    convertKmlToGeoJson,
} from "@infrastructure/services/geodata/WnaKmlToGeoJsonConverter";
import { getGraphDataByGeoJsonAsync } from "@infrastructure/services/geodata/WnaMapsGraphDataService";
import * as DocumentPicker from "expo-document-picker";
import { Platform } from "react-native";
import * as mime from "react-native-mime-types";
import WnaLogger from "wna-logger";

const _tsFileName = "WnaMapsGoogle.native.tsx";

const pickGeoFileAsync = async (onError: (errorMessage: string) => void) => {
    let ret = new WnaMapsGraphData();
    try {
        WnaLogger.start(_tsFileName, pickGeoFileAsync.name);

        const mimeTypeKml = mime.lookup("kml");
        const mimeTypeGeoJson = mime.lookup("geojson");
        const mimeTypeGpx = mime.lookup("gpx");
        const result = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: Platform.OS != "web",
            // ToDo https://en.wikipedia.org/wiki/Media_type

            type:
                Platform.OS != "web"
                    ? [
                          "text/xml",
                          "text/gpsxml",
                          "application/xml",
                          "application/gpsxml",

                          "text/kml",
                          "application/vnd.google-earth.kml",
                          "application/vnd.google-earth.kml+xml",

                          "text/kmz",
                          "application/vnd.google-earth.kmz",
                          "application/vnd.google-earth.kmz+xml",

                          "text/gpx",
                          "application/gpx",
                          "application/gpx+xml",
                          "application/vnd.google-earth.gpx",
                          "application/vnd.google-earth.gpx+xml",

                          "application/octet-stream",

                          mimeTypeKml != false ? mimeTypeKml : "",
                          mimeTypeGeoJson != false ? mimeTypeGeoJson : "",
                          mimeTypeGpx != false ? mimeTypeGpx : "",
                      ]
                    : ["*.*"],
        });

        if (result.assets == null) return ret;

        const fileName = result.assets[0].name;
        const fileExt = fileName.split(".").pop();
        const fileExtLow = fileExt!.toLocaleLowerCase();
        switch (fileExtLow) {
            case "geojson":
            case "kml":
            case "gpx":
                break;
            default:
                onError("*.geojson, *.kml, *.gpx");
                return ret;
        }
        // WnaLogger.info(JSON.stringify(result.assets[0]));
        // https://stackoverflow.com/a/76913577
        const response = await fetch(result.assets[0].uri);
        const fileText = await response.text();

        let geoJson = "";
        switch (fileExtLow) {
            case "geojson":
                {
                    geoJson = fileText;
                    const miniGeoJson = minifyJson(geoJson);
                    if (miniGeoJson != "") geoJson = miniGeoJson;
                }
                break;
            case "kml":
                geoJson = convertKmlToGeoJson(fileText);
                break;
            case "gpx":
                geoJson = convertGpxToGeoJson(fileText);
                break;
            default:
                break;
        }

        if (geoJson == "") {
            onError("Es ist ein Fehler aufgetreten");
            return ret;
        }

        ret = await getGraphDataByGeoJsonAsync(geoJson, 4);
    } catch (error) {
        WnaLogger.error(_tsFileName, pickGeoFileAsync.name, error);
    } finally {
        WnaLogger.end(_tsFileName, pickGeoFileAsync.name);
    }

    return ret;
};

export { pickGeoFileAsync };
