import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import WnaRoute from "@domain/entities/WnaRoute";
import WnaUser from "@domain/entities/WnaUser";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import createOrUpdateRouteAsync from "@infrastructure/wnaApi/route/commands/CreateOrUpdateRoute";
import getAllRoutesAsync from "@infrastructure/wnaApi/route/queries/GetAllRoutes";
import getRouteByIdentifierAsync from "@infrastructure/wnaApi/route/queries/GetRouteByIdentifier";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

const _col = "routes";
const _useDb = true;
const _encrypted = false;

/**
 * Dao for routes
 */
export default class WnaRouteDao {
    private static _isSynced: boolean = false;

    public static resetIsSynced() {
        WnaRouteDao._isSynced = false;
    }

    public static getPath(itemIdentifier: string) {
        if (itemIdentifier == "") return "";

        return _col + "/" + itemIdentifier;
    }

    /**
     * readByIdentifier
     * @param user
     * @param identifier
     * @returns
     */
    public static async readByIdentifierAsync(
        user: WnaUser | null,
        identifier: string
    ) {
        WnaLogger.start(
            WnaRouteDao.name,
            WnaRouteDao.readByIdentifierAsync.name,
            identifier
        );

        let ret = new WnaRoute();
        try {
            WnaLogger.info(
                WnaRouteDao.name,
                WnaRouteDao.readByIdentifierAsync.name,
                "is synced - read from async storage"
            );
            const json =
                (await WnaAsyncStorageProvider.getItemAsync(
                    _col + identifier,
                    _encrypted,
                    _useDb
                )) ?? "";
            if (json == "") {
                ret = await getRouteByIdentifierAsync(identifier);

                if (ret.geoJsonHash != "") {
                    // store to async storage
                    const json = JSON.stringify(ret);
                    await WnaAsyncStorageProvider.setItemAsync(
                        _col + identifier,
                        json,
                        _encrypted,
                        _useDb
                    );
                }
            } else {
                ret = JSON.parse(json);
            }
        } catch (error) {
            WnaLogger.error(
                WnaRouteDao.name,
                WnaRouteDao.readByIdentifierAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaRouteDao.name,
            WnaRouteDao.readByIdentifierAsync.name,
            identifier
        );
        return ret;
    }

    public static async createOrUpdateAsync(route: WnaRoute) {
        let ret = new WnaRoute();
        try {
            WnaLogger.start(
                WnaRouteDao.name,
                WnaRouteDao.createOrUpdateAsync.name
            );

            ret = await createOrUpdateRouteAsync(route);
        } catch (error) {
            WnaLogger.error(
                WnaRouteDao.name,
                WnaRouteDao.createOrUpdateAsync.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaRouteDao.name,
                WnaRouteDao.createOrUpdateAsync.name
            );
        }
        return ret;
    }

    public static async readAllAsync(forceRefresh: boolean) {
        let ret = new Array<WnaRoute>();
        try {
            WnaLogger.start(
                WnaRouteDao.name,
                WnaRouteDao.readAllAsync.name,
                "forceRefresh: " + forceRefresh
            );
            if (forceRefresh) WnaRouteDao.resetIsSynced();

            if (!forceRefresh && WnaRouteDao._isSynced) {
                WnaLogger.info(
                    WnaRouteDao.name,
                    WnaRouteDao.readAllAsync.name,
                    "is synced - read from async storage"
                );
                const json =
                    (await WnaAsyncStorageProvider.getItemAsync(
                        _col,
                        _encrypted,
                        _useDb
                    )) ?? "";
                ret = JSON.parse(json);
            } else {
                WnaLogger.info(
                    WnaRouteDao.name,
                    WnaRouteDao.readAllAsync.name,
                    "is not synced - write to async storage"
                );

                ret = await getAllRoutesAsync();

                // store to async storage
                const json = JSON.stringify(ret);
                await WnaAsyncStorageProvider.setItemAsync(
                    _col,
                    json,
                    _encrypted,
                    _useDb
                );
                WnaRouteDao._isSynced = true;
            }
        } catch (error) {
            WnaLogger.error(
                WnaRouteDao.name,
                WnaRouteDao.readAllAsync.name,
                error
            );
            WnaRouteDao._isSynced = false;
        } finally {
            WnaLogger.end(
                WnaRouteDao.name,
                WnaRouteDao.readAllAsync.name,
                "forceRefresh: " + forceRefresh
            );
        }
        return ret;
    }

    public static async uploadStaticMapImagesAsync(
        wnaRoute: WnaRoute,
        graphData: WnaMapsGraphData,
        googleApiKey: string,
        firestorePath: string,
        mapType: "satellite" | "hybrid" | "terrain" | "roadmap",
        quality: Int32
    ) {
        try {
            WnaLogger.start(
                WnaRouteDao.name,
                WnaRouteDao.uploadStaticMapImagesAsync.name
            );

            // const staticUrl = WnaGeoHelper.getGoogleMapsStaticUrl(
            //     graphData,
            //     googleApiKey,
            //     mapType
            // );
            // const fi = await WnaFirebase.uploadImageAsync(
            //     firestorePath,
            //     staticUrl,
            //     "route_" + mapType + ".png",
            //     undefined,
            //     undefined,
            //     quality
            // );
            //
            // switch (mapType) {
            //     case "hybrid":
            //         wnaRoute.staticHybridUrl = fi.url;
            //         wnaRoute.staticHybrid256Url = fi.urlTh256;
            //         wnaRoute.staticHybrid512Url = fi.urlTh512;
            //         break;
            //     case "satellite":
            //         wnaRoute.staticSatelliteUrl = fi.url;
            //         wnaRoute.staticSatellite256Url = fi.urlTh256;
            //         wnaRoute.staticSatellite512Url = fi.urlTh512;
            //         break;
            //     case "roadmap":
            //         wnaRoute.staticRoadMapUrl = fi.url;
            //         wnaRoute.staticRoadMap256Url = fi.urlTh256;
            //         wnaRoute.staticRoadMap512Url = fi.urlTh512;
            //         break;
            //     case "terrain":
            //         wnaRoute.staticTerrainUrl = fi.url;
            //         wnaRoute.staticTerrain256Url = fi.urlTh256;
            //         wnaRoute.staticTerrain512Url = fi.urlTh512;
            //         break;
            // }
        } catch (error) {
            WnaLogger.error(
                WnaRouteDao.name,
                WnaRouteDao.uploadStaticMapImagesAsync.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaRouteDao.name,
                WnaRouteDao.uploadStaticMapImagesAsync.name
            );
        }
    }
}
