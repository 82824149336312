import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaChapterEditView from "@app/screens/diary/diaryEntry/detail/chapterEdit/WnaChapterEditView";
import WnaDiaryEntryListItem from "@app/screens/diary/diaryEntry/list/WnaDiaryEntryListItem";
import { WnaDiaryEntryListScreenProps } from "@app/screens/diary/diaryEntry/list/WnaDiaryEntryListScreenProps";
import WnaDiaryEntryListDummy from "@app/screens/diary/diaryEntry/listDummy/WnaDiaryEntryListDummy";
import WnaDiaryEntryListEmpty from "@app/screens/diary/diaryEntry/listEmpty/WnaDiaryEntryListEmpty";
import SideMenu from "@chakrahq/react-native-side-menu";
import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import WnaChapter from "@domain/entities/WnaChapter";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaDiaryEntryDao from "@infrastructure/dao/WnaDiaryEntryDao";
import { i18nKeysCommon, i18nKeysDialog } from "@infrastructure/i18n/i18nKeys";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import { useIsFocused } from "@react-navigation/native";
import WnaButtonHeader from "@ui/components/buttons/WnaButtonHeader";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import { useOnRefreshListener } from "@ui/events/refresh/WnaUseOnRefreshListener";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, RefreshControl, View } from "react-native";
import { useModal } from "react-native-modalfy";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

const WnaDiaryEntryListScreen: FC<WnaStackScreenProps> = (props) => {
    const pProps = props.route.params as WnaDiaryEntryListScreenProps;
    const isFocused = useIsFocused();
    const { openModal } = useModal();
    const {
        isInternetReachable,
        currentUser,
        currentUserSettings,
        currentAppTheme,
        currentAppStyle,
    } = useContext(WnaAppContext);
    const [currentUserId, setCurrentUserId] = useState("");
    const [isBusy, setIsBusy] = useState(
        currentUser != null && currentUser.id != ""
    );
    const [items, setItems] = useState<Array<WnaDiaryEntry>>([]);
    const itemsRef = useRef(items);
    const [extraData, setExtraData] = useState(new Date());
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentIndexRef = useRef(currentIndex);
    const [diaryOrderBy, setDiaryOrderBy] = useState(
        currentUserSettings?.diaryOrderBy ?? 1
    );
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const isSidebarOpenRef = useRef(isSidebarOpen);
    const sidebarRef = useRef<SideMenu>(null);
    const flRef = useRef<FlatList<WnaDiaryEntry>>(null);
    const [backgroundImageB64, setBackgroundImageB64] = useState("");
    const { t } = useTranslation(["common", "dialog"]);

    const closeSideBar = () => {
        if (isSidebarOpenRef.current) {
            setIsSidebarOpen(false);
            isSidebarOpenRef.current = false;
            return false;
        }
        return true;
    };

    const loadBackgroundImageB64Async = async (imageUrl: string) => {
        const imageB64 =
            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                imageUrl,
                "png"
            );
        setBackgroundImageB64(imageB64);
    };

    const loadItemsFromDbAsync = async (forceReload: boolean) => {
        let newItems = new Array<WnaDiaryEntry>();
        try {
            WnaLogger.start(
                WnaDiaryEntryListScreen.name,
                loadItemsFromDbAsync.name
            );
            setCurrentIndex(0);
            currentIndexRef.current = 0;

            if (currentUser == null) {
                WnaLogger.warn("currentUser is null");
                return newItems;
            }

            setCurrentUserId(currentUser.id);
            let needReload = false;

            if (itemsRef.current.length < 1) needReload = true;

            if (forceReload) needReload = true;

            if (needReload) {
                WnaLogger.info("reloading data for user: " + currentUser.id);
                setIsBusy(true);
                if (isInternetReachable === true) {
                    newItems = await WnaDiaryEntryDao.readAllByChapterAsync(
                        currentUser,
                        pProps.currentChapter,
                        currentUserSettings?.diaryOrderBy ?? 1,
                        forceReload
                    );
                } else {
                    newItems = await WnaDiaryEntryDao.readAllByChapterAsync(
                        currentUser,
                        pProps.currentChapter,
                        currentUserSettings?.diaryOrderBy ?? 1,
                        false
                    );
                }
                setItems(newItems);
                itemsRef.current = newItems;
                setIsBusy(false);
            }
            setDiaryOrderBy(currentUserSettings?.diaryOrderBy ?? 1);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryListScreen.name,
                loadItemsFromDbAsync.name,
                error
            );
        }
        WnaLogger.end(WnaDiaryEntryListScreen.name, loadItemsFromDbAsync.name);
        return newItems;
    };

    const onRefreshAsync = async (p: WnaRefreshParam) => {
        if (p.sender === WnaApplicationConstants.ViewNameChapterEdit) {
            const chapter = p.context as WnaChapter;
            await loadBackgroundImageB64Async(chapter.imageUrl);
            return;
        }

        if (p.sender != WnaApplicationConstants.ScreenNameDiaryEntryDetail)
            return;

        if (p.context == null) return;

        setTimeout(async () => {
            try {
                WnaLogger.start(
                    WnaDiaryEntryListScreen.name,
                    onRefreshAsync.name
                );
                let newItems = itemsRef.current ?? new Array<WnaDiaryEntry>();
                let scrollToIndex = itemsRef.current.findIndex(
                    (x) => x.identifier === p.context.identifier
                );
                switch (p.action) {
                    case WnaRefreshAction.create:
                        WnaLogger.info("setTimeout create");
                        newItems = await loadItemsFromDbAsync(true);
                        scrollToIndex = newItems.findIndex(
                            (x) => x.identifier === p.context.identifier
                        );
                        break;
                    case WnaRefreshAction.update:
                        WnaLogger.info("setTimeout update");
                        itemsRef.current[scrollToIndex] = p.context;
                        items[scrollToIndex] = p.context;
                        newItems = itemsRef.current;
                        setExtraData(new Date());
                        break;
                    case WnaRefreshAction.delete:
                        WnaLogger.info("setTimeout delete");
                        newItems = [
                            ...newItems.filter(
                                (x) => x.identifier != p.context.identifier
                            ),
                        ];
                        setItems(newItems);
                        itemsRef.current = newItems;
                        if (scrollToIndex < 1 && newItems.length > 0) {
                            scrollToIndex = 0;
                        } else if (
                            scrollToIndex > 0 &&
                            newItems.length > 0 &&
                            scrollToIndex < newItems.length
                        ) {
                            scrollToIndex -= 1;
                            // keep index
                        } else {
                            scrollToIndex = 0;
                        }
                        setExtraData(new Date());
                        break;
                    case WnaRefreshAction.none:
                        WnaLogger.info("setTimeout none");
                        break;
                }

                const animated =
                    Math.abs(currentIndexRef.current - scrollToIndex) < 4;
                WnaLogger.info(
                    "scroll to index: " +
                        scrollToIndex +
                        " | animated: " +
                        animated
                );

                if (
                    currentIndexRef.current === scrollToIndex ||
                    scrollToIndex < 0 ||
                    scrollToIndex >= newItems.length
                )
                    return;

                setTimeout(() => {
                    WnaLogger.info("scrolling to index now: " + scrollToIndex);
                    try {
                        flRef.current?.scrollToIndex({
                            animated: animated,
                            index: scrollToIndex,
                            viewPosition: 0.5,
                        });
                    } catch (error) {
                        WnaLogger.error(
                            WnaDiaryEntryListScreen.name,
                            onRefreshAsync.name + "scroll",
                            error
                        );
                    }
                }, 300);
            } catch (error) {
                WnaLogger.error(
                    WnaDiaryEntryListScreen.name,
                    onRefreshAsync.name,
                    error
                );
            } finally {
                WnaLogger.end(
                    WnaDiaryEntryListScreen.name,
                    onRefreshAsync.name
                );
            }
        }, 500);
        return p;
    };

    const addAsync = async () => {
        if (items.length >= 31) {
            WnaDialogProvider.showOk(
                t(i18nKeysDialog.warningCreateNewChapter),
                () => {
                    onGoBackAsync();
                }
            );
            return;
        }
        props.navigation.push(
            WnaApplicationConstants.ScreenNameDiaryEntryDetail,
            {
                currentChapter: pProps.currentChapter,
                currentItem: null,
                items,
            }
        );
    };

    const headerRight = () => {
        return (
            <View style={{ flexDirection: "row" }}>
                <View style={{ alignItems: "center" }}>
                    <WnaButtonHeader
                        text={t(i18nKeysCommon.actionEditChapter)}
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        iconName={"book"}
                        onPress={() => {
                            if (isSidebarOpenRef.current) {
                                setIsSidebarOpen(false);
                                isSidebarOpenRef.current = false;
                            } else {
                                setIsSidebarOpen(true);
                                isSidebarOpenRef.current = true;
                            }
                        }}
                        t={t}
                        checkInternetConnection={false}
                    />
                </View>
            </View>
        );
    };

    const setHeader = (chapter: WnaChapter) => {
        props.navigation.setOptions({
            headerRight: () => headerRight(),
            headerTitle: chapter ? chapter.designator : "",
        });
    };

    const onGoBackAsync = async (): Promise<boolean> => {
        try {
            WnaLogger.start(WnaDiaryEntryListScreen.name, onGoBackAsync.name);
            if (isSidebarOpenRef.current) {
                setIsSidebarOpen(false);
                isSidebarOpenRef.current = false;
                return false;
            }
            return true;
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryListScreen.name,
                onGoBackAsync.name,
                error
            );
            return true;
        } finally {
            WnaLogger.end(WnaDiaryEntryListScreen.name, onGoBackAsync.name);
        }
    };

    useEffect(() => {
        WnaLogger.start(WnaDiaryEntryListScreen.name, useEffect.name);
        if (isFocused && currentUser != null && currentUser.id != "") {
            if (currentUser?.id != currentUserId) {
                loadItemsFromDbAsync(true);
            } else {
                if (
                    (isFocused && itemsRef.current.length < 1) ||
                    (currentUserSettings?.diaryOrderBy ?? 1) != diaryOrderBy
                )
                    loadItemsFromDbAsync(false);
            }

            loadBackgroundImageB64Async(pProps.currentChapter.imageUrl);

            setHeader(pProps.currentChapter);
        }

        WnaLogger.end(WnaDiaryEntryListScreen.name, useEffect.name);
    }, [isFocused, currentUserSettings, currentUser]);

    useOnRefreshListener(async (p) => {
        await onRefreshAsync(p.refreshParameter);
    }, []);

    const onItemPress = (currentItem: WnaDiaryEntry) => {
        const index = itemsRef.current.findIndex(
            (x) => x.identifier === currentItem.identifier
        );
        WnaLogger.start(
            WnaDiaryEntryListScreen.name,
            "navigate",
            "index: " + index + " | identifier: " + currentItem.identifier
        );
        setCurrentIndex(index);
        currentIndexRef.current = index;
        props.navigation.push(
            WnaApplicationConstants.ScreenNameDiaryEntryDetail,
            {
                currentChapter: pProps.currentChapter,
                currentItem: currentItem,
                items: itemsRef.current,
            }
        );
        WnaLogger.end(
            WnaDiaryEntryListScreen.name,
            "navigate",
            "index: " + index + " | identifier: " + currentItem.identifier
        );
    };

    const renderItem = (currentItem: WnaDiaryEntry) => {
        return (
            <WnaDiaryEntryListItem
                key={currentItem.identifier}
                currentItem={currentItem}
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                onPress={onItemPress}
                t={t}
            />
        );
    };

    const menu = (
        <WnaChapterEditView
            currentChapter={pProps.currentChapter!}
            isVisible={isSidebarOpen}
            canDelete={items.length < 1}
            parentProps={props}
            onUpdate={(updateChapter: WnaChapter) => {
                try {
                    WnaLogger.start(
                        WnaDiaryEntryListScreen.name,
                        "Chapter.onUpdate"
                    );
                    setHeader(updateChapter);
                    setIsSidebarOpen(false);
                    isSidebarOpenRef.current = false;
                    if (
                        new Date(updateChapter.dateDelete).toISOString() >
                        getUnixMinDate().toISOString()
                    ) {
                        setTimeout(() => {
                            props.navigation.goBack();
                        }, 300);
                    }
                } catch (error) {
                    WnaLogger.error(
                        WnaDiaryEntryListScreen.name,
                        "Chapter.onUpdate",
                        error
                    );
                } finally {
                    WnaLogger.end(
                        WnaDiaryEntryListScreen.name,
                        "Chapter.onUpdate"
                    );
                }
            }}
            closeSideBar={closeSideBar}
        />
    );

    if (backgroundImageB64 === "") return null;

    return (
        <WnaBaseScreenWithInfo
            isBusy={isBusy}
            backgroundImageUrl={backgroundImageB64}>
            {currentUser != null && currentUser.id != "" ? (
                <>
                    {
                        // @ts-ignore
                        <SideMenu
                            menu={menu}
                            ref={sidebarRef}
                            autoClosing
                            isOpen={isSidebarOpen}
                            openMenuOffset={256}
                            onChange={(isOpen: boolean) => {
                                setIsSidebarOpen(isOpen);
                                isSidebarOpenRef.current = isOpen;
                            }}
                            menuPosition="right">
                            {items.length > 0 ? (
                                <FlatList
                                    ref={flRef}
                                    contentContainerStyle={{
                                        paddingBottom: 80,
                                        paddingTop: 16,
                                        paddingHorizontal: 16,
                                    }}
                                    ItemSeparatorComponent={() => (
                                        <View style={{ height: 8 }}></View>
                                    )}
                                    data={items}
                                    initialNumToRender={3}
                                    maxToRenderPerBatch={3}
                                    extraData={extraData}
                                    keyExtractor={(item, index) =>
                                        item.identifier
                                    }
                                    refreshControl={
                                        <RefreshControl
                                            refreshing={isBusy}
                                            onRefresh={() =>
                                                loadItemsFromDbAsync(true)
                                            }
                                        />
                                    }
                                    renderItem={({ item: currentItem }) =>
                                        renderItem(currentItem)
                                    }
                                />
                            ) : (
                                <WnaDiaryEntryListEmpty
                                    currentAppStyle={currentAppStyle}
                                    currentAppTheme={currentAppTheme}
                                    onPress={() => {
                                        addAsync();
                                    }}
                                    t={t}
                                />
                            )}
                        </SideMenu>
                    }
                    {/* Add Button */}
                    {isInternetReachable === true && !isSidebarOpen ? (
                        <WnaButtonIcon
                            toolTip={t(i18nKeysCommon.actionCreateDiaryEntry)}
                            toolTipPosition="left"
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="pen"
                            style={{
                                position: "absolute",
                                bottom: 16,
                                right: 16,
                            }}
                            onPress={addAsync}
                            t={t}
                            checkInternetConnection={true}
                        />
                    ) : null}
                </>
            ) : (
                <WnaDiaryEntryListDummy
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    onLogIn={() => {
                        openModal("LoginModal", { isBusy: false });
                    }}
                    t={t}
                />
            )}
        </WnaBaseScreenWithInfo>
    );
};

export default WnaDiaryEntryListScreen;
