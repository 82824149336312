import { WnaAppContext } from "@app/WnaAppContext";
import React, { useContext, useEffect } from "react";
import { useModal } from "react-native-modalfy";

export type WnaModalProviderProps = {
    children: React.ReactNode;
    googleSignInPromptAsync: () => void;
    facebookSignInPromptAsync: () => void;
};
const WnaModalProvider: React.FC<WnaModalProviderProps> = (props) => {
    const { isLogInModalVisible, setIsLogInModalVisible } =
        useContext(WnaAppContext);
    const { openModal } = useModal();
    const showLogInModal = () => {
        openModal(
            "WnaLoginModal",
            {
                isBusy: false,
                onLogInWithGoogle: props.googleSignInPromptAsync,
                onLogInWithFacebook: props.facebookSignInPromptAsync,
            },
            () => setIsLogInModalVisible(false)
        );
    };

    useEffect(() => {
        if (isLogInModalVisible) showLogInModal();
    }, [isLogInModalVisible]);
    return props.children;
};

export default WnaModalProvider;
