import WnaNavigationHeaderOptions from "@app/WnaNavigationHeaderOptions";
import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";
import WnaAccommodationScreen from "@app/screens/appointment/accommodation/WnaAccommodationScreen";
import WnaAppointmentDetailScreen from "@app/screens/appointment/detail/WnaAppointmentDetailScreen";
import WnaAppointmentListScreen from "@app/screens/appointment/list/WnaAppointmentListScreen";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { WnaNavigationStackProps } from "@navigation/WnaNavigationStackProps";
import WnaNavigatorScreenOptions from "@navigation/WnaNavigatorScreenoptions";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { WnaApplicationConstants } from "wna-app-constants";

const Stack = createStackNavigator<WnaNavigationStackPropsMap>();
const WnaAppointmentStack: React.FC<WnaNavigationStackProps> = (props) => {
    const { t } = useTranslation(["common"]);
    return (
        <Stack.Navigator screenOptions={WnaNavigatorScreenOptions}>
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameAppointmentList}
                component={WnaAppointmentListScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleAppointmentList)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameAppointmentDetails}
                component={WnaAppointmentDetailScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleAppointmentDetails)
                )}
            />
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameAccommodation}
                component={WnaAccommodationScreen}
                options={WnaNavigationHeaderOptions(
                    t(i18nKeysCommon.screenTitleAccommodationDetails)
                )}
            />
        </Stack.Navigator>
    );
};
export default WnaAppointmentStack;
