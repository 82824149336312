import { WnaAppContext } from "@app/WnaAppContext";
import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaButtonIconText from "@ui/components/buttons/WnaButtonIconText";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import WnaPicker, { WnaDataSourceItem } from "@ui/components/misc/WnaPicker";
import WnaTextWithIcon from "@ui/components/text/WnaTextWithIcon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { ValueType } from "react-native-dropdown-picker";
import Svg from "react-native-svg";
import WnaLogger from "wna-logger";
import WnaMapsGraph from "./WnaMapsGraph";

export type WnaMapsProps = {
    graphData: WnaMapsGraphData;
    paneWidth: number;
    onSelectGeoDataFileAsync: () => void;
    onSelectRouteFromDropDownAsync: (selectedValue: any) => Promise<void>;
    routes: Array<WnaDataSourceItem>;
    isEditMode: boolean;
};

const WnaMapsInfos: React.FC<WnaMapsProps> = (props) => {
    const marginRight = 32;
    const _defaultGraphWidth = props.paneWidth - (marginRight + 16);
    const _defaultGraphHeight =
        props.paneWidth / 2 > 300 ? 300 : props.paneWidth / 2;
    const { currentAppTheme, currentAppStyle, currentUserSettings } =
        useContext(WnaAppContext);
    const [currentGraphWidth, setCurrentGraphWidth] =
        useState(_defaultGraphWidth);
    const [currentGraphHeight, setCurrentGraphHeight] =
        useState(_defaultGraphHeight);
    const [currentGraphData, setCurrentGraphData] = useState(
        new WnaMapsGraphData()
    );
    const [isBusy, setIsBusy] = useState(true);
    const svgRef = useRef<Svg>(null);
    const chartRef = useRef<LineChart>(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const [routesDs, setRoutesDs] = useState<Array<WnaDataSourceItem>>([]);
    const [selectedRouteDsVal, setSelectedRouteDsVal] = useState<any>();
    const currentGraphDataRouteHashRef = useRef<string>("");
    const { t } = useTranslation(["common", "dialog"]);

    const loadData = () => {
        if (currentGraphData.routeHash == props.graphData.routeHash) {
            setIsBusy(false);
            return;
        }

        currentGraphDataRouteHashRef.current = props.graphData.routeHash;
        WnaLogger.start(WnaMapsInfos.name, loadData.name);

        setCurrentGraphWidth(_defaultGraphWidth);
        setCurrentGraphHeight(_defaultGraphHeight);

        const graphData = props.graphData;
        if (graphData.yAxisData.length > 0) {
            setTimeout(async () => {
                if (currentUserSettings?.mapGraphAutoScale === 0) {
                    const gWidth = graphData.yAxisData.length; //graphData.distance / 50;
                    const gHeight =
                        ((graphData.yAxisMax - graphData.yAxisMin) / 200) * 32;
                    WnaLogger.info(
                        "graphWidth: " +
                            gWidth.toString() +
                            " | graphHeight: " +
                            gHeight
                    );

                    // fixed width for legend y
                    setCurrentGraphWidth(64 + gWidth);
                    // fixed height for legend x
                    setCurrentGraphHeight(64 + gHeight);
                }

                setCurrentGraphData(graphData);

                setIsBusy(false);
                WnaLogger.end(WnaMapsInfos.name, loadData.name);
            }, 10);
        } else {
            setCurrentGraphData(new WnaMapsGraphData());
            setIsBusy(false);
            WnaLogger.end(WnaMapsInfos.name, loadData.name);
        }
    };

    useEffect(() => {
        setIsEditMode(props.isEditMode);
        loadData();
        if (props.routes != routesDs) {
            WnaLogger.start(
                WnaMapsInfos.name,
                useEffect.name,
                setRoutesDs.name
            );
            setRoutesDs(props.routes);
            WnaLogger.end(WnaMapsInfos.name, useEffect.name, setRoutesDs.name);
        }

        const selectedRoute = props.routes.find(
            (x) => x.value === currentGraphDataRouteHashRef.current
        );
        if (selectedRoute && selectedRouteDsVal != selectedRoute.value) {
            WnaLogger.start(
                WnaMapsInfos.name,
                loadData.name,
                setSelectedRouteDsVal.name
            );
            setSelectedRouteDsVal(selectedRoute.value);
            WnaLogger.end(
                WnaMapsInfos.name,
                loadData.name,
                setSelectedRouteDsVal.name
            );
        }
    }, [props, currentUserSettings]);

    const iconColor = currentAppTheme.colors.accent4;
    const postfixColor = currentAppTheme.colors.coolgray6;
    const infoItemWidth = 224;
    const onRouteSelectionChanged = async (val: ValueType | null) => {
        try {
            WnaLogger.start(
                WnaMapsInfos.name,
                "onSelectionChanged - routeSelected"
            );

            if (val == currentGraphDataRouteHashRef.current) {
                WnaLogger.warn(
                    `val: ${val} is equal to currentGraphDataRouteHashRef.current: ${currentGraphDataRouteHashRef.current} - onSelectionChanged will be canceled.`
                );
                return;
            }

            WnaLogger.info(
                WnaMapsInfos.name,
                "onSelectionChanged - routeSelected",
                "will now call props.onSelectRouteFromDropDownAsync"
            );
            await props.onSelectRouteFromDropDownAsync(val);
        } catch (error) {
            WnaLogger.error(
                WnaMapsInfos.name,
                "onSelectionChanged - routeSelected",
                error
            );
        } finally {
            WnaLogger.end(
                WnaMapsInfos.name,
                "onSelectionChanged - routeSelected"
            );
        }
    };

    return isBusy ? (
        <View style={[currentAppStyle.containerCenterCenter, { height: 386 }]}>
            <WnaActivityIndicator currentAppTheme={currentAppTheme} />
        </View>
    ) : (
        <View
            style={{
                gap: 16,
                paddingVertical: 16,
                width: props.paneWidth,
            }}>
            {isEditMode ? (
                <View
                    style={[
                        currentAppStyle.containerCenter,
                        {
                            zIndex: 1000,
                            marginHorizontal: 16,
                            padding: 16,
                            gap: 16,
                            width: props.paneWidth - marginRight,
                            backgroundColor: currentAppTheme.colors.white,
                        },
                    ]}>
                    <WnaButtonIconText
                        text="GPX | KML | GEOJSON"
                        currentAppTheme={currentAppTheme}
                        iconName="upload"
                        style={{ marginHorizontal: 16 }}
                        onPress={() => {
                            setIsBusy(true);
                            props.onSelectGeoDataFileAsync();
                        }}
                        t={t}
                        checkInternetConnection={false}
                    />

                    <WnaPicker
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        dataSource={routesDs}
                        selectedKey={selectedRouteDsVal}
                        onSelectionChanged={onRouteSelectionChanged}
                    />
                </View>
            ) : null}

            {/* <View style={[currentAppStyle.containerCenter, {
                    marginHorizontal: 16,
                    padding: 16,
                    width: props.paneWidth - marginRight,
                    backgroundColor: currentAppTheme.colors.white
                }]}>
                    <WnaButtonIconText
                        text="Export"
                        currentAppTheme={currentAppTheme}
                        iconName="upload"
                        style={{ marginHorizontal: 16 }}
                        onPress={() => {
                            // const elem = chartRef.current?.render();
                            // elem?.
                            // svgRef.current?.toDataURL((base64) => {

                            //     const cleanB64 = base64.replace(/\r?\n|\r/g, '');
                            //     console.log(cleanB64);
                            // }, {
                            //     width: 1920,
                            //     height: 1080
                            // });
                        }} />
                </View> */}

            {currentGraphData.yAxisData.length > 0 ? (
                <View
                    style={{
                        flex: 1,
                        gap: 24,
                        flexDirection: "column",
                    }}>
                    <View
                        style={{
                            marginHorizontal: 16,
                            padding: 16,
                            width: props.paneWidth - marginRight,
                            backgroundColor: currentAppTheme.colors.white,
                        }}>
                        <Text style={[currentAppStyle.textTitleLarge, {}]}>
                            {currentGraphData.routeName}
                        </Text>
                    </View>

                    {/* infos */}
                    <View
                        style={{
                            backgroundColor: currentAppTheme.colors.white,
                            marginHorizontal: 16,
                            padding: 16,
                            width: props.paneWidth - marginRight,
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                        <View style={{ width: infoItemWidth }}>
                            {/* Stecken Länge */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrows-alt-h"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeysCommon.mapInfoPostfixLength)}
                                text={currentGraphData.infoDistanceText}
                            />

                            {/* Stecken Dauer */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="clock"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(
                                    i18nKeysCommon.mapInfoPostfixDuration
                                )}
                                text={currentGraphData.infoTotalDurationText}
                            />

                            {/* Höchster Punkt */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="long-arrow-alt-up"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(
                                    i18nKeysCommon.mapInfoPostfixHighestPoint
                                )}
                                text={currentGraphData.infoAltMaxText}
                            />

                            {/* Niedrigster Punkt */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="long-arrow-alt-down"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(
                                    i18nKeysCommon.mapInfoPostfixLowestPoint
                                )}
                                text={currentGraphData.infoAltMinText}
                            />
                            {/* Delta höchster / Niedrigster Punkt */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrows-alt-v"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeysCommon.mapInfoPostfixDelta)}
                                text={currentGraphData.infoAltDiffText}
                            />
                        </View>
                        <View style={{ width: infoItemWidth }}>
                            {/* Aufstieg */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-circle-up"
                                iconRotation={45}
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeysCommon.mapInfoPostfixUp)}
                                text={
                                    currentGraphData.infoAltUpText +
                                    " (" +
                                    currentGraphData.infoAltUpDistanceText +
                                    ")"
                                }
                            />

                            {/* Abstieg */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-circle-right"
                                iconRotation={45}
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeysCommon.mapInfoPostfixDown)}
                                text={
                                    currentGraphData.infoAltDownText +
                                    " (" +
                                    currentGraphData.infoAltDownDistanceText +
                                    ")"
                                }
                            />

                            {/* Ebene */}
                            <WnaTextWithIcon
                                width={infoItemWidth}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-alt-circle-right"
                                iconColor={iconColor}
                                postfixColor={postfixColor}
                                postfix={t(i18nKeysCommon.mapInfoPostfixEqual)}
                                text={currentGraphData.infoAltEqualDistanceText}
                            />
                        </View>
                    </View>

                    {/* graph */}
                    <View
                        style={{
                            marginLeft: 16,
                            paddingTop: 16,
                            width: props.paneWidth - marginRight,
                            backgroundColor: currentAppTheme.colors.white,
                        }}>
                        <WnaMapsGraph
                            svgRef={svgRef}
                            chartRef={chartRef}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            width={_defaultGraphWidth}
                            graphHeight={currentGraphHeight}
                            graphWidth={currentGraphWidth}
                            xAxisData={currentGraphData.xAxisData}
                            yAxisData={currentGraphData.yAxisData}
                            yAxisMax={currentGraphData.yAxisMax}
                            yAxisMin={currentGraphData.yAxisMin}
                        />
                    </View>
                </View>
            ) : (
                <View
                    style={{
                        marginHorizontal: 16,
                        padding: 16,
                        width: props.paneWidth - marginRight,
                        backgroundColor: currentAppTheme.colors.white,
                    }}>
                    <Text style={[currentAppStyle.textTitleLarge, {}]}>
                        {t(i18nKeysCommon.infoNoMapData)}
                    </Text>
                </View>
            )}
        </View>
    );
};

export default WnaMapsInfos;
