import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { convertHexToRgba } from "@infrastructure/services/colors/WnaColorConverter";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import { Image } from "expo-image";
import { Component } from "react";
import { ImageStyle, Platform, View } from "react-native";
import WnaLogger from "wna-logger";

export type WnaImageProps = {
    currentAppTheme: WnaTheme;
    imageUrl: string;
    style?: ImageStyle | ImageStyle[];
    thumbnailUrl?: string | null;
    placeholderUrl?: string | null;
    displayLoadingState?: boolean | null;
};

export type imageState = {
    loadedImageUrl: string;
    cachedImageBase64Url: string;
};

const _blurHash =
    "|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[";

class WnaImage extends Component<WnaImageProps> {
    state = { cachedImageBase64Url: "", loadedImageUrl: "" } as imageState;

    shouldComponentUpdate(nextProps: Readonly<WnaImageProps>): boolean {
        return (
            nextProps.imageUrl != this.props.imageUrl ||
            this.state.cachedImageBase64Url == "" ||
            this.state.loadedImageUrl != this.props.imageUrl
        );
    }

    render() {
        // WnaLogger.info(WnaImage.name, this.render.name, this.props.imageUrl);
        const loadImage = () => {
            try {
                let imageUrl = this.props.imageUrl;

                // WnaLogger.start(WnaImage.name, loadImage.name, this.props.imageUrl);
                if (imageUrl == "") {
                    // WnaLogger.warn("imageUrl is empty --> try thumbnail");
                    imageUrl = this.props.thumbnailUrl ?? "";
                }

                if (imageUrl == "") {
                    // WnaLogger.warn("imageUrl is empty --> try placeholder");
                    imageUrl = this.props.placeholderUrl ?? "";
                }

                if (imageUrl == "") {
                    WnaLogger.warn("imageUrl is empty");
                    return;
                }

                if (!imageUrl.startsWith("http")) {
                    // WnaLogger.info("imageUrl is base64");
                    this.setState({
                        cachedImageBase64Url: imageUrl,
                        loadedImageUrl: imageUrl,
                    } as imageState);
                } else {
                    // WnaLogger.info("try to cache: " + this.props.imageUrl);
                    WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                        imageUrl,
                        "png"
                    ).then((b64) => {
                        this.setState({
                            cachedImageBase64Url: b64,
                            loadedImageUrl: this.props.imageUrl,
                        } as imageState);
                    });
                }
            } catch (error) {
                WnaLogger.error(WnaImage.name, loadImage.name, error);
            } finally {
                // WnaLogger.end(WnaImage.name, loadImage.name, this.props.imageUrl);
            }
        };

        if (
            this.state.cachedImageBase64Url == "" ||
            this.state.loadedImageUrl != this.props.imageUrl
        ) {
            if (this.state.loadedImageUrl != this.props.imageUrl) loadImage();

            return (
                <View
                    style={[
                        {
                            backgroundColor: convertHexToRgba("#000000", 0.5),
                        },
                        [this.props.style],
                    ]}>
                    <></>
                </View>
            );
        }

        return (
            <View
                style={[
                    {
                        backgroundColor: convertHexToRgba("#000000", 0.5),
                    },
                    [this.props.style],
                ]}>
                {Platform.OS === "web" ? (
                    <Image
                        style={[this.props.style]}
                        // cachePolicy="none"
                        source={{
                            uri:
                                this.state.cachedImageBase64Url != null &&
                                this.state.cachedImageBase64Url != ""
                                    ? this.state.cachedImageBase64Url
                                    : this.props.placeholderUrl ?? _blurHash,
                        }}
                        transition={500}
                    />
                ) : (
                    <Image
                        style={[this.props.style]}
                        cachePolicy="none"
                        source={{
                            uri:
                                this.state.cachedImageBase64Url != null &&
                                this.state.cachedImageBase64Url != ""
                                    ? this.state.cachedImageBase64Url
                                    : this.props.placeholderUrl ?? _blurHash,
                        }}
                        transition={500}
                    />
                )}
            </View>
        );
    }
}

export default WnaImage;
