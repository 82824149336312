import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns user
 * @param user
 */
const deleteUserAsync = async (user: WnaUser) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/delete`;
    const request = initJsonRequest("DELETE");
    request.body = JSON.stringify(user);
    const response = await fetch(url, request);
    return await response.json();
};

export default deleteUserAsync;
