import { WnaMenuItem } from "@ui/types/WnaMenuItem";
import { Component } from "react";
import { FlatList, View } from "react-native";

export type WnaNavigationListProps = {
    items: WnaMenuItem[];
    renderItem: (item: WnaMenuItem) => {};
};

class WnaNavigationList extends Component<WnaNavigationListProps> {
    render() {
        return (
            <FlatList
                contentContainerStyle={{
                    paddingBottom: 16,
                    paddingTop: 16,
                    paddingHorizontal: 16,
                }}
                ItemSeparatorComponent={() => <View style={{ height: 8 }} />}
                data={this.props.items}
                // extraData={extraData}
                // @ts-ignore
                renderItem={({ item: currentItem }) =>
                    this.props.renderItem(currentItem)
                }
            />
        );
    }
}

export default WnaNavigationList;
