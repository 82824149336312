import WnaApp from "@app/WnaApp";
import { WnaAppContextProvider } from "@app/WnaAppContext";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import WnaFirebase from "@infrastructure/firebase/WnaFirebase";
import { getThemedColors } from "@infrastructure/services/WnaThemeService";
import { useURL } from "expo-linking";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useRef, useState } from "react";
import { useColorScheme } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import WnaLogger from "wna-logger";

//WnaLogger.isEnabled = true;

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

export default function App() {
    const url = useURL();

    const currentSystemColorScheme = useColorScheme();
    const currentSystemThemeId = currentSystemColorScheme == "dark" ? 1 : 0;

    const loadAppSettingsAsync = async () => {
        const localAppSettings = await WnaAppSettingsDao.getAppSettingsAsync();
        const appSettings =
            await WnaAppSettingsDao.getAppSettingsAsync(localAppSettings);

        const cachedDarkBg = await WnaAppSettingsDao.getResourceAsync(
            appSettings.backgroundImageDarkUrl
        );
        const cachedLightBg = await WnaAppSettingsDao.getResourceAsync(
            appSettings.backgroundImageUrl
        );

        const currentAppThemeId =
            await WnaAppSettingsDao.getCurrentAppThemeIdAsync();
        const currentTheme = getThemedColors(
            appSettings,
            currentAppThemeId,
            currentSystemColorScheme == "dark"
        );

        WnaLogger.info(App.name, useEffect.name, "WnaFirebase.init");
        await WnaFirebase.initAsync(appSettings);
        setAppSettings(appSettings);
        setCurrentAppTheme(currentTheme);
        setDarkBgImageUri(cachedDarkBg);
        setLightBgImageUri(cachedLightBg);
    };

    const handleURL = (url: string) => {
        if (url.endsWith("firebase-cloud-messaging-push-scope")) {
            WnaLogger.warn("firebase-cloud-messaging-push-scope");
        }
    };

    const [appSettings, setAppSettings] = useState<WnaAppSettings | null>(null);
    const [currentAppTheme, setCurrentAppTheme] = useState(
        getThemedColors(null, currentSystemThemeId, currentSystemThemeId == 1)
    );
    const [darkBgImageUri, setDarkBgImageUri] = useState<string>("");
    const [lightBgImageUri, setLightBgImageUri] = useState<string>("");
    const isAppSettingsLoadedRef = useRef<boolean>(false);
    useEffect(() => {
        WnaLogger.start(App.name, useEffect.name);
        if (url) handleURL(url);

        if (!isAppSettingsLoadedRef.current) {
            isAppSettingsLoadedRef.current = true;
            loadAppSettingsAsync();
        }

        WnaLogger.end(App.name, useEffect.name);
    }, [url]);

    return (
        <GestureHandlerRootView style={{ flex: 1 }}>
            {/*<NetworkProvider*/}
            {/*    pingInterval={5000}*/}
            {/*    pingTimeout={100}*/}
            {/*    pingServerUrl="https://www.opendns.com/"*/}
            {/*    httpMethod="HEAD"*/}
            {/*    shouldPing={false}*/}
            {/*    pingOnlyIfOffline={true}*/}
            {/*    customHeaders={{} as HTTPHeaders}>*/}
            <WnaAppContextProvider>
                {appSettings != null &&
                darkBgImageUri != "" &&
                lightBgImageUri != "" ? (
                    <WnaApp
                        appSettings={appSettings}
                        currentAppTheme={currentAppTheme}
                        darkBgImageUri={darkBgImageUri}
                        lightBgImageUri={lightBgImageUri}
                    />
                ) : (
                    <></>
                )}
            </WnaAppContextProvider>
            {/*</NetworkProvider>*/}
        </GestureHandlerRootView>
    );
}
