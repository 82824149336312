import WnaLogger from "wna-logger";

export enum WnaToastTypeEnm {
    error,
    success,
    info,
}

export default class WnaToastProvider {
    private static _popIn: (text: string, iSuccess: boolean) => void;

    public static showSuccess(text: string) {
        try {
            WnaLogger.start(
                WnaToastProvider.name,
                WnaToastProvider.showSuccess.name,
                text
            );
            WnaToastProvider._popIn(text, true);
            WnaLogger.end(
                WnaToastProvider.name,
                WnaToastProvider.showSuccess.name,
                text
            );
        } catch (error) {
            WnaLogger.error(
                WnaToastProvider.name,
                WnaToastProvider.showSuccess.name,
                error
            );
        }
    }

    public static showError(text: string) {
        try {
            WnaLogger.start(
                WnaToastProvider.name,
                WnaToastProvider.showError.name,
                text
            );
            WnaToastProvider._popIn(text, false);
            WnaLogger.end(
                WnaToastProvider.name,
                WnaToastProvider.showError.name,
                text
            );
        } catch (error) {
            WnaLogger.error(
                WnaToastProvider.name,
                WnaToastProvider.showError.name,
                error
            );
        }
    }

    public static init(popIn: (text: string, iSuccess: boolean) => void) {
        // WnaLogger.start(WnaToastProvider.name, WnaToastProvider.init.name);
        WnaToastProvider._popIn = popIn;
        // WnaLogger.end(WnaToastProvider.name, WnaToastProvider.init.name);
    }
}
