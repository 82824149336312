import WnaAppointment from "@domain/entities/WnaAppointment";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns list of appointment
 */
const getAllAppointmentsAsync = async () => {
    const url = `${WnaApiSettings.apiBaseUrl}/appointments`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 10000,
            abortHandler: (err) => {
                return new Response(
                    JSON.stringify([] as Array<WnaAppointment>)
                );
            },
        })
    );
    return (await response.json()) as Array<WnaAppointment>;
};

export default getAllAppointmentsAsync;
