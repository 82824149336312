import { minifyJson } from "@infrastructure/services/WnaJsonMinify";
import { Platform } from "react-native";
import WnaLogger from "wna-logger";
import toGeoJson from "./togeojson";

const _tsFileName = "WnaKmlToGeoJsonConverter.ts";

const convertKmlToGeoJsonInternal = (xml: string, isGpx: boolean) => {
    let ret = "";
    try {
        WnaLogger.start(
            _tsFileName,
            convertKmlToGeoJsonInternal.name,
            "isGpx: " + isGpx
        );
        let xmlDoc;
        if (Platform.OS == "web") {
            WnaLogger.info("start DOMParser");
            xmlDoc = new DOMParser().parseFromString(xml, "text/xml");
        } else {
            WnaLogger.info("start @xmldom/xmldom");
            const domparser = new (require("@xmldom/xmldom").DOMParser)();
            if (domparser) {
                xmlDoc = domparser.parseFromString(xml, "text/xml");
            } else {
                WnaLogger.warn("could not load dom parser");
            }
        }
        // WnaLogger.info(xmlDoc);
        let json: { features: any[]; type: string };
        if (isGpx) {
            WnaLogger.info("start toGeoJson.gpx");
            json = toGeoJson.gpx(xmlDoc);
        } else {
            WnaLogger.info("start toGeoJson.kml");
            json = toGeoJson.kml(xmlDoc);
        }
        ret = JSON.stringify(json);
        const miniJsonStr = minifyJson(ret);
        if (miniJsonStr != "") ret = miniJsonStr;
    } catch (error) {
        WnaLogger.error(_tsFileName, convertKmlToGeoJsonInternal.name, error);
    } finally {
        // WnaLogger.info(ret);
        WnaLogger.end(
            _tsFileName,
            convertKmlToGeoJsonInternal.name,
            "isGpx: " + isGpx
        );
    }
    return ret;
};

const convertKmlToGeoJson = (xml: string) =>
    convertKmlToGeoJsonInternal(xml, false);
const convertGpxToGeoJson = (xml: string) =>
    convertKmlToGeoJsonInternal(xml, true);

export { convertGpxToGeoJson, convertKmlToGeoJson };
