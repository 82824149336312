import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { useIsFocused } from "@react-navigation/native";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaNotificationHeaderRight from "@ui/components/tabbar/WnaNotificationHeaderRight";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WnaLogger from "wna-logger";

const WnaLocationScreen: React.FC<WnaStackScreenProps> = (props) => {
    const isFocused = useIsFocused();
    const {
        appSettings,
        currentUser,
        currentAppTheme,
        currentAppStyle,
        currentUserSettings,
        unreadNotificationCount,
    } = useContext(WnaAppContext);

    if (currentUser == null || appSettings == null) return null;

    const { t } = useTranslation(["common"]);
    const [isBusy, setIsBusy] = useState(false);
    const [isBusyText, setIsBusyText] = useState("");

    useEffect(() => {
        WnaLogger.start(WnaLocationScreen.name, useEffect.name);

        props.navigation.setOptions({
            headerRight: () => (
                <WnaNotificationHeaderRight
                    unreadNotificationCount={unreadNotificationCount}
                    currentAppStyle={currentAppStyle}
                    currentAppTheme={currentAppTheme}
                    currentUser={currentUser}
                    t={t}
                />
            ),
        });

        WnaLogger.end(WnaLocationScreen.name, useEffect.name);
    }, [isFocused, currentUserSettings, currentUser, unreadNotificationCount]);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy} isBusyText={isBusyText}>
            <></>
        </WnaBaseScreenWithInfo>
    );
};

export default WnaLocationScreen;
