import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import WnaFileInfo from "@domain/contracts/types/WnaFileInfo";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaDiaryEntry extends WnaEntityBase {
    public priority: Int32 = 0;
    public dateStart: Date = getUnixMinDate();
    public dateEnd: Date = getUnixMinDate();
    public chapterIdentifier: string = "";

    // #region Images
    imageUrl: string = "";
    public images: WnaFileInfo[] = [];
    // #endregion

    // #region Route
    public geoJson: string = "";
    public routeIdentifier: string = "";
    public routeGeoJsonUrl: string = "";
    public routeStaticUrl: string = "";
    public routeStatic512Url: string = "";
    public routeStatic256Url: string = "";
    // #endregion

    // #region Fasting
    public fastingMyLastNight: string = "";
    public fastingGeneralCondition: string = "";
    public fastingTemperaturePerception: string = "";
    public fastingBowelMovement: string = "";
    public fastingUrineColor: string = "";
    public fastingWeightInTheMorning: string = "";
    public fastingDrank: string = "";
    public fastingEaten: string = "";
    public fastingMealAtNoon: string = "";
    public fastingMealInTheEvening: string = "";
    public fastingDailyRoutine: string = "";
    public fastingMorningExercise: string = "";
    public fastingHiking: string = "";
    public fastingPersonalThoughts: string = "";

    // #endregion

    constructor(init?: Partial<WnaDiaryEntry>) {
        super(init);
        if (init != null) {
            if (
                init.dateStart == undefined ||
                new Date(init.dateStart).toISOString() ==
                    getUnixMinDate().toISOString()
            ) {
                init.dateStart = init.dateCreate;
            }
            if (
                init.dateEnd == undefined ||
                new Date(init.dateEnd).toISOString() ==
                    getUnixMinDate().toISOString()
            ) {
                init.dateEnd = init.dateCreate;
            }
        }
        Object.assign(this, init);
        this.images = [];
        init?.images?.forEach((img) =>
            this.images.push(Object.assign({}, img))
        );
    }
}
