import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import WnaLogger from "wna-logger";

const getResourceBase64Async = async (fileName: string) => {
    const url = `${WnaApiSettings.apiBaseUrl}/resources/${fileName}/base64`;
    WnaLogger.start(getResourceBase64Async.name, url);
    const request = initJsonRequest("GET");
    const response = await fetchWithTimeout(url, request, {
        timeout: 10000,
        abortHandler: (err) => {
            WnaLogger.error(getResourceBase64Async.name, url, err.message);
            return new Response("");
        },
    });
    const finalResponse = await handleResponseAsync(response);
    const txt = await finalResponse.text();
    WnaLogger.end(getResourceBase64Async.name, url);
    return txt;
};

const getResourceTextAsync = async (fileName: string) => {
    const url = `${WnaApiSettings.apiBaseUrl}/resources/${fileName}/text`;
    const request = initJsonRequest("GET");
    const response = await fetchWithTimeout(url, request, {
        timeout: 3000,
        abortHandler: (err) => {
            WnaLogger.error(getResourceTextAsync.name, url, err.message);
            return new Response("");
        },
    });
    const finalResponse = await handleResponseAsync(response);
    return await finalResponse.text();
};

const getResourceBlobAsync = async (fileName: string) => {
    const url = `${WnaApiSettings.apiBaseUrl}/resources/${fileName}`;
    const request = initJsonRequest("GET");
    const response = await fetchWithTimeout(url, request, {
        timeout: 3000,
        abortHandler: (err) => {
            WnaLogger.error(getResourceBlobAsync.name, url, err.message);
            return new Response();
        },
    });
    const finalResponse = await handleResponseAsync(response);
    return await finalResponse.blob();
};

export { getResourceBase64Async, getResourceBlobAsync, getResourceTextAsync };
