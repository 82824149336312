import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaAppointment extends WnaEntityBase {
    public state: Int32 = 0;
    public imageUrl: string = "";
    public dateStart: Date = getUnixMinDate();
    public dateEnd: Date = getUnixMinDate();
    public slotsMin: Int32 = 0;
    public slotsMax: Int32 = 0;
    public slotsTaken: Int32 = 0;
    public location: string = "";

    constructor(init?: Partial<WnaAppointment>) {
        super(init);
        Object.assign(this, init);
    }
}
