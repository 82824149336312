import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";

export default class WnaNavigationProvider {
    private static _navigation: any;

    public static navigateToNotificationListScreen() {
        try {
            WnaLogger.start(
                WnaNavigationProvider.name,
                WnaNavigationProvider.navigateToNotificationListScreen.name
            );
            WnaNavigationProvider._navigation.navigate("start", {
                screen: WnaApplicationConstants.ScreenNameStartPage,
                params: {
                    navigateToNotifications: new Date().toString(),
                },
            });
        } catch (error) {
            WnaLogger.error(
                WnaNavigationProvider.name,
                WnaNavigationProvider.navigateToNotificationListScreen.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaNavigationProvider.name,
                WnaNavigationProvider.navigateToNotificationListScreen.name
            );
        }
    }

    public static navigateToStartPageScreen() {
        try {
            WnaLogger.start(
                WnaNavigationProvider.name,
                WnaNavigationProvider.navigateToStartPageScreen.name
            );
            WnaNavigationProvider._navigation.navigate("web", {
                screen: WnaApplicationConstants.ScreenNameStartPage,
            });
        } catch (error) {
            WnaLogger.error(
                WnaNavigationProvider.name,
                WnaNavigationProvider.navigateToStartPageScreen.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaNavigationProvider.name,
                WnaNavigationProvider.navigateToStartPageScreen.name
            );
        }
    }

    public static init(navigation: any) {
        WnaNavigationProvider._navigation = navigation;
    }
}
