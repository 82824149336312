// https://www.youtube.com/watch?v=rbuSx1yEgV8
import { FirebaseApp, initializeApp } from "@firebase/app";
import {
    Auth,
    browserLocalPersistence,
    getAuth,
    signOut,
} from "@firebase/auth";

import WnaGoogleAuthConfig from "@domain/contracts/types/appSettings/WnaGoogleAuthConfig";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaUser from "@domain/entities/WnaUser";
import { setJwt } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import WnaLogger from "wna-logger";
import WnaFacebookSignIn from "../auth/facebookSignIn/WnaFacebookSignIn";
import WnaGoogleSignIn from "../auth/googleSignIn/WnaGoogleSignIn";
import WnaUserDao from "../dao/WnaUserDao";

export default class WnaFirebase {
    public static FirebaseApp = {} as FirebaseApp;
    public static GoogleAuthConfig = {} as WnaGoogleAuthConfig;
    public static FirebaseAuth = {} as Auth;

    public static async initAsync(appSettings: WnaAppSettings) {
        WnaLogger.start(WnaFirebase.name, WnaFirebase.initAsync.name);
        WnaFirebase.GoogleAuthConfig = appSettings.googleAuthConfig;
        WnaFirebase.FirebaseApp = initializeApp({
            apiKey: appSettings.firebaseAppConfig.apiKey,
            appId: appSettings.firebaseAppConfig.appId,
            authDomain: appSettings.firebaseAppConfig.authDomain,
            measurementId: appSettings.firebaseAppConfig.measurementId,
            messagingSenderId: appSettings.firebaseAppConfig.messagingSenderId,
            projectId: appSettings.firebaseAppConfig.projectId,
            storageBucket: appSettings.firebaseAppConfig.storageBucket,
        });
        let firebaseAuth: Auth;
        firebaseAuth = getAuth(WnaFirebase.FirebaseApp);
        await firebaseAuth.setPersistence(browserLocalPersistence);
        WnaFirebase.FirebaseAuth = firebaseAuth;
        WnaLogger.end(WnaFirebase.name, WnaFirebase.initAsync.name);
    }

    public static async signInWithGoogleAsync(): Promise<WnaUser> {
        try {
            WnaLogger.start(
                WnaFirebase.name,
                WnaFirebase.signInWithGoogleAsync.name
            );
            return await WnaGoogleSignIn.signInWithGoogleAsync();
        } catch (error) {
            WnaLogger.error(
                WnaFirebase.name,
                WnaFirebase.signInWithGoogleAsync.name,
                error
            );
            return new WnaUser();
        } finally {
            WnaLogger.end(
                WnaFirebase.name,
                WnaFirebase.signInWithGoogleAsync.name
            );
        }
    }

    public static async signInWithFacebookAsync() {
        try {
            WnaLogger.start(
                WnaFirebase.name,
                WnaFirebase.signInWithFacebookAsync.name
            );
            return await WnaFacebookSignIn.signInWithFacebookAsync();
        } catch (error) {
            WnaLogger.error(
                WnaFirebase.name,
                WnaFirebase.signInWithFacebookAsync.name,
                error
            );
            return new WnaUser();
        } finally {
            WnaLogger.end(
                WnaFirebase.name,
                WnaFirebase.signInWithFacebookAsync.name
            );
        }
    }

    public static async signOutAsync() {
        try {
            WnaLogger.start(WnaFirebase.name, WnaFirebase.signOutAsync.name);
            await signOut(WnaFirebase.FirebaseAuth);
            setJwt("", "");
        } catch (error) {
            WnaLogger.error(
                WnaFirebase.name,
                WnaFirebase.signOutAsync.name,
                JSON.stringify(error)
            );
        } finally {
            WnaLogger.end(WnaFirebase.name, WnaFirebase.signOutAsync.name);
        }
    }

    public static async signOutAndDeleteDataAsync() {
        try {
            WnaLogger.start(
                WnaFirebase.name,
                WnaFirebase.signOutAndDeleteDataAsync.name
            );
            await WnaUserDao.deleteAsync();
            await signOut(WnaFirebase.FirebaseAuth);
            setJwt("", "");
        } catch (error) {
            WnaLogger.error(
                WnaFirebase.name,
                WnaFirebase.signOutAndDeleteDataAsync.name,
                JSON.stringify(error)
            );
        } finally {
            WnaLogger.end(
                WnaFirebase.name,
                WnaFirebase.signOutAndDeleteDataAsync.name
            );
        }
    }
}
