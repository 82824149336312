import WnaNavigationHeaderOptions from "@app/WnaNavigationHeaderOptions";
import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";
import WnaLocationScreen from "@app/screens/location/WnaLocationScreen";
import { WnaNavigationStackProps } from "@navigation/WnaNavigationStackProps";
import WnaNavigatorScreenOptions from "@navigation/WnaNavigatorScreenoptions";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { WnaApplicationConstants } from "wna-app-constants";

const Stack = createStackNavigator<WnaNavigationStackPropsMap>();
const WnaLocationStack: React.FC<WnaNavigationStackProps> = (props) => {
    return (
        <Stack.Navigator screenOptions={WnaNavigatorScreenOptions}>
            <Stack.Screen
                name={WnaApplicationConstants.ScreenNameLocation}
                component={WnaLocationScreen}
                options={WnaNavigationHeaderOptions("Routenaufzeichnung")}
            />
        </Stack.Navigator>
    );
};
export default WnaLocationStack;
