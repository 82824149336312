import WnaUserSettings from "@domain/entities/WnaUserSettings";
import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaLogger from "wna-logger";

const _col = "_currentUserSettingsStore";
const _encrypted = false;
const _useDb = false;

export default class WnaUserSettingsDao {
    public static async createOrUpdateAsync(item: WnaUserSettings) {
        WnaLogger.start(
            WnaUserSettingsDao.name,
            WnaUserSettingsDao.createOrUpdateAsync.name
        );
        try {
            const currentJson = JSON.stringify(item);
            const currentUserJsonInStorage =
                await WnaAsyncStorageProvider.getItemAsync(
                    _col,
                    _encrypted,
                    _useDb
                );
            if (currentJson != currentUserJsonInStorage) {
                WnaLogger.info(
                    WnaUserSettingsDao.name,
                    WnaUserSettingsDao.createOrUpdateAsync.name,
                    "userSettings is not synced"
                );
                item.dateUpdate = new Date();
                await WnaAsyncStorageProvider.setItemAsync(
                    _col,
                    currentJson,
                    _encrypted,
                    _useDb
                );
            }
        } catch (error) {
            WnaLogger.error(
                WnaUserSettingsDao.name,
                WnaUserSettingsDao.createOrUpdateAsync.name,
                error
            );
        }
        WnaLogger.end(
            WnaUserSettingsDao.name,
            WnaUserSettingsDao.createOrUpdateAsync.name
        );
    }

    public static async readAsync() {
        WnaLogger.start(
            WnaUserSettingsDao.name,
            WnaUserSettingsDao.readAsync.name
        );
        let ret = new WnaUserSettings();
        try {
            const json = await WnaAsyncStorageProvider.getItemAsync(
                _col,
                _encrypted,
                _useDb
            );
            if (json != null) ret = new WnaUserSettings(JSON.parse(json));
        } catch (error) {
            ////WnaLogger.error(WnaUserSettingsDao.name, WnaUserSettingsDao.readAsync.name, error);
            await WnaUserSettingsDao.createOrUpdateAsync(ret);
        }
        WnaLogger.end(
            WnaUserSettingsDao.name,
            WnaUserSettingsDao.readAsync.name
        );
        return ret;
    }
}
