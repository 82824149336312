import WnaRoute from "@domain/entities/WnaRoute";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns all routes
 */
const getAllRoutesAsync = async () => {
    const url = `${WnaApiSettings.apiBaseUrl}/routes`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 10000,
            abortHandler: (err) => {
                return new Response(JSON.stringify([] as Array<WnaRoute>));
            },
        })
    );
    return (await response.json()) as Array<WnaRoute>;
};

export default getAllRoutesAsync;
