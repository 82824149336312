import { WnaAppContext } from "@app/WnaAppContext";
import { BottomTabNavigationOptions } from "@react-navigation/bottom-tabs";
import { useContext } from "react";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import TabBarIcon from "./WnaTabBarIcon";

export default function WnaTabOptions({
    isVisible,
    headerTitle,
    iconName,
    unmountOnBlur,
    badge,
}: {
    isVisible: boolean;
    headerTitle: string;
    iconName: string;
    unmountOnBlur: boolean;
    badge?: Int32 | null;
}) {
    const { currentAppTheme, currentAppStyle } = useContext(WnaAppContext);

    let headerBgColor = currentAppTheme.colors.accent4;
    if (currentAppTheme.dark)
        headerBgColor = currentAppTheme.colors.staticAccent5;

    const headerStyle = { backgroundColor: headerBgColor };
    return {
        style: { display: "none" },
        headerShown: false,
        headerTitle: headerTitle,
        headerTintColor: currentAppTheme.colors.staticWhite,
        headerTitleStyle: currentAppStyle.navigationHeaderTitleStyle,
        headerStyle: headerStyle,
        tabBarItemStyle: isVisible ? {} : { display: "none" },
        tabBarBadge: badge,
        tabBarBadgeStyle: currentAppStyle.tabBarBadgeStyle,
        headerShadowVisible: true,
        tabBarStyle: currentAppStyle.tabBarStyle,
        tabBarLabel: () => {
            return null;
        },
        tabBarIcon: ({ focused }: { focused: boolean }) =>
            TabBarIcon({ focused, icon: iconName, currentAppTheme }),
        unmountOnBlur: unmountOnBlur,
    } as BottomTabNavigationOptions;
}
