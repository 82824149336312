import WnaGenerateHtmlRequest from "@domain/contracts/types/WnaGenerateHtmlRequest";
import WnaGenerateHtmlResponse from "@domain/contracts/types/WnaGenerateHtmlResponse";
import WnaUser from "@domain/entities/WnaUser";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import WnaLogger from "wna-logger";

/**
 * returns html of diary
 * @param wnaUser
 * @param chapterIdentifier
 * @param startIndex
 * @param length
 */
const getHtmlAsync = async (
    wnaUser: WnaUser,
    chapterIdentifier: string,
    startIndex: number,
    length: number
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/${chapterIdentifier}/html-paginated`;
    const request = initJsonRequest("POST");
    const generateHtmlRequest = {
        userId: wnaUser.id,
        chapterIdentifier: chapterIdentifier,
        startIndex: startIndex,
        length: length,
        prepareForPdf: false,
    } as WnaGenerateHtmlRequest;

    request.body = JSON.stringify(generateHtmlRequest);
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 120000,
            abortHandler: (err) => {
                WnaLogger.error(getHtmlAsync.name, err);
                const defaultResp = {
                    html: "",
                    length: 0,
                    startIndex: 0,
                    endIndex: 0,
                } as WnaGenerateHtmlResponse;
                return new Response(JSON.stringify(defaultResp));
            },
        })
    );

    const responseJson = await response.json();
    return responseJson as WnaGenerateHtmlResponse;
};

export default getHtmlAsync;
