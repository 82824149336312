import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import { WnaDiaryEntryDetailScreenProps } from "@app/screens/diary/diaryEntry/detail/WnaDiaryEntryDetailScreenProps";
import WnaDataView from "@app/screens/diary/diaryEntry/detail/dataView/WnaDataView";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import getNewIdentifier from "@domain/contracts/WnaIdentifier";
import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import { WnaDiaryEntryPlaceholders } from "@domain/contracts/types/WnaDiaryEntryPlaceholder";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import WnaRoute from "@domain/entities/WnaRoute";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import WnaDiaryEntryDao from "@infrastructure/dao/WnaDiaryEntryDao";
import WnaRouteDao from "@infrastructure/dao/WnaRouteDao";
import { i18nKeysCommon, i18nKeysDialog } from "@infrastructure/i18n/i18nKeys";
import {
    isGreaterThan,
    toStringDateWeekday,
} from "@infrastructure/services/WnaDateTimeService";
import { minifyImageAsync } from "@infrastructure/services/images/WnaImageService";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaButtonHeader from "@ui/components/buttons/WnaButtonHeader";
import WnaButtonIcon from "@ui/components/buttons/WnaButtonIcon";
import WnaCarousel, {
    WnaCarouselItem,
} from "@ui/components/images/WnaCarousel";
import WnaMaps from "@ui/components/maps/WnaMaps";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaMultilineHeader from "@ui/components/tabbar/WnaMultilineHeader";
import WnaRefreshNotifier from "@ui/events/refresh/WnaRefreshNotifier";
import WnaRefreshParam, {
    WnaRefreshAction,
} from "@ui/events/refresh/WnaRefreshParam";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View, useWindowDimensions } from "react-native";
import { TabBar, TabView } from "react-native-tab-view";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import { default as WnaLogger, default as wnaLogger } from "wna-logger";

const WnaDiaryEntryDetailScreen: FC<WnaStackScreenProps> = (props) => {
    const {
        isInternetReachable,
        currentUser,
        currentUserSettings,
        appSettings,
        currentAppTheme,
        currentAppStyle,
        isLandscape,
    } = useContext(WnaAppContext);

    const pProps = props.route.params as WnaDiaryEntryDetailScreenProps;
    const { t } = useTranslation(["common", "dialog"]);
    if (currentUser == null || appSettings == null) return null;

    const layout = useWindowDimensions();
    const [isBusy, setIsBusy] = useState(false);
    const isBusyRef = useRef(false);
    const [isBusyText, setIsBusyText] = useState("");

    const isCarouselBusyRef = useRef(false);
    const [isCommentTyping, setIsCommentTyping] = useState(false);
    const [tabViewIndex, setTabViewIndex] = useState(
        pProps == null ? 0 : currentUserSettings?.diaryDefaultTab ?? 0
    );
    const [tabViewRoutes] = useState([
        {
            key: "entry",
            title: t(i18nKeysCommon.diaryEntryTabNameEntry),
            props,
        },
        { key: "map", title: t(i18nKeysCommon.diaryEntryTabNameMap), props },
        {
            key: "fotos",
            title: t(i18nKeysCommon.diaryEntryTabNamePictures),
            props,
        },
    ]);

    const currentItemOrgRef = useRef(new WnaDiaryEntry());
    const currentItemRef = useRef(new WnaDiaryEntry());

    const itemsRef = useRef<Array<WnaDiaryEntry>>([]);
    const [currentItemFirebasePath, setCurrentItemFirebasePath] = useState("");

    const isDirtyRef = useRef(false);
    const dirtyImagesRef = useRef<Array<WnaCarouselItem>>([]);
    const isNavigationBlockedRef = useRef(false);

    const [currentRoute, setCurrentRoute] = useState<WnaRoute>(new WnaRoute());
    const isRouteDirtyRef = useRef(false);
    const currentRouteRef = useRef(new WnaRoute());
    const dirtyGraphDataRef = useRef(new WnaMapsGraphData());
    const saveChangesRef = useRef(false);
    const placeholdersRef = useRef({} as WnaDiaryEntryPlaceholders);

    const refreshParameter = new WnaRefreshParam(
        props.route.name,
        props.route.path
    );
    refreshParameter.action = WnaRefreshAction.create;

    const [isEditMode, setIsEditMode] = useState(false);
    const isEditModeRef = useRef(false);

    const isAddNewModeRef = useRef(false);
    const isUploadingRef = useRef(false);
    const [backgroundImageB64, setBackgroundImageB64] = useState("");

    const loadBackgroundImageB64Async = async (imageUrl: string) => {
        WnaLogger.start(
            loadBackgroundImageB64Async.name,
            `imageUrl: '${imageUrl}'`
        );
        const imageB64 =
            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                imageUrl,
                "png"
            );
        setBackgroundImageB64(imageB64);
        WnaLogger.end(
            loadBackgroundImageB64Async.name,
            `imageUrl: '${imageUrl}'`
        );
    };
    const onNavigateNextAsync = async () => {
        if (isBusyRef.current || isNavigationBlockedRef.current) {
            WnaToastProvider.showError(
                t(i18nKeysDialog.warningWaitForProgress)
            );
            return;
        }

        const currentIndex = itemsRef.current.findIndex(
            (item) => item.identifier === currentItemRef.current.identifier
        );
        if (currentIndex < itemsRef.current.length - 1)
            await initCurrentItemAsync(
                itemsRef.current[currentIndex + 1],
                false
            );
        else await initCurrentItemAsync(itemsRef.current[0], false);

        // select default tab
        if (currentUserSettings?.diaryRestoreDiaryTab === true)
            setTabViewIndex(currentUserSettings?.diaryDefaultTab ?? 0);
    };
    const onNavigatePrevAsync = async () => {
        if (isBusyRef.current || isNavigationBlockedRef.current) {
            WnaToastProvider.showError(
                t(i18nKeysDialog.warningWaitForProgress)
            );
            return;
        }

        const currentIndex = itemsRef.current.findIndex(
            (item) => item.identifier === currentItemRef.current.identifier
        );
        if (currentIndex > 0)
            await initCurrentItemAsync(
                itemsRef.current[currentIndex - 1],
                false
            );
        else
            await initCurrentItemAsync(
                itemsRef.current[itemsRef.current.length - 1],
                false
            );

        // select default tab
        if (currentUserSettings?.diaryRestoreDiaryTab === true)
            setTabViewIndex(currentUserSettings?.diaryDefaultTab ?? 0);
    };
    const onSetIsBusy = (isBusy: boolean) => {
        setIsBusy(isBusy);
        isBusyRef.current = isBusy;
    };
    const onItemUpdated = (item: WnaDiaryEntry) => {
        try {
            WnaLogger.start(WnaDiaryEntryDetailScreen.name, onItemUpdated.name);
            initHeader(item, true, isAddNewModeRef.current);
            currentItemRef.current = item;
            isDirtyRef.current = true;
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                onItemUpdated.name,
                error
            );
        } finally {
            WnaLogger.end(WnaDiaryEntryDetailScreen.name, onItemUpdated.name);
        }
    };
    const onImageCollectionChanged = async (items: Array<WnaCarouselItem>) => {
        try {
            WnaLogger.start(
                WnaDiaryEntryDetailScreen.name,
                onImageCollectionChanged.name
            );
            if (currentItemRef.current == null) return;

            const newItem = new WnaDiaryEntry(currentItemRef.current);
            dirtyImagesRef.current = items;
            onItemUpdated(newItem);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                onImageCollectionChanged.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaDiaryEntryDetailScreen.name,
                onImageCollectionChanged.name
            );
        }
    };

    const headerEditButtonOnPressAsync = async () => {
        if (isBusyRef.current || isNavigationBlockedRef.current) {
            WnaToastProvider.showError(
                t(i18nKeysDialog.warningWaitForProgress)
            );
            return;
        }

        if (isInternetReachable === true) {
            await initCurrentItemAsync(currentItemRef.current, true);
        } else {
            WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
        }
    };
    const headerDeleteButtonOnPressAsync = async () => {
        if (isBusyRef.current || isNavigationBlockedRef.current) {
            WnaToastProvider.showError(
                t(i18nKeysDialog.warningWaitForProgress)
            );
            return;
        }

        if (isInternetReachable === true && !isCarouselBusyRef.current) {
            await onDeleteAsync();
        } else {
            WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
        }
    };
    const headerRight = (pIsEditMode: boolean, pIsAddNewMode: boolean) => {
        return (
            <View style={{ flexDirection: "row" }}>
                {!pIsEditMode ? (
                    <View style={{ alignItems: "center" }}>
                        <WnaButtonHeader
                            text={t(i18nKeysCommon.actionEdit)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="pen"
                            onPress={headerEditButtonOnPressAsync}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>
                ) : !pIsAddNewMode ? (
                    <View style={{ alignItems: "center" }}>
                        <WnaButtonHeader
                            text={t(i18nKeysCommon.actionDelete)}
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            iconName="trash-alt"
                            onPress={headerDeleteButtonOnPressAsync}
                            t={t}
                            checkInternetConnection={true}
                        />
                    </View>
                ) : null}
                {!pIsEditMode ? (
                    <>
                        <View style={{ alignItems: "center" }}>
                            <WnaButtonHeader
                                text={t(i18nKeysCommon.actionPreviousEntry)}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-alt-circle-left"
                                onPress={onNavigatePrevAsync}
                                t={t}
                                checkInternetConnection={false}
                            />
                        </View>
                        <View style={{ alignItems: "center" }}>
                            <WnaButtonHeader
                                text={t(i18nKeysCommon.actionNextEntry)}
                                currentAppStyle={currentAppStyle}
                                currentAppTheme={currentAppTheme}
                                iconName="arrow-alt-circle-right"
                                onPress={onNavigateNextAsync}
                                t={t}
                                checkInternetConnection={false}
                            />
                        </View>
                    </>
                ) : null}
            </View>
        );
    };
    const undoButtonOnPressAsync = async () => {
        if (!isDirtyRef.current && isAddNewModeRef.current) {
            props.navigation.goBack();
            return;
        }

        setIsEditMode(false);
        isEditModeRef.current = false;
        isDirtyRef.current = false;
        const restoredItem = currentItemOrgRef.current;
        let isEditMode = false;
        if (pProps == null) {
            // add new
            restoredItem.identifier = getNewIdentifier();
            restoredItem.dateStart = new Date();
            restoredItem.dateEnd = restoredItem.dateStart;
            isEditMode = true;
        }
        await initCurrentItemAsync(restoredItem, isEditMode);
    };
    const onRouteSelectedAsync = async (
        selectedRoute: WnaRoute,
        graphData: WnaMapsGraphData
    ) => {
        try {
            WnaLogger.start(
                WnaDiaryEntryDetailScreen.name,
                onRouteSelectedAsync.name
            );
            if (currentRouteRef.current.identifier == selectedRoute.identifier)
                return;

            dirtyGraphDataRef.current = graphData;
            currentRouteRef.current = selectedRoute;
            isRouteDirtyRef.current = true;
            isDirtyRef.current = true;
            setCurrentRoute(selectedRoute);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                onRouteSelectedAsync.name,
                error
            );
        } finally {
            WnaLogger.end(
                WnaDiaryEntryDetailScreen.name,
                onRouteSelectedAsync.name
            );
        }
    };

    const onDeleteAsync = async () => {
        try {
            WnaLogger.start(WnaDiaryEntryDetailScreen.name, onDeleteAsync.name);
            if (isInternetReachable == false) {
                WnaToastProvider.showError(t(i18nKeysCommon.errorNoInternet));
                return;
            }

            // dlg onDeleteAsync
            const onDialogResult = async () => {
                onSetIsBusy(true);
                isUploadingRef.current = true;
                await WnaDiaryEntryDao.deleteAsync(
                    currentUser,
                    pProps.currentChapter!,
                    currentItemRef.current
                );
                isUploadingRef.current = false;
                onSetIsBusy(false);
                refreshParameter.action = WnaRefreshAction.delete;
                refreshParameter.context = currentItemRef.current;
                WnaRefreshNotifier.notify({ refreshParameter });
                const newItem = new WnaDiaryEntry(currentItemRef.current);
                newItem.dateDelete = new Date();
                currentItemRef.current = newItem;
                props.navigation.goBack();
                WnaToastProvider.showSuccess(t(i18nKeysCommon.successDelete));
            };
            WnaDialogProvider.showYesNo(
                t(i18nKeysDialog.questionDelete),
                onDialogResult
            );
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                onDeleteAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaDiaryEntryDetailScreen.name, onDeleteAsync.name);
        }
    };

    const showProgress = (val: Int32, max: Int32) => {
        const progressVal = ((val / max) * 100).toFixed(0);
        setIsBusyText(progressVal + " %");
    };

    const onGoBackAsync = async (): Promise<boolean> => {
        try {
            WnaLogger.start(WnaDiaryEntryDetailScreen.name, onGoBackAsync.name);

            if (
                new Date(currentItemRef.current.dateDelete).toISOString() >
                getUnixMinDate().toISOString()
            ) {
                WnaLogger.info(
                    WnaDiaryEntryDetailScreen.name,
                    onGoBackAsync.name,
                    "item was deleted -> do nothing"
                );
                return true;
            }

            refreshParameter.action = WnaRefreshAction.none;

            if (!saveChangesRef.current) {
                // save changes was not set by user - check isDirty
                if (isDirtyRef.current && isEditModeRef.current) {
                    const goAhead = await WnaDialogProvider.showYesNoAsync(
                        t(i18nKeysDialog.questionSaveChanges)
                    );
                    WnaLogger.info(
                        WnaDiaryEntryDetailScreen.name,
                        onGoBackAsync.name,
                        "goAhead: " + goAhead
                    );
                    if (!goAhead) {
                        saveChangesRef.current = false;
                        WnaRefreshNotifier.notify({
                            refreshParameter: refreshParameter,
                        });
                        return true; // do not save --> continue go back without saving changes
                    }
                } else {
                    WnaRefreshNotifier.notify({
                        refreshParameter: refreshParameter,
                    });
                    return true; // item is not dirty - just trigger refresh
                }
            }

            if (!isDirtyRef.current && saveChangesRef.current) {
                saveChangesRef.current = false;
                WnaRefreshNotifier.notify({
                    refreshParameter: refreshParameter,
                });
                return false; // do not save --> stay on page
            }

            if (!isDirtyRef.current) {
                WnaRefreshNotifier.notify({
                    refreshParameter: refreshParameter,
                });
                return true; // do not save
            }

            // // check for empty route
            // if (
            //     currentItemRef.current.routeIdentifier == "" &&
            //     dirtyGraphDataRef.current.routeHash == ""
            // ) {
            //     const goAhead = await WnaDialogProvider.showYesNoAsync(
            //         "Du hast noch keine Route ausgewählt. Trotzdem speichern?"
            //     );
            //     WnaLogger.info(
            //         WnaDiaryEntryDetailScreen.name,
            //         onGoBackAsync.name,
            //         "goAhead: " + goAhead
            //     );
            //     if (!goAhead) {
            //         saveChangesRef.current = false;
            //         WnaRefreshNotifier.notify({
            //             refreshParameter: refreshParameter,
            //         });
            //         // switch to route tab
            //         setTabViewIndex(1);
            //         return false; // do not save --> continue go back without saving changes
            //     }
            // }

            // check for empty images
            if (
                currentItemRef.current.images.length < 1 &&
                dirtyImagesRef.current.length < 1
            ) {
                const goAhead = await WnaDialogProvider.showYesNoAsync(
                    "Du hast noch keine Bilder ausgewählt. Trotzdem speichern?"
                );
                WnaLogger.info(
                    WnaDiaryEntryDetailScreen.name,
                    onGoBackAsync.name,
                    "goAhead: " + goAhead
                );
                if (!goAhead) {
                    saveChangesRef.current = false;
                    WnaRefreshNotifier.notify({
                        refreshParameter: refreshParameter,
                    });
                    // switch to images tab
                    setTabViewIndex(2);
                    return false; // do not save --> continue go back without saving changes
                }
            }

            saveChangesRef.current = false;

            setIsEditMode(false);
            isEditModeRef.current = false;
            initHeader(currentItemRef.current, false, false);

            onSetIsBusy(true);
            isUploadingRef.current = true;

            let maxProgress = 1; // wnaDiaryEntry
            let progressVal = 0;
            if (isRouteDirtyRef.current) maxProgress += 5;

            let resultDiaryEntry: WnaDiaryEntry | null;

            // favorite image
            if (
                dirtyImagesRef.current != null &&
                dirtyImagesRef.current.length > 0
            ) {
                const favImage = dirtyImagesRef.current.find((x) => {
                    return x.fileInfo.isFavourite;
                });
                if (favImage != undefined)
                    currentItemRef.current.imageUrl = favImage.fileInfo.url;
            }

            // current route
            if (
                dirtyGraphDataRef.current != null &&
                dirtyGraphDataRef.current.routeHash !== ""
            ) {
                currentItemRef.current.routeGeoJsonUrl =
                    currentRouteRef.current.geoJsonUrl;
                currentItemRef.current.routeStaticUrl =
                    currentRouteRef.current.staticTerrainUrl;
                currentItemRef.current.routeStatic512Url =
                    currentRouteRef.current.staticTerrain512Url;
                currentItemRef.current.routeStatic256Url =
                    currentRouteRef.current.staticTerrain256Url;
                currentItemRef.current.routeIdentifier =
                    currentRouteRef.current.identifier;
            }

            // data item first
            if (
                new Date(currentItemRef.current.dateCreate).toISOString() >
                getUnixMinDate().toISOString()
            ) {
                // update
                resultDiaryEntry = await WnaDiaryEntryDao.updateAsync(
                    currentUser,
                    pProps.currentChapter!,
                    currentItemRef.current
                );

                if (resultDiaryEntry == null) {
                    WnaToastProvider.showError(t(i18nKeysCommon.errorUnknown));
                    return false;
                }

                currentItemRef.current = resultDiaryEntry;
                refreshParameter.action = WnaRefreshAction.update;
            } else {
                // add new
                resultDiaryEntry = await WnaDiaryEntryDao.createAsync(
                    currentUser,
                    pProps.currentChapter!,
                    currentItemRef.current
                );
                if (resultDiaryEntry.identifier === "") {
                    WnaToastProvider.showError(t(i18nKeysCommon.errorUnknown));
                    return false;
                }

                currentItemRef.current = resultDiaryEntry;
                refreshParameter.action = WnaRefreshAction.create;
            }

            if (
                dirtyImagesRef.current != null &&
                dirtyImagesRef.current.length > 0
            )
                maxProgress += dirtyImagesRef.current.length;

            // upload images
            if (
                dirtyImagesRef.current != null &&
                dirtyImagesRef.current.length > 0
            ) {
                WnaLogger.info(
                    WnaDiaryEntryDetailScreen.name,
                    onGoBackAsync.name,
                    "uploading files..."
                );
                for (let i = 0; i < dirtyImagesRef.current.length; i++) {
                    progressVal += 1;
                    showProgress(progressVal, maxProgress);
                    const image = dirtyImagesRef.current[i];
                    if (image.fileInfo.isLocal && !image.fileInfo.isDeleted) {
                        WnaLogger.info(
                            WnaDiaryEntryDetailScreen.name,
                            onGoBackAsync.name,
                            "uploading file: " + image.fileInfo.fileName
                        );

                        const miniBlob = await minifyImageAsync(
                            image.fileInfo.url,
                            appSettings.imageMaxWidth,
                            appSettings.imageMaxHeight,
                            appSettings.imageQuality
                        );

                        resultDiaryEntry =
                            await WnaDiaryEntryDao.uploadImageAsync(
                                currentUser,
                                pProps.currentChapter!,
                                currentItemRef.current,
                                image.fileInfo.fileName,
                                image.fileInfo.isFavourite,
                                miniBlob
                            );

                        if (resultDiaryEntry == null) {
                            WnaToastProvider.showError(
                                t(i18nKeysCommon.errorUnknown)
                            );
                            return false;
                        }

                        currentItemRef.current = resultDiaryEntry;
                    } else if (image.fileInfo.isDeleted) {
                        // image deleted
                        WnaLogger.info(
                            WnaDiaryEntryDetailScreen.name,
                            onGoBackAsync.name,
                            "deleting file: " + image.fileInfo.fileName
                        );
                        image.fileInfo.isFavourite = false;
                        resultDiaryEntry =
                            await WnaDiaryEntryDao.deleteImageAsync(
                                currentUser,
                                pProps.currentChapter!,
                                currentItemRef.current,
                                image.fileInfo.fileName
                            );

                        if (resultDiaryEntry == null) {
                            WnaToastProvider.showError(
                                t(i18nKeysCommon.errorUnknown)
                            );
                            return false;
                        }

                        currentItemRef.current = resultDiaryEntry;
                    }
                }
            }

            // upload route
            if (isRouteDirtyRef.current) {
                progressVal += 1;
                showProgress(progressVal, maxProgress);
                // const routeFirestorePath =
                //     "routes/" + dirtyGraphDataRef.current.routeHash;
                // const existsRouteDir =
                //     await WnaFirebase.existsDirAsync(routeFirestorePath);
                //
                // if (!existsRouteDir) {
                //     await WnaRouteDao.uploadStaticMapImagesAsync(
                //         currentRouteRef.current,
                //         dirtyGraphDataRef.current,
                //         appSettings.googleApiKey,
                //         routeFirestorePath,
                //         "satellite",
                //         appSettings.imageQuality
                //     );
                //
                //     progressVal += 1;
                //     showProgress(progressVal, maxProgress);
                //     await WnaRouteDao.uploadStaticMapImagesAsync(
                //         currentRouteRef.current,
                //         dirtyGraphDataRef.current,
                //         appSettings.googleApiKey,
                //         routeFirestorePath,
                //         "hybrid",
                //         appSettings.imageQuality
                //     );
                //
                //     progressVal += 1;
                //     showProgress(progressVal, maxProgress);
                //     await WnaRouteDao.uploadStaticMapImagesAsync(
                //         currentRouteRef.current,
                //         dirtyGraphDataRef.current,
                //         appSettings.googleApiKey,
                //         routeFirestorePath,
                //         "roadmap",
                //         appSettings.imageQuality
                //     );
                //
                //     progressVal += 1;
                //     showProgress(progressVal, maxProgress);
                //     await WnaRouteDao.uploadStaticMapImagesAsync(
                //         currentRouteRef.current,
                //         dirtyGraphDataRef.current,
                //         appSettings.googleApiKey,
                //         routeFirestorePath,
                //         "terrain",
                //         appSettings.imageQuality
                //     );
                //
                //     const geoJsonToUpload = dirtyGraphDataRef.current.geoJson;
                //     currentRouteRef.current.geoJsonUrl =
                //         await WnaFirebase.uploadFileTextAsync(
                //             geoJsonToUpload,
                //             "route.geojson",
                //             routeFirestorePath
                //         );
                // } else {
                //     progressVal += 3;
                // }

                progressVal += 1;
                showProgress(progressVal, maxProgress);
                // currentRouteRef.current.geoJson = ""; // clear geoJson
                // currentRouteRef.current = await WnaRouteDao.createOrUpdateAsync(
                //     currentRouteRef.current
                // );
                setCurrentRoute(currentRouteRef.current);

                currentItemRef.current.routeGeoJsonUrl =
                    currentRouteRef.current.geoJsonUrl;
                currentItemRef.current.routeStaticUrl =
                    currentRouteRef.current.staticTerrainUrl;
                currentItemRef.current.routeStatic512Url =
                    currentRouteRef.current.staticTerrain512Url;
                currentItemRef.current.routeStatic256Url =
                    currentRouteRef.current.staticTerrain256Url;

                currentItemRef.current.routeIdentifier =
                    currentRouteRef.current.identifier;

                isRouteDirtyRef.current = false;
            }

            progressVal += 1;
            showProgress(progressVal, maxProgress);

            refreshParameter.context = resultDiaryEntry;

            WnaRefreshNotifier.notify({ refreshParameter: refreshParameter });
            dirtyImagesRef.current = [];
            isDirtyRef.current = false;
            saveChangesRef.current = false;
            onSetIsBusy(false);
            isUploadingRef.current = false;
            setIsBusyText("");

            // const goAhead = await WnaDialogProvider.showYesNoAsync("Zurück zur Liste?");
            // WnaLogger.info(DayDetailsTabScreen.name, onGoBackAsync.name, "goAhead: " + goAhead);
            // if (goAhead == true)
            //   return true; // go back to list

            await initCurrentItemAsync(currentItemRef.current, false);
            return false;
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                onGoBackAsync.name,
                error
            );
            return true;
        } finally {
            WnaLogger.end(WnaDiaryEntryDetailScreen.name, onGoBackAsync.name);
        }
    };

    const initHeader = (
        item: WnaDiaryEntry,
        pIsEditMode: boolean,
        pIsAddNewMode: boolean
    ) => {
        try {
            WnaLogger.start(WnaDiaryEntryDetailScreen.name, initHeader.name);
            const itemDateIso = new Date(item.dateStart).toISOString();
            let title = toStringDateWeekday(item.dateStart);
            const titleSegments = title.split(",");
            let countOfItemsWithSameTitle = 0;
            let itemsOrderedByCreatedDate = [
                ...itemsRef.current
                    .filter(
                        (x) =>
                            new Date(x.dateStart).toISOString() == itemDateIso
                    )
                    .sort((a, b) => isGreaterThan(a.dateCreate, b.dateCreate)),
            ];
            for (let i = 0; i < itemsOrderedByCreatedDate.length; i++) {
                if (itemsOrderedByCreatedDate[i].identifier == item.identifier)
                    break;
                else countOfItemsWithSameTitle++;
            }

            if (countOfItemsWithSameTitle > 0) {
                title += " (" + countOfItemsWithSameTitle + ")";
                titleSegments[1] += " (" + countOfItemsWithSameTitle + ")";
            }

            props.navigation.setOptions({
                title: title,
                headerTitle: () => {
                    return isLandscape
                        ? WnaMultilineHeader(
                              currentAppStyle,
                              currentAppTheme,
                              title
                          )
                        : WnaMultilineHeader(
                              currentAppStyle,
                              currentAppTheme,
                              title,
                              titleSegments[0],
                              titleSegments[1]
                          );
                },
                headerRight: () => headerRight(pIsEditMode, pIsAddNewMode),
            });
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                initHeader.name,
                error
            );
        } finally {
            WnaLogger.end(WnaDiaryEntryDetailScreen.name, initHeader.name);
        }
    };

    const initCurrentItemAsync = async (
        item: WnaDiaryEntry,
        pIsEditMode: boolean
    ) => {
        try {
            WnaLogger.start(
                WnaDiaryEntryDetailScreen.name,
                initCurrentItemAsync.name
            );
            const pItemOrg = new WnaDiaryEntry(item);
            currentItemOrgRef.current = pItemOrg;

            const pItem = new WnaDiaryEntry(pItemOrg);
            pItem.chapterIdentifier = pProps.currentChapter?.identifier ?? "";

            currentItemRef.current = pItem;

            const itemFirebasePath = WnaDiaryEntryDao.getPath(
                currentUser,
                pProps.currentChapter!,
                item.identifier
            );
            setCurrentItemFirebasePath(itemFirebasePath);

            placeholdersRef.current =
                await WnaAppSettingsDao.getDiaryEntryPlaceholdersAsync();

            if (item.routeIdentifier != "") {
                const wnaRoute = await WnaRouteDao.readByIdentifierAsync(
                    currentUser,
                    item.routeIdentifier
                );
                currentRouteRef.current = wnaRoute;
                setCurrentRoute(wnaRoute);
            } else {
                currentRouteRef.current = new WnaRoute();
                setCurrentRoute(new WnaRoute());
            }

            setIsEditMode(pIsEditMode);
            isEditModeRef.current = pIsEditMode;

            // WnaLogger.info(DayDetailsTabScreen.name, React.useEffect.name);
            if (
                new Date(item.dateCreate).toISOString() >
                new Date().toISOString()
            ) {
                // update mode
                WnaLogger.info(
                    WnaDiaryEntryDetailScreen.name,
                    initCurrentItemAsync.name,
                    "currentItem.identifier: " + item.identifier
                );
            }
            initHeader(item, pIsEditMode, isAddNewModeRef.current);
            if (item.imageUrl != null && item.imageUrl != "")
                await loadBackgroundImageB64Async(item.imageUrl);
        } catch (error) {
            WnaLogger.error(
                WnaDiaryEntryDetailScreen.name,
                initCurrentItemAsync.name,
                error
            );
        } finally {
            onSetIsBusy(false);
            WnaLogger.end(
                WnaDiaryEntryDetailScreen.name,
                initCurrentItemAsync.name
            );
        }
    };

    useEffect(() => {
        if (isNavigationBlockedRef.current || isBusyRef.current) return;

        WnaLogger.start(WnaDiaryEntryDetailScreen.name, useEffect.name);

        setIsBusy(true);
        isBusyRef.current = true;
        itemsRef.current = pProps.items;
        let currentItem = pProps.currentItem;

        const pBackgroundImageUrl =
            currentItemRef.current?.imageUrl != null &&
            currentItemRef.current?.imageUrl != ""
                ? currentItemRef.current.imageUrl
                : pProps.currentItem?.imageUrl != null &&
                    pProps.currentItem?.imageUrl != ""
                  ? pProps.currentItem?.imageUrl
                  : pProps.currentChapter?.imageUrl ?? "";

        wnaLogger.info(
            "currentChapter:" + pProps.currentChapter?.imageUrl ?? ""
        );

        loadBackgroundImageB64Async(pBackgroundImageUrl ?? "");

        let isEditMode = false;
        if (currentItem == null) {
            isAddNewModeRef.current = true;
            // add new
            currentItem = new WnaDiaryEntry();
            currentItem.identifier = getNewIdentifier();
            currentItem.dateStart = new Date();
            currentItem.dateEnd = currentItem.dateStart;
            isEditMode = true;
        }

        initCurrentItemAsync(currentItem, isEditMode);
        WnaLogger.end(WnaDiaryEntryDetailScreen.name, React.useEffect.name);

        // back navigation
        props.navigation.addListener("beforeRemove", (e) => {
            e.preventDefault();
            if (isBusyRef.current || isNavigationBlockedRef.current) {
                WnaToastProvider.showError(
                    t(i18nKeysDialog.warningWaitForProgress)
                );
                return;
            }

            onGoBackAsync().then((continueGoBack: boolean) => {
                if (continueGoBack) props.navigation.dispatch(e.data.action);
            });
        });
    }, [props]);

    // @ts-ignore
    const renderScene = ({ route }) => {
        if (currentItemRef.current == null) return null;

        switch (route.key) {
            case "entry":
                return (
                    <WnaDataView
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        currentItem={currentItemRef.current}
                        onUpdate={onItemUpdated}
                        onCommentTyping={setIsCommentTyping}
                        isEditMode={isEditMode}
                        placeholders={placeholdersRef.current}
                    />
                );
            case "fotos":
                return (
                    <WnaCarousel
                        firebaseItemPath={currentItemFirebasePath}
                        fileInfos={currentItemRef.current.images}
                        onCollectionChanged={onImageCollectionChanged}
                        isEditMode={isEditMode}
                        onSetIsBusy={(isCarouselBusy) => {
                            isNavigationBlockedRef.current = isCarouselBusy;
                        }}
                        onFirst={onNavigatePrevAsync}
                        onLast={onNavigateNextAsync}
                    />
                );
            case "map":
                return (
                    <WnaMaps
                        wnaRoute={currentRoute}
                        onRouteSelectedAsync={onRouteSelectedAsync}
                        isEditMode={isEditMode}
                    />
                );
            default:
                return null;
        }
    };

    // @ts-ignore
    const renderTabBar = (props) => (
        <TabBar
            {...props}
            activeColor={currentAppTheme.colors.accent4}
            inactiveColor={currentAppTheme.colors.coolgray4}
            indicatorStyle={{ backgroundColor: currentAppTheme.colors.accent4 }}
            style={{ backgroundColor: currentAppTheme.colors.white }}
            renderLabel={({ route, focused, color }) => (
                <Text
                    style={{
                        color,
                        width: 96,
                        textAlign: "center",
                        fontWeight: focused ? "bold" : "normal",
                        textTransform: "uppercase",
                        fontSize: focused ? 16 : 14,
                    }}>
                    {route.title}
                </Text>
            )}
        />
    );

    const renderPlaceHolder = () => {
        return (
            <View style={currentAppStyle.containerCenterCenter}>
                <WnaActivityIndicator currentAppTheme={currentAppTheme} />
            </View>
        );
    };

    if (currentItemRef.current.identifier == "") return null;

    if (backgroundImageB64 == "") return null;

    return (
        <WnaBaseScreenWithInfo
            isBusy={isBusy}
            isBusyText={isBusyText}
            backgroundImageUrl={backgroundImageB64}>
            <TabView
                navigationState={{ index: tabViewIndex, routes: tabViewRoutes }}
                renderScene={renderScene}
                renderTabBar={renderTabBar}
                onIndexChange={setTabViewIndex}
                swipeEnabled={false} //{Platform.OS != "web"}
                initialLayout={{ width: layout.width }}
                lazy
                tabBarPosition="top"
                renderLazyPlaceholder={renderPlaceHolder}
            />

            {!isEditMode ||
            isCarouselBusyRef.current ||
            isCommentTyping ? null : (
                <>
                    <WnaButtonIcon
                        toolTip={t(i18nKeysCommon.actionSave)}
                        toolTipPosition="left"
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        iconName="check"
                        style={{ position: "absolute", bottom: 16, right: 16 }}
                        onPress={() => {
                            saveChangesRef.current = true;
                            props.navigation.goBack();
                        }}
                        t={t}
                        checkInternetConnection={true}
                    />
                    <WnaButtonIcon
                        toolTip={t(i18nKeysCommon.actionUndo)}
                        toolTipPosition="right"
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        iconName="undo"
                        style={{ position: "absolute", bottom: 16, left: 16 }}
                        onPress={undoButtonOnPressAsync}
                        t={t}
                        checkInternetConnection={false}
                    />
                </>
            )}
        </WnaBaseScreenWithInfo>
    );
};

export default WnaDiaryEntryDetailScreen;
