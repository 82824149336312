import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaUserSettings extends WnaEntityBase {
    public mapDisableDefaultUI: boolean = false;
    public mapDefaultType: Int32 = 0;
    public diaryDefaultTab: Int32 = 0;
    public diaryOrderBy: 0 | 1 = 1;
    public mapGraphQuality: Int32 = 2;
    public mapGraphTolerance: Int32 = 0;
    public mapGraphAutoScale: Int32 = 1;
    public diaryRestoreDiaryTab: boolean = false;
    public appIsLoggingEnabled: boolean = false;
    public appIsOfflineCacheEnabled: boolean = false;

    constructor(init?: Partial<WnaUserSettings | null>) {
        super(init);
        Object.assign(this, init);
    }
}
