import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import WnaUser from "@domain/entities/WnaUser";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import WnaPicker, { WnaDataSourceItem } from "@ui/components/misc/WnaPicker";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import WnaLogger from "wna-logger";

const WnaAdminScreen: React.FC<WnaStackScreenProps> = () => {
    const { currentUser, setCurrentUser, currentAppTheme, currentAppStyle } =
        useContext(WnaAppContext);
    const { t } = useTranslation(["common"]);

    // switch user start
    const usersDsRawRef = useRef<Array<WnaUser>>([]);
    const [usersDs, setUsersDs] = useState<Array<WnaDataSourceItem>>([]);
    const [selectedUserDsVal, setSelectedUserDsVal] = useState<any>();
    const [isBusy, setIsBusy] = useState(false);
    // switch user end

    const setSelectedUserAsync = async (selectedUser: WnaUser) => {
        if (selectedUser.id == currentUser?.id) return;

        WnaUserDao.disableCreateOrUpdate();
        selectedUser.pictureBase64 =
            await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                selectedUser.picture,
                "png"
            );
        setCurrentUser(selectedUser);
        WnaToastProvider.showSuccess(selectedUser.email);
    };

    const loadDataAsync = async () => {
        WnaLogger.start(WnaAdminScreen.name, loadDataAsync.name);
        try {
            usersDsRawRef.current = await WnaUserDao.readAllAsync();
            setUsersDs(
                usersDsRawRef.current.map((item) => {
                    return {
                        value: item.identifier,
                        label: item.email,
                    } as WnaDataSourceItem;
                })
            );
            const selectedUser = usersDsRawRef.current.find(
                (x) => x.identifier === currentUser?.identifier
            );
            if (selectedUser) setSelectedUserDsVal(selectedUser.id);
        } catch (error) {
            WnaLogger.error(WnaAdminScreen.name, loadDataAsync.name, error);
        }
        WnaLogger.end(WnaAdminScreen.name, loadDataAsync.name);
    };

    useEffect(() => {
        loadDataAsync();
    }, []);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            <View style={currentAppStyle.containerCenterMaxWidth}>
                {/* switch user */}
                <View style={[{ zIndex: 2000 }]}>
                    <WnaPicker
                        currentAppStyle={currentAppStyle}
                        currentAppTheme={currentAppTheme}
                        dataSource={usersDs}
                        selectedKey={selectedUserDsVal}
                        onSelectionChanged={(val) => {
                            if (val == null) return;

                            const selectedUser = usersDsRawRef.current.find(
                                (x) => x.identifier === val
                            );
                            if (selectedUser != undefined) {
                                setSelectedUserDsVal(selectedUser.identifier);
                                setSelectedUserAsync(selectedUser);
                            }
                        }}
                    />
                </View>
            </View>
        </WnaBaseScreenWithInfo>
    );
};

export default WnaAdminScreen;
