import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaActivityIndicator from "@ui/components/misc/WnaActivityIndicator";
import React, { Component } from "react";
import { View } from "react-native";

export type WnaBaseScreenProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    isBusy?: boolean;
    children?: React.ReactNode;
};

class WnaBaseScreen extends Component<WnaBaseScreenProps> {
    render() {
        return (
            <>
                {this.props.isBusy === true ? (
                    <View
                        style={
                            this.props.currentAppStyle.containerCenterCenter
                        }>
                        <WnaActivityIndicator
                            currentAppTheme={this.props.currentAppTheme}
                        />
                    </View>
                ) : (
                    this.props.children
                )}
            </>
        );
    }
}

export default WnaBaseScreen;
