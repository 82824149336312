import { WnaAppContext } from "@app/WnaAppContext";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import React, { useContext, useRef, useState } from "react";
import { Animated, Pressable, StyleSheet, Text, View } from "react-native";
import WnaToastProvider from "./WnaToastProvider";

export default function WnaToast() {
    const animationDuration = 250;
    const visibleDuration = 2000;
    const toastOpacity = useRef(new Animated.Value(0)).current;
    const [text, setText] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const { currentAppTheme } = useContext(WnaAppContext);
    const popOut = () => {
        if (isBusy) return;

        setIsBusy(true);
        setTimeout(() => {
            Animated.timing(toastOpacity, {
                toValue: 0,
                duration: animationDuration,
                useNativeDriver: false,
            }).start(() => setIsBusy(false));
        }, visibleDuration);
    };

    const popIn = (text: string, isSuccess: boolean) => {
        if (isBusy) return;

        setIsBusy(true);
        // console.log("popIn");
        setText(text);
        setIsSuccess(isSuccess);
        Animated.timing(toastOpacity, {
            toValue: 1,
            duration: animationDuration,
            useNativeDriver: false,
        }).start(() => {
            setIsBusy(false);
            popOut();
        });
    };

    const instantPopOut = () => {
        Animated.timing(toastOpacity, {
            toValue: 0,
            duration: animationDuration,
            useNativeDriver: false,
        }).start(() => setIsBusy(false));
    };

    WnaToastProvider.init(popIn);
    return (
        <View
            style={[
                styles(currentAppTheme).mainContainer,
                { display: isBusy ? "flex" : "none" },
            ]}
            pointerEvents="box-none">
            <Animated.View style={{ opacity: toastOpacity }}>
                <View
                    style={{
                        backgroundColor: isSuccess
                            ? currentAppTheme.colors.green4
                            : currentAppTheme.colors.red4,
                        padding: 16,
                        borderRadius: 24,
                        overflow: "hidden",
                    }}>
                    <Pressable onPress={instantPopOut}>
                        <View style={[styles(currentAppTheme).toastRow]}>
                            <View>
                                <FontAwesome5
                                    size={24}
                                    name={
                                        isSuccess
                                            ? "check-circle"
                                            : "times-circle"
                                    }
                                    color={currentAppTheme.colors.staticWhite}
                                />
                            </View>
                            <View style={styles(currentAppTheme).toastText}>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        color: currentAppTheme.colors
                                            .staticWhite,
                                    }}
                                    numberOfLines={3}
                                    ellipsizeMode="tail">
                                    {text}
                                </Text>
                            </View>
                        </View>
                    </Pressable>
                </View>
            </Animated.View>
        </View>
    );
}

const styles = (theme: WnaTheme) =>
    StyleSheet.create({
        mainContainer: {
            alignItems: "center",
            position: "absolute",
            bottom: 64,
            left: 24,
            right: 24,
            marginLeft: "auto",
            marginRight: "auto",
            elevation: 10000,
            zIndex: 10000,
            overflow: "visible",
        },

        toastRow: {
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        },
        toastText: {
            marginLeft: 16,
            color: theme.colors.black,
        },
    });
