import WnaGlobalState from "@app/WnaGlobalState";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaUser from "@domain/entities/WnaUser";
import WnaUserSettings from "@domain/entities/WnaUserSettings";
import { setAppStyle } from "@infrastructure/services/WnaStyleService";
import { getThemedColors } from "@infrastructure/services/WnaThemeService";
import React from "react";
import { Dimensions } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";
import WnaUserDao from "../infrastructure/dao/WnaUserDao";

const initialColors = getThemedColors(null, 0, false);
const sW = Dimensions.get("screen").width;
const sH = Dimensions.get("screen").height;
const wW = Dimensions.get("window").width;
const wH = Dimensions.get("window").height;
const isLandscape = wW > wH;

// Declaring the state object globally.
const initialState: WnaGlobalState = {
    isLogInModalVisible: false,
    setIsLogInModalVisible: () => {},
    currentScreenWidth: sW,
    setCurrentScreenWidth: () => {},
    currentScreenHeight: sH,
    setCurrentScreenHeight: () => {},
    currentWindowWidth: wW,
    setCurrentWindowWidth: () => {},
    currentWindowHeight: wH,
    setCurrentWindowHeight: () => {},
    isLandscape: isLandscape,
    setIsLandscape: () => {},
    currentAppTheme: initialColors,
    setCurrentAppTheme: () => {},
    currentAppStyle: setAppStyle(initialColors),
    currentUser: null,
    setCurrentUser: () => {},
    currentAdminUser: null,
    setCurrentAdminUser: () => {},
    currentUserSettings: null,
    setCurrentUserSettings: () => {},
    isInternetReachable: false,
    setIsInternetReachable: () => {},
    isInternetSlow: false,
    setIsInternetSlow: () => {},
    appSettings: null,
    setAppSettings: () => {},
    unreadNotificationCount: null,
    setUnreadNotificationCount: () => {},
    darkAppBackgroundImage: "",
    setDarkAppBackgroundImage: () => {},
    lightAppBackgroundImage: "",
    setLightAppBackgroundImage: () => {},
    isStatusBarVisible: true,
    setIsStatusBarVisible: () => {},
};
export const wnaAppContext = (component?: React.Component) => ({
    ...initialState,
    setIsLogInModalVisible: (isVisible: boolean) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setIsLogInModalVisible",
            "isVisible: " + isVisible
        );
        initialState.isLogInModalVisible = isVisible;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentScreenWidth: (screenWidth: number) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentScreenWidth",
            "screenWidth: " + screenWidth
        );
        initialState.currentScreenWidth = screenWidth;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentScreenHeight: (screenHeight: number) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentScreenHeight",
            "screenHeight: " + screenHeight
        );
        initialState.currentScreenHeight = screenHeight;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentWindowWidth: (windowWidth: number) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentWindowWidth",
            "windowWidth: " + windowWidth
        );
        initialState.currentWindowWidth = windowWidth;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentWindowHeight: (windowHeight: number) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentWindowHeight",
            "windowHeight: " + windowHeight
        );
        initialState.currentWindowHeight = windowHeight;
        component?.setState({ context: wnaAppContext(component) });
    },
    setIsLandscape: (isLandscape: boolean) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setIsLandscape",
            "isLandscape: " + isLandscape
        );
        initialState.isLandscape = isLandscape;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentAppTheme: (currentAppTheme: WnaTheme) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentAppTheme",
            "currentAppTheme: " + currentAppTheme.id
        );
        initialState.currentAppTheme = currentAppTheme;
        initialState.currentAppStyle = setAppStyle(currentAppTheme);
        component?.setState({ context: wnaAppContext(component) });
    },
    setIsInternetReachable: (isInternetReachable: boolean | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setIsInternetReachable",
            "isInternetReachable: " + isInternetReachable
        );
        initialState.isInternetReachable = isInternetReachable;
        component?.setState({ context: wnaAppContext(component) });
    },
    setIsInternetSlow: (isInternetSlow: boolean | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setIsInternetSlow",
            "isInternetSlow: " + isInternetSlow
        );
        initialState.isInternetSlow = isInternetSlow;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentUser: (currentUser: WnaUser | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentUser",
            "currentUser: " + currentUser
        );
        if (currentUser == null) initialState.currentAdminUser = null;

        initialState.currentUser = currentUser;
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentAdminUser: (currentAdminUser: WnaUser | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentAdminUser",
            "currentAdminUser: " + currentAdminUser
        );
        if (
            currentAdminUser != null &&
            WnaUserDao.isAdmin(currentAdminUser, initialState.appSettings)
        ) {
            initialState.currentAdminUser = currentAdminUser;
        } else {
            initialState.currentAdminUser = null;
        }
        component?.setState({ context: wnaAppContext(component) });
    },
    setCurrentUserSettings: (currentUserSettings: WnaUserSettings | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setCurrentUserSettings",
            "currentUserSettings: " + currentUserSettings
        );
        initialState.currentUserSettings = currentUserSettings;
        if (currentUserSettings != null) {
            WnaLogger.warn(
                WnaLogger.name,
                "appIsLoggingEnabled: " +
                    currentUserSettings.appIsLoggingEnabled
            );
            WnaLogger.isEnabled = currentUserSettings.appIsLoggingEnabled;
        }

        component?.setState({ context: wnaAppContext(component) });
    },
    setAppSettings: (appSettings: WnaAppSettings | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setAppSettings",
            "appSettings: " + appSettings
        );
        initialState.appSettings = appSettings;
        component?.setState({ context: wnaAppContext(component) });
    },
    setUnreadNotificationCount: (unreadNotificationCount: Int32 | null) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setUnreadNotificationCount",
            "unreadNotificationCount: " + unreadNotificationCount
        );
        initialState.unreadNotificationCount = unreadNotificationCount;
        component?.setState({ context: wnaAppContext(component) });
    },
    setDarkAppBackgroundImage: (uri: string) => {
        // WnaLogger.info(
        //     wnaAppContextWrapper.name,
        //     "setDarkAppBackgroundImage",
        //     "uri: " + uri
        // );
        initialState.darkAppBackgroundImage = uri;
        component?.setState({ context: wnaAppContext(component) });
    },
    setLightAppBackgroundImage: (uri: string) => {
        // WnaLogger.info(
        //     wnaAppContextWrapper.name,
        //     "setLightAppBackgroundImage",
        //     "uri: " + uri
        // );
        initialState.lightAppBackgroundImage = uri;
        component?.setState({ context: wnaAppContext(component) });
    },
    setIsStatusBarVisible: (isVisible: boolean) => {
        WnaLogger.info(
            wnaAppContext.name,
            "setIsStatusBarVisible",
            "isVisible: " + isVisible
        );
        initialState.isStatusBarVisible = isVisible;
        component?.setState({ context: wnaAppContext(component) });
    },
});

export type Context = ReturnType<typeof wnaAppContext>;

export const WnaAppContext = React.createContext<Context>(wnaAppContext());

export interface State {
    context: Context;
}

export class WnaAppContextProvider extends React.Component {
    state: State = {
        context: wnaAppContext(this),
    };

    render() {
        return (
            <WnaAppContext.Provider value={this.state.context}>
                {
                    // @ts-ignore
                    this.props.children
                }
            </WnaAppContext.Provider>
        );
    }
}
