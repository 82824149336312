const i18nKeysCommon = {
    actionSave: "actionSave",
    actionPhoneCall: "actionPhoneCall",
    actionEmail: "actionEmail",
    actionRoute: "actionRoute",
    actionNextEntry: "actionNextEntry",
    actionPreviousEntry: "actionPreviousEntry",
    actionInfo: "actionInfo",
    actionCancel: "actionCancel",
    actionCreateNotification: "actionCreateNotification",
    actionCreateAppointment: "actionCreateAppointment",
    actionCreateChapter: "actionCreateChapter",
    actionCreateDiaryEntry: "actionCreateDiaryEntry",
    actionEdit: "actionEdit",
    actionUndo: "actionUndo",
    actionEditChapter: "actionEditChapter",
    actionDelete: "actionDelete",
    actionDeleteLog: "actionDeleteLog",
    actionDonate: "actionDonate",
    actionFullscreen: "actionFullscreen",
    actionSend: "actionSend",
    actionLogIn: "actionLogIn",
    actionSendErrorLog: "actionSendErrorLog",
    actionLogOut: "actionLogOut",
    actionLogInWithGoogle: "actionLogInWithGoogle",
    actionLogInWithFacebook: "actionLogInWithFacebook",
    actionLogOutAndDeleteData: "actionLogOutAndDeleteData",
    actionGoBack: "actionGoBack",
    actionLogInForFree: "actionLogInForFree",
    actionPrint: "actionPrint",
    actionAddPicture: "actionAddPicture",
    diaryEntryTabNameEntry: "diaryEntryTabNameEntry",
    diaryEntryTabNameMap: "diaryEntryTabNameMap",
    diaryEntryTabNamePictures: "diaryEntryTabNamePictures",
    screenTitlePrivacy: "screenTitlePrivacy",
    screenTitleAppointmentList: "screenTitleAppointmentList",
    screenSubtitleAppointmentList: "screenSubtitleAppointmentList",
    screenTitleAppointmentDetails: "screenTitleAppointmentDetails",
    screenTitleAccommodationDetails: "screenTitleAccommodationDetails",
    screenTitleDisclaimer: "screenTitleDisclaimer",
    screenTitleMenu: "screenTitleMenu",
    screenTitleProfile: "screenTitleProfile",
    screenTitleAdministration: "screenTitleAdministration",
    screenTitleSettings: "screenTitleSettings",
    screenTitleSettingsDiary: "screenTitleSettingsDiary",
    screenTitleSettingsMap: "screenTitleSettingsMap",
    screenTitleSettingsAdvanced: "screenTitleSettingsAdvanced",
    screenTitleNotificationList: "screenTitleNotificationList",
    screenTitleNotificationDetails: "screenTitleNotificationDetails",
    screenTitleDayList: "screenTitleDayList",
    screenTitleDiaryPreview: "screenTitleDiaryPreview",
    screenTitleDayDetails: "screenTitleDayDetails",
    screenTitleStartPage: "screenTitleStartPage",
    screenErrorLog: "screenErrorLog",
    wordDiary: "wordDiary",
    wordMap: "wordMap",
    wordBegin: "wordBegin",
    wordEnd: "wordEnd",
    wordAdvanced: "wordAdvanced",
    settingsMapDefaultMap: "settingsMapDefaultMap",
    settingsMapControls: "settingsMapControls",
    settingsMapScaleElevationChart: "settingsMapScaleElevationChart",
    settingsDiaryOrderItems: "settingsDiaryOrderItems",
    settingsDiaryDefaultTab: "settingsDiaryDefaultTab",
    settingsDiarySwitchToDefaultTab: "settingsDiarySwitchToDefaultTab",
    settingsTheme: "settingsTheme",
    settingsAdvancedLogging: "settingsAdvancedLogging",
    infoNotAvailable: "infoNotAvailable",
    infoUserLoggedOut: "infoUserLoggedOut",
    infoUserLoggedIn: "infoUserLoggedOut",
    infoNeedToLogIn: "infoNeedToLogIn",
    infoPleaseWait: "infoPleaseWait",
    infoWorkInProgress: "infoWorkInProgress",
    successDelete: "successDelete",
    errorDelete: "errorDelete",
    success: "success",
    successSave: "successSave",
    errorSave: "errorSave",
    successSend: "successSend",
    successDownload: "successDownload",
    errorSend: "errorSend",
    errorInputInvalid: "errorInputInvalid",
    errorNoInternet: "errorNoInternet",
    errorUnknown: "errorUnknown",
    wordYes: "wordYes",
    wordNo: "wordNo",
    catalogThemeSystem: "catalogThemeSystem",
    catalogThemeLight: "catalogThemeLight",
    catalogThemeDark: "catalogThemeDark",
    catalogOrderByAsc: "catalogOrderByAsc",
    catalogOrderByDesc: "catalogOrderByDesc",
    catalogDefaultDiaryTabEntry: "catalogDefaultDiaryTabEntry",
    catalogDefaultDiaryTabMap: "catalogDefaultDiaryTabMap",
    catalogDefaultDiaryTabPictures: "catalogDefaultDiaryTabPictures",
    catalogGraphQualityLow: "catalogGraphQualityLow",
    catalogGraphQualityMedium: "catalogGraphQualityMedium",
    catalogGraphQualityHigh: "catalogGraphQualityHigh",
    catalogMapTypeSatellite: "catalogMapTypeSatellite",
    catalogMapTypeHybrid: "catalogMapTypeHybrid",
    catalogMapTypeRoadmap: "catalogMapTypeRoadmap",
    catalogMapTypeTerrain: "catalogMapTypeTerrain",
    catalogNotificationStateUnread: "catalogNotificationStateUnread",
    catalogNotificationStateRead: "catalogNotificationStateRead",
    catalogNotificationStateDeleted: "catalogNotificationStateDeleted",
    placeholderAppointmentDetails: "placeholderAppointmentDetails",
    placeholderChapter: "placeholderChapter",
    infoNoMapData: "infoNoMapData",
    infoNoRoute: "infoNoRoute",
    mapInfoPostfixLength: "mapInfoPostfixLength",
    mapInfoPostfixDuration: "mapInfoPostfixDuration",
    mapInfoPostfixHighestPoint: "mapInfoPostfixHighestPoint",
    mapInfoPostfixLowestPoint: "mapInfoPostfixLowestPoint",
    mapInfoPostfixDelta: "mapInfoPostfixDelta",
    mapInfoPostfixUp: "mapInfoPostfixUp",
    mapInfoPostfixDown: "mapInfoPostfixDown",
    mapInfoPostfixEqual: "mapInfoPostfixEqual",
    dummyDiaryEntry1Designator: "dummyDiaryEntry1Designator",
    dummyDiaryEntry1Comment: "dummyDiaryEntry1Comment",
    dummyDiaryEntry2Designator: "dummyDiaryEntry2Designator",
    dummyDiaryEntry2Comment: "dummyDiaryEntry2Comment",
    infoPreviewInProgress: "infoPreviewInProgress",
};

const i18nKeysDialog = {
    warningWaitForProgress: "warningWaitForProgress",
    warningCreateNewChapter: "warningCreateNewChapter",
    questionLogOut: "questionLogOut",
    questionSend: "questionSend",
    questionLogOutAndDeleteData: "questionLogOutAndDeleteData",
    questionDelete: "questionDelete",
    questionSaveChanges: "questionSaveChanges",
    answerYes: "answerYes",
    answerNo: "answerNo",
};

export { i18nKeysCommon, i18nKeysDialog };
