import WnaNotification from "@domain/entities/WnaNotification";
import WnaUser from "@domain/entities/WnaUser";
import fetchWithTimeout from "@infrastructure/services/WnaFetchWithTimeoutProvider";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

/**
 * returns list of diaryEntries
 * @param wnaUser
 */
const getAllNotificationsAsync = async (wnaUser: WnaUser) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/notifications`;
    const request = initJsonRequest("GET");
    const response = await handleResponseAsync(
        await fetchWithTimeout(url, request, {
            timeout: 10000,
            abortHandler: (err) => {
                return new Response(
                    JSON.stringify([] as Array<WnaNotification>)
                );
            },
        })
    );
    return (await response.json()) as Array<WnaNotification>;
};

export default getAllNotificationsAsync;
