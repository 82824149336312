import WnaAsyncStorageProvider from "@infrastructure/services/storage/WnaAsyncStorageProvider/WnaAsyncStorageProvider";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";
import { Mutex } from "async-mutex";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import WnaLogger from "wna-logger";

const _mutex = new Mutex();
const _col = "c";
const _useDb = true;
const _cacheDir = FileSystem.cacheDirectory + "c";
const getLocalUriAsync = async (key: string, extension: string) => {
    const di = await FileSystem.getInfoAsync(_cacheDir);
    if (!di.exists)
        await FileSystem.makeDirectoryAsync(_cacheDir, { intermediates: true });

    return _cacheDir + "/" + key + "." + extension;
};
const getCleanKeyName = (url: string) => {
    if (url === null || url === "") return "";

    return (_col + "_" + url.replace(/[^A-Z0-9]+/gi, ""))
        .replace("httpsfirebasestoragegoogleapiscom", "f")
        .replace("appspotcom", "")
        .replace("httpsftfastenmitvolkerdefileprovider2php", "");
};

const blobToBase64DataURL = (b: Blob) =>
    new Promise((resolvePromise) => {
        const reader = new FileReader();
        reader.onload = () => resolvePromise(reader.result);
        reader.readAsDataURL(b);
    });

/**
 *
 * @param url
 * @param key
 * @param extension
 * @returns local uri after caching
 */
const cacheFileByUrlAsync = async (
    url: string,
    key: string,
    extension: string
) => {
    let ret = "";

    try {
        // WnaLogger.start(WnaAsyncFileCacheProvider.name, cacheUrlAsync.name, url);

        if (Platform.OS === "web") {
            const resp = await fetch(url, {
                headers: {
                    ApiKey: WnaApiSettings.apiKey,
                },
                method: "GET",
                mode: "cors",
            });
            const bl = await resp.blob();
            ret = (await blobToBase64DataURL(bl)) as string;
            if (ret != "")
                await WnaAsyncStorageProvider.setItemAsync(
                    key,
                    ret,
                    false,
                    _useDb
                );
            else
                WnaLogger.error(
                    WnaAsyncFileCacheProvider.name,
                    cacheFileByUrlAsync.name,
                    "b64 is empty --> could not cache " + key
                );
        } else {
            const localUri = await getLocalUriAsync(key, extension);
            const dlResult = await FileSystem.downloadAsync(url, localUri, {
                headers: {
                    ApiKey: WnaApiSettings.apiKey,
                },
            });
            if (dlResult.status != 200)
                throw "download failed - Status: " + dlResult.status;

            ret = dlResult.uri;

            WnaLogger.info(
                WnaAsyncFileCacheProvider.name,
                cacheFileByUrlAsync.name,
                "cached uri: " + ret
            );
        }
    } catch (error) {
        WnaLogger.error(
            WnaAsyncFileCacheProvider.name,
            cacheFileByUrlAsync.name,
            error
        );
    }
    // finally {
    //     WnaLogger.end(WnaAsyncFileCacheProvider.name, cacheUrlAsync.name, url);
    // }
    return ret;
};

/**
 *
 * @param url
 * @param key
 * @param extension
 * @returns local uri after caching
 */
const cacheFileTextByUrlAsync = async (
    url: string,
    key: string,
    extension: string
) => {
    let ret = "";

    try {
        // WnaLogger.start(WnaAsyncFileCacheProvider.name, cacheFileTextByUrlAsync.name, url);

        if (Platform.OS == "web") {
            const resp = await fetch(url, {
                headers: {
                    ApiKey: WnaApiSettings.apiKey,
                },
                method: "GET",
                mode: "cors",
            });
            ret = await resp.text();
            if (ret != "")
                await WnaAsyncStorageProvider.setItemAsync(
                    key,
                    ret,
                    false,
                    _useDb
                );
            else
                WnaLogger.error(
                    WnaAsyncFileCacheProvider.name,
                    cacheFileTextByUrlAsync.name,
                    "text is empty --> could not cache " + key
                );
        } else {
            const localUri = await getLocalUriAsync(key, extension);
            const dlResult = await FileSystem.downloadAsync(url, localUri, {
                headers: {
                    ApiKey: WnaApiSettings.apiKey,
                },
            });
            if (dlResult.status != 200)
                throw "download failed - Status: " + dlResult.status;

            ret = await FileSystem.readAsStringAsync(dlResult.uri);
        }
    } catch (error) {
        WnaLogger.error(
            WnaAsyncFileCacheProvider.name,
            cacheFileTextByUrlAsync.name,
            error
        );
    }
    // finally {
    //     WnaLogger.end(WnaAsyncFileCacheProvider.name, cacheFileTextByUrlAsync.name, url);
    // }
    return ret;
};

export default class WnaAsyncFileCacheProvider {
    public static async cacheFileByBlobAsync(
        blob: Blob,
        url: string,
        extension: string
    ) {
        return await _mutex.runExclusive(async () => {
            try {
                const key = getCleanKeyName(url);
                if (Platform.OS == "web") {
                    const b64 = (await blobToBase64DataURL(blob)) as string;
                    if (b64 != "")
                        await WnaAsyncStorageProvider.setItemAsync(
                            key,
                            b64,
                            false,
                            _useDb
                        );
                    else
                        WnaLogger.error(
                            WnaAsyncFileCacheProvider.name,
                            WnaAsyncFileCacheProvider.cacheFileByBlobAsync.name,
                            "b64 is empty --> could not cache " + key
                        );
                } else {
                    await cacheFileByUrlAsync(url, key, extension);
                }
            } catch (error) {
                WnaLogger.error(
                    WnaAsyncFileCacheProvider.name,
                    WnaAsyncFileCacheProvider.cacheFileByBlobAsync.name,
                    error
                );
            }
        });
    }

    public static async cacheFileByTextAsync(
        text: string,
        url: string,
        extension: string
    ) {
        return await _mutex.runExclusive(async () => {
            try {
                const key = getCleanKeyName(url);
                if (Platform.OS == "web") {
                    await WnaAsyncStorageProvider.setItemAsync(
                        key,
                        text,
                        false,
                        _useDb
                    );
                } else {
                    await cacheFileTextByUrlAsync(url, key, extension);
                }
            } catch (error) {
                WnaLogger.error(
                    WnaAsyncFileCacheProvider.name,
                    WnaAsyncFileCacheProvider.cacheFileByTextAsync.name,
                    error
                );
            }
        });
    }

    public static async removeCachedFileByUrlAsync(
        url: string,
        extension: string
    ) {
        return await _mutex.runExclusive(async () => {
            try {
                const key = getCleanKeyName(url);
                if (Platform.OS == "web") {
                    await WnaAsyncStorageProvider.setItemAsync(
                        key,
                        "",
                        false,
                        _useDb
                    );
                } else {
                    const localUri = await getLocalUriAsync(key, extension);
                    await FileSystem.deleteAsync(localUri, {
                        idempotent: true,
                    });
                }
            } catch (error) {
                WnaLogger.error(
                    WnaAsyncFileCacheProvider.name,
                    WnaAsyncFileCacheProvider.removeCachedFileByUrlAsync.name,
                    error
                );
            }
        });
    }

    public static async isFileCachedAsync(url: string, extension: string) {
        let ret = false;
        try {
            const key = getCleanKeyName(url);
            if (Platform.OS == "web") {
                ret = await WnaAsyncStorageProvider.existsKeyAsync(key, _useDb);
            } else {
                const localUri = await getLocalUriAsync(key, extension);
                const fi = await FileSystem.getInfoAsync(localUri);
                ret = fi.exists;
            }

            WnaLogger.info(
                WnaAsyncFileCacheProvider.name,
                WnaAsyncFileCacheProvider.isFileCachedAsync.name,
                ret
            );
        } catch (error) {
            WnaLogger.error(
                WnaAsyncFileCacheProvider.name,
                WnaAsyncFileCacheProvider.isFileCachedAsync.name,
                error
            );
        }
        return ret;
    }

    /**
     *
     * @param url
     * @param extension
     * @returns web: base64 | android local imagePath
     */
    public static async getCachedFileByUrlAsync(
        url: string,
        extension: string
    ) {
        if (url == null || url == "") return "";

        let ret = "";
        try {
            // WnaLogger.start(WnaAsyncFileCacheProvider.name, WnaAsyncFileCacheProvider.getCachedImageByUrlAsync.name, url);
            const key = getCleanKeyName(url);
            if (key != "") {
                if (Platform.OS === "web") {
                    WnaLogger.info("try to get cached b64Uri for " + key);
                    ret =
                        (await WnaAsyncStorageProvider.getItemAsync(
                            key,
                            false,
                            true
                        )) ?? "";
                    if (ret == "") {
                        WnaLogger.info(
                            "could not get cached b64uri - try to fetch item to cache for: " +
                                url
                        );
                        ret = await cacheFileByUrlAsync(url, key, extension); // url not cached --> try to cache
                    } else {
                        WnaLogger.info(
                            "will use local cached image for: " + url
                        );
                    }
                } else {
                    const localUri = await getLocalUriAsync(key, extension);
                    const fi = await FileSystem.getInfoAsync(localUri);
                    if (fi.exists) ret = fi.uri;
                    else ret = await cacheFileByUrlAsync(url, key, extension);

                    //WnaLogger.info(WnaAsyncFileCacheProvider.name, WnaAsyncFileCacheProvider.getCachedImageByUrlAsync.name, "cached uri: " + ret);
                }
            } else throw "invalid key";
        } catch (error) {
            WnaLogger.error(
                WnaAsyncFileCacheProvider.name,
                WnaAsyncFileCacheProvider.getCachedFileByUrlAsync.name,
                error
            );
        }
        // finally {
        //     WnaLogger.end(WnaAsyncFileCacheProvider.name, WnaAsyncFileCacheProvider.getCachedImageByUrlAsync.name, url);
        // }
        return ret;
    }

    /**
     *
     * @param url
     * @param extension
     * @returns text
     */
    public static async getCachedTextByUrlAsync(
        url: string,
        extension: string
    ) {
        if (url == null || url == "") return "";

        let ret = "";
        try {
            // WnaLogger.start(WnaAsyncFileCacheProvider.name, WnaAsyncFileCacheProvider.getCachedTextByUrlAsync.name, url);
            const key = getCleanKeyName(url);
            if (key != "") {
                if (Platform.OS === "web") {
                    // WnaLogger.info("try to get cached text for " + key);
                    ret =
                        (await WnaAsyncStorageProvider.getItemAsync(
                            key,
                            false,
                            true
                        )) ?? "";
                    if (ret == "") {
                        //WnaLogger.info("try to fetch item to cache for: " + url);
                        ret = await cacheFileTextByUrlAsync(
                            url,
                            key,
                            extension
                        ); // url not cached --> try to cache
                    } else {
                        // WnaLogger.info("will use local cached image for: " + url);
                    }
                } else {
                    const localUri = await getLocalUriAsync(key, extension);
                    const fi = await FileSystem.getInfoAsync(localUri);
                    if (fi.exists)
                        ret = await FileSystem.readAsStringAsync(fi.uri);
                    else
                        ret = await cacheFileTextByUrlAsync(
                            url,
                            key,
                            extension
                        );

                    //WnaLogger.info(WnaAsyncFileCacheProvider.name, WnaAsyncFileCacheProvider.getCachedImageByUrlAsync.name, "cached uri: " + ret);
                }
            } else throw "invalid key";
        } catch (error) {
            WnaLogger.error(
                WnaAsyncFileCacheProvider.name,
                WnaAsyncFileCacheProvider.getCachedTextByUrlAsync.name,
                error
            );
        }
        // finally {
        //     WnaLogger.end(WnaAsyncFileCacheProvider.name, WnaAsyncFileCacheProvider.getCachedTextByUrlAsync.name, url);
        // }
        return ret;
    }

    public static async clearCacheAsync() {
        return await _mutex.runExclusive(async () => {
            try {
                WnaLogger.start(
                    WnaAsyncFileCacheProvider.name,
                    WnaAsyncFileCacheProvider.clearCacheAsync.name
                );
                if (Platform.OS == "web") {
                    await WnaAsyncStorageProvider.clearAsync(_col + "_");
                } else {
                    const files =
                        await FileSystem.readDirectoryAsync(_cacheDir);
                    for (const file of files) {
                        try {
                            const fi = await FileSystem.getInfoAsync(file);
                            if (fi.exists)
                                await FileSystem.deleteAsync(fi.uri, {
                                    idempotent: true,
                                });
                        } catch (error) {
                            WnaLogger.warn(
                                WnaAsyncFileCacheProvider.name,
                                WnaAsyncFileCacheProvider.clearCacheAsync.name,
                                error
                            );
                        }
                    }
                    try {
                        const di = await FileSystem.getInfoAsync(_cacheDir);
                        if (di.exists)
                            await FileSystem.deleteAsync(di.uri, {
                                idempotent: true,
                            });
                    } catch (error) {
                        WnaLogger.warn(
                            WnaAsyncFileCacheProvider.name,
                            WnaAsyncFileCacheProvider.clearCacheAsync.name,
                            error
                        );
                    }
                }
            } catch (error) {
                WnaLogger.error(
                    WnaAsyncFileCacheProvider.name,
                    WnaAsyncFileCacheProvider.clearCacheAsync.name,
                    error
                );
            } finally {
                WnaLogger.end(
                    WnaAsyncFileCacheProvider.name,
                    WnaAsyncFileCacheProvider.clearCacheAsync.name
                );
            }
        });
    }
}
