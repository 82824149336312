import { WnaAppContext } from "@app/WnaAppContext";
import WnaDialog from "@app/dialog/WnaDialog";
import WnaDialogProvider from "@app/dialog/WnaDialogProvider";
import WnaDiaryViewModal from "@app/modals/WnaDiaryViewModal";
import WnaImageViewerModal from "@app/modals/WnaImageViewerModal/WnaImageViewerModal";
import WnaLoginModal from "@app/modals/WnaLoginModal";
import WnaMapInfoModal from "@app/modals/WnaMapInfoModal";
import WnaModalProvider from "@app/modals/WnaModalProvider";
import WnaLogInProvider from "@app/services/WnaLogInPorvider";
import WnaAppointmentStack from "@app/stacks/WnaAppointmentStack";
import WnaDiaryStack from "@app/stacks/WnaDiaryStack";
import WnaLocationStack from "@app/stacks/WnaLocationStack";
import WnaMenuStack from "@app/stacks/WnaMenuStack";
import WnaStartPageStack from "@app/stacks/WnaStartPageStack";
import WnaToast from "@app/toast/WnaToast";
import WnaToastProvider from "@app/toast/WnaToastProvider";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaUser from "@domain/entities/WnaUser";
import { Courgette_400Regular, useFonts } from "@expo-google-fonts/courgette";
import WnaUserDao from "@infrastructure/dao/WnaUserDao";
import { i18n } from "@infrastructure/i18n/i18n";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { setAppStyle } from "@infrastructure/services/WnaStyleService";
import registerFcmTokenAsync from "@infrastructure/wnaApi/fcmToken/commands/RegisterFcmToken";
import LogRocket from "@logrocket/react-native";
import WnaLinking from "@navigation/WnaLinking";
import WnaNavigationProvider from "@navigation/WnaNavigationProvider";
import NetInfo from "@react-native-community/netinfo";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import WnaImageBackground from "@ui/components/images/WnaImageBackground";
import WnaStatusBar from "@ui/components/misc/WnaStatusBar";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import WnaTabOptions from "@ui/components/tabbar/WnaBottomTabNavigationOptions";
import * as SplashScreen from "expo-splash-screen";
import * as WebBrowser from "expo-web-browser";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { Dimensions, Linking, Platform, View } from "react-native";
import {
    ModalOptions,
    ModalProvider,
    ModalStackConfig,
    createModalStack,
} from "react-native-modalfy";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { WnaApplicationConstants } from "wna-app-constants";
import WnaLogger from "wna-logger";
import WnaNotificationDao from "../infrastructure/dao/WnaNotificationDao";
import WnaUserSettingsDao from "../infrastructure/dao/WnaUserSettingsDao";
import WnaFirebaseFcm from "../infrastructure/fcm/WnaFirebaseFcm";
import WnaFirebase from "../infrastructure/firebase/WnaFirebase";

const pkg = require("wna-app-json");

if (Platform.OS != "web") {
    LogRocket.init("quxlae/fastentagebuch");
}
WebBrowser.maybeCompleteAuthSession();

export type AppComponentProps = {
    appSettings: WnaAppSettings;
    currentAppTheme: WnaTheme;
    darkBgImageUri: string;
    lightBgImageUri: string;
};

// https://colorfy-software.gitbook.io/react-native-modalfy/guides/params
const modalConfig: ModalStackConfig = {
    WnaLoginModal,
    WnaImageViewerModal,
    WnaMapInfoModal,
    WnaDiaryViewModal,
};
const modalDefaultOptions: ModalOptions = {
    backdropOpacity: 0.9,
    disableFlingGesture: true,
};
const modalStack = createModalStack(modalConfig, modalDefaultOptions);
const tabNavigator = createBottomTabNavigator();

// @ts-ignore
let dimensionTimerHandle = null;
const WnaApp: FC<AppComponentProps> = (props) => {
    if (props.appSettings == null) return null;

    const { t } = useTranslation(["common"]);

    // appContext
    const {
        currentAppTheme,
        setCurrentAppTheme,
        currentUser,
        setCurrentUser,
        currentAdminUser,
        setCurrentAdminUser,
        appSettings,
        setAppSettings,
        currentUserSettings,
        setCurrentUserSettings,
        isInternetReachable,
        setIsInternetReachable,
        setUnreadNotificationCount,
        setCurrentScreenWidth,
        setCurrentScreenHeight,
        setCurrentWindowWidth,
        setCurrentWindowHeight,
        setIsLandscape,
        darkAppBackgroundImage,
        setDarkAppBackgroundImage,
        lightAppBackgroundImage,
        setLightAppBackgroundImage,
        setIsLogInModalVisible,
        isStatusBarVisible,
    } = useContext(WnaAppContext);

    // font
    const [fontsLoaded] = useFonts(
        Platform.OS == "web"
            ? {
                  Courgette_400Regular: require("../../assets/fonts/Courgette-Regular.ttf"),
              }
            : { Courgette_400Regular }
    );

    const [isBusy, setIsBusy] = useState(true);
    const [isBusyMessage, setIsBusyMessage] = useState("Bereite App vor");
    const [isAppReady, setIsAppReady] = useState(false);
    const [didWriteUserInfoToLog, setDidWriteUserInfoToLog] = useState(false);
    const fcmListener = useRef();
    const fcmResponseListener = useRef();
    const oldIsInternetReachableRef = useRef(isInternetReachable);

    const startDimensionsTimer = () => {
        dimensionTimerHandle = setTimeout(() => {
            const sW = Dimensions.get("screen").width;
            const sH = Dimensions.get("screen").height;
            const wW = Dimensions.get("window").width;
            const wH = Dimensions.get("window").height;
            const isLandscape = wW > wH;
            setCurrentScreenWidth(sW);
            setCurrentScreenHeight(sH);
            setCurrentWindowWidth(wW);
            setCurrentWindowHeight(wH);
            setIsLandscape(isLandscape);
            WnaLogger.info(
                "updateLayout: sW: " +
                    sW +
                    " | sH: " +
                    sH +
                    " | wW: " +
                    wW +
                    " | wH: " +
                    wH +
                    " | isLandscape: " +
                    isLandscape
            );
        }, 500);
    };

    const onUserChangedAsync = async (wnaUser: WnaUser) => {
        WnaLogger.start(WnaApp.name, onUserChangedAsync.name);

        if (await WnaUserDao.isAlive()) {
            if (wnaUser.id != "") {
                if (Platform.OS != "web") {
                    setIsBusyMessage("Init LogRocket");
                    LogRocket.identify(wnaUser.id, {
                        name: wnaUser.name,
                        email: wnaUser.email,
                    });
                }

                // store fcm token for user to db
                setIsBusyMessage("Get FCM Token");
                const fcmToken = await WnaFirebaseFcm.getCurrentFcmTokenAsync();
                await registerFcmTokenAsync(wnaUser, fcmToken);

                // trigger after a second
                setTimeout(() => readNotificationCountAsync(wnaUser), 1000);
            }
        }
        setCurrentUser(wnaUser);
        setCurrentAdminUser(wnaUser);
        setIsBusy(false);
        setIsBusyMessage("");
        WnaLogger.end(WnaApp.name, onUserChangedAsync.name);
    };

    const isConnectedUnsubscribe = NetInfo.addEventListener(async (state) => {
        //WnaLogger.info(AppComponent.name, useEffect.name, "NetInfo state changed: " + JSON.stringify(state));
        const newIsInternetReachable = state.isInternetReachable !== false;
        if (newIsInternetReachable !== oldIsInternetReachableRef.current) {
            setIsInternetReachable(newIsInternetReachable);
            oldIsInternetReachableRef.current = newIsInternetReachable;
            WnaLogger.info(
                WnaApp.name,
                useEffect.name,
                "newIsInternetReachable: " + newIsInternetReachable
            );
        }
    });

    const onNotificationReceivedAsync = async (title: string, body: string) => {
        try {
            WnaLogger.start(WnaApp.name, onNotificationReceivedAsync.name);
            const c =
                await WnaNotificationDao.readCountUnreadAsync(currentUser);
            setUnreadNotificationCount(c > 0 ? c : null);
            WnaDialogProvider.showOk(body);
        } catch (error) {
            WnaLogger.error(
                WnaApp.name,
                onNotificationReceivedAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaApp.name, onNotificationReceivedAsync.name);
        }
    };

    const readNotificationCountAsync = async (wnaUser: WnaUser) => {
        setIsBusyMessage("Read Notifications");
        // check notification count
        const c = await WnaNotificationDao.readCountUnreadAsync(wnaUser);
        setUnreadNotificationCount(c > 0 ? c : null);
    };

    const setAppBaseUrlAsync = async () => {
        if (Platform.OS != "web") return;

        const currentUrlAsString = (await Linking.getInitialURL()) ?? "";
        const currentUrl = new URL(currentUrlAsString);
        WnaApplicationConstants.AppBaseUrl = `${currentUrl.protocol}//${currentUrl.hostname}`;
    };

    // enable / disable logging
    WnaLogger.isEnabled = true;
    // appSettings != null &&
    // currentUser != null &&
    // //&& (appSettings.userLogs.find(x => x === currentUser.id)) === currentUser.id;
    // currentUserSettings != null &&
    // currentUserSettings.appIsLoggingEnabled;
    if (WnaLogger.isEnabled && !didWriteUserInfoToLog) {
        WnaLogger.start(WnaApp.name, "####################");
        WnaLogger.info(
            "Platform: " +
                Platform.OS +
                " | version: " +
                pkg.expo.version +
                " | themeId: " +
                currentAppTheme.id +
                " dark: " +
                currentAppTheme.dark
        );
        WnaLogger.info(JSON.stringify(currentUser));
        WnaLogger.end(WnaApp.name, "####################");
        setDidWriteUserInfoToLog(true);
    }

    useEffect(() => {
        WnaLogger.start(WnaApp.name, useEffect.name);

        setCurrentAppTheme(props.currentAppTheme);
        setAppStyle(props.currentAppTheme);
        setAppSettings(props.appSettings);
        setDarkAppBackgroundImage(props.darkBgImageUri);
        setLightAppBackgroundImage(props.lightBgImageUri);

        const prepareAppAsync = async () => {
            try {
                setIsBusyMessage("Bereite App vor");
                WnaLogger.start(WnaApp.name, prepareAppAsync.name);
                if (isAppReady) {
                    WnaLogger.info("app is ready - nothing to do");
                    return;
                }

                WnaLogger.info("##### APP START #####");
                await setAppBaseUrlAsync();

                setIsBusyMessage("Lade Benutzereinstellungen");
                const userSettings = await WnaUserSettingsDao.readAsync();
                setCurrentUserSettings(userSettings);

                setIsBusyMessage("Prüfe Anmeldung");
                const possibleLoggedInUser =
                    await WnaUserDao.getCurrentUserFromStorageAsync();

                if (
                    possibleLoggedInUser !== undefined &&
                    possibleLoggedInUser !== null &&
                    possibleLoggedInUser.id !== ""
                ) {
                    if (
                        (await WnaUserDao.isAlive()) &&
                        possibleLoggedInUser.wnaApiJwt !== ""
                    ) {
                        possibleLoggedInUser.wnaApiJwt =
                            await WnaUserDao.verifyJwtAsync(
                                possibleLoggedInUser.id,
                                possibleLoggedInUser.wnaApiJwt
                            );
                        if (possibleLoggedInUser.wnaApiJwt === "") {
                            setIsBusyMessage(
                                t(i18nKeysCommon.infoUserLoggedOut)
                            );
                            const loggedOutUser = new WnaUser();
                            await WnaUserDao.setCurrentUserToStorageAsync(
                                loggedOutUser
                            );
                            await onUserChangedAsync(loggedOutUser);
                            setTimeout(() => {
                                // sucks, but required because of rendering
                                WnaToastProvider.showError(
                                    t(i18nKeysCommon.infoUserLoggedOut)
                                );
                            }, 2000);
                        } else {
                            // user is logged in
                            setIsBusyMessage(
                                t(i18nKeysCommon.infoUserLoggedOut)
                            );
                            await onUserChangedAsync(possibleLoggedInUser);
                        }
                    } else {
                        // user is logged in
                        setIsBusyMessage(t(i18nKeysCommon.infoUserLoggedIn));
                        await onUserChangedAsync(possibleLoggedInUser);
                    }
                } else {
                    await onUserChangedAsync(possibleLoggedInUser);
                }

                const updateLayout = () => {
                    // @ts-ignore
                    clearTimeout(dimensionTimerHandle);
                    startDimensionsTimer();
                };
                Dimensions.addEventListener("change", updateLayout);

                WnaLogger.info(
                    WnaApp.name,
                    prepareAppAsync.name,
                    "WnaFirebaseFcm.init"
                );
                WnaFirebaseFcm.init(
                    fcmListener,
                    fcmResponseListener,
                    onNotificationReceivedAsync
                );
                WnaLogInProvider.init(() => setIsLogInModalVisible(true));
            } catch (error) {
                WnaLogger.warn(WnaApp.name, prepareAppAsync.name, error);
            } finally {
                // Tell the application to render
                WnaLogger.info("setAppIsReady true");
                setIsAppReady(true);
                WnaLogger.end(WnaApp.name, prepareAppAsync.name);
            }
        };

        // setIsInternetSlow((pIsInternetFast === false));
        // WnaLogger.warn("INTERNET IS SLOW: " + (pIsInternetFast === false));

        prepareAppAsync();

        WnaLogger.end(WnaApp.name, useEffect.name);

        // Clean up the subscription when the component unmounts
        return () => {
            isConnectedUnsubscribe();
        };
    }, []);

    const onNavigationContainerReady = async () => {
        await SplashScreen.hideAsync();
    };

    if (!isAppReady || !fontsLoaded)
        return (
            <View style={{ backgroundColor: currentAppTheme.colors.white }} />
        );

    return (
        <PaperProvider theme={currentAppTheme}>
            <SafeAreaView
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: currentAppTheme.colors.white,
                }}>
                <WnaImageBackground
                    image={
                        currentAppTheme.dark
                            ? darkAppBackgroundImage
                            : lightAppBackgroundImage
                    }>
                    <I18nextProvider i18n={i18n}>
                        <ModalProvider stack={modalStack}>
                            <WnaModalProvider
                                googleSignInPromptAsync={async () => {
                                    // googleSignInPromptAsync
                                    const wnaUser =
                                        await WnaFirebase.signInWithGoogleAsync();
                                    await onUserChangedAsync(wnaUser);
                                    if (wnaUser.id !== "") {
                                        WnaToastProvider.showSuccess(
                                            t(i18nKeysCommon.infoUserLoggedIn)
                                        );
                                    }
                                }}
                                facebookSignInPromptAsync={async () => {
                                    // WnaToastProvider.showSuccess("facebook is here")
                                    const wnaUser =
                                        await WnaFirebase.signInWithFacebookAsync();
                                    await onUserChangedAsync(wnaUser);
                                    if (wnaUser.id !== "") {
                                        WnaToastProvider.showSuccess(
                                            t(i18nKeysCommon.infoUserLoggedIn)
                                        );
                                    }
                                }}>
                                {isBusy || !fontsLoaded ? (
                                    <WnaBaseScreenWithInfo
                                        isBusy
                                        isBusyText={isBusyMessage}>
                                        <></>
                                    </WnaBaseScreenWithInfo>
                                ) : (
                                    <NavigationContainer
                                        theme={currentAppTheme}
                                        linking={WnaLinking}
                                        onReady={onNavigationContainerReady}>
                                        <tabNavigator.Navigator
                                            initialRouteName={
                                                currentUser == null ||
                                                currentUser.id == ""
                                                    ? "start"
                                                    : "diary"
                                            }>
                                            <tabNavigator.Screen
                                                name="start"
                                                navigationKey="startNavigation"
                                                children={(props) => (
                                                    <WnaStartPageStack />
                                                )}
                                                options={(props) => {
                                                    WnaNavigationProvider.init(
                                                        props.navigation
                                                    );
                                                    return WnaTabOptions({
                                                        isVisible: true,
                                                        headerTitle:
                                                            appSettings!
                                                                .startTitle,
                                                        iconName: "home",
                                                        unmountOnBlur: false,
                                                    });
                                                }}
                                            />

                                            <tabNavigator.Screen
                                                name="appointments"
                                                navigationKey="appointmentsNavigation"
                                                children={(props) => (
                                                    <WnaAppointmentStack />
                                                )}
                                                // @ts-ignore
                                                options={() => {
                                                    return WnaTabOptions({
                                                        isVisible: true,
                                                        headerTitle: t(
                                                            i18nKeysCommon.screenTitleAppointmentList
                                                        ),
                                                        iconName:
                                                            "calendar-alt",
                                                        unmountOnBlur: true,
                                                    });
                                                }}
                                            />

                                            <tabNavigator.Screen
                                                name="diary"
                                                navigationKey="diaryNavigation"
                                                children={(props) => (
                                                    <WnaDiaryStack />
                                                )}
                                                // @ts-ignore
                                                options={() => {
                                                    return WnaTabOptions({
                                                        isVisible: true,
                                                        headerTitle: t(
                                                            i18nKeysCommon.screenTitleDayList
                                                        ),
                                                        iconName: "book-open",
                                                        unmountOnBlur: false,
                                                    });
                                                }}
                                            />

                                            <tabNavigator.Screen
                                                name="location"
                                                navigationKey="locationNavigation"
                                                children={(props) => (
                                                    <WnaLocationStack />
                                                )}
                                                // @ts-ignore
                                                options={() => {
                                                    return WnaTabOptions({
                                                        isVisible:
                                                            currentAdminUser !=
                                                                null &&
                                                            currentAdminUser.id !=
                                                                "",
                                                        headerTitle: "Route",
                                                        iconName: "map",
                                                        unmountOnBlur: true,
                                                    });
                                                }}
                                            />

                                            <tabNavigator.Screen
                                                name="menu"
                                                navigationKey="menuNavigation"
                                                children={(props) => (
                                                    <WnaMenuStack />
                                                )}
                                                // @ts-ignore
                                                options={() => {
                                                    return WnaTabOptions({
                                                        isVisible: true,
                                                        headerTitle: t(
                                                            i18nKeysCommon.screenTitleMenu
                                                        ),
                                                        iconName: "bars",
                                                        unmountOnBlur: true,
                                                    });
                                                }}
                                            />
                                        </tabNavigator.Navigator>
                                    </NavigationContainer>
                                )}
                                <WnaDialog />
                                <WnaToast />
                            </WnaModalProvider>
                        </ModalProvider>
                    </I18nextProvider>
                    <WnaStatusBar
                        currentAppTheme={currentAppTheme}
                        isVisible={isStatusBarVisible}
                    />
                </WnaImageBackground>
            </SafeAreaView>
        </PaperProvider>
    );
};

export default WnaApp;
