import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import WnaAppointment from "@domain/entities/WnaAppointment";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { getLangCode } from "@infrastructure/i18n/i18n";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import WnaButtonIconText from "@ui/components/buttons/WnaButtonIconText";
import WnaImage from "@ui/components/images/WnaImage";
import WnaHtmlRenderer from "@ui/components/misc/WnaHtmlRenderer";
import WnaText from "@ui/components/text/WnaText";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { Linking, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import WnaLogger from "wna-logger";

export type AppointmentReadOnlyProps = {
    currentAppStyle: WnaAppStyle;
    appSettings: WnaAppSettings;
    currentAppTheme: WnaTheme;
    currentItem: WnaAppointment;
    t: TFunction<string[], undefined>;
};

const _btnWidth = 256;

class WnaAppointmentReadOnly extends Component<AppointmentReadOnlyProps> {
    state = {
        html: "",
    };

    shouldComponentUpdate(
        nextProps: Readonly<AppointmentReadOnlyProps>
    ): boolean {
        return (
            nextProps.currentItem.designator !=
                this.props.currentItem.designator ||
            nextProps.currentItem.comment != this.props.currentItem.comment ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            this.state.html == ""
        );
    }

    render() {
        const document =
            getLangCode() === "en"
                ? "appointment-en.html"
                : "appointment-de.html";
        WnaAppSettingsDao.getResourceAsync(document).then((html) =>
            this.setState({ html: html })
        );
        return this.state.html == "" ? (
            <></>
        ) : (
            <ScrollView
                style={[
                    this.props.currentAppStyle.containerFillPage,
                    { paddingHorizontal: 16 },
                ]}>
                <View
                    style={[
                        this.props.currentAppStyle.containerCenterMaxWidth,
                        {
                            backgroundColor:
                                this.props.currentAppTheme.colors.white,
                            padding: 16,
                            gap: 16,
                            margin: 16,
                        },
                    ]}>
                    <WnaImage
                        currentAppTheme={this.props.currentAppTheme}
                        imageUrl={WnaAppSettingsDao.getImageResourceUrl(
                            this.props.appSettings.appointmentConfig.imageUrl
                        )}
                        style={{
                            height: 240,
                            maxHeight: 240,
                            backgroundColor:
                                this.props.currentAppTheme.colors.coolgray2,
                        }}
                    />

                    {this.props.currentItem.comment != "" ? (
                        <WnaText
                            currentAppStyle={this.props.currentAppStyle}
                            currentAppTheme={this.props.currentAppTheme}
                            text={this.props.currentItem.comment}
                            fontColor={this.props.currentAppTheme.colors.black}
                            fontSize={16}
                        />
                    ) : null}

                    <View>
                        <WnaHtmlRenderer
                            currentAppStyle={this.props.currentAppStyle}
                            currentAppTheme={this.props.currentAppTheme}
                            width={480}
                            html={this.state.html}
                        />
                    </View>

                    <View
                        style={{
                            gap: 24,
                            alignSelf: "center",
                            flex: 1,
                            alignItems: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}>
                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            currentAppTheme={this.props.currentAppTheme}
                            iconName={"phone"}
                            text={this.props.t(i18nKeysCommon.actionPhoneCall)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .tel;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            WnaLogger.error(
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        WnaLogger.error(
                                            WnaAppointmentReadOnly.name,
                                            "phone",
                                            error
                                        )
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />

                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            currentAppTheme={this.props.currentAppTheme}
                            iconName={"envelope"}
                            text={this.props.t(i18nKeysCommon.actionEmail)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .email;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            WnaLogger.error(
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        WnaLogger.error(
                                            WnaAppointmentReadOnly.name,
                                            "email",
                                            error
                                        )
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />

                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            currentAppTheme={this.props.currentAppTheme}
                            iconName={"map"}
                            text={this.props.t(i18nKeysCommon.actionRoute)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .address;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            WnaLogger.error(
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        WnaLogger.error(
                                            WnaAppointmentReadOnly.name,
                                            "address",
                                            error
                                        )
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />

                        <WnaButtonIconText
                            style={{ width: _btnWidth }}
                            currentAppTheme={this.props.currentAppTheme}
                            iconName={"info-circle"}
                            text={this.props.t(i18nKeysCommon.actionInfo)}
                            onPress={() => {
                                const url =
                                    this.props.appSettings.appointmentConfig
                                        .url;
                                Linking.canOpenURL(url)
                                    .then((supported) => {
                                        if (!supported) {
                                            WnaLogger.error(
                                                "cannot open url: " + url
                                            );
                                        } else {
                                            return Linking.openURL(url);
                                        }
                                    })
                                    .catch((error) =>
                                        WnaLogger.error(
                                            WnaAppointmentReadOnly.name,
                                            "info",
                                            error
                                        )
                                    );
                            }}
                            t={this.props.t}
                            checkInternetConnection={false}
                        />
                    </View>
                </View>
            </ScrollView>
        );
    }
}

export default WnaAppointmentReadOnly;
