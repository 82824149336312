import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import DropDownPicker, {
    ItemType,
    ValueType,
} from "react-native-dropdown-picker";

export type WnaDataSourceItem = {
    value: any;
    label: string;
};

export type WnaPickerProps = {
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
    dataSource: Array<WnaDataSourceItem>;
    selectedKey: any;
    onSelectionChanged: (selectedKey: ValueType | null) => void;
    placeholder?: string;
};

const WnaPicker: React.FC<WnaPickerProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(
        props.dataSource as Array<ItemType<ValueType>>
    );
    const [value, setValue] = useState(props.selectedKey as ValueType);

    useEffect(() => {
        setItems(props.dataSource);
        setValue(props.selectedKey);
    }, [props]);

    return (
        <>
            <DropDownPicker
                open={open}
                setOpen={setOpen}
                items={items}
                setItems={setItems}
                value={value}
                setValue={setValue}
                onChangeValue={props.onSelectionChanged}
                placeholder={props.placeholder ?? "select item"}
                zIndex={99999}
                style={[
                    props.currentAppStyle.textInput,
                    {
                        // marginVertical: 16,
                        alignSelf: "center",
                        overflow: "hidden",
                        // zIndex:9,
                        // elevation:9
                    },
                ]}
                listMode={Platform.OS == "web" ? "FLATLIST" : "MODAL"}
                // mode="DEFAULT"
                itemSeparator
                showTickIcon
                textStyle={{
                    color: props.currentAppTheme.colors.coolgray6,
                }}
                listItemLabelStyle={{
                    lineHeight: 16,
                    maxHeight: 32,
                    overflow: "hidden",
                }}
                modalContentContainerStyle={{
                    backgroundColor: props.currentAppTheme.colors.white,
                }}
                modalTitleStyle={{
                    color: props.currentAppTheme.colors.black,
                }}
                // listMode="FLATLIST"
                // autoScroll
                selectedItemLabelStyle={{
                    // backgroundColor: props.currentAppTheme.colors.coolgray2
                    fontWeight: "bold",
                    color: props.currentAppTheme.colors.black,
                }}
                // labelStyle={{
                //     lineHeight:16,
                //     maxHeight: 32,
                //     overflow: "hidden"
                // }}
                labelProps={{
                    numberOfLines: 2,
                    ellipsizeMode: "middle",
                }}
                dropDownContainerStyle={[
                    props.currentAppStyle.textInput,
                    {
                        alignSelf: "center",
                    },
                ]}
            />
        </>
    );
};

export default WnaPicker;
