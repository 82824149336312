import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import { WnaFontFamilies } from "@domain/contracts/theme/WnaFontFamilies";
import WnaButtonHeader from "@ui/components/buttons/WnaButtonHeader";
import WnaBaseScreen from "@ui/components/screens/WnaBaseScreen";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, ScrollView, View, useWindowDimensions } from "react-native";
import RenderHtml from "react-native-render-html";
import WnaLogger from "wna-logger";

const WnaErrorLogScreen: React.FC<WnaStackScreenProps> = (props) => {
    const { currentAppTheme, currentAppStyle } = useContext(WnaAppContext);
    const [isBusy, setIsBusy] = useState(true);
    const [html, setHtml] = useState("");
    const { width: contentWidth } = useWindowDimensions();
    const { t } = useTranslation(["common"]);

    const tagsStyles = {
        body: {
            color: currentAppTheme.colors.coolgray6,
        },
        a: {},
    };

    const htmlStyle = {
        padding: 24,
        fontFamily: WnaFontFamilies.MonoSpace,
        fontSize: 14,
        backgroundColor: currentAppTheme.colors.white,
        color: currentAppTheme.colors.black,
        "> h1": {
            backgroundColor: "red",
        },
    };

    const headerRight = function () {
        return (
            <WnaButtonHeader
                text="Teilen"
                currentAppStyle={currentAppStyle}
                currentAppTheme={currentAppTheme}
                iconName="share-alt"
                onPress={() => WnaLogger.shareLogfileAsync()}
                t={t}
                checkInternetConnection={false}
            />
        );
    };

    useEffect(() => {
        WnaLogger.readLogFileAsync().then((c) => {
            try {
                WnaLogger.start(WnaErrorLogScreen.name, useEffect.name);
                const maxChars = 32768;
                const stripped =
                    c.length > maxChars
                        ? "[...]" + c.substring(c.length - maxChars)
                        : c;
                const reg = new RegExp("\r?\n", "g");
                const htmlText = `<!DOCTYPE html><html lang="de"><body>${stripped}</body></html>`;
                const lineBrokenText = htmlText.replace(reg, "<br /><br />");
                setHtml(lineBrokenText);
            } catch (error) {
                WnaLogger.error(WnaErrorLogScreen.name, useEffect.name, error);
            } finally {
                setIsBusy(false);
                WnaLogger.end(WnaErrorLogScreen.name, useEffect.name);
            }
        });
        props.navigation.setOptions({ headerRight });
    }, []);

    return (
        <WnaBaseScreen
            isBusy={isBusy}
            currentAppStyle={currentAppStyle}
            currentAppTheme={currentAppTheme}>
            <ScrollView style={currentAppStyle.containerFillPage}>
                <View style={[currentAppStyle.containerCenterMaxWidth]}>
                    {Platform.OS === "web" ? (
                        <div
                            style={htmlStyle}
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    ) : (
                        <View style={htmlStyle}>
                            <RenderHtml
                                source={{ html: html }}
                                contentWidth={contentWidth}
                                tagsStyles={tagsStyles}
                            />
                        </View>
                    )}
                </View>
            </ScrollView>
        </WnaBaseScreen>
    );
};
export default WnaErrorLogScreen;
