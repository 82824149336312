import { WnaAppContext } from "@app/WnaAppContext";
import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import WnaUserSettings from "@domain/entities/WnaUserSettings";
import WnaUserSettingsDao from "@infrastructure/dao/WnaUserSettingsDao";
import { getLangCode } from "@infrastructure/i18n/i18n";
import {
    getCatalogIdIdByMapTypeVal,
    getMapTypeValByCatalogId,
} from "@infrastructure/services/catalogMapper/WnaCatalogMapTypeMapper";
import { getGoogleMapsStaticUrl } from "@infrastructure/services/geodata/WnaGoogleMapsStaticUrlProvider";
import { getBoundsOfMapPoints } from "@infrastructure/services/geodata/WnaMapsGraphDataService";
import WnaAsyncFileCacheProvider from "@infrastructure/services/storage/WnaAsyncFileCacheProvider";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import WnaLogger from "wna-logger";

export type WnaMapsGoogleProps = {
    graphData: WnaMapsGraphData;
};

const containerStyle = {
    width: "100%",
    height: "100%",
};

const center = {
    lat: 54.4472012,
    lng: 12.5591166,
};

const mapsApi = {
    id: "google-map-script",
    libraries: ["geometry"],
    googleMapsApiKey: "",
    language: getLangCode(),
};

const WnaMapsGoogle = (props: WnaMapsGoogleProps, ref: any) => {
    const {
        currentAppTheme,
        appSettings,
        currentUserSettings,
        setCurrentUserSettings,
    } = useContext(WnaAppContext);

    useImperativeHandle(ref, () => ({
        getSnapshotImageAsync: async () => {
            return await getSnapshotImageAsync();
        },
    }));

    // These are local methods, they are not seen by `ref`,
    const getSnapshotImageAsync = async () => {
        try {
            return;
            WnaLogger.start(WnaMapsGoogle.name, getSnapshotImageAsync.name);
            const url = getGoogleMapsStaticUrl(
                currentGraphDataRef.current,
                mapsApi.googleMapsApiKey
            );
            if (url != "") {
                const cachedUrl =
                    await WnaAsyncFileCacheProvider.getCachedFileByUrlAsync(
                        url,
                        "png"
                    );
                debugger;
            } else {
                WnaLogger.warn("route is empty");
            }
        } catch (error) {
            WnaLogger.error(
                WnaMapsGoogle.name,
                getSnapshotImageAsync.name,
                error
            );
        } finally {
            WnaLogger.end(WnaMapsGoogle.name, getSnapshotImageAsync.name);
        }
    };

    if (appSettings == null || currentUserSettings == null) return null;

    mapsApi.googleMapsApiKey = appSettings.googleApiKey;

    // @ts-ignore
    const { isLoaded } = useJsApiLoader(mapsApi);
    const [currentMap, setCurrentMap] = React.useState<google.maps.Map | null>(
        null
    );
    const currentGraphDataRef = useRef(new WnaMapsGraphData());
    const [currentPolyline, setCurrentPolyline] =
        useState<google.maps.Polyline | null>(null);
    const [currentStartMarker, setCurrentStartMarker] =
        useState<google.maps.Marker | null>(null);
    const [currentEndMarker, setCurrentEndMarker] =
        useState<google.maps.Marker | null>(null);
    const [currentInfoWindow, setCurrentInfoWindow] =
        useState<google.maps.InfoWindow>();
    const [currentFeaturePoints, setCurrentFeaturePoints] = useState<
        Array<google.maps.Marker>
    >([]);

    // @ts-ignore
    const onLoad = useCallback(
        function callback(map: google.maps.Map) {
            WnaLogger.start(WnaMapsGoogle.name, onLoad.name);
            if (isLoaded) {
                WnaLogger.warn(
                    WnaMapsGoogle.name,
                    onLoad.name,
                    "map is already loaded"
                );
            } else {
                WnaLogger.warn(
                    WnaMapsGoogle.name,
                    onLoad.name,
                    "will load maps"
                );
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: GeolocationPosition) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                                accuracy: position.coords.accuracy,
                                altitude: position.coords.altitude,
                                altitudeAccuracy:
                                    position.coords.altitudeAccuracy,
                                speed: position.coords.speed,
                                heading: position.coords.heading,
                                timestamp: position.timestamp,
                            };
                            // setCurrentLocation(pos);
                            //WnaToastProvider.showSuccess("lat: " + pos.lat + " | lon: " + pos.lng);
                            //WnaLogger.info("pos: " + JSON.stringify(pos));
                            // map.fitBounds(bounds);
                            // map.setCenter(pos);

                            // @ts-ignore

                            // if (currentLocation.lat == null) {

                            // }

                            // if (document.getElementById("panToCurrentLocationButton") == null) {
                            //     debugger
                            //     const btn = createCurrentLocationButton(map);
                            //     const mapElem = document.getElementById("map") as HTMLElement
                            //     const mapInPaneElem = document.getElementById("mapInPane") as HTMLElement
                            //     mapElem.appendChild(btn);
                            //     mapInPaneElem.appendChild(btn);

                            // }

                            // setCurrentLocation(pos);
                        },
                        (err) => {
                            // WnaToastProvider.showError("naaa");
                            WnaLogger.warn(
                                WnaMapsGoogle.name,
                                useCallback.name,
                                `FAILED TO GET LOCATION: ${err}`
                            );
                        }
                    );
                } else {
                    WnaLogger.warn(
                        WnaMapsGoogle.name,
                        onLoad.name,
                        "geolocation not available"
                    );
                }
                // https://cloud.google.com/blog/products/maps-platform/smart-scrolling-comes-to-mobile-web-maps?hl=en

                map.setOptions({ gestureHandling: "auto" });
                map.setMapTypeId(
                    getMapTypeValByCatalogId(currentUserSettings.mapDefaultType)
                );

                google.maps.event.addListener(
                    map,
                    "mapTypeId_changed",
                    async () => {
                        try {
                            const currentMapType = map.getMapTypeId();
                            const newMapTypeId = getCatalogIdIdByMapTypeVal(
                                currentMapType ?? ""
                            );
                            const newUserSettings = new WnaUserSettings(
                                currentUserSettings ?? new WnaUserSettings()
                            );
                            if (
                                newUserSettings.mapDefaultType != newMapTypeId
                            ) {
                                newUserSettings.mapDefaultType = newMapTypeId;
                                await WnaUserSettingsDao.createOrUpdateAsync(
                                    newUserSettings
                                );
                                setCurrentUserSettings(newUserSettings);
                            }
                        } catch (error) {
                            WnaLogger.error(
                                WnaMapsGoogle.name,
                                "maptypeid_changed",
                                error
                            );
                        }
                    }
                );
            }

            setCurrentMap(map);
            WnaLogger.end(WnaMapsGoogle.name, onLoad.name);
        },
        [props]
    );

    useEffect(() => {
        WnaLogger.start(WnaMapsGoogle.name, useEffect.name);

        if (currentMap == null || !isLoaded) {
            WnaLogger.warn(
                WnaMapsGoogle.name,
                useEffect.name,
                "currentMap is null: " +
                    (currentMap == null) +
                    " | isLoaded: " +
                    isLoaded
            );
        } else {
            const currentMapType = getCatalogIdIdByMapTypeVal(
                currentMap.getMapTypeId() ?? ""
            );
            if (currentMapType != currentUserSettings.mapDefaultType)
                currentMap.setMapTypeId(
                    getMapTypeValByCatalogId(currentUserSettings.mapDefaultType)
                );

            if (
                props.graphData.routeHash != "" &&
                props.graphData.routeHash !=
                    currentGraphDataRef.current.routeHash
            )
                loadRouteAsync(currentMap, props.graphData);
            else if (
                props.graphData.routeHash !=
                currentGraphDataRef.current.routeHash
            )
                loadDefault(currentMap);
            // else
            // WnaLogger.warn(WnaMapsGoogle.name, useEffect.name, "props.geoJson == currentGeoJson");
        }
        WnaLogger.end(WnaMapsGoogle.name, useEffect.name);
    }, [props, currentUserSettings, isLoaded, currentMap]);

    const clearMap = () => {
        if (currentStartMarker != null) {
            currentStartMarker.setMap(null);
        }

        if (currentFeaturePoints.length > 0) {
            currentFeaturePoints.forEach((wp) => {
                wp.setMap(null);
            });
        }

        if (currentEndMarker != null) {
            currentEndMarker.setMap(null);
        }

        if (currentPolyline != null) {
            currentPolyline.setMap(null);
            currentPolyline.setPath([]);
        }
    };

    const loadDefault = (map: google.maps.Map) => {
        WnaLogger.start(WnaMapsGoogle.name, loadDefault.name);
        currentGraphDataRef.current = new WnaMapsGraphData();
        clearMap();
        const iWindow = currentInfoWindow ?? new google.maps.InfoWindow();
        setCurrentInfoWindow(iWindow);
        setCurrentPolyline(null);
        map.setCenter(center);
        setCurrentFeaturePoints([]);
        setCurrentStartMarker(null);
        WnaLogger.end(WnaMapsGoogle.name, loadDefault.name);
    };

    const loadRouteAsync = async (
        map: google.maps.Map,
        pGraphData: WnaMapsGraphData
    ) => {
        try {
            WnaLogger.start(WnaMapsGoogle.name, loadRouteAsync.name);
            currentGraphDataRef.current = pGraphData;
            clearMap();
            const iWindow = currentInfoWindow ?? new google.maps.InfoWindow();
            setCurrentInfoWindow(iWindow);
            const showInfoMarker = (fPoint: google.maps.Marker) => {
                iWindow.close();
                iWindow.setContent(fPoint.getTitle());
                iWindow.open(fPoint.getMap(), fPoint);
            };

            const routeCoordinates = [];
            for (let routePoint of pGraphData.routePoints) {
                const pos = {
                    lat: routePoint.lat ?? 0,
                    lng: routePoint.lng ?? 0,
                    altitude: routePoint.altitude ?? 0,
                };

                routeCoordinates.push(pos);
            }

            let p =
                currentPolyline ??
                new google.maps.Polyline({
                    geodesic: true,
                    strokeColor: currentAppTheme.colors.red4,
                    strokeOpacity: 0.75,
                    strokeWeight: 4,
                });
            p.setPath(routeCoordinates);
            setCurrentPolyline(p);
            let bounds = getBoundsOfMapPoints(routeCoordinates);
            let minB = new google.maps.LatLng(bounds.minLat, bounds.minLng);
            let maxB = new google.maps.LatLng(bounds.maxLat, bounds.maxLng);
            let gBounds = new google.maps.LatLngBounds(minB, maxB);
            map.fitBounds(gBounds);
            p.setMap(map);

            let pFeaturePoints = new Array<google.maps.Marker>();
            if (pGraphData.featurePoints.length > 0) {
                let fPointCounter = 1;
                pGraphData.featurePoints.forEach((fp) => {
                    let fPoint = new google.maps.Marker({
                        title: fp.title ?? "",
                        label: {
                            text: fPointCounter.toString(),
                            color: "white",
                        },
                        position: {
                            lat: fp.lat ?? 0,
                            lng: fp.lng ?? 0,
                        },
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 14,
                            fillColor: currentAppTheme.colors.accent4,
                            fillOpacity: 0.7,
                            strokeColor: currentAppTheme.colors.staticWhite,
                            strokeOpacity: 0.7,
                            strokeWeight: 1,
                        },
                    });
                    fPoint.addListener("click", () => showInfoMarker(fPoint));
                    fPointCounter += 1;
                    fPoint.setMap(map);
                    pFeaturePoints.push(fPoint);
                });
            }
            setCurrentFeaturePoints(pFeaturePoints);

            const sMarker =
                currentStartMarker ??
                new google.maps.Marker({
                    label: { text: "A", color: "white" },
                });
            sMarker.setTitle("Start (" + routeCoordinates[0].altitude + " m)");
            sMarker.addListener("click", () => showInfoMarker(sMarker));
            sMarker.setPosition(routeCoordinates[0]);
            sMarker.setMap(map);
            setCurrentStartMarker(sMarker);

            const eMarker =
                currentEndMarker ??
                new google.maps.Marker({
                    label: { text: "B", color: "white" },
                });
            eMarker.setTitle(
                "Ziel (" +
                    routeCoordinates[routeCoordinates.length - 1].altitude +
                    " m)"
            );
            eMarker.setPosition(routeCoordinates[routeCoordinates.length - 1]);
            eMarker.setMap(map);
            eMarker.addListener("click", () => showInfoMarker(eMarker));
            setCurrentEndMarker(eMarker);
        } catch (error) {
            WnaLogger.error(WnaMapsGoogle.name, loadRouteAsync.name, error);
        } finally {
            WnaLogger.end(WnaMapsGoogle.name, loadRouteAsync.name);
        }
    };

    // @ts-ignore
    const onUnmount = React.useCallback(function callback() {
        WnaLogger.start(WnaMapsGoogle.name, onUnmount.name);
        setCurrentMap(null);
        WnaLogger.end(WnaMapsGoogle.name, onUnmount.name);
    }, []);

    return isLoaded ? (
        <>
            <GoogleMap
                id="mapInPane"
                options={{
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    panControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    disableDefaultUI:
                        currentUserSettings?.mapDisableDefaultUI ?? false,
                    // mapTypeId: initialMapType
                }}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                // mapTypeId={initialMapType}
                onLoad={onLoad}
                onUnmount={onUnmount}>
                <></>
            </GoogleMap>
        </>
    ) : null;
};
export default forwardRef(WnaMapsGoogle);
