import WnaEntityBase from "./WnaEntityBase";

export default class WnaUser extends WnaEntityBase {
    public id: string = "";
    public email: string = "";
    public given_name: string = "";
    public name: string = "";
    public picture: string = "";
    public locale: string = "";
    public verified_email: boolean = false;
    public docRefId: string = "";
    public fcmToken: string = "";
    public isBlocked: boolean = false;
    public idToken: string = "";
    public refreshToken: string = "";
    public pictureBase64: string = "";
    public wnaApiJwt: string = "";

    constructor(init?: Partial<WnaUser | null>) {
        super(init);
        Object.assign(this, init);
    }
}
