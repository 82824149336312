import { WnaFontFamilies } from "@domain/contracts/theme/WnaFontFamilies";
import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaText from "@ui/components/text/WnaText";
import { Component } from "react";
import { Platform, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

export type WnaDataViewReadOnlyProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    currentItem: WnaDiaryEntry;
};

class WnaDataViewReadOnly extends Component<WnaDataViewReadOnlyProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaDataViewReadOnlyProps>
    ): boolean {
        return (
            nextProps.currentItem.designator !=
                this.props.currentItem.designator ||
            nextProps.currentItem.comment != this.props.currentItem.comment ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        return (
            <ScrollView
                style={[
                    this.props.currentAppStyle.containerFillPage,
                    { padding: 16 },
                ]}>
                <View
                    style={[
                        this.props.currentAppStyle.containerCenterMaxWidth,
                        {
                            backgroundColor:
                                this.props.currentAppTheme.colors.white,
                            gap: 16,
                            padding: 0,
                            // borderRadius: 8,
                            overflow: "hidden",
                        },
                    ]}>
                    <View style={{ gap: 16, padding: 16 }}>
                        {/*designator*/}
                        <Text
                            style={[this.props.currentAppStyle.textTitleLarge]}
                            textBreakStrategy={"highQuality"}>
                            {this.props.currentItem.designator != ""
                                ? this.props.currentItem.designator
                                : "Ohne Titel"}
                        </Text>

                        {/*comment*/}
                        <WnaText
                            currentAppTheme={this.props.currentAppTheme}
                            currentAppStyle={this.props.currentAppStyle}
                            style={[
                                this.props.currentAppStyle.textSmall,
                                {
                                    // height: "100%"
                                    height: 512,
                                },
                            ]}
                            textBreakStrategy={"highQuality"}
                            text={
                                this.props.currentItem.comment != ""
                                    ? this.props.currentItem.comment
                                    : "Ohne Inhalt"
                            }
                            showHtml
                            fontSize={16}
                            fontFamily={WnaFontFamilies.Courgette}
                            fontColor={this.props.currentAppTheme.colors.black}
                        />
                    </View>
                </View>
                {Platform.OS !== "web" ? ( // needed cause padding / margin not working for android
                    <View style={{ height: 32 }} />
                ) : null}
            </ScrollView>
        );
    }
}

export default WnaDataViewReadOnly;
