import * as FileSystem from "expo-file-system";
import { EncodingType } from "expo-file-system";
import { shareAsync } from "expo-sharing";
import { Platform } from "react-native";
import {
    consoleTransport,
    fileAsyncTransport,
    logger,
} from "react-native-logs";
import mime from "react-native-mime-types";
const config = {
    levels: {
        debug: 0,
        log: 1,
        start: 4,
        end: 4,
        info: 4,
        warn: 5,
        error: 6,
    },
    transport: [consoleTransport],
    transportOptions: {
        mapLevels: {
            debug: "log",
            log: "log",
            info: "info",
            warn: "warn",
            err: "error",
            start: "info",
            enf: "info",
        },
        colors: {
            debug: "white",
            log: "white",
            start: "grey",
            end: "grey",
            info: "blueBright",
            warn: "yellowBright",
            error: "redBright",
        },
        FS: FileSystem,
        fileName: `app.log`, // Create a new file every day
    },
    dateFormat: "iso",
    printDate: false,
    printLevel: true,
    enabled: true,
};

if (Platform.OS != "web") {
    config.printDate = true;
    config.transport.push(fileAsyncTransport);
}

const reactLogger = logger.createLogger<
    "start" | "end" | "info" | "warn" | "error"
>(config);
reactLogger.patchConsole();

export enum LogPrefix {
    start,
    end,
    info,
    warn,
    error,
}

const logFilePath = FileSystem.documentDirectory + "app.log";

class WnaLogger {
    public static isEnabled: boolean = false;

    public static async shareLogfileAsync() {
        const mimeType = mime.lookup("log");
        await shareAsync(logFilePath, {
            dialogTitle: "logfile",
            mimeType: mimeType.toString(),
        });
    }

    public static async deleteLogFileAsync() {
        const info = await FileSystem.getInfoAsync(logFilePath);
        if (info.exists) await FileSystem.deleteAsync(logFilePath);
    }

    public static async readLogFileAsync(): Promise<string> {
        try {
            return await FileSystem.readAsStringAsync(logFilePath, {
                encoding: EncodingType.UTF8,
            });
        } catch {
            return "";
        }
    }

    public static start(msg: string): void;
    public static start(methodName: string, msg: string): void;
    public static start(
        className: string,
        methodName: string,
        msg: unknown
    ): void;
    public static start(
        className?: string,
        methodName?: string,
        msg?: unknown
    ) {
        if (!this.isEnabled) return;

        this.log(LogPrefix.start, msg ?? "", className, methodName);
    }

    public static end(msg: string): void;
    public static end(methodName: string, msg: string): void;
    public static end(
        className: string,
        methodName: string,
        msg: unknown
    ): void;
    public static end(className?: string, methodName?: string, msg?: unknown) {
        if (!this.isEnabled) return;

        this.log(LogPrefix.end, msg ?? "", className, methodName);
    }

    public static info(msg: string): void;
    public static info(methodName: string, msg: string): void;
    public static info(
        className: string,
        methodName: string,
        msg: unknown
    ): void;
    public static info(className?: string, methodName?: string, msg?: unknown) {
        if (!this.isEnabled) return;

        this.log(LogPrefix.info, msg ?? "", className, methodName);
    }

    public static warn(msg: string): void;
    public static warn(methodName: string, msg: string): void;
    public static warn(
        className: string,
        methodName: string,
        msg: unknown
    ): void;
    public static warn(className?: string, methodName?: string, msg?: unknown) {
        this.log(LogPrefix.warn, msg ?? "", className, methodName);
    }

    public static error(msg: unknown): void;
    public static error(methodName: string, msg: unknown): void;
    public static error(
        className: string,
        methodName: string,
        msg: unknown
    ): void;
    public static error(
        className?: string,
        methodName?: string,
        msg?: unknown
    ) {
        this.log(LogPrefix.error, msg, className, methodName);
    }

    private static log(
        prefix: LogPrefix,
        msg: unknown,
        className?: string,
        methodName?: string
    ) {
        let l = "";
        if (className != null && methodName != null) {
            l += className + "." + methodName;
        } else {
            l += className ?? "";
            l += methodName ?? "";
        }

        try {
            if (msg != undefined && msg != "") {
                l += ": " + msg ?? "";
            }

            switch (prefix) {
                case LogPrefix.error:
                    reactLogger.error(l);
                    break;
                case LogPrefix.warn:
                    reactLogger.warn(l);
                    break;
                case LogPrefix.info:
                    reactLogger.info(l);
                    break;
                case LogPrefix.start:
                    reactLogger.start(l);
                    if (Platform.OS === "web") console.time(l);

                    break;
                case LogPrefix.end:
                    if (Platform.OS === "web") console.timeEnd(l);

                    reactLogger.end(l);
                    break;
            }
        } catch (error) {
            reactLogger.error(l + " COULD NOT LOG!!!");
        }
    }
}

export default WnaLogger;
