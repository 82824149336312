import WnaChapter from "@domain/entities/WnaChapter";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const deleteDiaryEntryImageAsync = async (
    wnaUser: WnaUser,
    chapter: WnaChapter,
    diaryEntryIdentifier: string,
    fileName: string
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/${chapter.identifier}/diaryEntries/${diaryEntryIdentifier}/deleteImage/${fileName}`;
    const request = initJsonRequest("DELETE");
    const response = await handleResponseAsync(await fetch(url, request));
    return new WnaDiaryEntry(await response.json());
};

export default deleteDiaryEntryImageAsync;
