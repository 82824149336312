import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaListCardWhiteDecent from "@ui/components/cards/WnaListCardWhiteDecent";
import React, { Component } from "react";
import { Text, View } from "react-native";

export type WnaSettingsItemFrameProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    text: string;
    icon: string;
    children: React.ReactNode;
};

class WnaSettingsItemFrame extends Component<WnaSettingsItemFrameProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaSettingsItemFrameProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.text != this.props.text
        );
    }

    render() {
        return (
            <View
                style={[
                    this.props.currentAppStyle.containerCenterMaxWidth,
                    { borderRadius: 8, padding: 0 },
                ]}>
                <WnaListCardWhiteDecent
                    currentAppTheme={this.props.currentAppTheme}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            gap: 16,
                            alignContent: "center",
                        }}>
                        <View
                            style={{
                                flex: 1,
                                gap: 16,
                            }}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    gap: 16,
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <FontAwesome5
                                    name={this.props.icon}
                                    size={20}
                                    color={
                                        this.props.currentAppTheme.colors
                                            .accent5
                                    }
                                />
                                <Text
                                    style={[
                                        this.props.currentAppStyle
                                            .textTitleLarge,
                                        {
                                            color: this.props.currentAppTheme
                                                .colors.black,
                                            alignSelf: "center",
                                        },
                                    ]}
                                    textBreakStrategy={"highQuality"}
                                    numberOfLines={1}
                                    ellipsizeMode={"tail"}>
                                    {this.props.text.replace(/\.[^.$]+$/, "")}
                                </Text>
                            </View>
                            {this.props.children}
                        </View>
                    </View>
                </WnaListCardWhiteDecent>
            </View>
        );
    }
}

export default WnaSettingsItemFrame;
