import { WnaDarkThemeColors } from "@domain/contracts/theme/WnaDarkThemeColors";
import { WnaLightThemeColors } from "@domain/contracts/theme/WnaLightThemeColors";
import { WnaStaticColors } from "@domain/contracts/theme/WnaStaticColors";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaAppSettings from "@domain/entities/WnaAppSettings";
import { shadeHexColor } from "@infrastructure/services/colors/WnaColorConverter";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

const isValidHexColor = (clr: string) => {
    const reg = /^#([0-9a-f]{3}){1,2}$/i;
    return reg.test(clr);
};

const getThemedColors = (
    appSettings: WnaAppSettings | null,
    themeId: Int32,
    isCurrentSystemThemeDark: boolean
) => {
    WnaLogger.info("getThemedColors", "themeId: " + themeId);

    if (appSettings != null) {
        if (
            appSettings.colorAccent != "" &&
            isValidHexColor(appSettings.colorAccent)
        ) {
            WnaStaticColors.staticAccent1 = shadeHexColor(
                appSettings.colorAccent,
                0.8
            );
            WnaStaticColors.staticAccent2 = shadeHexColor(
                appSettings.colorAccent,
                0.6
            );
            WnaStaticColors.staticAccent3 = shadeHexColor(
                appSettings.colorAccent,
                0.4
            );
            WnaStaticColors.staticAccent4 = shadeHexColor(
                appSettings.colorAccent,
                0.2
            );
            WnaStaticColors.staticAccent5 = shadeHexColor(
                appSettings.colorAccent,
                0
            );
            WnaStaticColors.staticAccent6 = shadeHexColor(
                appSettings.colorAccent,
                -0.2
            );
            WnaStaticColors.staticAccent7 = shadeHexColor(
                appSettings.colorAccent,
                -0.4
            );
            WnaStaticColors.staticAccent8 = shadeHexColor(
                appSettings.colorAccent,
                -0.6
            );

            WnaLightThemeColors.colors.notification =
                WnaStaticColors.staticAccent5;

            WnaLightThemeColors.colors.staticAccent1 =
                WnaStaticColors.staticAccent1;
            WnaLightThemeColors.colors.staticAccent2 =
                WnaStaticColors.staticAccent2;
            WnaLightThemeColors.colors.staticAccent3 =
                WnaStaticColors.staticAccent3;
            WnaLightThemeColors.colors.staticAccent4 =
                WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.staticAccent5 =
                WnaStaticColors.staticAccent5;
            WnaLightThemeColors.colors.staticAccent6 =
                WnaStaticColors.staticAccent6;
            WnaLightThemeColors.colors.staticAccent7 =
                WnaStaticColors.staticAccent7;
            WnaLightThemeColors.colors.staticAccent8 =
                WnaStaticColors.staticAccent8;

            WnaLightThemeColors.colors.accent = WnaStaticColors.staticAccent5;

            // backgrounnd
            // WnaLightThemeColors.colors.background= WnaStaticColors.staticCoolgray2;

            WnaLightThemeColors.colors.card = WnaStaticColors.staticWhite;
            WnaLightThemeColors.colors.text = WnaStaticColors.staticBlack;

            WnaLightThemeColors.colors.border = WnaStaticColors.staticCoolgray2;
            WnaLightThemeColors.colors.notification =
                WnaStaticColors.staticWhite;

            WnaLightThemeColors.colors.primary = WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.primaryContainer =
                WnaStaticColors.staticAccent4;

            WnaLightThemeColors.colors.secondary =
                WnaStaticColors.staticAccent3;
            WnaLightThemeColors.colors.secondaryContainer =
                WnaStaticColors.staticCoolgray4;

            WnaLightThemeColors.colors.tertiary = WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.tertiaryContainer =
                WnaStaticColors.yellow7;

            WnaLightThemeColors.colors.surface = WnaStaticColors.staticWhite;
            WnaLightThemeColors.colors.surfaceVariant =
                WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.surfaceDisabled =
                WnaStaticColors.yellow7;

            WnaLightThemeColors.colors.error = WnaStaticColors.red5;
            WnaLightThemeColors.colors.errorContainer = WnaStaticColors.red2;

            WnaLightThemeColors.colors.onPrimary = WnaStaticColors.staticWhite;
            WnaLightThemeColors.colors.onPrimaryContainer =
                WnaStaticColors.yellow7;

            WnaLightThemeColors.colors.onSecondary = WnaStaticColors.yellow7;
            WnaLightThemeColors.colors.onSecondaryContainer =
                WnaStaticColors.staticCoolgray6;
            WnaLightThemeColors.colors.onTertiary = WnaStaticColors.yellow7;
            WnaLightThemeColors.colors.onTertiaryContainer =
                WnaStaticColors.yellow7;

            WnaLightThemeColors.colors.onSurface = WnaStaticColors.staticBlack;
            WnaLightThemeColors.colors.onSurfaceVariant =
                WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.onSurfaceDisabled =
                WnaStaticColors.yellow7;
            WnaLightThemeColors.colors.onError = WnaStaticColors.staticWhite;
            WnaLightThemeColors.colors.onErrorContainer =
                WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.onBackground =
                WnaStaticColors.staticBlack;

            WnaLightThemeColors.colors.outline = WnaStaticColors.yellow7;
            WnaLightThemeColors.colors.outlineVariant = WnaStaticColors.yellow7;
            WnaLightThemeColors.colors.inverseSurface = WnaStaticColors.yellow7;
            WnaLightThemeColors.colors.inverseOnSurface =
                WnaStaticColors.yellow7;

            WnaLightThemeColors.colors.inversePrimary =
                WnaStaticColors.staticAccent6;
            WnaLightThemeColors.colors.shadow = "#000000";
            WnaLightThemeColors.colors.scrim = WnaStaticColors.staticAccent4;
            ////WnaLightThemeColors.colors.backdrop = WnaStaticColors.staticAccent4;

            // WnaLightThemeColors.colors.background = WnaStaticColors.staticWhite;

            WnaLightThemeColors.colors.accent1 = WnaStaticColors.staticAccent1;
            WnaLightThemeColors.colors.accent2 = WnaStaticColors.staticAccent2;
            WnaLightThemeColors.colors.accent3 = WnaStaticColors.staticAccent3;
            WnaLightThemeColors.colors.accent4 = WnaStaticColors.staticAccent4;
            WnaLightThemeColors.colors.accent5 = WnaStaticColors.staticAccent5;
            WnaLightThemeColors.colors.accent6 = WnaStaticColors.staticAccent6;
            WnaLightThemeColors.colors.accent7 = WnaStaticColors.staticAccent7;
            WnaLightThemeColors.colors.accent8 = WnaStaticColors.staticAccent8;

            // DARK
            WnaDarkThemeColors.colors.staticAccent1 =
                WnaStaticColors.staticAccent1;
            WnaDarkThemeColors.colors.staticAccent2 =
                WnaStaticColors.staticAccent2;
            WnaDarkThemeColors.colors.staticAccent3 =
                WnaStaticColors.staticAccent3;
            WnaDarkThemeColors.colors.staticAccent4 =
                WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.staticAccent5 =
                WnaStaticColors.staticAccent5;
            WnaDarkThemeColors.colors.staticAccent6 =
                WnaStaticColors.staticAccent6;
            WnaDarkThemeColors.colors.staticAccent7 =
                WnaStaticColors.staticAccent7;
            WnaDarkThemeColors.colors.staticAccent8 =
                WnaStaticColors.staticAccent8;

            WnaDarkThemeColors.colors.primary = WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.accent = WnaStaticColors.staticAccent5;

            WnaDarkThemeColors.colors.accent = WnaStaticColors.staticAccent5;

            // backgrounnd
            // WnaDarkThemeColors.colors.background= WnaStaticColors.staticCoolgray6;

            WnaDarkThemeColors.colors.card = WnaStaticColors.staticBlack;
            WnaDarkThemeColors.colors.text = WnaStaticColors.staticWhite;

            WnaDarkThemeColors.colors.border = WnaStaticColors.staticAccent6;
            WnaDarkThemeColors.colors.notification =
                WnaStaticColors.staticBlack;

            WnaDarkThemeColors.colors.primary = WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.primaryContainer =
                WnaStaticColors.staticAccent4;

            WnaDarkThemeColors.colors.secondary = WnaStaticColors.staticAccent3;
            WnaDarkThemeColors.colors.secondaryContainer =
                WnaStaticColors.staticCoolgray4;

            WnaDarkThemeColors.colors.tertiary = WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.tertiaryContainer =
                WnaStaticColors.yellow7;

            WnaDarkThemeColors.colors.surface = WnaStaticColors.staticBlack;
            WnaDarkThemeColors.colors.surfaceVariant =
                WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.surfaceDisabled = WnaStaticColors.yellow7;

            WnaDarkThemeColors.colors.error = WnaStaticColors.red5;
            WnaDarkThemeColors.colors.errorContainer = WnaStaticColors.red2;

            WnaDarkThemeColors.colors.onPrimary = WnaStaticColors.staticBlack;
            WnaDarkThemeColors.colors.onPrimaryContainer =
                WnaStaticColors.yellow7;

            WnaDarkThemeColors.colors.onSecondary = WnaStaticColors.yellow7;
            WnaDarkThemeColors.colors.onSecondaryContainer =
                WnaStaticColors.staticCoolgray2;
            WnaDarkThemeColors.colors.onTertiary = WnaStaticColors.yellow7;
            WnaDarkThemeColors.colors.onTertiaryContainer =
                WnaStaticColors.yellow7;

            WnaDarkThemeColors.colors.onSurface = WnaStaticColors.staticWhite;
            WnaDarkThemeColors.colors.onSurfaceVariant =
                WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.onSurfaceDisabled =
                WnaStaticColors.yellow7;
            WnaDarkThemeColors.colors.onError = WnaStaticColors.staticBlack;
            WnaDarkThemeColors.colors.onErrorContainer =
                WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.onBackground =
                WnaStaticColors.staticWhite;

            WnaDarkThemeColors.colors.outline = WnaStaticColors.yellow7;
            WnaDarkThemeColors.colors.outlineVariant = WnaStaticColors.yellow7;
            WnaDarkThemeColors.colors.inverseSurface = WnaStaticColors.yellow7;
            WnaDarkThemeColors.colors.inverseOnSurface =
                WnaStaticColors.yellow7;

            WnaDarkThemeColors.colors.inversePrimary =
                WnaStaticColors.staticAccent6;
            WnaDarkThemeColors.colors.shadow = "#000000";
            WnaDarkThemeColors.colors.scrim = WnaStaticColors.staticAccent4;
            ////WnaLightThemeColors.colors.backdrop = WnaStaticColors.staticAccent4;

            // WnaLightThemeColors.colors.background = WnaStaticColors.staticWhite;

            WnaDarkThemeColors.colors.card = WnaStaticColors.staticBlack;
            WnaDarkThemeColors.colors.text = WnaStaticColors.staticWhite;
            WnaDarkThemeColors.colors.border = WnaStaticColors.staticCoolgray6;
            WnaDarkThemeColors.colors.notification =
                WnaStaticColors.staticBlack;

            WnaDarkThemeColors.colors.accent1 = WnaStaticColors.staticAccent8;
            WnaDarkThemeColors.colors.accent2 = WnaStaticColors.staticAccent7;
            WnaDarkThemeColors.colors.accent3 = WnaStaticColors.staticAccent6;
            WnaDarkThemeColors.colors.accent4 = WnaStaticColors.staticAccent5;
            WnaDarkThemeColors.colors.accent5 = WnaStaticColors.staticAccent4;
            WnaDarkThemeColors.colors.accent6 = WnaStaticColors.staticAccent3;
            WnaDarkThemeColors.colors.accent7 = WnaStaticColors.staticAccent2;
            WnaDarkThemeColors.colors.accent8 = WnaStaticColors.staticAccent1;
        }
    }

    switch (themeId) {
        case 1:
            // WnaLogger.info("returning light theme");
            return WnaLightThemeColors;
        case 2:
            // WnaLogger.info("returning dark theme");
            return WnaDarkThemeColors;
        default: {
            // WnaLogger.info("returning default theme");
            const systemThemeColors = {} as WnaTheme;
            Object.assign(
                systemThemeColors,
                isCurrentSystemThemeDark
                    ? WnaDarkThemeColors
                    : WnaLightThemeColors
            );
            systemThemeColors.id = themeId;
            systemThemeColors.dark = isCurrentSystemThemeDark;
            return systemThemeColors;
        }
    }
};

export { WnaStaticColors, getThemedColors };
