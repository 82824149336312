import WnaLogger from "wna-logger";

export default class WnaDialogProvider {
    private static _isDialogVisible: boolean = false;
    private static _setIsVisible: (isVisible: boolean) => void;
    private static _setShowCancel: (isVisible: boolean) => void;
    private static _onOk: () => void;
    private static _onCancel: () => void;
    public static text: string = "";

    public static showOk(text: string, onOk: () => void = () => {}) {
        if (WnaDialogProvider._isDialogVisible) return;

        WnaLogger.start(
            WnaDialogProvider.name,
            WnaDialogProvider.showOk.name,
            text
        );
        WnaDialogProvider._setShowCancel(false);
        WnaDialogProvider.text = text;
        WnaDialogProvider._onOk = onOk;
        WnaDialogProvider._onCancel = () => {};
        WnaDialogProvider._setIsVisible(true);
        WnaDialogProvider._isDialogVisible = true;
        WnaLogger.end(
            WnaDialogProvider.name,
            WnaDialogProvider.showOk.name,
            text
        );
    }

    public static async showYesNoAsync(text: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (WnaDialogProvider._isDialogVisible) return reject;

            WnaLogger.start(
                WnaDialogProvider.name,
                WnaDialogProvider.showYesNoAsync.name,
                text
            );
            WnaDialogProvider._setShowCancel(true);
            WnaDialogProvider.text = text;
            WnaDialogProvider._onOk = () => {
                resolve(true);
            };
            WnaDialogProvider._onCancel = () => {
                resolve(false);
            };
            WnaDialogProvider._setIsVisible(true);
            WnaDialogProvider._isDialogVisible = true;
            WnaLogger.end(
                WnaDialogProvider.name,
                WnaDialogProvider.showYesNoAsync.name,
                text
            );
        });
    }

    public static showYesNo(text: string, onOk: () => void) {
        if (WnaDialogProvider._isDialogVisible) return;

        WnaLogger.start(
            WnaDialogProvider.name,
            WnaDialogProvider.showYesNo.name,
            text
        );
        WnaDialogProvider._setShowCancel(true);
        WnaDialogProvider.text = text;
        WnaDialogProvider._onOk = onOk;
        WnaDialogProvider._onCancel = () => {};
        WnaDialogProvider._setIsVisible(true);
        WnaDialogProvider._isDialogVisible = true;
        WnaLogger.end(
            WnaDialogProvider.name,
            WnaDialogProvider.showYesNo.name,
            text
        );
    }

    public static onOk() {
        WnaLogger.start(
            WnaDialogProvider.name,
            WnaDialogProvider.onOk.name,
            WnaDialogProvider.text
        );
        WnaDialogProvider._onOk();
        WnaDialogProvider._setIsVisible(false);
        WnaDialogProvider._isDialogVisible = false;
        WnaLogger.end(
            WnaDialogProvider.name,
            WnaDialogProvider.onOk.name,
            WnaDialogProvider.text
        );
    }
    public static onCancel() {
        WnaLogger.start(
            WnaDialogProvider.name,
            WnaDialogProvider.onCancel.name,
            WnaDialogProvider.text
        );
        WnaDialogProvider._onCancel();
        WnaDialogProvider._setIsVisible(false);
        WnaDialogProvider._isDialogVisible = false;
        WnaLogger.end(
            WnaDialogProvider.name,
            WnaDialogProvider.onCancel.name,
            WnaDialogProvider.text
        );
    }

    public static init(
        setIsVisible: (isVisible: boolean) => void,
        setShowCancel: (isVisible: boolean) => void
    ) {
        // WnaLogger.start(WnaDialogProvider.name, WnaDialogProvider.init.name);
        WnaDialogProvider._setIsVisible = setIsVisible;
        WnaDialogProvider._setShowCancel = setShowCancel;
        // WnaLogger.end(WnaDialogProvider.name, WnaDialogProvider.init.name);
    }
}
