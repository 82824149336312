import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStaticColors } from "@domain/contracts/theme/WnaStaticColors";
import { useContext } from "react";
import { View } from "react-native";

export default function WnaSeparatorHorizontal() {
    const { currentAppStyle } = useContext(WnaAppContext);
    return (
        <View
            style={[
                currentAppStyle.separatorHorizontal,
                { backgroundColor: WnaStaticColors.staticCoolgray4 },
            ]}
        />
    );
}
