import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import { Component } from "react";
import { View } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

export type WnaProfileIconProps = {
    currentAppTheme: WnaTheme;
    iconSize?: Int32;
};

class WnaProfileIcon extends Component<WnaProfileIconProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaProfileIconProps>): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        const iconSize = this.props.iconSize ?? 32;
        const faSize = iconSize * 0.56;
        return (
            <View
                style={[
                    {
                        width: iconSize,
                        height: iconSize,
                        maxHeight: iconSize,
                        backgroundColor:
                            this.props.currentAppTheme.colors.staticCoolgray4,
                        overflow: "hidden",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                ]}>
                <FontAwesome5
                    name={"user"}
                    size={faSize}
                    color={this.props.currentAppTheme.colors.staticWhite}
                />
            </View>
        );
    }
}

export default WnaProfileIcon;
