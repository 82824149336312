import WnaChapter from "@domain/entities/WnaChapter";
import WnaDiaryEntry from "@domain/entities/WnaDiaryEntry";
import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const updateDiaryEntryAsync = async (
    wnaUser: WnaUser,
    chapter: WnaChapter,
    diaryEntry: WnaDiaryEntry
) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/${chapter.identifier}/diaryEntries/update`;
    const request = initJsonRequest("PUT");
    request.body = JSON.stringify(diaryEntry);
    const response = await handleResponseAsync(await fetch(url, request));
    return new WnaDiaryEntry(await response.json());
};

export default updateDiaryEntryAsync;
