import { WnaNavigationStackPropsMap } from "@app/WnaNavigationStackPropsMap";

export const WnaApplicationConstants = {
    AppBaseUrl: "",

    // StartPage
    ScreenNameStartPage:
        "WnaStartPageScreen" as keyof WnaNavigationStackPropsMap,
    // Notification
    ScreenNameNotificationList:
        "WnaNotificationListScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameNotificationDetails:
        "WnaNotificationDetailsScreen" as keyof WnaNavigationStackPropsMap,
    // Appointments
    ScreenNameAppointmentList:
        "WnaAppointmentListScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameAppointmentDetails:
        "WnaAppointmentDetailsScreen" as keyof WnaNavigationStackPropsMap,
    // Accommodation
    ScreenNameAccommodation:
        "WnaAccommodationScreen" as keyof WnaNavigationStackPropsMap,
    // Chapter
    ScreenNameChapterList:
        "WnaChapterListScreen" as keyof WnaNavigationStackPropsMap,
    // ChapterEdit
    ViewNameChapterEdit: "WnaChapterEditView" as string,
    // Diary
    ScreenNameDiaryEntryList:
        "WnaDiaryEntryListScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameDiaryEntryDetail:
        "WnaDiaryEntryDetailScreen" as keyof WnaNavigationStackPropsMap,
    // Diary Preview
    ScreenNameDiaryPreview:
        "WnaDiaryPreviewScreen" as keyof WnaNavigationStackPropsMap,
    // Location
    ScreenNameLocation: "WnaLocationScreen" as keyof WnaNavigationStackPropsMap,
    // Menu
    ScreenNameMenu: "WnaMenuScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameDisclaimer:
        "WnaDisclaimerScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNamePrivacy: "WnaPrivacyScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameProfile: "WnaProfileScreen" as keyof WnaNavigationStackPropsMap,
    // Settings
    ScreenNameSettings: "WnaSettingsScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameSettingsAdvanced:
        "WnaSettingsAdvancedScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameSettingsDiary:
        "WnaSettingsDiaryScreen" as keyof WnaNavigationStackPropsMap,
    ScreenNameSettingsMap:
        "WnaSettingsMapScreen" as keyof WnaNavigationStackPropsMap,
    // ErrorLog
    ScreenNameErrorLog: "WnaErrorLogScreen" as keyof WnaNavigationStackPropsMap,
    // Admin
    ScreenNameAdmin: "WnaAdminScreen" as keyof WnaNavigationStackPropsMap,
    // Login
    ScreenNameLogIn: "WnaLoginModal",
};
