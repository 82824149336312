import { WnaAppContext } from "@app/WnaAppContext";
import { FontAwesome5 } from "@expo/vector-icons";
import { execWhenOnlineAsync } from "@infrastructure/services/WnaExecWhenOnline";
import { TFunction } from "i18next";
import { useContext } from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";

export type WnaButtonIconDecentProps = {
    iconName: keyof typeof FontAwesome5.glyphMap;
    style?: ViewStyle | ViewStyle[];
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
};

// Use the full props within the actual component
const WnaButtonIconDecent = (props: WnaButtonIconDecentProps) => {
    const { iconName, onPress, t, checkInternetConnection, style } = props;
    const { currentAppTheme } = useContext(WnaAppContext);
    return (
        <Pressable
            style={({ pressed }) => [
                {
                    opacity: pressed ? 0.7 : 1,
                },
                style,
            ]}
            android_ripple={{ color: currentAppTheme.colors.white }}
            onPress={() =>
                execWhenOnlineAsync(checkInternetConnection, t, onPress)
            }>
            <View style={componentStyle.container}>
                <FontAwesome5
                    name={iconName}
                    size={24}
                    color={currentAppTheme.colors.accent5}
                />
            </View>
        </Pressable>
    );
};

const componentStyle = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: 64,
        height: 64,
        minWidth: 0,
    },
    icon: {
        padding: 10,
        margin: 0,
    },
});
export default WnaButtonIconDecent;
