import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaUser from "@domain/entities/WnaUser";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaListCardWhiteDecent from "@ui/components/cards/WnaListCardWhiteDecent";
import WnaImage from "@ui/components/images/WnaImage";
import WnaProfileIcon from "@ui/components/misc/WnaProfileIcon";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View } from "react-native";

export type WnaNavigationItemProfileProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    currentUser: WnaUser | null;
    logInTitle: string;
    onPress: () => void;
    t: TFunction<string[], undefined>;
};

class WnaNavigationItemProfile extends Component<WnaNavigationItemProfileProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaNavigationItemProfileProps>,
        nextState: Readonly<{}>,
        nextContext: any
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.currentUser != this.props.currentUser
        );
    }

    render() {
        const currentUserName = this.props.currentUser?.name ?? "";
        const currentUserId = this.props.currentUser?.id ?? "";
        return (
            <WnaPressable
                style={[
                    this.props.currentAppStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                    },
                ]}
                onPress={this.props.onPress}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteDecent
                    currentAppTheme={this.props.currentAppTheme}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            gap: 16,
                            alignContent: "center",
                            // backgroundColor: "red",
                        }}>
                        {/* icon or image */}
                        <View
                            style={[
                                this.props.currentAppStyle.containerCenter,
                                {
                                    width: 32,
                                    height: 32,
                                    borderRadius: 16,
                                    borderColor:
                                        this.props.currentAppTheme.colors
                                            .staticCoolgray2,
                                    borderWidth: 2,
                                    alignContent: "center",
                                    overflow: "hidden",
                                    marginLeft: -8,
                                },
                            ]}>
                            {this.props.currentUser != null &&
                            this.props.currentUser.id != "" ? (
                                <WnaImage
                                    currentAppTheme={this.props.currentAppTheme}
                                    imageUrl={
                                        this.props.currentUser?.pictureBase64 ??
                                        ""
                                    }
                                    displayLoadingState={false}
                                    style={{
                                        width: 32,
                                        height: 32,
                                    }}
                                />
                            ) : (
                                <WnaProfileIcon
                                    currentAppTheme={this.props.currentAppTheme}
                                />
                            )}
                        </View>
                        {/* text */}
                        <Text
                            style={[
                                this.props.currentAppStyle.textTitleLarge,
                                {
                                    color: this.props.currentAppTheme.colors
                                        .black,
                                    width: "100%",
                                    paddingRight: 32,
                                    alignSelf: "center",
                                },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {currentUserId === ""
                                ? this.props.logInTitle
                                : currentUserName}
                        </Text>
                        {/* arrow */}
                        <View
                            style={{
                                position: "absolute",
                                right: 0,
                                alignSelf: "center",
                            }}>
                            <FontAwesome5
                                name={"angle-right"}
                                size={24}
                                color={
                                    this.props.currentAppTheme.colors.coolgray4
                                }
                            />
                        </View>
                    </View>
                </WnaListCardWhiteDecent>
            </WnaPressable>
        );
    }
}

export default WnaNavigationItemProfile;
