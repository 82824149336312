import { WnaAppContext } from "@app/WnaAppContext";
import { WnaStackScreenProps } from "@app/WnaStackScreenProps";
import WnaAppSettingsDao from "@infrastructure/dao/WnaAppSettingsDao";
import { getLangCode } from "@infrastructure/i18n/i18n";
import WnaHtmlRenderer from "@ui/components/misc/WnaHtmlRenderer";
import WnaBaseScreenWithInfo from "@ui/components/screens/WnaBaseScreenWithInfo";
import React, { useContext, useEffect, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { fallbackHtml } from "./WnaFallBackDisclaimer";

const WnaDisclaimerScreen: React.FC<WnaStackScreenProps> = (props) => {
    const { currentAppTheme, currentAppStyle } = useContext(WnaAppContext);
    const [isBusy, setIsBusy] = useState(true);
    const [html, setHtml] = useState("");
    const { width: contentWidth } = useWindowDimensions();

    useEffect(() => {
        const document =
            getLangCode() === "en"
                ? "disclaimer-en.html"
                : "disclaimer-de.html";
        WnaAppSettingsDao.readResourceAsync(document).then((html) => {
            if (html == "") html = fallbackHtml;

            const utf8decoded = require("utf8").decode(html);
            setHtml(utf8decoded);
            setIsBusy(false);
        });
    }, []);

    return (
        <WnaBaseScreenWithInfo isBusy={isBusy}>
            <ScrollView style={[currentAppStyle.containerFillPage]}>
                <View
                    style={[
                        currentAppStyle.containerCenterMaxWidth,
                        { paddingVertical: 24 },
                    ]}>
                    <View
                        style={{
                            backgroundColor: currentAppTheme.colors.white,
                        }}>
                        <WnaHtmlRenderer
                            currentAppStyle={currentAppStyle}
                            currentAppTheme={currentAppTheme}
                            width={contentWidth}
                            padding={24}
                            html={html}
                            fallbackHtml={fallbackHtml}
                        />
                    </View>
                </View>
            </ScrollView>
        </WnaBaseScreenWithInfo>
    );
};
export default WnaDisclaimerScreen;
