import { WnaMapPoint } from "@domain/contracts/types/WnaMapPoint";
import { WnaMapsGraphData } from "@domain/entities/WnaMapsGraphData";
import { getDurationByMinutes } from "@infrastructure/services/WnaDateTimeService";
import { getSha256HashAsync } from "@infrastructure/services/WnaHashService";
import * as geolib from "geolib";
import { GeolibInputCoordinates } from "geolib/es/types";
import { LatLng } from "react-native-maps";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";

const _tsFileName = "WnaMapsGraphDataService.ts";
const _debugLogging: boolean = false;
const _ignoreUnderNull: boolean = true;
const _defaultMinAltitude: Int32 = -44000;
const _defaultMaxAltitude: Int32 = 44000;
// const _segmentsPerDistanceUnit = 2; // fast 2 = 0 | 0.5
// const _segmentsPerDistanceUnit = 3; // 3 = 0 | 0.333 | 0.667
// const _segmentsPerDistanceUnit = 4; // medium 4 = 0 | 0.25 | 0.5 | 0.75
// const _segmentsPerDistanceUnit = 5; // 5 = 0 | 0.2 | 0.4 | 0.6 | 0.8
// const _segmentsPerDistanceUnit = 6; // 6 = 0 | 0.166 | 0.333 | 0.5 | 0.667 | 0.833
// const _segmentsPerDistanceUnit = 7; // 7 = 0 | 0.166 | 0.333 | 0.5 | 0.667 | 0.833
// const _segmentsPerDistanceUnit = 10; // quality 10 = 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9

const getRegionOfLatLng = (origin: LatLng, destination: LatLng) => {
    const oLat = Math.abs(origin.latitude);
    const oLng = Math.abs(origin.longitude);
    const dLat = Math.abs(destination.latitude);
    const dLng = Math.abs(destination.longitude);
    const z = 0.05;
    return {
        latitude: (origin.latitude + destination.latitude) / 2,
        longitude: (origin.longitude + destination.longitude) / 2,
        latitudeDelta: Math.abs(oLat - dLat) + z,
        longitudeDelta: Math.abs(oLng - dLng) + z,
    };
};
const getDistanceOfMapPoints = (points: Array<WnaMapPoint>) => {
    let dist = 0;
    if (points.length < 2) return dist;

    for (let i = 1; i < points.length; i++) {
        const p1 = points[i - 1];
        const p2 = points[i];
        const pDist = geolib.getDistance(
            {
                latitude: p1.lat ?? 0,
                longitude: p1.lng ?? 0,
            },
            {
                latitude: p2.lat ?? 0,
                longitude: p2.lng ?? 0,
            },
            1
        );
        dist += pDist;
    }

    return dist;
};
const getBoundsOfMapPoints = (points: Array<WnaMapPoint>) => {
    const geoPoints = new Array<GeolibInputCoordinates>();
    points.forEach((p) =>
        geoPoints.push({
            latitude: p.lat ?? 0,
            longitude: p.lng ?? 0,
        })
    );
    return geolib.getBounds(geoPoints);
};
const flatAltitude = (altitude: number, tolerance: Int32) => {
    if (_ignoreUnderNull && altitude < 0) return 0;
    else {
        if (tolerance < 1) return altitude;

        // 123 / 10 = 12.3 --> 12 * 10 = 120
        const altTol = parseInt((altitude / tolerance).toFixed(0));
        return parseInt((altTol * tolerance).toFixed(0));
    }
};
const calcAltUpDownEqualV2 = (
    currentP: WnaMapPoint,
    prevP: WnaMapPoint,
    graphData: WnaMapsGraphData,
    altitudeThresholdInMeters: Int32
) => {
    const prevAlt = prevP.altitude ?? _defaultMinAltitude;
    const currentAlt = currentP.altitude ?? _defaultMinAltitude;

    if (currentAlt == _defaultMinAltitude || prevAlt == _defaultMinAltitude)
        return [0, 0, 0];

    // Aufstieg = prev: 9, current: 10 --> 1
    // Abstieg = prev: 10, current: 9 --> -1
    // Eben = prev: 10, current: 10 --> 0
    const diff = parseInt((currentAlt - prevAlt).toFixed(0));

    // aufstieg / abstieg
    if (diff > altitudeThresholdInMeters) {
        // Aufstieg
        return [
            1,
            parseInt(graphData.infoAltUp.toFixed(0)) + diff,
            currentP.distanceToPreviousAltPoint!,
        ];
    } else if (diff < altitudeThresholdInMeters * -1) {
        // Abstieg
        return [
            -1,
            parseInt(graphData.infoAltDown.toFixed(0)) + diff * -1,
            currentP.distanceToPreviousAltPoint!,
        ];
    } else {
        // Ebene
        return [0, 0, currentP.distanceToPreviousAltPoint!];
    }
};

const getGraphDataByGeoJsonAsync = async (geoJson: string, quality: Int32) => {
    const ret = new WnaMapsGraphData();
    try {
        WnaLogger.start(
            _tsFileName,
            getGraphDataByGeoJsonAsync.name,
            "quality: " + quality
        );
        if (geoJson == "") return ret;

        const altitudeThresholdInMeters = 18; // good value

        ret.geoJson = geoJson;
        const responseJson = JSON.parse(ret.geoJson);
        let rawRoutePoints = responseJson.features[0].geometry.coordinates;
        if (rawRoutePoints === undefined) {
            // kml parsed
            rawRoutePoints =
                responseJson.features[0].geometry.geometries[0].coordinates;
        }

        const routeName = responseJson.features[0].properties.name as string;
        ret.routeName = routeName.replace(" on GPSies.com", "");

        // check raw points empty
        if (rawRoutePoints.length < 1) {
            WnaLogger.warn(
                _tsFileName,
                getGraphDataByGeoJsonAsync.name,
                "rawRoutePoints.length < 1"
            );
            return ret;
        }

        ret.distanceScale = 0.001; // km
        ret.distanceUnitText = "km";
        const distantScaleText = "Kilometer";

        // altitude stuff start
        let prevPointAltitude: Int32 = 0;
        let currentPointAltitude: Int32 = 0;
        let prevPoint = {
            distanceToPreviousPoint: 0,
            distanceToStartPoint: 0,
            altitude: _defaultMinAltitude,
        } as WnaMapPoint;
        let prevAltRefPoint = {
            distanceToPreviousPoint: 0,
            distanceToStartPoint: 0,
            altitude: _defaultMinAltitude,
        } as WnaMapPoint;
        let isAltitudeValid: boolean = true;
        let minAltitude: Int32 = _defaultMaxAltitude;
        let maxAltitude: Int32 = _defaultMinAltitude;
        const segmentStep = parseFloat((1 / quality).toFixed(6));
        let currentXUnit = 1; // from 0 to 1 km
        let currentXUnitSegment = 0; // 0

        for (let pI = 0; pI < rawRoutePoints.length; pI++) {
            const p = rawRoutePoints[pI];
            const rawP = {
                lat: p[1],
                lng: p[0],
                accuracy: 0,
                altitude: p.length > 1 ? p[2] : _defaultMinAltitude,
                altitudeAccuracy: 0,
                distanceToPreviousPoint: 0,
                distanceToPreviousAltPoint: 0,
                distanceToStartPoint: 0,
            } as WnaMapPoint;

            if (pI > 0) {
                const distPrevPoint = getDistanceOfMapPoints([rawP, prevPoint]);
                rawP.distanceToPreviousPoint = distPrevPoint;
                rawP.distanceToPreviousAltPoint! += distPrevPoint;
                rawP.distanceToStartPoint =
                    prevPoint.distanceToStartPoint! + distPrevPoint;
            }

            // correction yMax
            if (rawP.altitude != null && rawP.altitude > _defaultMinAltitude) {
                if (rawP.altitude < minAltitude)
                    minAltitude = flatAltitude(rawP.altitude, 0);

                if (rawP.altitude > maxAltitude)
                    maxAltitude = flatAltitude(rawP.altitude, 0);

                if (maxAltitude > ret.yAxisMax) ret.yAxisMax += 500;
            } else {
                isAltitudeValid = false;
            }

            ret.routePoints.push(rawP);

            if (rawP.altitude != null && rawP.altitude != _defaultMinAltitude) {
                // some altitude data might be missing ...
                currentPointAltitude = flatAltitude(rawP.altitude, 0);
            }

            if (
                prevPoint.altitude != null &&
                prevPoint.altitude != _defaultMinAltitude
            ) {
                // some altitude data might be missing ...
                prevPointAltitude = flatAltitude(prevPoint.altitude, 0);
            }

            if (prevPointAltitude === _defaultMinAltitude && _debugLogging)
                WnaLogger.warn(
                    _tsFileName,
                    getGraphDataByGeoJsonAsync.name,
                    "pI: " + pI + " | Altitude is null"
                );

            const pointDistFromStart = parseFloat(
                (rawP.distanceToStartPoint! * ret.distanceScale).toFixed(3)
            );
            const segmentDistFromStart = parseFloat(
                (currentXUnit + currentXUnitSegment * segmentStep).toFixed(3)
            );

            if (pI < 1) {
                // first
                if (_debugLogging)
                    WnaLogger.info(
                        _tsFileName,
                        getGraphDataByGeoJsonAsync.name,
                        pointDistFromStart +
                            "pI: " +
                            pI +
                            " | segmentDistanceFromStart: " +
                            segmentDistFromStart +
                            " | cU: " +
                            currentXUnit +
                            " | sC: " +
                            currentXUnitSegment +
                            " | Alt: " +
                            currentPointAltitude +
                            " - ADD LEGEND - FIRST POINT"
                    );
                ret.yAxisData.push(currentPointAltitude);
                ret.xAxisData.push("0");
                currentXUnit = 0; // from 0 to 1
                currentXUnitSegment = 1; // next segment 0,333
                prevPoint = rawP;
                prevAltRefPoint = rawP;
            } else if (pI == rawRoutePoints.length - 1) {
                // last
                if (_debugLogging)
                    WnaLogger.info(
                        _tsFileName,
                        getGraphDataByGeoJsonAsync.name,
                        pointDistFromStart +
                            "pI: " +
                            pI +
                            " | segmentDistanceFromStart: " +
                            segmentDistFromStart +
                            " | cU: " +
                            currentXUnit +
                            " | sC: " +
                            currentXUnitSegment +
                            " | Alt: " +
                            currentPointAltitude +
                            " - ADD LEGEND - LAST POINT"
                    );
                ret.yAxisData.push(currentPointAltitude);
                ret.xAxisData.push("");

                const altUpDown = calcAltUpDownEqualV2(
                    rawP,
                    prevAltRefPoint,
                    ret,
                    altitudeThresholdInMeters
                );
                switch (altUpDown[0]) {
                    case 1:
                        ret.infoAltUp = altUpDown[1];
                        ret.infoAltUpDistance += altUpDown[2];
                        prevAltRefPoint = rawP;
                        break;
                    case -1:
                        ret.infoAltDown = altUpDown[1];
                        ret.infoAltDownDistance += altUpDown[2];
                        prevAltRefPoint = rawP;
                        break;
                    default:
                        ret.infoAltEqual = altUpDown[1];
                        ret.infoAltEqualDistance += altUpDown[2];
                        break;
                }
            } else {
                // between
                if (pointDistFromStart > segmentDistFromStart) {
                    // point is 0.34 | segment is 0.33 --> add prev point because it was 0.33
                    ret.yAxisData.push(prevPointAltitude);
                    if (
                        currentXUnit > 0 &&
                        currentXUnitSegment < 1 &&
                        currentXUnit % 2 == 0
                    ) {
                        ret.xAxisData.push(currentXUnit.toFixed());
                        if (_debugLogging)
                            WnaLogger.info(
                                _tsFileName,
                                getGraphDataByGeoJsonAsync.name,
                                pointDistFromStart +
                                    "pI: " +
                                    pI +
                                    " | segmentDistanceFromStart: " +
                                    segmentDistFromStart +
                                    " | cU: " +
                                    currentXUnit +
                                    " | sC: " +
                                    currentXUnitSegment +
                                    " | Alt: " +
                                    prevPointAltitude +
                                    " - ADD LEGEND"
                            );
                    } else {
                        ret.xAxisData.push("");
                        if (_debugLogging)
                            WnaLogger.info(
                                _tsFileName,
                                getGraphDataByGeoJsonAsync.name,
                                pointDistFromStart +
                                    "pI: " +
                                    pI +
                                    " | segmentDistanceFromStart: " +
                                    segmentDistFromStart +
                                    " | cU: " +
                                    currentXUnit +
                                    " | sC: " +
                                    currentXUnitSegment +
                                    " | Alt: " +
                                    prevPointAltitude +
                                    " - ADD POINT"
                            );
                    }

                    if (currentXUnitSegment < quality - 1) {
                        // next segment
                        currentXUnitSegment += 1;
                    } else {
                        // 1 to 2 km
                        currentXUnit += 1;
                        // segment = 0 --> 2 | 2,333 | 2,667
                        currentXUnitSegment = 0;
                    }

                    const altUpDown = calcAltUpDownEqualV2(
                        rawP,
                        prevAltRefPoint,
                        ret,
                        altitudeThresholdInMeters
                    );
                    switch (altUpDown[0]) {
                        case 1:
                            ret.infoAltUp = altUpDown[1];
                            ret.infoAltUpDistance += altUpDown[2];
                            prevAltRefPoint = rawP;
                            break;
                        case -1:
                            ret.infoAltDown = altUpDown[1];
                            ret.infoAltDownDistance += altUpDown[2];
                            prevAltRefPoint = rawP;
                            break;
                        default:
                            ret.infoAltEqual = altUpDown[1];
                            ret.infoAltEqualDistance += altUpDown[2];
                            break;
                    }

                    prevPoint = rawP;
                } else {
                    if (_debugLogging)
                        WnaLogger.info(
                            _tsFileName,
                            getGraphDataByGeoJsonAsync.name,
                            pointDistFromStart +
                                "pI: " +
                                pI +
                                " | segmentDistanceFromStart: " +
                                segmentDistFromStart +
                                " | cU: " +
                                currentXUnit +
                                " | sC: " +
                                currentXUnitSegment +
                                " | Alt: " +
                                prevPointAltitude +
                                " - IGNORE"
                        );
                }
            }
        }

        if (responseJson.features.length > 1) {
            for (
                let featureIndex = 1;
                featureIndex < responseJson.features.length;
                featureIndex++
            ) {
                const feature = responseJson.features[featureIndex];
                if (feature.geometry.type === "Point") {
                    const fp = {
                        lat: feature.geometry.coordinates[1],
                        lng: feature.geometry.coordinates[0],
                        altitude:
                            feature.geometry.coordinates.length > 1
                                ? feature.geometry.coordinates[2]
                                : _defaultMinAltitude,
                        altitudeAccuracy: 0,
                        distanceToPreviousPoint: 0,
                        distanceToStartPoint: 0,
                    } as WnaMapPoint;

                    // get distance to start-point from start
                    fp.distanceToStartPoint = getDistanceOfMapPoints([
                        ret.routePoints[0],
                        fp,
                    ]);

                    if (
                        feature.properties.name &&
                        feature.properties.name != ""
                    ) {
                        fp.title = feature.properties.name;
                        if (fp.altitude != _defaultMinAltitude)
                            fp.title += " (Höhe: " + fp.altitude + " m)";
                    }

                    ret.featurePoints.push(fp);
                }
            }
        }

        const nOpt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

        ret.infoDistance =
            ret.routePoints[ret.routePoints.length - 1].distanceToStartPoint!;
        ret.infoDistanceText =
            (ret.infoDistance * ret.distanceScale).toLocaleString(
                undefined,
                nOpt
            ) + " km";

        if (isAltitudeValid) {
            // Strecken-Dauer
            ret.infoDistanceDurationInMinutes = ret.infoDistance / (4200 / 60); // tatsächliche Länge / (4,2km/h)

            // niedrigster Punkt
            ret.infoAltMin = minAltitude;
            ret.infoAltMinText = minAltitude.toLocaleString() + " hm";

            // höchster Punkt
            ret.infoAltMax = maxAltitude;
            ret.infoAltMaxText = maxAltitude.toLocaleString() + " hm";

            // Diff höchster / niedrigster
            ret.infoAltDiff = maxAltitude - minAltitude;
            ret.infoAltDiffText = ret.infoAltDiff.toLocaleString() + " hm";

            // Aufstieg
            ret.infoAltUpText = ret.infoAltUp.toLocaleString() + " hm";
            ret.infoAltUpDistanceText =
                (ret.infoAltUpDistance * ret.distanceScale).toLocaleString(
                    undefined,
                    nOpt
                ) + " km";

            // Im Aufstieg: 15 Minuten für 100 Höhenmeter sowie zusätzlich 15 Minuten für 1 km Distanz.
            ret.infoAltUpDurationInMinutes =
                ret.infoAltUp / 100 + 15 + (ret.infoAltUpDistance / 1000) * 15; // 300m pro stunde * tatsächlicher Abstieg

            // Abstieg
            ret.infoAltDownText = ret.infoAltDown.toLocaleString() + " hm";
            ret.infoAltDownDistanceText =
                (ret.infoAltDownDistance * ret.distanceScale).toLocaleString(
                    undefined,
                    nOpt
                ) + " km";
            // Im Abstieg: 15 Minuten für 200 Höhenmeter sowie zusätzlich 15 Minuten für 1 km Distanz.
            ret.infoAltDownDurationInMinutes =
                (ret.infoAltDown / 200) * 15 +
                (ret.infoAltDownDistance / 1000) * 15; // 300m pro stunde * tatsächlicher Abstieg

            // Ebene
            ret.infoAltEqualDistanceText =
                (ret.infoAltEqualDistance * ret.distanceScale).toLocaleString(
                    undefined,
                    nOpt
                ) + " km";
            ret.infoAltEqualDurationInMinutes = ret.infoAltEqual / (4200 / 60);

            // Gesamt Streckendauer

            // Distanz 10km / Aufstiegshöhe 600Hm / Abstiegshöhe 500Hm
            // 10km : 4 = 2,5 Std.
            // 600Hm : 300Hm = 2 Std.
            // 500Hm : 300Hm = 1 Std. (gerundet)
            // Höhenmeter total 3 Std.
            // Grösserer Wert der Höhenmeter von 3 Std. plus (+) die Hälfte des kleineren Wertes (2,5 : 2 = 1,25 Std.) = 4.25 Std

            ret.infoTotalDurationInMinutes =
                ret.infoDistanceDurationInMinutes +
                ret.infoAltUpDurationInMinutes +
                ret.infoAltDownDurationInMinutes;
            ret.infoTotalDurationText = getDurationByMinutes(
                ret.infoTotalDurationInMinutes
            );

            // determine yMin + yMax
            const rMinAltitude =
                parseFloat((minAltitude / 1000).toFixed(1)) * 1000;
            const rMaxAltitude =
                parseFloat((maxAltitude / 1000).toFixed(1)) * 1000;

            if (_debugLogging)
                WnaLogger.info(
                    _tsFileName,
                    getGraphDataByGeoJsonAsync.name,
                    "rMinAltitude: " + rMinAltitude
                );
            if (_debugLogging)
                WnaLogger.info(
                    _tsFileName,
                    getGraphDataByGeoJsonAsync.name,
                    "rMaxAltitude: " + rMaxAltitude
                );

            if (rMinAltitude < 100 && rMaxAltitude < 100) {
                ret.yAxisMin = _ignoreUnderNull ? 0 : -200;
                ret.yAxisMax = 600;
            }

            if (ret.yAxisMin < 0 && rMinAltitude > -10) ret.yAxisMin = 0;

            while (rMinAltitude - ret.yAxisMin > 400) ret.yAxisMin += 100;

            while (ret.yAxisMax - rMaxAltitude > 400) ret.yAxisMax -= 100;

            while ((ret.yAxisMax - ret.yAxisMin) % 200 > 0) ret.yAxisMax += 100;

            if (_debugLogging)
                WnaLogger.info(
                    _tsFileName,
                    getGraphDataByGeoJsonAsync.name,
                    "yMin: " + ret.yAxisMin
                );

            if (_debugLogging)
                WnaLogger.info(
                    _tsFileName,
                    getGraphDataByGeoJsonAsync.name,
                    "yMax: " + ret.yAxisMax
                );
        }

        if (
            ret.routePoints[0].altitude != _defaultMinAltitude &&
            ret.routePoints[ret.routePoints.length - 1].altitude !=
                _defaultMinAltitude
        ) {
            // Niedrige Höhe + kaum Höhenunterschied
            ret.prosaText =
                "Meine heutige Wanderung war voller Überraschungen!\nIch startete auf " +
                ret.routePoints[0].altitude +
                " Metern und erreichte am höchsten Punkt " +
                maxAltitude +
                " Meter. Der tiefste Punkt lag bei " +
                minAltitude +
                " Metern.\nInsgesamt bewältigte ich einen Höhenunterschied von " +
                (maxAltitude - minAltitude) +
                " Metern über eine " +
                (ret.infoDistance * ret.distanceScale).toFixed(2) +
                " " +
                distantScaleText +
                " lange Strecke.\n" +
                "Die Natur zeigte mir, dass es nicht immer die extremen Höhen sind, die den Reiz ausmachen, sondern die Vielfalt und die kleinen Details am Wegesrand.\nJeder Schritt zählte heute! 🌄 🥾\n#Wandern #NaturVielfalt";
        } else {
            ret.prosaText =
                "Heute habe ich eine wundervolle " +
                (ret.infoDistance * ret.distanceScale).toFixed(2) +
                " " +
                distantScaleText +
                " lange Wanderung unternommen. Schritt für Schritt die Natur erleben und den Alltag hinter mir lassen – einfach unbezahlbar. 🚶‍♀️🌲 #Wandern #NaturGenuss";
        }

        ret.routeHash = await getSha256HashAsync(ret.geoJson);
        WnaLogger.info(
            _tsFileName,
            getGraphDataByGeoJsonAsync.name,
            "altitudeData: " +
                ret.yAxisData.length +
                " | legendData: " +
                ret.xAxisData.length +
                " | hash: " +
                ret.routeHash
        );
    } catch (error) {
        WnaLogger.error(_tsFileName, getGraphDataByGeoJsonAsync.name, error);
    } finally {
        WnaLogger.end(
            _tsFileName,
            getGraphDataByGeoJsonAsync.name,
            "quality: " + quality
        );
    }
    return ret;
};

export {
    getBoundsOfMapPoints,
    getDistanceOfMapPoints,
    getGraphDataByGeoJsonAsync,
    getRegionOfLatLng,
};
