import getUnixMinDate from "@domain/contracts/WnaUnixDate";

export default class WnaEntityBase {
    public identifier: string = "";
    public designator: string = "";
    public comment: string = "";
    public parentTypeName: string = "";
    public parentIdentifier = "";
    public dateCreate: Date = getUnixMinDate();
    public dateUpdate: Date = getUnixMinDate();
    public dateRead: Date = getUnixMinDate();
    public dateDelete: Date = getUnixMinDate();

    constructor(init?: Partial<WnaEntityBase | null>) {
        // fix dates
        if (init != null) {
            init.dateCreate = init.dateCreate
                ? init.dateCreate
                : getUnixMinDate();
            init.dateUpdate = init.dateUpdate
                ? init.dateUpdate
                : getUnixMinDate();
            init.dateRead = init.dateRead ? init.dateRead : getUnixMinDate();
            init.dateDelete = init.dateDelete
                ? init.dateDelete
                : getUnixMinDate();
        }
        Object.assign(this, init);
    }
}
