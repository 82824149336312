import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import WnaHtmlRenderer from "@ui/components/misc/WnaHtmlRenderer";
import { Component } from "react";
import { Text, TextStyle } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";

export type WnaTextProps = {
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
    text: string;
    style?: TextStyle | TextStyle[];
    numberOfLines?: Int32;
    ellipseMode?: "clip" | "head" | "middle" | "tail";
    textBreakStrategy?: "balanced" | "simple" | "highQuality";
    showHtml?: boolean;
    fontFamily?: string;
    fontSize?: Int32;
    fontColor?: string;
    maxHeight?: Int32;
};

class WnaText extends Component<WnaTextProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaTextProps>,
        nextState: Readonly<{}>,
        nextContext: any
    ): boolean {
        return (
            nextProps.text != this.props.text ||
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark
        );
    }

    render() {
        const showHtml =
            this.props.showHtml ?? /(<([^>]+)>)/i.test(this.props.text);
        const numberOfLines = this.props.numberOfLines
            ? this.props.numberOfLines
            : 0;
        const ellipseMode = this.props.ellipseMode
            ? this.props.ellipseMode
            : "clip";
        const textBreakStrategy = this.props.textBreakStrategy
            ? this.props.textBreakStrategy
            : "simple";
        const stripHtmlRegEx = /(<([^>]+)>)/gi;
        const fontColor =
            this.props.fontColor ?? this.props.currentAppTheme.colors.black;
        return showHtml ? (
            <WnaHtmlRenderer
                currentAppStyle={this.props.currentAppStyle}
                currentAppTheme={this.props.currentAppTheme}
                width={2048}
                maxHeight={this.props.maxHeight}
                html={this.props.text}
                fontFamily={this.props.fontFamily}
                fontSize={this.props.fontSize}
                fontColor={fontColor}
            />
        ) : (
            <Text
                style={[
                    this.props.style
                        ? this.props.style
                        : this.props.currentAppStyle.textSmall,
                    {
                        color: fontColor,
                    },
                ]}
                textBreakStrategy={textBreakStrategy}
                numberOfLines={numberOfLines}
                ellipsizeMode={ellipseMode}>
                {this.props.text.replace(stripHtmlRegEx, "")}
            </Text>
        );
    }
}

export default WnaText;
