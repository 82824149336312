import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";

export default function WnaTabBarIcon({
    focused,
    icon,
    currentAppTheme,
}: {
    focused: boolean;
    icon: string;
    currentAppTheme: WnaTheme;
}) {
    const clr = focused
        ? currentAppTheme.colors.accent4
        : currentAppTheme.colors.coolgray4;
    const iconSize = focused ? 24 : 20;
    return <FontAwesome5 name={icon} size={iconSize} color={clr} />;
}
