import getUnixMinDate from "@domain/contracts/WnaUnixDate";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaChapter extends WnaEntityBase {
    public imageUrl: string = "";
    public dateStart: Date = getUnixMinDate();
    public dateEnd: Date = getUnixMinDate();

    constructor(init?: Partial<WnaChapter>) {
        super(init);
        if (init != null) {
            if (
                init.dateStart == undefined ||
                new Date(init.dateStart).toISOString() ==
                    getUnixMinDate().toISOString()
            ) {
                init.dateStart = init.dateCreate;
            }
            if (
                init.dateEnd == undefined ||
                new Date(init.dateEnd).toISOString() ==
                    getUnixMinDate().toISOString()
            ) {
                init.dateEnd = init.dateCreate;
            }
        }
        Object.assign(this, init);
    }
}
