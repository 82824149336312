import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaEntityBase from "./WnaEntityBase";

export default class WnaNotification extends WnaEntityBase {
    public fcmToken: string = "";
    public state: Int32 = 0;
    constructor(init?: Partial<WnaNotification>) {
        super(init);
        Object.assign(this, init);
    }
}
