import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const registerFcmTokenAsync = async (wnaUser: WnaUser, fcmToken: string) => {
    if (fcmToken === "") return;

    const url =
        wnaUser.id === ""
            ? `${WnaApiSettings.apiBaseUrl}/fcmTokens/register/${fcmToken}` // register to general
            : `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/fcmTokens/register/${fcmToken}`; // register to general and user

    const request = initJsonRequest("POST");
    request.body = JSON.stringify(fcmToken);
    const response = await handleResponseAsync(await fetch(url, request));
    return response.status == 200;
};

export default registerFcmTokenAsync;
