import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import React, { Component } from "react";
import { View, ViewStyle } from "react-native";

export type WnaListCardWhiteDecentProps = {
    currentAppTheme: WnaTheme;
    children: React.ReactNode;
    style?: ViewStyle | ViewStyle[];
};

class WnaListCardWhiteDecent extends Component<WnaListCardWhiteDecentProps> {
    render() {
        return (
            <View
                style={[
                    {
                        backgroundColor:
                            this.props.currentAppTheme.colors.white,
                        borderRadius: 8,
                        borderColor:
                            this.props.currentAppTheme.colors.coolgray2,
                        borderWidth: 1,
                        paddingVertical: 24,
                        paddingHorizontal: 24,
                    },
                    this.props.style,
                ]}>
                {this.props.children}
            </View>
        );
    }
}

export default WnaListCardWhiteDecent;
