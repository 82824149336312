import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { StatusBar } from "expo-status-bar";
import { Component } from "react";

export type WnaStatusBarProps = {
    currentAppTheme: WnaTheme;
    isVisible: boolean;
};

class WnaStatusBar extends Component<WnaStatusBarProps> {
    shouldComponentUpdate(nextProps: Readonly<WnaStatusBarProps>): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.isVisible != this.props.isVisible
        );
    }

    render() {
        const headerBgColor = this.props.currentAppTheme.dark
            ? this.props.currentAppTheme.colors.accent3
            : this.props.currentAppTheme.colors.accent5;
        return (
            <StatusBar
                animated
                style="light"
                hidden={!this.props.isVisible}
                backgroundColor={headerBgColor}
            />
        );
    }
}

export default WnaStatusBar;
