import WnaChapter from "@domain/entities/WnaChapter";
import WnaUser from "@domain/entities/WnaUser";
import { initJsonRequest } from "@infrastructure/wnaApi/WnaApiRequestInitializer";
import handleResponseAsync from "@infrastructure/wnaApi/WnaApiResponseHandler";
import WnaApiSettings from "@infrastructure/wnaApi/apiSettings/WnaApiSettings";

const deleteChapterAsync = async (wnaUser: WnaUser, chapter: WnaChapter) => {
    const url = `${WnaApiSettings.apiBaseUrl}/users/${wnaUser.id}/chapters/delete`;
    const request = initJsonRequest("DELETE");
    request.body = JSON.stringify(chapter);
    const response = await handleResponseAsync(await fetch(url, request));
    return new WnaChapter(await response.json());
};

export default deleteChapterAsync;
