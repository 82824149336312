import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { execWhenOnlineAsync } from "@infrastructure/services/WnaExecWhenOnline";
import { TFunction } from "i18next";
import { Component } from "react";
import { Pressable, Text, View, ViewStyle } from "react-native";

export type WnaButtonTextDecentProps = {
    currentAppTheme: WnaTheme;
    currentAppStyle: WnaAppStyle;
    text: string;
    textColor?: string;
    style?: ViewStyle | ViewStyle[];
    onPress: () => void;
    checkInternetConnection: boolean;
    t: TFunction<string[], undefined>;
};

class WnaButtonTextDecent extends Component<WnaButtonTextDecentProps> {
    render() {
        const textColor =
            this.props.textColor ?? this.props.currentAppTheme.colors.black;
        return (
            <Pressable
                style={({ pressed }) => [
                    {
                        opacity: pressed ? 0.7 : 1,
                    },
                    this.props.style,
                ]}
                android_ripple={{
                    color: this.props.currentAppTheme.colors.white,
                }}
                onPress={() =>
                    execWhenOnlineAsync(
                        this.props.checkInternetConnection,
                        this.props.t,
                        this.props.onPress
                    )
                }>
                <View
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        maxHeight: 64,
                        height: 64,
                        minWidth: 0,
                    }}>
                    <Text
                        style={{
                            color: textColor,
                            fontSize: 18,
                            textTransform: "uppercase",
                        }}>
                        {this.props.text}
                    </Text>
                </View>
            </Pressable>
        );
    }
}

export default WnaButtonTextDecent;
