import WnaCatalogItem from "@domain/contracts/types/WnaCatalogItem";
import { i18nKeysCommon } from "@infrastructure/i18n/i18nKeys";
import { WnaStaticColors } from "@infrastructure/services/WnaThemeService";

export const WnaCatalogNotificationState: Array<WnaCatalogItem> = [
    {
        id: 0,
        text: i18nKeysCommon.catalogNotificationStateUnread,
        displayColor: WnaStaticColors.burgundy3,
    },
    {
        id: 1,
        text: i18nKeysCommon.catalogNotificationStateRead,
        displayColor: "transparent",
    },
    {
        id: 2,
        text: i18nKeysCommon.catalogNotificationStateDeleted,
        displayColor: "transparent",
    },
];
