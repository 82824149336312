import WnaAppStyle from "@domain/contracts/types/WnaAppStyle";
import { WnaTheme } from "@domain/contracts/types/WnaTheme";
import { FontAwesome5 } from "@expo/vector-icons";
import WnaPressable from "@ui/components/buttons/WnaPressable";
import WnaListCardWhiteDecent from "@ui/components/cards/WnaListCardWhiteDecent";
import { TFunction } from "i18next";
import { Component } from "react";
import { Text, View, ViewStyle } from "react-native";

export type WnaNavigationItemProps = {
    currentAppStyle: WnaAppStyle;
    currentAppTheme: WnaTheme;
    text: string;
    iconName: string;
    onPress: (text: string) => void;
    iconRightName?: string;
    cardStyle?: ViewStyle | ViewStyle[];
    t: TFunction<string[], undefined>;
};

class WnaNavigationItem extends Component<WnaNavigationItemProps> {
    shouldComponentUpdate(
        nextProps: Readonly<WnaNavigationItemProps>
    ): boolean {
        return (
            nextProps.currentAppTheme.dark != this.props.currentAppTheme.dark ||
            nextProps.text != this.props.text
        );
    }

    render() {
        const iconRightName = this.props.iconRightName ?? "angle-right";
        return (
            <WnaPressable
                toolTip=""
                style={[
                    this.props.currentAppStyle.containerCenterMaxWidth,
                    {
                        borderRadius: 8,
                        padding: 0,
                    },
                ]}
                onPress={() => {
                    this.props.onPress(this.props.text);
                }}
                t={this.props.t}
                checkInternetConnection={false}>
                <WnaListCardWhiteDecent
                    currentAppTheme={this.props.currentAppTheme}
                    style={this.props.cardStyle}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "flex-start",
                            gap: 20,
                            alignContent: "center",
                            // backgroundColor: "red"
                        }}>
                        {/* icon */}
                        <FontAwesome5
                            name={this.props.iconName}
                            size={24}
                            color={this.props.currentAppTheme.colors.accent5}
                        />
                        {/* text */}
                        <Text
                            style={[
                                this.props.currentAppStyle.textTitleLarge,
                                {
                                    color: this.props.currentAppTheme.colors
                                        .black,
                                    width: "100%",
                                    paddingRight: 32,
                                    alignSelf: "center",
                                },
                            ]}
                            textBreakStrategy={"highQuality"}
                            numberOfLines={1}
                            ellipsizeMode={"tail"}>
                            {/* {this.props.text.replace(/\.[^.$]+$/, '')} */}
                            {this.props.text}
                        </Text>
                        {/* arrow */}
                        <View
                            style={{
                                position: "absolute",
                                right: 0,
                                alignSelf: "center",
                            }}>
                            <FontAwesome5
                                name={iconRightName}
                                size={24}
                                color={
                                    this.props.currentAppTheme.colors.coolgray4
                                }
                            />
                        </View>
                    </View>
                </WnaListCardWhiteDecent>
            </WnaPressable>
        );
    }
}

export default WnaNavigationItem;
