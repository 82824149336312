import { blobToDataUriAsync } from "@infrastructure/services/WnaBase64Service";
import { Image, Platform } from "react-native";
import { Int32 } from "react-native/Libraries/Types/CodegenTypes";
import WnaLogger from "wna-logger";
import { resizeImageAsync } from "./WnaImageResizer/WnaImageResizer";

export type WnaImageSizeType = {
    width: number;
    height: number;
};

const _tsFileName = "WnaImageService.ts";

const getResizedImageBlobAsync = async (
    fileUri: string,
    width: number,
    height: number,
    pMaxWidth: Int32,
    pMaxHeight: Int32,
    pQuality: Int32
) => {
    try {
        WnaLogger.start(_tsFileName, getResizedImageBlobAsync.name);
        const isLandscape = width > height;
        const maxHeight = isLandscape ? pMaxHeight : pMaxWidth;
        const maxWidth = isLandscape ? pMaxWidth : pMaxHeight;
        if (Platform.OS == "web") {
            const r = await fetch(fileUri);
            if (r.ok) {
                const rawBlob = await r.blob();
                return (await resizeImageAsync(
                    rawBlob,
                    maxWidth,
                    maxHeight,
                    pQuality
                )) as Blob;
            } else {
                return undefined;
            }
        } else {
            return (await resizeImageAsync(
                fileUri,
                maxWidth,
                maxHeight,
                pQuality
            )) as Blob;
        }
    } catch (error) {
        WnaLogger.error(_tsFileName, getResizedImageBlobAsync.name, error);
        return undefined;
    } finally {
        WnaLogger.end(_tsFileName, getResizedImageBlobAsync.name);
    }
};

const getImageSizeByUriAsync = async (
    fileUri: string
): Promise<WnaImageSizeType> => {
    return new Promise((resolve, reject) => {
        Image.getSize(
            fileUri,
            async (width, height) => {
                resolve({
                    width: width,
                    height: height,
                });
            },
            (error) => {
                reject(error);
            }
        );
    });
};

const getImageSizeByBlobAsync = async (fileBlob: Blob) => {
    const dataUrl = await blobToDataUriAsync(fileBlob);
    return await getImageSizeByUriAsync(dataUrl);
};

const minifyImageAsync = async (
    fileUri: string,
    pMaxWidth?: Int32,
    pMaxHeight?: Int32,
    pQuality?: Int32
): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        try {
            WnaLogger.start(_tsFileName, minifyImageAsync.name);
            WnaLogger.info(
                "pMaxWidth: " +
                    pMaxWidth +
                    " | pMaxHeight: " +
                    pMaxHeight +
                    " | pQuality: " +
                    pQuality
            );

            Image.getSize(
                fileUri,
                async (width, height) => {
                    const ppMaxWidth = pMaxWidth ?? width;
                    const ppMaxHeight = pMaxHeight ?? height;
                    const ppQuality = pQuality ?? 100;
                    const miniBlob = await getResizedImageBlobAsync(
                        fileUri,
                        width,
                        height,
                        ppMaxWidth,
                        ppMaxHeight,
                        ppQuality
                    );
                    if (miniBlob != undefined) {
                        resolve(miniBlob);
                        WnaLogger.end(_tsFileName, minifyImageAsync.name);
                    } else {
                        reject();
                        WnaLogger.end(_tsFileName, minifyImageAsync.name);
                    }
                },
                (error) => {
                    WnaLogger.error(_tsFileName, minifyImageAsync.name, error);
                    reject();
                    WnaLogger.end(_tsFileName, minifyImageAsync.name);
                }
            );
        } catch (error) {
            WnaLogger.error(_tsFileName, minifyImageAsync.name, error);
            reject();
        } finally {
            WnaLogger.end(_tsFileName, minifyImageAsync.name);
        }
    });
};

export {
    getImageSizeByBlobAsync,
    getImageSizeByUriAsync,
    getResizedImageBlobAsync,
    minifyImageAsync,
};
